import React, { useState } from "react";

interface ConfirmTaskToggleProps {
  taskId: string;
  setTaskToggle: (value: string, isActive: boolean) => void;
}

export default function ConfirmTaskToggle({
  taskId,
  setTaskToggle,
}: ConfirmTaskToggleProps) {
  const [isActive, setActive] = useState(false);

  const handleClick = async () => {
    setActive(!isActive);
    setTaskToggle(taskId, !isActive);
  };

  return (
    <label className="inline-flex relative items-center mr-5 cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={isActive}
        readOnly
        onClick={handleClick}
      />
      <div className="mr-2 w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600" />

      {!isActive ? (
        <>
          <div className="font-bold bg-gray-400 w-4 h-4 relative rounded-full items-center justify-center mr-1" />

          <span className="ml-2 text-sm font-medium text-gray-900 min-w-[100px]">
            Laisser en attente
          </span>
        </>
      ) : (
        <>
          <div className="font-bold bg-add-blue-dark w-4 h-4 relative rounded-full items-center justify-center mr-1" />
          <span className="ml-2 text-sm font-medium text-gray-900 min-w-[100px]">
            Confirmée
          </span>
        </>
      )}
    </label>
  );
}
