import { useMutation } from "@apollo/client/react";
import { Label, TextInput } from "flowbite-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";

import { SEND_ADMIN_REGISTER_LINK } from "../API/modules/user/resolvers/mutation";
import SpinnerComponent from "../components/shared/SpinnerComponent";

interface AdminRegistrationForm {
  email: string;
}

export default function SendEmailAdminRegistrationForm() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AdminRegistrationForm>();
  const [message, setMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [sendAdminRegisterLink, { loading }] = useMutation(
    SEND_ADMIN_REGISTER_LINK,
    {
      onError: (error) => {
        if (
          error.message.includes(
            "Déjà un utilisateur trouvé avec cette adresse mail",
          )
        ) {
          setErrorMessage("Un utilisateur avec cet e-mail existe déjà");
        } else {
          setErrorMessage(
            "Une erreur inconnue s’est produite. Veuillez réessayer plus tard.",
          );
        }
      },
      onCompleted: (data) => {
        setMessage(data.sendAdminRegisterLink as string);
        setValue("email", "");
      },
    },
  );

  const onSubmit: SubmitHandler<AdminRegistrationForm> = async (values) => {
    setErrorMessage(null);
    setMessage(null);
    try {
      const { data } = await sendAdminRegisterLink({
        variables: { email: values.email },
      });
      setMessage(data && (data.sendAdminRegisterLink as string));
      setValue("email", "");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <form
      className="flex flex-col space-y-6 px-6 p-4 sm:p-6 lg:px-8 xl:pb-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <h2 className="text-xl font-medium underline underline-offset-2 decoration-add-blue-dark">
          Envoyer un formulaire d’inscription pour un nouvel administrateur
        </h2>
      </div>
      <div>
        <div className="mb-2 block">
          <Label
            htmlFor="email"
            value="Saisissez l’email de la personne à ajouter en tant qu’admin :"
          />
        </div>
        <TextInput
          id="email"
          placeholder="name@example.com"
          type="email"
          className="lg:w-1/3 xl:w-1/4"
          {...register("email", { required: true })}
        />
        {errors.email && (
          <span className="text-red-600">L'email est requis</span>
        )}
      </div>

      {errorMessage && (
        <p className="p-4 text-sm text-red-800 rounded-lg bg-red-50">
          {errorMessage}
        </p>
      )}

      {message && (
        <p className="p-4 text-sm text-green-800 rounded-lg bg-green-50">
          {message}
        </p>
      )}

      {!loading ? (
        <div className="w-full">
          <button
            type="submit"
            className="button-add bg-add-blue-dark p-2 font-semibold text-sm"
          >
            Envoyer
          </button>
        </div>
      ) : (
        <SpinnerComponent />
      )}
    </form>
  );
}
