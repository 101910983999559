/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { DocumentNode } from "graphql";
import ReactPaginate from "react-paginate";
import DateCell from "../../components/table/cell-component/DateCell";
import StatusCell from "../../components/table/cell-component/StatusCell";
import UserCell from "../../components/table/cell-component/UserCell";
import {
  EDIT_FRAMEWORK_AGREEMENT,
  REMOVE_FRAMEWORK_AGREEMENT,
} from "../../API/modules/framework-agreement/resolvers/mutations";
import DropdownActionItem from "../../components/table/button/DropdownActionItem";
import ModalFrameworkAgreementRow from "./ModalFrameworkAgreementRow";
import { getOneFrameworkAgreement } from "../utils/QueriesFrameworkAgreement";
import ModalFilterTableFrameworkAgreement from "./ModalFilterTableFrameworkAgreement";
import { useUserContext } from "../../context/userContext";
import { UserRole } from "../../API/models/types";
import {
  FrameworkAgreementData,
  ItemFrameworkAgreement,
  UserInformation,
} from "../models/models";
import TableNotPaginateData from "../../components/table/TableNotPaginateData";

interface Props {
  getOneFrameworkAgreements: (
    value: string,
    value1: FrameworkAgreementData[],
  ) => FrameworkAgreementData;

  getAllFrameworkAgreements: FrameworkAgreementData[];
  refetchQueries: DocumentNode;
  data: ItemFrameworkAgreement[];
}
// Pagination example
// export const LINKS_PER_PAGE = 4;
// export const getQueryVariables = (isNewPage: boolean, page: number) => {
//   const skip = isNewPage ? (page - 1) * LINKS_PER_PAGE : 0;
//   const take = isNewPage ? LINKS_PER_PAGE : 100;
//   const orderBy = { createdAt: "DESC" };
//   return { take, skip, orderBy };
// };
export default function TableFrameworkAgreement(props: Props) {
  const {
    getOneFrameworkAgreements,
    getAllFrameworkAgreements,
    refetchQueries,
    data,
  } = props;
  // contexte user
  const { user } = useUserContext();
  // modal view row table
  const [modalRow, setModalRow] = useState<boolean>(false);
  const [modalItemRow, setModalItemRow] = useState<FrameworkAgreementData>();
  // Modal détail de la tâche : "edit"
  const [, setModalEditRow] = useState<boolean>(false);

  // header table
  const cols = useMemo<ColumnDef<ItemFrameworkAgreement>[]>(
    () => [
      {
        header: "Contrat",
        cell: (row) => row.renderValue(),
        accessorKey: "contract",
        filter: "fuzzy",
      },
      {
        header: "Client",
        cell: (row) => {
          const value: UserInformation = row.getValue() as UserInformation;
          return UserCell(value, false);
        },
        accessorKey: "client",

        enableGlobalFilter: false,
      },
      {
        header: "userName",
        cell: (row) => row.renderValue(),
        accessorKey: "userName",
        filter: "fuzzy",
      },

      {
        header: "Date",
        cell: (row) => {
          const value: Date = row.getValue() as Date;
          return DateCell(
            value.toLocaleDateString("fr-fr", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
            false,
          );
        },
        enableGlobalFilter: false,
        accessorKey: "date",
        sortType: "datetime",
      },
      {
        header: "Statut",
        enableSorting: false,
        cell: (row) => {
          const value: string = row.getValue() as string;
          return StatusCell(value);
        },
        accessorKey: "status",
        enableGlobalFilter: false,
      },
      {
        header: " ",

        cell: (getContext) => {
          return DropdownActionItem({
            pathToEditRow: "/contrat-cadre/edition",
            mutationToEditRow: EDIT_FRAMEWORK_AGREEMENT,
            mutationToRemoveRow: REMOVE_FRAMEWORK_AGREEMENT,
            refetchQueries,
            showDeleteRow: false,
            showEditRow: user.role !== UserRole.CUSTOMER,
            showViewRow: true,
            item:
              getAllFrameworkAgreements.length > 0 &&
              getOneFrameworkAgreements(
                getContext.row.original.id as string,
                getAllFrameworkAgreements as FrameworkAgreementData[],
              ),
            setModal: setModalRow,
            setModalEdit: setModalEditRow,
            setItem: setModalItemRow,
          });
        },
        accessorKey: "",
      },
    ],
    [getOneFrameworkAgreement, getAllFrameworkAgreements],
  );

  // ouverture fermeture fenetre modale pour filtrer la donnée
  const [modalFilter, setModalFilter] = useState(false);
  const [filteredData, setFilteredData] = useState<ItemFrameworkAgreement[]>(
    [],
  );
  // nombre de ligne par page de tableau
  const ROWBYPAGE: number = 6;

  // calcul nombre de page
  const nbPageFloat: number = filteredData && filteredData.length / ROWBYPAGE;

  // nombre de page arrondi à l' entier supérieur
  // const nbPage = Number(nbPageFloat.toFixed(0));
  const nbPage = Math.ceil(nbPageFloat);
  const myFilters: string | null = localStorage.getItem(
    "myFrameworkAgreementsListPage",
  );
  const [page, setPage] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (myFilters && myFilters !== null) {
      setPage(myFilters && JSON.parse(myFilters) && JSON.parse(myFilters).page);
    } else {
      setPage(1);
    }
  }, [myFilters]);

  const handlePageChange = (event: any) => {
    setPage(event.selected + 1);
    localStorage.setItem(
      "myFrameworkAgreementsListPage",
      JSON.stringify({ page: event.selected + 1 }),
    );
  };
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <div className="w-full h-full m-auto">
      <TableNotPaginateData
        data={filteredData}
        columns={cols}
        showFooter
        showNavigation
        showGlobalFilter
        showButtonAddItem={user.role !== UserRole.CUSTOMER}
        showButtonActionItem={false}
        labelButtonAddItem="Ajouter un contrat"
        pathButtonAddItem="/contrat-cadre/creation"
        modalFilter={modalFilter}
        setModalFilter={setModalFilter}
        hiddenColumns={{ userName: false }}
        showFilterButton
        rowByPage={ROWBYPAGE}
        page={page && page}
      />
      <div className="flex flex-row justify-left text-center">
        <div className="basis-4/5 text-left">
          <span className="items-center gap-1 flex flex-row text-xs p-4">
            <p className="text-gray-400 text-xs">Page</p>
            <strong className="flex color:#40788B">{page}</strong>
            <p className="text-gray-400 text-xs">sur</p>
            {nbPage === 0 ? <strong>1</strong> : <strong> {nbPage}</strong>}
          </span>
        </div>
        <div className="basis-1/5">
          {nbPage !== 0 ? (
            <ReactPaginate
              onPageChange={handlePageChange}
              pageRangeDisplayed={ROWBYPAGE}
              pageCount={nbPage}
              forcePage={page ? page - 1 : 0}
              marginPagesDisplayed={2}
              nextLabel=">"
              containerClassName="pagination"
              previousLinkClassName="pagination__link"
              nextLinkClassName="pagination__link"
              disabledClassName="pagination__link--disabled"
              activeClassName="pagination__link--active"
              previousLabel="<"
              onPageActive={() => page}
            />
          ) : null}
        </div>
      </div>

      <ModalFrameworkAgreementRow
        open={modalRow}
        setOpen={setModalRow}
        item={modalItemRow as FrameworkAgreementData}
      />
      <ModalFilterTableFrameworkAgreement
        open={modalFilter}
        setOpen={setModalFilter}
        datas={data as ItemFrameworkAgreement[]}
        setDatas={setFilteredData}
      />
      {/* .... */}
    </div>
  );
}
