import { useMutation } from "@apollo/client";
import { FiDownload } from "react-icons/fi";
import { UserRole } from "../../API/models/types";
import { FilterTask } from "../../pages/models/models";
import SpinnerComponent from "./SpinnerComponent";
import { DOWNLOAD_CSV_TASKS_LIST } from "../../API/modules/task/resolvers/mutations";
import generateFileNameWithDate from "../../pages/utils/generateFileNameWithDate";

interface Props {
  fileName: string;
  userRole: UserRole;
  userId: string;
  filters: FilterTask;
}
export default function DownloadTasksCsvButton(props: Props) {
  const { fileName, userRole, userId, filters } = props;
  const [downloadCsvTasksList, { loading, error: apolloError }] = useMutation(
    DOWNLOAD_CSV_TASKS_LIST,
  );

  const downloadCSV = async () => {
    try {
      const csvData = await downloadCsvTasksList({
        variables: {
          paginationArgs: filters,
          role: userRole,
          userId,
        },
      });

      const csvContent =
        csvData &&
        csvData.data &&
        csvData.data.downloadCsvTasksList &&
        `data:text/csv;charset=utf-8,${csvData.data.downloadCsvTasksList.csvData}`; // Préparer le contenu CSV pour le téléchargement
      const encodedUri = encodeURI(csvContent); // Encoder le contenu CSV
      const link = document.createElement("a"); // Créer un élément <a> pour télécharger le CSV
      link.setAttribute("href", encodedUri);
      const finalFileName = generateFileNameWithDate(fileName, "csv");
      link.setAttribute("download", finalFileName);
      document.body.appendChild(link); // Ajouter l'élément <a> à la page
      link.click(); // Simuler un clic sur l'élément <a> pour lancer le téléchargement
      document.body.removeChild(link); // Retirer l'élément <a> de la page
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  // eslint-disable-next-line no-nested-ternary
  return apolloError ? (
    <p>problème de téléchargement du fichier csv</p>
  ) : loading ? (
    <SpinnerComponent />
  ) : (
    <button
      type="button"
      className="text-xs bg-add-blue-dark button-add font-medium rounded-lg px-4 p-3 text-center inline-flex items-center"
      onClick={downloadCSV}
    >
      <FiDownload className="mr-1" />
      Export
    </button>
  );
}
