import dayjs from "dayjs";
import {
  FindAgendaTasksForAdmin_findAgendaTasksForAdmin,
  FindAgendaTasksForCustomers_findAgendaTasksForCustomers,
  FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin,
  FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries,
  UserRole,
} from "../../../API/models/types";
import { useCalendarContext } from "../../../context/CalendarContext";
import { useUserContext } from "../../../context/userContext";
import {
  FindAgendaTasksForAdmin,
  FindAgendaTasksForCustomers,
  FindAgendaTasksForSuperAdmin,
  FindNotPendingAgendaTasksForSecretaries,
} from "../../utils/QueriesTasks";

export function useTasks(): {
  tasks: Task[];
  loading: boolean;
  error: any;
} {
  const { user } = useUserContext(); // Utiliser le hook useUserContext ici
  const { dayIndex } = useCalendarContext();
  const firstDayOfMonth = dayjs()
    .add(dayIndex, "day")
    .startOf("month")
    .toDate();

  if (user && user.role === UserRole.SUPER_ADMIN) {
    return FindAgendaTasksForSuperAdmin(firstDayOfMonth);
  }
  if (user && user.role === UserRole.CUSTOMER) {
    return FindAgendaTasksForCustomers(user.id as string, firstDayOfMonth);
  }
  if (user && user.role === UserRole.ADMIN) {
    return FindAgendaTasksForAdmin(user.id as string, firstDayOfMonth);
  }
  if (user && user.role === UserRole.SECRETARY) {
    return FindNotPendingAgendaTasksForSecretaries(
      user.id as string,
      firstDayOfMonth,
    );
  }
  return { tasks: [], loading: false, error: undefined };
}

export type Task =
  | FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin
  | FindAgendaTasksForCustomers_findAgendaTasksForCustomers
  | FindAgendaTasksForAdmin_findAgendaTasksForAdmin
  | FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries;
