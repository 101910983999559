import { UserRole } from "../../API/models/types";
import { isSmallScreen } from "../../context/is-small-screen";

import tourTexts_CUSTOMER from "./data-by-user-role/tourTexts_CUSTOMER.json";
import tourTexts_SECRETARY from "./data-by-user-role/tourTexts_SECRETARY.json";
import tourTexts_SUPER_ADMIN from "./data-by-user-role/tourTexts_SUPER_ADMIN.json";
import tourTexts_ADMIN from "./data-by-user-role/tourTexts_ADMIN.json";

interface TourStep {
  id: string;
  title: string;
  text: string[];
  elementId: string | null;
}
interface TourBase {
  id: string;
  beforeShowPromise(): Promise<void>;
  buttons: {
    classes: string;
    text: string;
    type: string;
  }[];
  classes: string;
  highlightClass: string;
  scrollTo: boolean;
  cancelIcon: {
    enabled: boolean;
  };
  title: string;
  text: string[];
}

interface TourWithAttachTo extends TourBase {
  attachTo: { element: string; on: "top" | "bottom" | "left" | "right" };
}

type Tour = TourBase | TourWithAttachTo;

function getAttachTo(
  width: number,
  elementId: string,
): { element: string; on: "top" | "bottom" | "left" | "right" } {
  return {
    element: elementId,
    on: isSmallScreen(width) ? "bottom" : "right",
  };
}

export default function getSteps(width: number, userRole: UserRole) {
  // choisir le bon jeu de données basé sur userRole
  let tourTexts: TourStep[];
  switch (userRole) {
    case UserRole.CUSTOMER:
      tourTexts = tourTexts_CUSTOMER;
      break;
    case UserRole.SECRETARY:
      tourTexts = tourTexts_SECRETARY;
      break;
    case UserRole.SUPER_ADMIN:
      tourTexts = tourTexts_SUPER_ADMIN;
      break;
    case UserRole.ADMIN:
      tourTexts = tourTexts_ADMIN;
      break;
    default:
      tourTexts = [];
  }

  return tourTexts.map((step) => {
    let tour: Tour = {
      id: step.id,
      beforeShowPromise() {
        return new Promise(function resolvePromise(resolve) {
          setTimeout(function timeoutCallback() {
            window.scrollTo(0, 0);
            resolve(undefined);
          }, 500);
        });
      },
      buttons: [
        {
          classes: "shepherd-button-secondary",
          text: "Fermer",
          type: "cancel",
        },
        {
          classes: "shepherd-button-primary",
          text: "Précédent",
          type: "back",
        },
        {
          classes: "shepherd-button-primary",
          text: "Suivant",
          type: "next",
        },
      ],
      classes: "bg-add-blue-dark",
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: step.title,
      text: step.text,
    };

    // Add attachTo only if elementId is not null
    if (step.elementId) {
      tour = {
        ...tour,
        attachTo: getAttachTo(width, step.elementId),
      };
    }

    return tour;
  });
}
