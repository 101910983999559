import React, { useState } from "react";
import { Select } from "flowbite-react";

interface DatePickerUsersSubscriptionChartProps {
  values: string[];
  onChange: (value: string) => void;
}

function DatePickerContractedHoursChart({
  values,
  onChange,
}: DatePickerUsersSubscriptionChartProps) {
  const [selectedDateRange, setSelectedDateRange] = useState("7");

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDateRange(event.target.value);
    onChange(event.target.value);
  };

  const options = values.map((value) => ({
    label: `Les ${value} derniers mois`,
    value,
  }));

  return (
    <span className="text-sm text-gray-600">
      <label htmlFor="select-date-range-contracted-hours-chart">
        <Select
          id="select-date-range-contracted-hours-chart"
          value={selectedDateRange as string}
          onChange={handleSelectChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </label>
    </span>
  );
}

export default DatePickerContractedHoursChart;
