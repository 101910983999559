import React, { useState } from "react";
import dayjs from "dayjs";
import { BiHide } from "react-icons/bi";

interface TaskMonthViewCellProps {
  isPrivate: boolean;
  startDate: Date;
  color: string;
  label: string;
}

function TaskMonthViewCell({
  isPrivate,
  startDate,
  color,
  label,
}: TaskMonthViewCellProps) {
  const [colorTask] = useState(color);
  return isPrivate ? (
    <div className="flex flex-col justify-center items-center md:items-start md:justify-start w-[80%]">
      <div className="flex space-x-2">
        <p className={`text-${colorTask}-600 font-bold text-sm`}>
          {dayjs(startDate).format("H[h]mm")}
        </p>
        <BiHide className="h-5 w-5 fill-add-blue-dark hidden md:block" />
        <p
          className={`text-${colorTask}-600 text-xs xl:text-sm truncate hover:overflow-visible px-1 hidden xl:block`}
        >
          Tâche privée{" "}
        </p>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center md:items-start md:justify-start w-full">
      <p className={`font-bold text-${colorTask}-600 text-sm`}>
        {dayjs(startDate).format("H[h]mm")}
      </p>
      <p
        className={`text-${colorTask}-600 first-letter:uppercase max-h-9 text-xs xl:text-sm truncate hover:overflow-visible px-1 hidden md:block`}
      >
        - {label}
      </p>
    </div>
  );
}

export default TaskMonthViewCell;
