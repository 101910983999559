/* eslint-disable import/no-cycle */
import React, { useState, useCallback } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CalendarHeader from "./components/CalendarHeader";
import MonthView from "./components/Month/MonthView";
import WeekView from "./components/Week/WeekView";

import { useCalendarContext } from "../../context/CalendarContext";
import { useTasks, Task } from "./hook/useTasks";
import SpinnerComponent from "../../components/shared/SpinnerComponent";
import { getMonth } from "./utils/getMonth";
import { getWeek } from "./utils/getWeek";
import getDay from "./utils/getDay";
import DayView from "./components/Day/DayView";
import ButtonFilterTable from "../../components/table/button/ButtonFilterTable";
import ModalFilterCalendar from "./components/ModalFilterCalendar";
import SomethingHappened from "../SomethingHappened";

interface ViewContainerProps {
  currentView: ViewKey;
  month: ReturnType<typeof getMonth>;
  week: ReturnType<typeof getWeek>;
  day: ReturnType<typeof getDay>;
  tasks: Task[];
}

export type ViewKey = "month" | "week" | "day";

function ViewContainer({
  currentView,
  month,
  week,
  day,
  tasks,
}: ViewContainerProps) {
  const viewComponents = {
    month: <MonthView month={month} tasks={tasks} />,
    week: <WeekView tasks={tasks} week={week} />,
    day: <DayView tasks={tasks} day={day} />,
  };

  return <div>{viewComponents[currentView]}</div>;
}

export default function Calendar() {
  dayjs.locale("fr");
  dayjs.extend(utc);

  // context
  const { yearIndex, monthIndex, weekIndex, dayIndex } = useCalendarContext();

  // state
  const { tasks, loading, error } = useTasks();
  // Task to show

  const [currentView, setCurrentView] = useState<ViewKey>("month");
  // ouverture fermeture fenetre modale pour filtrer la donnée
  const [modalFilter, setModalFilter] = useState(false);

  // data filtrer par la modale
  const [filteredData, setFilteredData] = useState<Task[]>([]);

  // View functions
  const handleViewChange = useCallback((view: ViewKey) => {
    setCurrentView(view);
  }, []);

  // Task to render
  const tasksToRender =
    filteredData.length > 0
      ? (filteredData.filter(
          (task: Task) =>
            task.startDate && dayjs.utc(task.startDate).month() === monthIndex,
        ) as Task[])
      : (tasks.filter(
          (task: Task) =>
            task.startDate &&
            dayjs.utc(task.startDate).month() === monthIndex &&
            !task.isPrivate,
        ) as Task[]);

  return !error ? (
    <div className="flex flex-col p-4 bg-gray-100">
      <CalendarHeader onViewChange={handleViewChange} />
      <div className="py-2">
        <ButtonFilterTable
          isOpen={modalFilter as boolean}
          setOpen={setModalFilter as (open: boolean) => void}
        />
      </div>
      <ModalFilterCalendar
        open={modalFilter}
        setOpen={setModalFilter}
        datas={tasks as Task[]}
        setDatas={setFilteredData}
      />
      {!loading ? (
        <ViewContainer
          currentView={currentView}
          month={getMonth(monthIndex, yearIndex)}
          week={getWeek(weekIndex, yearIndex)}
          day={getDay(dayIndex)}
          tasks={tasksToRender}
        />
      ) : (
        <SpinnerComponent />
      )}
    </div>
  ) : (
    <SomethingHappened />
  );
}
