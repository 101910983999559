import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

// eslint-disable-next-line import/prefer-default-export
export const GET_DATA_FOR_SECRETARY_DASHBOARD: DocumentNode = gql`
  query GetDataForSecretaryDashBoard($input: SecretaryDashboardDto!) {
    getDataForSecretaryDashBoard(input: $input) {
      tasks {
        id
        label
        startDate
        plannedDuration
        status
      }
      totalQuoteAgreements
      contractPercentInRelationToLastMonth
      users {
        id
        first_name
        last_name
        email
        avatar_url
      }
      graphData {
        name
        data
      }
      graphX
    }
  }
`;
