import { DocumentNode, gql } from "@apollo/client";

export const DOWNLOAD_CSV_TASKS_LIST: DocumentNode = gql`
  mutation DownloadCsvTasksList(
    $paginationArgs: TaskPaginationDto!
    $role: UserRole!
    $userId: String!
  ) {
    downloadCsvTasksList(
      paginationArgs: $paginationArgs
      role: $role
      userId: $userId
    ) {
      csvData
    }
  }
`;
export const EDIT_TASK: DocumentNode = gql`
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
      }
    }
  }
`;

export const REMOVE_TASK: DocumentNode = gql`
  mutation RemoveTask($id: String!) {
    removeTask(id: $id)
  }
`;

export const CREATED_TASK: DocumentNode = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      task {
        id
      }
    }
  }
`;

export const UPDATE_TASKS: DocumentNode = gql`
  mutation UpdateTasks($updateTasksInput: UpdateTasksInput!) {
    updateTasks(updateTasksInput: $updateTasksInput) {
      id
      label
      status
    }
  }
`;
