import { DocumentNode, gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_USERS: DocumentNode = gql`
  query UsersList {
    usersList {
      id
      admin {
        id
      }
      createdAt
      updatedAt
      first_name
      last_name
      avatar_url
      phone_number
      email
      geographical_area
      siret
      business_name
      experience
      criticity_request
      commentary
      isActive
      isVerified
      role
      taskTypes {
        id
        label
        categoryTaskType {
          id
          createdAt
          updatedAt
          label
        }
      }
    }
  }
`;

export const GET_USER_BY_EMAIL: DocumentNode = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      first_name
      last_name
      email
      role
      avatar_url
      isActive
      admin {
        id
      }
    }
  }
`;

export const GET_ONE_USER_BY_ID: DocumentNode = gql`
  query GetUserByID($id: String!) {
    getUserByID(id: $id) {
      id
      admin {
        id
      }
      createdAt
      updatedAt
      first_name
      last_name
      avatar_url
      phone_number
      email
      activity_sector
      geographical_area
      siret
      business_name
      experience
      criticity_request
      commentary
      isActive
      isVerified
      role
      taskTypes {
        id
        label
        categoryTaskType {
          id
          createdAt
          updatedAt
          label
        }
      }
    }
  }
`;

export const GET_ALL_USERS_SELECT_USER: DocumentNode = gql`
  query getAllUser {
    usersList {
      id
      first_name
      last_name
      avatar_url
      role
      email
      admin {
        id
      }
    }
  }
`;

export const GET_ALL_SECRETARIES_AND_ADMIN_ACTIVE: DocumentNode = gql`
  query secretariesAndAdminList {
    secretariesAndAdminList {
      id
      first_name
      last_name
      avatar_url
      role
      email
      admin {
        id
      }
    }
  }
`;

export const CHECK_IF_EMAIL_ALREADY_EXIST: DocumentNode = gql`
  query CheckEmail($email: String!) {
    checkIfEmailAlreadyExist(email: $email)
  }
`;

export const CUSTOMERS_LIST: DocumentNode = gql`
  query CustomersList {
    customersList {
      id
      first_name
      last_name
      email
      avatar_url
      phone_number
      role
      geographical_area
      business_name
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;
export const CUSTOMERS_LIST_FOR_ADMIN: DocumentNode = gql`
  query CustomersListForAdmin($adminId: String!) {
    customersListForAdmin(adminId: $adminId) {
      id
      first_name
      last_name
      email
      phone_number
      avatar_url
      role
      geographical_area
      business_name
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;
export const CUSTOMERS_LIST_FOR_SECRETARY: DocumentNode = gql`
  query CustomersListForSecretary($secretaryId: String!) {
    customersListForSecretary(secretaryId: $secretaryId) {
      id
      first_name
      last_name
      email
      avatar_url
      phone_number
      role
      geographical_area
      business_name
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;
export const ADMINS_LIST: DocumentNode = gql`
  query AdminsList {
    adminsList {
      id
      first_name
      last_name
      email
      avatar_url
      phone_number
      role
      geographical_area
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;

export const USERS_LIST_WITH_ACTIVE_FRAMEWORK_AGREEMENT: DocumentNode = gql`
  query UsersListWithActiveFrameworkAgreement {
    usersListWithActiveFrameworkAgreement {
      id
      first_name
      last_name
      email
      avatar_url
      role
      geographical_area
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;
export const USERS_LIST_WITH_ACTIVE_QUOTE_AGREEMENT: DocumentNode = gql`
  query UsersListWithActiveQuoteAgreement {
    usersListWithActiveQuoteAgreement {
      id
      first_name
      last_name
      email
      avatar_url
      role
      geographical_area
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;
export const SECRETARIES_LIST_WITH_ACTIVE_QUOTE_AGREEMENT_BY_CUSTOMER: DocumentNode = gql`
  query SecretariesListWithActiveQuoteAgreementByCustomer(
    $customerId: String!
  ) {
    secretariesListWithActiveQuoteAgreementByCustomer(customerId: $customerId) {
      id
      first_name
      last_name
      email
      phone_number
      avatar_url
      role
      geographical_area
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;
export const SECRETARIES_LIST: DocumentNode = gql`
  query SecretariesList {
    secretariesList {
      id
      first_name
      last_name
      email
      avatar_url
      phone_number
      role
      geographical_area
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;
export const SECRETARIES_LIST_FOR_ADMIN: DocumentNode = gql`
  query SecretariesListForAdmin($adminId: String!) {
    secretariesListForAdmin(adminId: $adminId) {
      id
      first_name
      last_name
      email
      phone_number
      avatar_url
      role
      geographical_area
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;
export const SECRETARIES_LIST_FOR_CUSTOMER: DocumentNode = gql`
  query SecretariesListForCustomer($customerId: String!) {
    secretariesListForCustomer(customerId: $customerId) {
      id
      first_name
      last_name
      email
      avatar_url
      phone_number
      role
      geographical_area
      isActive
      phone_number
      admin {
        id
      }
    }
  }
`;
export const GET_INACTIVE_USERS: DocumentNode = gql`
  query GetInactiveUsers {
    getInactiveUsers {
      id
      first_name
      last_name
      role
      isActive
      email
      avatar_url
    }
  }
`;

export const GET_NEW_AFFILIATED_USERS_FOR_ADMIN: DocumentNode = gql`
  query GetNewAffiliatedUsersForAdmin($adminId: String!) {
    getNewAffiliatedUsersForAdmin(adminId: $adminId) {
      id
      first_name
      last_name
      role
      isActive
      email
      avatar_url
    }
  }
`;

export const EXPORT_SECRETARIES_LIST_TO_XLSX: DocumentNode = gql`
  query ExportSecretariesListToXlsx {
    exportSecretariesListToXlsx {
      id
      userName
      email
      phone_number
      geographical_area
      commentary
      experience
      taskTypesStr
    }
  }
`;

export const EXPORT_CUSTOMERS_LIST_TO_XLSX: DocumentNode = gql`
  query ExportCustomersListToXlsx {
    exportCustomersListToXlsx {
      id
      userName
      email
      phone_number
      geographical_area
      commentary
      business_name
      taskTypesStr
    }
  }
`;
