import { DocumentNode, useMutation } from "@apollo/client";
import { Label, TextInput, Textarea } from "flowbite-react";
import React, { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  FrameworkAgreementUpdateInput,
  UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement,
  UserRole,
} from "../../API/models/types";

import {
  GET_ALL_FRAMEWORK_AGREEMENTS,
  GET_ALL_FRAMEWORK_AGREEMENTS_FOR_ADMIN,
  GET_ALL_FRAMEWORK_AGREEMENTS_FOR_USER,
} from "../../API/modules/framework-agreement/resolvers/queries";
import { GET_ALL_USERS_SELECT_USER } from "../../API/modules/user/resolvers/queries";
import SelectUser from "../../components/shared/SelectUser";
import { useUserContext } from "../../context/userContext";

export default function EditFrameworkAgreement() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FrameworkAgreementUpdateInput>();
  // routeur
  const navigate = useNavigate();
  const location = useLocation();
  // contexte user
  const { user } = useUserContext();
  // paramètres passé par le routeur
  const mutationEditRow =
    location &&
    location.state &&
    (location.state.mutationEditRow as DocumentNode | null);
  const itemRow =
    location &&
    location.state &&
    (location.state
      .item as UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement | null);

  // mutation création contrat cadre
  const [updateFrameworkAgreement, { error: BadCredentialsError }] =
    mutationEditRow
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useMutation(mutationEditRow)
      : [() => null, { error: null }];

  // validation formulaire
  const onSubmit: SubmitHandler<FrameworkAgreementUpdateInput> = async (
    values: FrameworkAgreementUpdateInput,
  ) => {
    if (user.id && itemRow) {
      try {
        await updateFrameworkAgreement({
          variables: {
            input: {
              id: itemRow.id,
              label: values.label ? values.label : itemRow.label,
              reference: values.reference
                ? values.reference
                : itemRow.reference,
              url: values.url ? values.url : itemRow.url,
              description: values.description
                ? values.description
                : itemRow.description,
              isActive:
                Object.prototype.hasOwnProperty.call(values, "isActive") &&
                typeof values.isActive === "boolean"
                  ? values.isActive
                  : itemRow.isActive,
              userId: values.userId ? values.userId : itemRow.user.id,
            },
          },
          refetchQueries: [
            // eslint-disable-next-line no-nested-ternary
            user.role === UserRole.SUPER_ADMIN
              ? {
                  query: GET_ALL_FRAMEWORK_AGREEMENTS,
                }
              : user.role === UserRole.ADMIN
              ? {
                  query: GET_ALL_FRAMEWORK_AGREEMENTS_FOR_ADMIN,
                  variables: { adminId: user.id },
                }
              : {
                  query: GET_ALL_FRAMEWORK_AGREEMENTS_FOR_USER,
                  variables: { userId: user.id },
                },
          ],
        });
        navigate("/contrat-cadre");
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error, BadCredentialsError);
      }
    }
  };

  // initialisation checkbox isActive
  const [isActive, setActive] = useState<boolean>(
    itemRow &&
      Object.prototype.hasOwnProperty.call(itemRow, "isActive") &&
      typeof itemRow.isActive === "boolean"
      ? itemRow.isActive
      : false,
  );
  // fonction pour récupérer l'id du client (dans le select) lors de la création d'un contrat cadre
  const setSelectedUserId = (value: string) => {
    setValue("userId", value);
  };
  return (user.role === UserRole.ADMIN || UserRole.SUPER_ADMIN) &&
    itemRow !== null ? (
    <div className="flex w-full h-full flex-col px-5 overflow-y-auto">
      <div className="w-full border-b-2 border-gray-200 mt-6">
        <h2 className="font-semibold">Modifier contrat cadre</h2>
      </div>
      <span className="font-light text-gray-500 text-xs">
        (*) champs obligatoires
      </span>
      <form
        className="lg:flex lg:flex-col w-full lg:py-10 lg:px-14 sm:py-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="sm:py-2 mb-4 lg:w-1/3">
          <Label htmlFor="label">Nom du contrat</Label>
          <TextInput
            id="label"
            defaultValue={itemRow.label as string}
            placeholder="Contrat"
            type="text"
            {...register("label", { required: true })}
          />
          {errors.label && (
            <span className="text-red-600">
              un nom de contrat doit être renseigné
            </span>
          )}
        </div>
        <div className="sm:py-1 mb-4 lg:w-1/4 z-10">
          <Label htmlFor="userId">Choisir un client</Label>
          <Controller
            control={control}
            rules={{ required: true }}
            defaultValue={itemRow.user.id}
            name="userId"
            render={() => (
              <SelectUser
                setSelectedUserId={setSelectedUserId}
                userId={itemRow.user.id as string}
                userRole={UserRole.CUSTOMER}
                query={GET_ALL_USERS_SELECT_USER}
                queryName="usersList"
              />
            )}
          />
          {errors.userId && (
            <span className="text-red-600">un client doit être renseigné</span>
          )}
        </div>
        <div className="sm:py-1 mb-4 lg:w-1/3 ">
          <Label htmlFor="reference">Référence du contrat cadre</Label>
          <TextInput
            id="reference"
            placeholder="Cadre13ADD"
            type="text"
            defaultValue={itemRow.reference as string}
            {...register("reference", { required: false })}
          />
        </div>
        <div className="sm:py-1 mb-4 lg:w-1/3 ">
          <Label htmlFor="url">Lien URL vers le contrat cadre</Label>
          <TextInput
            id="url"
            placeholder="https://..."
            type="text"
            defaultValue={itemRow.url as string}
            {...register("url", { required: false })}
          />
        </div>
        <div className="sm:py-1 mb-4 lg:w-3/4">
          <Label htmlFor="description">
            <h2 className="font-bold">Description (facultatif)</h2>
          </Label>

          <Textarea
            id="description"
            placeholder="Laissez un commentaire..."
            rows={9}
            defaultValue={itemRow.description as string}
            {...register("description", { required: false })}
          />
        </div>
        <div className="mb-4 gap-y-3 sm:py-1 items-center justify-center  overflow-hidden">
          <Label htmlFor="reference">Ce contrat est il actif?</Label>
          <div className="flex">
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                defaultChecked={itemRow.isActive}
                readOnly
                onClick={() => {
                  setActive(!isActive);
                  setValue("isActive", !isActive);
                }}
                {...register("isActive", { required: false })}
              />
              <div className="mr-2 w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600" />

              {!isActive ? (
                <>
                  <div className="font-bold bg-red-600 w-4 h-4 relative rounded-full items-center justify-center mr-1" />

                  <span className="ml-2 text-sm font-medium text-gray-900">
                    Désactivé
                  </span>
                </>
              ) : (
                <>
                  {" "}
                  <div className="font-bold bg-green-600 w-4 h-4 relative  rounded-full items-center justify-center mr-1" />
                  <span className="ml-2 text-sm font-medium text-gray-900">
                    Actif
                  </span>
                </>
              )}
            </label>
          </div>
        </div>
        <div
          className="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 md:w-1/2"
          role="alert"
        >
          <svg
            aria-hidden="true"
            className="flex-shrink-0 inline w-5 h-5 mr-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <span className="font-medium">
              Un seul contrat cadre peut être actif par client. Celui-ci viendra
              remplacer le dernier contrat cadre actif s'il y en avait un.
            </span>
          </div>
        </div>
        <div className="flex w-full flex-row sm:py-1 mb-6">
          <button
            className="w-28 bg-add-blue-dark p-2 font-semibold button-add mr-2"
            type="submit"
          >
            Enregistrer
          </button>
          <button
            type="button"
            className="w-28 bg-add-blue-dark p-2 font-semibold button-add"
          >
            <Link to="/contrat-cadre">Fermer</Link>
          </button>
        </div>
        {BadCredentialsError && (
          <span className="text-red-600 block mb-6">
            {BadCredentialsError.message}
          </span>
        )}
      </form>
    </div>
  ) : null;
}
