import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { GET_ONE_USER_BY_ID } from "../../API/modules/user/resolvers/queries";
import SpinnerComponent from "../../components/shared/SpinnerComponent";
import UserProfileView from "./UserProfileView";
import { GetUserByID_getUserByID } from "../../API/models/types";
import { UserContext } from "../../context/userContext";
import MyProfileView from "./MyProfileView";
import SomethingHappened from "../SomethingHappened";

export default function Account() {
  const { id } = useParams<string>();
  const { user } = useContext(UserContext);
  const { data, error, loading } = useQuery(GET_ONE_USER_BY_ID, {
    variables: {
      id,
    },
  });

  return !error ? (
    <div>
      {!loading ? (
        <div>
          {user !== null && user && user.id === id ? (
            <MyProfileView
              data={data && (data.getUserByID as GetUserByID_getUserByID)}
              userId={id}
            />
          ) : (
            <UserProfileView
              data={data && (data.getUserByID as GetUserByID_getUserByID)}
            />
          )}
        </div>
      ) : (
        <SpinnerComponent />
      )}
    </div>
  ) : (
    <SomethingHappened />
  );
}
