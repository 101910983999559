/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

export type ColorClasses = {
  teal: string;
  indigo: string;
  gray: string;
  green: string;
  red: string;
  purple: string;
  fuchsia: string;
  [key: string]: string;
};

interface ColorPickerProps {
  colorsClasses: ColorClasses;
  selectedColor: string;
  setSelectedColor: (value: string) => void;
}

export default function ColorPicker({
  selectedColor,
  setSelectedColor,
  colorsClasses,
}: ColorPickerProps) {
  const [openColorDropdown, setOpenColorDropdown] = useState(false);
  const [buttonBgColor, setButtonBgColor] = useState(selectedColor);

  useEffect(() => {
    if (selectedColor !== "") {
      setButtonBgColor(selectedColor);
    }
  }, [selectedColor]);

  return (
    <div className="relative">
      <label className="hidden" htmlFor="show colors palette">
        Couleur
      </label>
      <button
        className={`flex items-center p-2.5 rounded-lg ${buttonBgColor} ${
          buttonBgColor ? "text-white" : "text-gray-900"
        } font-bold shadow-md hover:shadow-2xl hover:scale-105`}
        style={{ backgroundColor: `${buttonBgColor}` }}
        onClick={() => setOpenColorDropdown(true)}
        type="button"
        id="show colors palette"
      >
        <AiOutlinePlusCircle className="w-5 h-5 mx-1" />
        Couleur
      </button>
      {openColorDropdown && (
        <div className="flex gap-x-2 absolute top-16 right-0">
          {Object.entries(colorsClasses).map(([colorName, colorHex]) => (
            <div key={colorName}>
              <label className="hidden" htmlFor={`${colorName}-button`}>
                Select {colorName}
              </label>
              <button
                type="button"
                id={`${colorName}-button`}
                onClick={(event) => {
                  event.preventDefault();
                  setSelectedColor(colorName);
                  setButtonBgColor(`${colorHex}`);
                  setOpenColorDropdown(false);
                }}
                className={`rounded-full w-6 h-6 cursor-pointer z-10 bg-${colorName}-400 ${
                  selectedColor === colorName ? "" : "border-2 border-white"
                }`}
                style={{ backgroundColor: `${colorHex}` }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
