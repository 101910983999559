/* eslint-disable import/prefer-default-export */
import { DocumentNode, gql } from "@apollo/client";

export const LOGIN: DocumentNode = gql`
  mutation login($username: String!, $password: String!) {
    authLogin(username: $username, password: $password) {
      accessToken
      isActive
      role
      id
      avatar_url
    }
  }
`;

export const CHECK_JWT: DocumentNode = gql`
  mutation checkJwt($token: CheckTokenDto!) {
    checkToken(input: $token)
  }
`;
