/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { DocumentNode, useQuery } from "@apollo/client";
import { TextInput } from "flowbite-react";
import UserCell from "../table/cell-component/UserCell";
import {
  CustomersList_customersList,
  getAllUser_usersList,
  SecretariesListWithActiveQuoteAgreementByCustomer_secretariesListWithActiveQuoteAgreementByCustomer,
  SecretariesList_secretariesList,
  UsersListWithActiveFrameworkAgreement_usersListWithActiveFrameworkAgreement,
  UsersListWithActiveQuoteAgreement_usersListWithActiveQuoteAgreement,
  AdminsList_adminsList,
  UserRole,
  secretariesAndAdminList_secretariesAndAdminList,
} from "../../API/models/types";
import { UserInformation } from "../../pages/models/models";

export type SelectUsersList =
  | getAllUser_usersList
  | secretariesAndAdminList_secretariesAndAdminList
  | SecretariesList_secretariesList
  | CustomersList_customersList
  | SecretariesListWithActiveQuoteAgreementByCustomer_secretariesListWithActiveQuoteAgreementByCustomer
  | UsersListWithActiveQuoteAgreement_usersListWithActiveQuoteAgreement
  | UsersListWithActiveFrameworkAgreement_usersListWithActiveFrameworkAgreement
  | AdminsList_adminsList;

interface Props {
  setSelectedUserId: (selectedUser: string) => void;
  // defaultValue
  userId?: string;
  userRole?: UserRole;
  // requete graphQl pour filtrer les utilisateurs
  query: DocumentNode;
  queryName: string;
  // filtre pour liste
  customerId?: string;
}
export default function SelectUser(props: Props) {
  const { setSelectedUserId, userId, userRole, query, customerId, queryName } =
    props;
  const [users, setUsers] = useState<SelectUsersList[]>([]);
  const [selectedValue, setSelectedValue] = useState<
    UserInformation | undefined
  >(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  // création reference element dom
  const selectUserRef = useRef<any>();
  // fonction pour fermer le dropdown
  const closeOpenMenus = useCallback(
    (e: any) => {
      if (
        selectUserRef.current &&
        open &&
        !selectUserRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    },
    [open, selectUserRef],
  );
  useEffect(() => {
    // au click exterieur on appelle la méthode poour fermer le dropdown
    document.addEventListener("mousedown", closeOpenMenus);
  }, [closeOpenMenus]);

  const { data } = useQuery(
    query,
    customerId
      ? {
          variables: { customerId },
          fetchPolicy: "no-cache",
        }
      : { fetchPolicy: "no-cache" },
  );

  useEffect(() => {
    if (data) {
      const usersList = data
        ? ([...data[queryName]] as SelectUsersList[])
        : ([] as SelectUsersList[]);
      const filteredUsers = userRole
        ? usersList.filter((user) => user.role === userRole)
        : usersList;
      setUsers(filteredUsers);

      // default selected value if update
      const userToUpdate =
        data &&
        usersList &&
        (usersList.filter(
          (user: SelectUsersList) => user.id === userId,
        ) as SelectUsersList[]);
      if (userToUpdate && userToUpdate.length > 0) {
        setSelectedValue({
          userName: `${userToUpdate[0].last_name.toUpperCase()} ${
            userToUpdate[0].first_name
          }`,
          avatar: userToUpdate[0].avatar_url,
          id: userToUpdate[0].id,
          email: userToUpdate[0].email,
          role: userToUpdate[0].role,
        });
      }
    }
  }, [data, queryName, userId, userRole]);

  // Select placeholder
  const getPlaceholder = () => {
    switch (userRole) {
      case UserRole.CUSTOMER:
        return "Choisir un client";
      case UserRole.SECRETARY:
        return "Choisir une secrétaire";
      case UserRole.ADMIN:
        return "Choisir un admin";
      default:
        return "Choisir";
    }
  };

  return (
    <div className="font-medium w-full" ref={selectUserRef}>
      <div
        onClick={() => setOpen(!open)}
        className={`bg-white flex flew-row items-center justify-start rounded z-20 ${
          !selectedValue && "text-gray-700"
        }`}
      >
        {selectedValue
          ? UserCell(selectedValue, true)
          : userRole === UserRole.CUSTOMER
          ? "Choisir un client"
          : userRole === UserRole.SECRETARY
          ? "Choisir une secrétaire"
          : userRole === UserRole.ADMIN
          ? "Choisir un admin"
          : "Choisir une secrétaire"}

        <BiChevronDown size={20} className={`${open && "rotate-180"}`} />
      </div>
      <ul
        className={`bg-white overflow-auto absolute z-20 ${
          open ? "max-h-80 relative sm:absolute w-64" : "max-h-0"
        } `}
      >
        <div className="flex items-center px-2 sticky top-0 bg-white">
          <AiOutlineSearch size={18} className="text-gray-700" />
          <TextInput
            onChange={(e) =>
              setInputValue(String(e.target.value).toLowerCase())
            }
            placeholder={getPlaceholder()}
            className="placeholder:text-gray-700 p-2 "
          />
        </div>
        {users &&
          users?.map((user: SelectUsersList) => (
            <li
              key={user.id}
              className={`p-1 text-sm hover:bg-add-blue-dark rounded-md
              ${`${user?.id}` === selectedValue?.id && "bg-add-dark rounded-md"}
              ${
                String(user?.first_name).toLowerCase().includes(inputValue) ||
                String(user?.last_name).toLowerCase().includes(inputValue)
                  ? "block"
                  : "hidden"
              }`}
              onClick={() => {
                if (`${user.id}` !== selectedValue?.id) {
                  setSelectedValue({
                    userName: `${user.last_name.toUpperCase()} ${
                      user?.first_name
                    }`,
                    avatar: user.avatar_url as string,
                    email: user.email as string,
                    id: user.id as string,
                    role: user.role as UserRole,
                  });

                  setSelectedUserId(user?.id);
                  setOpen(false);
                  setInputValue("");
                }
              }}
            >
              {UserCell(
                {
                  userName: `${user?.last_name.toUpperCase()} ${
                    user?.first_name
                  }`,
                  avatar: user.avatar_url as string,
                  email: user.email as string,
                  id: user.id as string,
                  role: user.role as UserRole,
                },
                true,
              )}
            </li>
          ))}
      </ul>
    </div>
  );
}
