export default function generateFileNameWithDate(
  baseName: string,
  extension: string,
): string {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().substring(2); // seulement les deux derniers chiffres
  const hour = `${date.getHours().toString().padStart(2, "0")}H`;
  const minute = date.getMinutes().toString().padStart(2, "0");

  return `${baseName}-${day}-${month}-${year}-${hour}${minute}.${extension}`;
}
