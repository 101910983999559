import { useQuery } from "@apollo/client";
import {
  CustomersListForAdmin_customersListForAdmin,
  CustomersListForSecretary_customersListForSecretary,
  CustomersList_customersList,
  ExportSecretariesListToXlsx_exportSecretariesListToXlsx,
  GetInactiveUsers_getInactiveUsers,
  GetNewAffiliatedUsersForAdmin_getNewAffiliatedUsersForAdmin,
  SecretariesListForAdmin_secretariesListForAdmin,
  SecretariesListForCustomer_secretariesListForCustomer,
  SecretariesList_secretariesList,
  ExportCustomersListToXlsx_exportCustomersListToXlsx,
} from "../../API/models/types";
import {
  CUSTOMERS_LIST,
  CUSTOMERS_LIST_FOR_ADMIN,
  CUSTOMERS_LIST_FOR_SECRETARY,
  EXPORT_CUSTOMERS_LIST_TO_XLSX,
  EXPORT_SECRETARIES_LIST_TO_XLSX,
  GET_INACTIVE_USERS,
  GET_NEW_AFFILIATED_USERS_FOR_ADMIN,
  SECRETARIES_LIST,
  SECRETARIES_LIST_FOR_ADMIN,
  SECRETARIES_LIST_FOR_CUSTOMER,
} from "../../API/modules/user/resolvers/queries";

export type CustomersListData =
  | CustomersList_customersList
  | CustomersListForAdmin_customersListForAdmin
  | CustomersListForSecretary_customersListForSecretary;

export type SecretatiesListData =
  | SecretariesList_secretariesList
  | SecretariesListForAdmin_secretariesListForAdmin
  | SecretariesListForCustomer_secretariesListForCustomer;

// queries customers list
export function GetAllCustomers() {
  const { data, loading, error } = useQuery(CUSTOMERS_LIST, {
    // variables: getQueryVariables(true, 2) exemple pagination
  });
  const customersList = data
    ? ([...data.customersList] as CustomersList_customersList[])
    : ([] as CustomersList_customersList[]);
  return { customersList, loading, error };
}

export function GetAllCustomersForAdmin(adminId: string) {
  const { data, loading, error } = useQuery(CUSTOMERS_LIST_FOR_ADMIN, {
    variables: { adminId },
  });
  const customersList = data
    ? ([
        ...data.customersListForAdmin,
      ] as CustomersListForAdmin_customersListForAdmin[])
    : ([] as CustomersListForAdmin_customersListForAdmin[]);
  return { customersList, loading, error };
}

export function GetAllCustomersForSecretary(secretaryId: string) {
  const { data, loading, error } = useQuery(CUSTOMERS_LIST_FOR_SECRETARY, {
    variables: { secretaryId },
  });
  const customersList = data
    ? ([
        ...data.customersListForSecretary,
      ] as CustomersListForSecretary_customersListForSecretary[])
    : ([] as CustomersListForSecretary_customersListForSecretary[]);
  return { customersList, loading, error };
}

// queries secretaries list
export function GetAllSecretaries() {
  const { data, loading, error } = useQuery(SECRETARIES_LIST, {
    // variables: getQueryVariables(true, 2) exemple pagination
  });
  const secretariesList = data
    ? ([...data.secretariesList] as SecretariesList_secretariesList[])
    : ([] as SecretariesList_secretariesList[]);
  return { secretariesList, loading, error };
}

// Export to XLSX
export function ExportSecretariesListToXlsx() {
  const { data, loading, error } = useQuery(EXPORT_SECRETARIES_LIST_TO_XLSX, {
    // variables: getQueryVariables(true, 2) exemple pagination
  });
  const exportSecretariesListToXlsx = data
    ? ([
        ...data.exportSecretariesListToXlsx,
      ] as ExportSecretariesListToXlsx_exportSecretariesListToXlsx[])
    : ([] as ExportSecretariesListToXlsx_exportSecretariesListToXlsx[]);
  return { exportSecretariesListToXlsx, loading, error };
}

export function ExportCustomersListToXlsx() {
  const { data, loading, error } = useQuery(EXPORT_CUSTOMERS_LIST_TO_XLSX, {
    // variables: getQueryVariables(true, 2) exemple pagination
  });
  const exportCustomersListToXlsx = data
    ? ([
        ...data.exportCustomersListToXlsx,
      ] as ExportCustomersListToXlsx_exportCustomersListToXlsx[])
    : ([] as ExportCustomersListToXlsx_exportCustomersListToXlsx[]);
  return { exportCustomersListToXlsx, loading, error };
}

export function GetAllSecretariesForAdmin(adminId: string) {
  const { data, loading, error } = useQuery(SECRETARIES_LIST_FOR_ADMIN, {
    variables: { adminId },
  });
  const secretariesList = data
    ? ([
        ...data.secretariesListForAdmin,
      ] as SecretariesListForAdmin_secretariesListForAdmin[])
    : ([] as SecretariesListForAdmin_secretariesListForAdmin[]);
  return { secretariesList, loading, error };
}

export function GetAllSecretariesForCustomer(customerId: string) {
  const { data, loading, error } = useQuery(SECRETARIES_LIST_FOR_CUSTOMER, {
    variables: { customerId },
  });
  const secretariesList = data
    ? ([
        ...data.secretariesListForCustomer,
      ] as SecretariesListForCustomer_secretariesListForCustomer[])
    : ([] as SecretariesListForCustomer_secretariesListForCustomer[]);
  return { secretariesList, loading, error };
}

export function GetInactiveUsersForSuperAdmin() {
  const { data } = useQuery(GET_INACTIVE_USERS);
  const usersList = data
    ? ([...data.getInactiveUsers] as GetInactiveUsers_getInactiveUsers[])
    : ([] as GetInactiveUsers_getInactiveUsers[]);

  return { usersList };
}

export function GetNewAffiliatedUsersForAdmin(adminId: string) {
  const { data } = useQuery(GET_NEW_AFFILIATED_USERS_FOR_ADMIN, {
    variables: { adminId },
  });
  const usersList = data
    ? ([
        ...data.getNewAffiliatedUsersForAdmin,
      ] as GetNewAffiliatedUsersForAdmin_getNewAffiliatedUsersForAdmin[])
    : ([] as GetNewAffiliatedUsersForAdmin_getNewAffiliatedUsersForAdmin[]);

  return { usersList };
}
