import React from "react";
import getCurrentDayClass from "../../utils/getCurrentDayClass";
import { Task } from "../../hook/useTasks";
import TaskDayViewCell from "./TaskDayViewCell";
import { HourMatrix } from "../../utils/getWeek";
import shouldDisplayTask from "../../utils/shouldDisplayTask";

interface DayViewProps {
  tasks: Task[];
  day: HourMatrix;
}

function DayView({ tasks, day }: DayViewProps) {
  const { hours } = day;

  return (
    <div className="flex bg-white p-2 shadow">
      <div className="w-auto pr-4">
        <div className="h-6 mb-2" />
        {hours.map((hour, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className="h-16 border-b border-gray-200 flex items-center"
          >
            <span className="text-gray-600">{hour.hour()}:00</span>
          </div>
        ))}
      </div>
      <div className="flex w-full">
        <div className="flex flex-col w-full">
          <div className="text-center mb-2 flex items-center justify-center space-x-2 border-l border-dashed">
            <span
              className={`font-bold text-gray-800 ${getCurrentDayClass(
                day.day,
              )}`}
            >
              {day.day.format("D")}
            </span>
            <span className="first-letter:uppercase text-gray-800">
              {day.day.format("dddd")}
            </span>
          </div>
          {hours.map((hour, hourIndex) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={hourIndex}
              className="h-16 border-b border-gray-200 flex items-center border-l border-dashed relative"
            >
              <div className="flex w-full h-full items-center justify-center">
                {tasks.map((task) => {
                  if (shouldDisplayTask(task.startDate, hour)) {
                    return <TaskDayViewCell key={task.id} task={task} />;
                  }
                  return null;
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DayView;
