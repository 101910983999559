import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <section className="bg-white dark:bg-gray-900 w-full h-full flex">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 place-self-center">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-add-green dark:text-primary-500">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
            Ressource non trouvée.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            Désolé, nous ne retrouvons pas cette page. Il y aura bien plus à
            explorer depuis la page d'accueil.
          </p>
          <Link to="/">
            <button type="button" className="bg-add-blue-dark button-add">
              Accueil
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
