import React from "react";
import { Link } from "react-router-dom";
import getAvatarUrl from "../../utils/getAvatarUrl";
import Avatar from "../../../components/shared/Avatar";
import { UserRole } from "../../../API/models/types";
import ProfileButton from "../../../components/table/cell-component/ProfileButton";
import { User } from "../../../hooks/useLocalStorage";

interface LastAffiliatedSecretariesProps {
  secretaries: User[];
}

function LastAffiliatedSecretaries({
  secretaries,
}: LastAffiliatedSecretariesProps) {
  return (
    <div className="w-full md:w-1/2">
      <div className="p-7 sm:p-10 place-self-end">
        <div className="mb-4 rounded-lg bg-white py-6 px-4 md:px-10 shadow sm:p-6 w-fit">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="font-bold leading-none dark:text-white">
              Liste des secrétaires affectées
            </h3>
          </div>
          <div className="flow-root">
            <ul className="divide-y divide-gray-200 max-h-96 overflow-y-auto scrollbar-thumb-add-blue-dark scrollbar-track-gray-100 scrollbar-thin">
              {secretaries &&
                secretaries.length > 0 &&
                secretaries.map((secretary: any) => (
                  <li
                    className="py-3 sm:py-4 w-full text-sm flex justify-between"
                    key={secretary.id}
                  >
                    <div className="flex flex-row justify-between items-center w-full">
                      <div className="flex flex-row">
                        <Avatar
                          imgUrl={
                            getAvatarUrl(
                              UserRole.SECRETARY,
                              secretary.avatar_url,
                            ) as string
                          }
                        />
                        <div className="flex flex-col">
                          <div className="font-bold">
                            {secretary.last_name.toUpperCase()}{" "}
                            {secretary.first_name}
                          </div>
                          <span className="text-gray-500 pr-2">
                            {secretary.email}
                          </span>
                        </div>
                      </div>
                      <div className="font-bold md:mr-3">
                        {ProfileButton(`/profil/${secretary.id}`)}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <div className="flex items-center justify-between border-t border-gray-200 pt-3 sm:pt-6">
            <div className="shrink-0">
              <Link
                to="/liste-des-secretaires"
                className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
              >
                Liste des secrétaires
                <svg
                  className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LastAffiliatedSecretaries;
