import React from "react";
import dayjs from "dayjs";
// eslint-disable-next-line import/no-cycle
import Day from "./Day";
// eslint-disable-next-line import/no-cycle
import { Task } from "../../hook/useTasks";

interface Props {
  month: dayjs.Dayjs[][];
  tasks: Task[];
}
function MonthView(props: Props) {
  const { month, tasks } = props;

  return (
    <div className="flex-1 grid grid-cols-7 grid-rows-5 bg-white">
      {month.map((week: dayjs.Dayjs[], index: number) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {week.map((day: dayjs.Dayjs, indexDay: number) => {
              const dayString = day.format("YYYY-MM-DD");

              const filteredTasksByDays = tasks.filter(
                (task) =>
                  dayjs.utc(task.startDate).local().format("YYYY-MM-DD") ===
                  dayString,
              );

              return (
                <Day
                  day={day}
                  // eslint-disable-next-line react/no-array-index-key
                  key={indexDay}
                  tasks={filteredTasksByDays}
                  rowIdx={index}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default MonthView;
