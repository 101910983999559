import React from "react";

export default function StatusCell(value: string) {
  const className =
    value === "désactivé"
      ? "font-bold bg-red-600 w-3 h-3 relative rounded-full items-center justify-center mr-3"
      : "font-bold bg-green-600 w-3 h-3 relative rounded-full items-center justify-center mr-3";

  return (
    <div className="flex flex-row items-center">
      <div className={className} />
      <div> {value}</div>
    </div>
  );
}
