import React, { useState } from "react";

import RegistrationStep from "../components/RegistrationStep";

const stepsArray = [
  {
    id: 0,
    icon: "check",
    label: "Je m'enregistre",
    commentary: "Vous avez déjà validé cette étape",
    text: "Nous récupérons les informations nécessaires à votre identification.",
  },
  {
    id: 1,
    icon: "phone",
    label: "L'équipe ADD me contacte",
    commentary: "Partie administration",
    text: "Nous choisissons ensemble les services adaptés à vos besoins pour sélectionner une secrétaire qualifiée.",
  },
  {
    id: 2,
    icon: "happy-face",
    label: "Vous profitez de l'application",
    commentary: "Toutes les fonctionnalités vous sont ouvertes",
    text: "Vous profitez de nos services” : gestion de contrats, suivi des tâches, agenda partagé… notre application vous offre un outil de travail avec votre secrétaire.",
  },
];

function RegistrationSteps() {
  const [steps] = useState(stepsArray);

  return (
    <div className="w-auto px-6 py-4 md:px-32 md:py-10 xl:px-80 lg:py-20">
      <div className="text-center mb-12">
        <h1 className="text-3xl lg:text-5xl mb-6">
          Votre compte est en cours de validation
        </h1>
        <p className="xl:px-40 text-gray-600">
          Notre équipe va vous recontacter rapidement afin de valider votre
          besoin et vous donner accès à toutes les fonctionnalités de notre
          application.
        </p>
      </div>
      <div className="p-2 lg:p-0 flex flex-col lg:flex-row">
        {steps &&
          steps.map((step) => (
            <RegistrationStep
              key={step.id}
              icon={step.icon}
              label={step.label}
              commentary={step.commentary}
              text={step.text}
            />
          ))}
      </div>
    </div>
  );
}

export default RegistrationSteps;
