import { useQuery } from "@apollo/client";
import {
  getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser,
  GetAllFrameworkAgreements_getAllFrameworkAgreements,
  GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin,
} from "../../API/models/types";
import {
  GET_ALL_FRAMEWORK_AGREEMENTS,
  GET_ALL_FRAMEWORK_AGREEMENTS_FOR_ADMIN,
  GET_ALL_FRAMEWORK_AGREEMENTS_FOR_USER,
} from "../../API/modules/framework-agreement/resolvers/queries";

export function GetAllFrameworkAgreement() {
  const { data, loading, error } = useQuery(GET_ALL_FRAMEWORK_AGREEMENTS, {
    // variables: getQueryVariables(true, 2) exemple pagination
  });
  const frameworkAgreements = data
    ? ([
        ...data.getAllFrameworkAgreements,
      ] as GetAllFrameworkAgreements_getAllFrameworkAgreements[])
    : ([] as GetAllFrameworkAgreements_getAllFrameworkAgreements[]);
  return { frameworkAgreements, loading, error };
}

export const getOneFrameworkAgreement = (
  id: string,
  getAllFrameworkAgreements: GetAllFrameworkAgreements_getAllFrameworkAgreements[],
): GetAllFrameworkAgreements_getAllFrameworkAgreements => {
  return getAllFrameworkAgreements.filter(
    (element: GetAllFrameworkAgreements_getAllFrameworkAgreements) =>
      element.id === id,
  )[0];
};

export function GetAllFrameworkAgreementsForUser(userId: string) {
  const { data, loading, error } = useQuery(
    GET_ALL_FRAMEWORK_AGREEMENTS_FOR_USER,
    {
      variables: { userId },
    },
  );
  const frameworkAgreements = data
    ? ([
        ...data.getAllFrameworkAgreementsForUser,
      ] as getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser[])
    : ([] as getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser[]);
  return { frameworkAgreements, loading, error };
}

export const getOneFrameworkAgreementForUser = (
  id: string,
  getAllFrameworkAgreements: getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser[],
): getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser => {
  return getAllFrameworkAgreements.filter(
    (
      element: getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser,
    ) => element.id === id,
  )[0];
};

export const GetAllFrameworkAgreementForAdmin = (adminId: string) => {
  const { data, loading, error } = useQuery(
    GET_ALL_FRAMEWORK_AGREEMENTS_FOR_ADMIN,
    {
      variables: { adminId },
    },
  );
  const frameworkAgreements = data
    ? ([
        ...data.getAllFrameworkAgreementsForAdmin,
      ] as GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin[])
    : ([] as GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin[]);
  return { frameworkAgreements, loading, error };
};
export const getOneFrameworkAgreementForAdmin = (
  id: string,
  getAllFrameworkAgreements: GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin[],
): GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin => {
  return getAllFrameworkAgreements.filter(
    (
      element: GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin,
    ) => element.id === id,
  )[0];
};
