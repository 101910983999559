import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import logo from "../../../assets/logo/logo-add.png";
import { useCalendarContext } from "../../../context/CalendarContext";

import ButtonAddItem from "../../../components/table/button/ButtonAddItem";
// eslint-disable-next-line import/no-cycle
import { ViewKey } from "../Calendar";
import { useUserContext } from "../../../context/userContext";
import { UserRole } from "../../../API/models/types";
import { useSidebarContext } from "../../../context/SideBarContext";
import { isSmallScreen } from "../../../context/is-small-screen";

interface CalendarHeaderProps {
  onViewChange: (view: ViewKey) => void;
}

function CalendarHeader({ onViewChange }: CalendarHeaderProps) {
  dayjs.extend(isoWeek);
  // context
  const { user } = useUserContext();
  const {
    yearIndex,
    setYearIndex,
    monthIndex,
    setMonthIndex,
    setWeekIndex,
    setDayIndex,
  } = useCalendarContext();

  // state
  const [selectedView, setSelectedView] = React.useState("month");

  // pour conserver la date actuellement sélectionnée
  const currentDateRef = React.useRef(dayjs());

  // Handle navigation
  function handleNavigation(direction: number | "reset") {
    // Déclaration de la nouvelle date
    let newDate;

    // En fonction de la vue sélectionnée
    switch (selectedView) {
      case "month": {
        if (direction === "reset") {
          newDate = dayjs();
        } else {
          newDate = currentDateRef.current.add(direction, "month");
        }

        setDayIndex(newDate.diff(dayjs().startOf("day"), "day"));

        const updatedMonthIndex = newDate.month();
        const updatedYearIndex = newDate.year();

        setMonthIndex(updatedMonthIndex);
        setWeekIndex(newDate.startOf("month").week());
        setYearIndex(updatedYearIndex);

        // Important: Mettez à jour la référence
        currentDateRef.current = newDate;

        break;
      }

      case "week":
        if (direction === "reset") {
          newDate = dayjs();
        } else {
          newDate = currentDateRef.current.add(direction * 7, "day");
        }

        setDayIndex(newDate.diff(dayjs().startOf("day"), "day"));
        setMonthIndex(newDate.month());
        setWeekIndex(newDate.isoWeek());
        setYearIndex(newDate.year());

        // Important: Mettez à jour la référence
        currentDateRef.current = newDate;
        break;

      case "day":
        if (direction === "reset") {
          newDate = dayjs();
        } else {
          newDate = currentDateRef.current.add(direction, "day");
        }

        setDayIndex(newDate.diff(dayjs().startOf("day"), "day"));
        setMonthIndex(newDate.month());
        setWeekIndex(newDate.isoWeek());
        setYearIndex(newDate.year());

        // Important: Mettez à jour la référence
        currentDateRef.current = newDate;
        break;

      default:
        break;
    }
  }

  // View change functions
  function handleViewChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const newView = e.target.value;
    setSelectedView(newView);
    onViewChange(newView as ViewKey);
  }

  // vérification de la taille de l'écran
  const { width } = useSidebarContext();
  const [smallScreen, setSmallScreen] = useState<boolean>(isSmallScreen(width));
  // vérification de la taille de l'écran pour formatter la date en fonction
  useEffect(() => {
    setSmallScreen(isSmallScreen(width));
  }, [width]);

  // Handle date
  function getFormattedDate() {
    switch (selectedView) {
      case "month":
        if (dayjs().month() === monthIndex && dayjs().year() === yearIndex) {
          return dayjs().format("D MMMM YYYY");
        }
        return dayjs().year(yearIndex).month(monthIndex).format("MMMM YYYY");

      case "week": {
        const startOfWeek = currentDateRef.current.startOf("week");
        const endOfWeek = startOfWeek.add(6, "day");

        const formatString = smallScreen ? "D MMM YYYY" : "D MMMM YYYY";
        return `${startOfWeek.format(formatString)} - ${endOfWeek.format(
          formatString,
        )}`;
      }

      case "day": {
        const currentDay = currentDateRef.current;
        return currentDay.format("D MMMM YYYY");
      }

      default:
        return "";
    }
  }

  return (
    <header className="px-2 md:px-6 py-2 md:py-4 flex md:flex-row flex-col items-center justify-between shadow bg-white w-full">
      <div className="flex items-center space-x-4 mb-4 min-h-[60px] md:min-h-0">
        <div className="hidden md:flex items-center">
          <img
            src={logo}
            alt="Logo atelier du dirigeant"
            className="mr-2 w-12 h-12"
          />
          <h1 className="md:mr-10 text-xl text-gray-500 font-bold">
            Calendrier
          </h1>
        </div>

        <div className="flex">
          <button
            type="button"
            className="border rounded py-2 px-4 mr-5 flex items-center hover:bg-gray-200 text-add-blue-dark"
            onClick={() => handleNavigation("reset")}
          >
            Aujourd'hui
          </button>

          <button
            onClick={() => handleNavigation(-1)}
            type="button"
            className="button-add group"
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1em"
              width="1em"
              className="group-hover:fill-white fill-gray-800 h-6 w-6"
            >
              <path d="M12.707 17.293L8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
            </svg>
          </button>

          <button
            type="button"
            onClick={() => handleNavigation(+1)}
            className="button-add group"
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1em"
              width="1em"
              className="group-hover:fill-white fill-gray-800 h-6 w-6"
            >
              <path d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z" />
            </svg>
          </button>
        </div>
        {/* Display the current date OR the month if different from the current one */}
        <p className="font-bold first-letter:uppercase text-gray-500 text-sm md:text-base">
          {getFormattedDate()}
        </p>
      </div>
      <div className="flex items-center ml-4">
        <label htmlFor="view-selector" className="mr-2 text-gray-500">
          Vue :
        </label>
        <select
          id="view-selector"
          value={selectedView}
          onChange={handleViewChange}
          className="border rounded py-2 px-4 text-add-blue-dark"
        >
          <option value="month">Mois</option>
          <option value="week">Semaine</option>
          <option value="day">Jour</option>
        </select>
      </div>
      <div className="flex items-center w-full md:w-auto mt-4 md:mt-0">
        <ButtonAddItem
          label={
            user.role !== UserRole.SECRETARY
              ? "Ajouter une tâche"
              : "Ajouter une tâche privée"
          }
          path={
            user.role !== UserRole.SECRETARY
              ? "/liste-des-taches/creation-tache"
              : "/liste-des-taches-en-cours/creation-tache-privee"
          }
          classNameCustom="w-full h-10 bg-add-blue-dark font-medium button-add rounded-lg text-xs px-2.5 py-2.5 text-center inline-flex justify-center items-center mr-2"
        />
      </div>
    </header>
  );
}

export default CalendarHeader;
