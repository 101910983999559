import React, { useCallback, useEffect, useState } from "react";
import { GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes } from "../../../API/models/types";
import { GetAllCategoriesTaskTypeForTask } from "../../utils/QueriesTasks";

interface Props {
  setTaskTypeId: (value: string) => void;
  // eslint-disable-next-line react/require-default-props
  defaultTaskType?: string;
}
export default function SelectTaskType(props: Props) {
  const { setTaskTypeId, defaultTaskType } = props;
  const tasksTypeList: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[] =
    GetAllCategoriesTaskTypeForTask();

  // méthode qui retrouve la catégorie avec l' id du type de tâche
  const findCategoryForDefaultValueTaskTypeId = useCallback(() => {
    let defaultCategory: string = "";
    if (defaultTaskType) {
      tasksTypeList.map((category) => {
        category.taskTypes.map((taskType) => {
          if (taskType.id === defaultTaskType) {
            defaultCategory = category.id;
          }
          return taskType;
        });
        return category;
      });
    }
    return defaultCategory;
  }, [defaultTaskType, tasksTypeList]);

  // catégorie par défaut
  const [selectedCategoryTask, setSelectedCategoryTask] = useState<string>("");
  // effet de bord pour l' update de la catégorie
  useEffect(() => {
    if (defaultTaskType) {
      setSelectedCategoryTask(findCategoryForDefaultValueTaskTypeId());
    }
  }, [defaultTaskType, findCategoryForDefaultValueTaskTypeId]);

  const [selectedTaskType, setSelectedTaskType] = useState<string>(
    defaultTaskType || "",
  );

  const handleCategoryTaskTypeSelect = (e: any) => {
    setSelectedCategoryTask(e.target.value);
    if (e.target.value === "") {
      setTaskTypeId("");
    }
  };
  const handleTaskTypeChange = (e: any) => {
    setTaskTypeId(e.target.value);
    setSelectedTaskType(e.target.value);
  };

  const buildOptionsTaskTypeByCategory = () => {
    const filterTaskType: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes =
      tasksTypeList.filter(
        (category) => category.id === selectedCategoryTask,
      )[0];

    const map =
      filterTaskType &&
      filterTaskType.taskTypes.map((taskType) => {
        return (
          <option key={taskType.id} value={taskType.id}>
            {taskType.label}
          </option>
        );
      });
    return map;
  };
  return (
    <div className="flex flex-row w-full">
      {/* Select a category Task Type */}
      <div className="flex flex-col w-1/2 sm:w-1/2">
        <label
          htmlFor="taskType"
          className="block mb-2 text-sm font-semibold dark:text-white"
        >
          Type de tâche
          <span className="font-normal"> *</span>
        </label>

        <select
          id="taskTypeId"
          value={selectedCategoryTask}
          className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-2.5 text-sm font-medium bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
          onChange={handleCategoryTaskTypeSelect}
        >
          <option key={0} value="">
            Choisir un type
          </option>
          {tasksTypeList.length > 0 &&
            tasksTypeList.map(
              (
                category: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
              ) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.label}
                  </option>
                );
              },
            )}
        </select>
      </div>
      {/* Select a Task Type */}
      <div className="flex flex-col w-1/2 sm:w-1/2">
        <label
          htmlFor="tasks"
          className={`block mb-2 text-sm font-semibold dark:text-white ${
            selectedCategoryTask === "" ? "text-gray-400" : ""
          }`}
        >
          Tâche
          <span className="font-normal"> *</span>
        </label>

        <select
          id="tasks"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-lg border-l-gray-100 dark:border-l-gray-700 border-l-2 focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          disabled={selectedCategoryTask === ""}
          onChange={handleTaskTypeChange}
          value={selectedTaskType}
        >
          <option key={0} value="">
            Choisir une tâche
          </option>
          {selectedCategoryTask ? buildOptionsTaskTypeByCategory() : null}
        </select>
      </div>
    </div>
  );
}
