import React, { useContext, useState } from "react";
import UserAvatarTooltip from "../../../../components/shared/UserAvatarTooltip";
import TaskMonthViewCell from "./TaskMonthViewCell";
import {
  getHexColorFromClassOrValue,
  getRGBColor,
} from "../../utils/getColorValue";
// eslint-disable-next-line import/no-cycle
import { Task } from "../../hook/useTasks";
import ModalTaskRow from "../../../task/modale/ModalTaskRow";
import { UserRole } from "../../../../API/models/types";
import { UserContext } from "../../../../context/userContext";

interface AgendaMonthTasksProps {
  dayTasks: Task[];
}
export default function AgendaMonthTasks(props: AgendaMonthTasksProps) {
  const { dayTasks } = props;

  const { user } = useContext(UserContext);

  // Pour gérer l'ouverture d'une seule modale correspondant à celle sur laquelle on clique
  // Nous initialisons `openModalTaskRow` comme un objet vide.
  // Chaque clé dans cet objet sera un ID de tâche, et chaque valeur sera un booléen indiquant si la modale correspondante est ouverte ou non.
  const [openModalTaskRow, setOpenModalTaskRow] = useState<
    Record<string, boolean>
  >({});

  const handleClick = (isPrivate: boolean, taskId: string) => {
    if (!isPrivate || (isPrivate && user.role === UserRole.SECRETARY)) {
      // Si la tâche n'est pas privée ou si l'utilisateur est une secrétaire (et donc autorisé à voir les tâches privées),
      // nous ouvrons la modale correspondante en mettant à jour l'état `openModalTaskRow` pour ajouter une nouvelle entrée avec l'ID de la tâche et la valeur `true`.
      setOpenModalTaskRow((prevState) => ({ ...prevState, [taskId]: true }));
    }
  };

  return (
    <div>
      {dayTasks.map(
        (task: Task) =>
          task &&
          task.color && (
            <div
              key={task.id}
              className={`p-1 mx-1 text-${task.color}-600 text-sm rounded mb-1`}
              style={{
                backgroundColor: getRGBColor(
                  getHexColorFromClassOrValue(task.color) || task.color,
                ),
              }}
            >
              <div className="flex justify-center flex-col xl:flex-row md:justify-between w-full">
                {/* Pour chaque tâche, nous passons à la modal un booléen indiquant si elle doit être ouverte ou non. 
            Nous obtenons cette valeur en cherchant l'ID de la tâche dans l'état `openModalTaskRow`. 
            Si aucune valeur n'est trouvée, nous utilisons `false` comme valeur par défaut. */}
                <ModalTaskRow
                  open={openModalTaskRow[task.id] || false}
                  setOpen={() =>
                    setOpenModalTaskRow((prevState) => ({
                      ...prevState,
                      [task.id]: false,
                    }))
                  }
                  item={task}
                />
                {/* Fonction `handleClick` en passant l'ID de la tâche correspondante. */}
                <button
                  type="button"
                  onClick={() => handleClick(task.isPrivate, task.id)}
                  aria-label="Détail d'une tache"
                  className="w-full xl:max-w-[70%]"
                >
                  <TaskMonthViewCell
                    isPrivate={task.isPrivate}
                    color={task.color}
                    startDate={task.startDate}
                    label={task.label}
                  />
                </button>
                <div className="hidden md:flex">
                  {/* Secretary avatar tooltip */}
                  {task && task.secretary ? (
                    <UserAvatarTooltip
                      id={task.secretary.id}
                      email={task.secretary.email}
                      firstName={task.secretary.first_name}
                      lastName={task.secretary.last_name}
                      imgUrl={task.secretary.avatar_url ?? null}
                      role={task.secretary.role}
                    />
                  ) : null}
                  {/* Customer avatar tooltip */}
                  {task && task.customer ? (
                    <UserAvatarTooltip
                      id={task.customer.id}
                      email={task.customer.email}
                      firstName={task.customer.first_name}
                      lastName={task.customer.last_name}
                      imgUrl={task.customer.avatar_url ?? null}
                      role={task.customer.role}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          ),
      )}
    </div>
  );
}
