import { useQuery } from "@apollo/client";
import {
  Modal,
  Label,
  Select as FlowbiteSelect,
  ToggleSwitch,
  Checkbox,
} from "flowbite-react";
import { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-tailwindcss-select";
import {
  GetAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
  TaskStatus,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
  UserRole,
} from "../../../API/models/types";
import { GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES } from "../../../API/modules/category-task-type/resolvers/queries";
import TaskStatusCell from "../../../components/table/cell-component/TaskStatusCell";
import { useUserContext } from "../../../context/userContext";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../context/FilterContext";

import {
  FilterTask,
  InitialFilter,
  OptionsSelect,
  Option,
  ItemNotPendingTask,
} from "../../models/models";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  datas: ItemNotPendingTask[];
}

export default function ModalFilterSecretaryTableTasks(props: Props) {
  // check de la route pour ne pas afficher le filtre sur le status
  // si on est sur la vue des tâches en attentes
  const location = useLocation();
  const { setFilters, filtersKey, pageKey } =
    useContext<FilterTasksContextType>(FilterTasksContext);

  // contexte user
  const { user } = useUserContext();
  // chargement des catégories
  const { data: dataFromQuery } = useQuery(
    GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES,
  );
  const getAllCategoryTaskTypeWithTaskTypes = dataFromQuery
    ? ([
        ...dataFromQuery.getAllCategoryTaskTypeWithTaskTypes,
      ] as GetAllCategoryTaskTypeWithTaskTypes[])
    : ([] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[]);
  const myFilters: string | null =
    filtersKey !== null && filtersKey ? localStorage.getItem(filtersKey) : null;

  const [initialFilter, setInitialFilter] = useState<InitialFilter | undefined>(
    myFilters ? (JSON.parse(myFilters) as InitialFilter) : undefined,
  );
  // ouverture fermeture modale + data à filtrer
  const { open, setOpen, datas } = props;
  // state formulaire filtrage
  const [statusToDo, setStatusToDo] = useState<boolean>(
    initialFilter ? initialFilter.statusToDo : true,
  );
  const [statusPending, setStatusPending] = useState<boolean>(
    initialFilter ? initialFilter.statusPending : false,
  );
  const [statusCompleted, setStatusCompleted] = useState<boolean>(
    initialFilter ? initialFilter.statusCompleted : false,
  );
  const [statusUnrealized, setStatusUnrealized] = useState<boolean>(
    initialFilter ? initialFilter.statusUnrealized : false,
  );
  const [secretaryId, setSecretaryId] = useState<string | undefined>(
    initialFilter ? initialFilter.secretaryId : undefined,
  );
  const [customerId, setCustomerId] = useState<string | undefined>(
    initialFilter ? initialFilter.customerId : undefined,
  );

  // toggle pour filtrer la liste en fonction du rôle
  const [toggleClient, setToggleClient] = useState<boolean>(
    initialFilter ? initialFilter.toggleClient : true,
  );
  const [toggleSecretary, setToggleSecretary] = useState<boolean>(
    initialFilter ? initialFilter.toggleSecretary : true,
  );
  const [taskTypeIds, setTaskTypeIds] = useState<Option[]>(
    initialFilter && initialFilter.taskTypeIds ? initialFilter.taskTypeIds : [],
  );
  // update filter context for secretaries role because two screen possible
  // useEffect(() => {
  //   if (customer !== filters.customerId) {
  //     setFilters({ ...filters, customerId: customer });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   const array: string[] = [];
  //   if (taskTypeIds && taskTypeIds.length > 0) {
  //     taskTypeIds.map((cat: Option) => {
  //       const index = array.indexOf(cat.value);
  //       if (index === -1) {
  //         array.push(cat.value);
  //       }
  //       return cat;
  //     });
  //   }
  //   if (array !== filters.taskTypeIds) {
  //     setFilters({ ...filters, taskTypeIds: array });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // fonction initialiser le filtre
  const handleClick = () => {
    setStatusCompleted(true);
    setStatusUnrealized(true);
    setStatusToDo(true);

    setStatusPending(true);
    setSecretaryId(undefined);
    setCustomerId(undefined);
    // reinitialise le tableau des categories
    // buildCategoriesTasks();
    setToggleClient(true);
    setToggleSecretary(true);
    setTaskTypeIds([] as Option[]);
  };

  // application du filtre sur la donnée
  const handleSubmit = useCallback(async () => {
    const filterDatas: FilterTask = {};

    // filtre client et secretaire
    if (
      secretaryId !== "" &&
      toggleSecretary &&
      secretaryId !== "Toutes les secrétaires"
    ) {
      filterDatas.secretaryId = secretaryId;
    } else {
      filterDatas.secretaryId = undefined;
    }

    if (customerId && toggleClient && secretaryId !== "Toutes les clients") {
      filterDatas.customerId = customerId;
    } else {
      filterDatas.customerId = undefined;
    }
    // filtre statut
    const roles: TaskStatus[] = [];
    if (statusToDo === true) {
      const index = roles.indexOf(TaskStatus.TODO);
      if (index === -1) {
        roles.push(TaskStatus.TODO);
      }
    }
    if (statusToDo === false) {
      const index = roles.indexOf(TaskStatus.TODO);
      if (index !== -1) {
        // Supprimer l'élément à l'indice trouvé
        roles.splice(index, 1);
      }
    }

    if (statusCompleted === true) {
      const index = roles.indexOf(TaskStatus.COMPLETED);
      if (index === -1) {
        roles.push(TaskStatus.COMPLETED);
      }
    }
    if (statusCompleted === false) {
      const index = roles.indexOf(TaskStatus.COMPLETED);
      if (index !== -1) {
        // Supprimer l'élément à l'indice trouvé
        roles.splice(index, 1);
      }
    }
    if (statusPending === true) {
      const index = roles.indexOf(TaskStatus.PENDING);
      if (index === -1) {
        roles.push(TaskStatus.PENDING);
      }
    }
    if (statusPending === false) {
      const index = roles.indexOf(TaskStatus.PENDING);
      if (index !== -1) {
        // Supprimer l'élément à l'indice trouvé
        roles.splice(index, 1);
      }
    }
    if (statusUnrealized === true) {
      const index = roles.indexOf(TaskStatus.UNREALIZED);
      if (index === -1) {
        roles.push(TaskStatus.UNREALIZED);
      }
    }
    if (statusUnrealized === false) {
      const index = roles.indexOf(TaskStatus.UNREALIZED);
      if (index !== -1) {
        // Supprimer l'élément à l'indice trouvé
        roles.splice(index, 1);
      }
    }

    filterDatas.status = roles;

    // filtre category
    const array: string[] = [];
    if (taskTypeIds && taskTypeIds.length > 0) {
      taskTypeIds.map((cat: Option) => {
        const index = array.indexOf(cat.value);
        if (index === -1) {
          array.push(cat.value);
        }
        return cat;
      });
      filterDatas.taskTypeIds = array;
    }
    filterDatas.taskTypeIds = array;
    // reinitialisation de la page à 1

    localStorage.setItem(pageKey, JSON.stringify({ page: 1 }));
    filterDatas.page = 1;

    localStorage.setItem(
      filtersKey,
      JSON.stringify({
        taskTypeIds,
        statusCompleted,
        statusPending,
        statusToDo,
        statusUnrealized,
        secretaryId,
        customerId,
        toggleClient,
        toggleSecretary,
      }),
    );
    setInitialFilter({
      statusCompleted,
      taskTypeIds,
      statusPending,
      statusToDo,
      statusUnrealized,
      secretaryId,
      customerId,
      toggleClient,
      toggleSecretary,
    });

    setFilters(filterDatas);
  }, [
    customerId,
    filtersKey,
    pageKey,
    secretaryId,
    setFilters,
    statusCompleted,
    statusPending,
    statusToDo,
    statusUnrealized,
    taskTypeIds,
    toggleClient,
    toggleSecretary,
  ]);
  // useLayoutEffect(() => {
  //   // Fonction de soumission et modification de l'affichage ici
  //   handleSubmit();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // options select en fonction du role
  const optionsCustomerSelect = () => {
    // fonction ppur avoir la liste des clients sélectionnables
    const customers = datas.map((element) => {
      return element.customer?.id;
    });

    // fonction pour avoir un tableau d' id avec des clients uniques
    let uniqueCustomers = customers.filter(
      (element, index) => customers.indexOf(element) === index,
    );
    // construction du tableau pour les champs de séléction des clients
    const customersOptions: ItemNotPendingTask[] = [];
    datas.map((element: ItemNotPendingTask) => {
      if (
        element.customer?.id &&
        uniqueCustomers.includes(element.customer.id) &&
        uniqueCustomers.length > 0
      ) {
        const index: number = uniqueCustomers.indexOf(element.customer.id);
        if (uniqueCustomers.length > 1) {
          uniqueCustomers.splice(index, 1);
        } else {
          uniqueCustomers = [];
        }
        customersOptions.push(element);
      }
      return element;
    });
    // retour options
    return (
      customersOptions.length > 0 &&
      customersOptions.map((element: ItemNotPendingTask) => {
        return (
          <option
            key={element.id}
            value={element.customer ? element.customer.id : ""}
          >
            {element.customer && element.customer.userName}
          </option>
        );
      })
    );
  };
  // TaskType Select options
  const getOptions = (
    getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
  ) => {
    const optionsToDisplay: OptionsSelect[] = [];
    const categoryArray = [
      ...getAllCategory,
    ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    categoryArray &&
      categoryArray.map(
        (
          element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
        ) => {
          const subOptionsArray: Option[] = [];
          const tasktypes = [
            ...element.taskTypes,
          ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasktypes &&
            tasktypes.map(
              (
                tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
              ) => {
                subOptionsArray.push({
                  value: tasktype.id,
                  label: tasktype.label,
                });
                return tasktype;
              },
            );
          optionsToDisplay.push({
            label: element.label,
            options: subOptionsArray,
          });
          return element;
        },
      );
    return optionsToDisplay;
  };
  const getOptionsForOneCategory = (
    getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
    categoryLabel: string,
  ) => {
    const optionsToDisplay: OptionsSelect[] = [];
    const categoryArray = [
      ...getAllCategory,
    ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const filterCategoryArray = categoryArray.filter(
      (element) => element.label === categoryLabel,
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    filterCategoryArray.length > 0 &&
      filterCategoryArray.map(
        (
          element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
        ) => {
          const subOptionsArray: Option[] = [];
          const tasktypes = [
            ...element.taskTypes,
          ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasktypes &&
            tasktypes.map(
              (
                tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
              ) => {
                subOptionsArray.push({
                  value: tasktype.id,
                  label: tasktype.label,
                });
                return tasktype;
              },
            );
          optionsToDisplay.push({
            label: element.label,
            options: subOptionsArray,
          });
          return element;
        },
      );

    return optionsToDisplay[0].options.flat();
  };
  return (
    <Modal size="sm" show={open} popup onClose={() => setOpen(false)}>
      <div className="w-full border-b-2 border-gray-200 mb-5">
        <Modal.Header>
          <p className="font-semibold">Filtres</p>
        </Modal.Header>
      </div>
      <Modal.Body>
        <div className="flex justify-left">
          <button
            type="button"
            className="button-add bg-add-blue-dark mr-2 text-sm"
            onClick={() => {
              handleSubmit();
              setOpen(!open);
            }}
          >
            Appliquer
          </button>

          <button
            type="button"
            className="px-2 py-1 button-add bg-add-blue-dark text-sm"
            onClick={handleClick}
          >
            Vider
          </button>
        </div>
        <div className="mb-3">
          <div className="mb-2 block">
            <Label
              htmlFor="contractNameSelectFilterQuoteAgreement"
              value="Sélectionnez un type de tâche"
            />
          </div>

          <Select
            value={taskTypeIds}
            onChange={(newValue: any) => {
              setTaskTypeIds(newValue);
            }}
            options={
              getOptions(getAllCategoryTaskTypeWithTaskTypes as any) as any
            }
            isMultiple
            isClearable
            isSearchable
            // eslint-disable-next-line react/no-unstable-nested-components
            formatGroupLabel={(data: OptionsSelect) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                className="py-2 text-xs flex items-center justify-between"
                onClick={() => {
                  if (
                    taskTypeIds !== null &&
                    taskTypeIds &&
                    taskTypeIds.length > 0
                  ) {
                    setTaskTypeIds(
                      taskTypeIds.concat(
                        getOptionsForOneCategory(
                          getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                          data.label,
                        ),
                      ) as any,
                    );
                  } else {
                    setTaskTypeIds(
                      getOptionsForOneCategory(
                        getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                        data.label,
                      ) as any,
                    );
                  }
                }}
              >
                <span className="font-bold">{data.label}</span>
                <span className="bg-gray-200 h-5 p-1.5 flex items-center justify-center rounded-full">
                  {data.options.length}
                </span>
              </div>
            )}
            primaryColor="teal"
            placeholder="Sélectionnez ..."
          />
        </div>
        <div className="flex flex-col gap-4 mb-3" id="toggle">
          {user.role !== UserRole.CUSTOMER ? (
            <ToggleSwitch
              checked={toggleClient}
              label="Client"
              onChange={() => setToggleClient(!toggleClient)}
            />
          ) : null}
          {user.role !== UserRole.SECRETARY &&
            location.pathname !==
              "/liste-des-taches-en-attente-d-acceptation" && (
              <ToggleSwitch
                checked={toggleSecretary}
                label="Secrétaire"
                onChange={() => setToggleSecretary(!toggleSecretary)}
              />
            )}
        </div>
        {user.role !== UserRole.CUSTOMER ? (
          <div className="mb-3">
            <div className="mb-2 block">
              <Label
                htmlFor="customerSelectFilterQuoteAgreement"
                value="Sélectionnez un client"
              />
            </div>
            <FlowbiteSelect
              value={customerId}
              onChange={(e: any) => {
                if (e.target.value === "") {
                  // si "tous les clients" on initialise l id à undefined
                  setCustomerId(undefined);
                } else {
                  setCustomerId(e.target.value);
                }
              }}
              id="customerSelectFilterQuoteAgreement"
              disabled={!toggleClient}
            >
              <option value="">Tous les clients</option>
              {optionsCustomerSelect()}
            </FlowbiteSelect>
          </div>
        ) : null}

        {user.role === UserRole.SECRETARY &&
          location.pathname !==
            "/liste-des-taches-en-attente-d-acceptation" && (
            <div
              className="flex flex-col gap-4"
              id="checkboxfilterActivityTask"
            >
              <Label htmlFor="checkboxfilterActivityTask">Statut</Label>
              <div className="flex items-center gap-2">
                <Checkbox
                  id="toDoTask"
                  checked={statusToDo}
                  onChange={() => {
                    setStatusToDo(!statusToDo);
                  }}
                />
                {TaskStatusCell(TaskStatus.TODO)}
              </div>
              <div className="flex items-center gap-2">
                <Checkbox
                  color="#40788B"
                  id="refusedTask"
                  checked={statusUnrealized}
                  onChange={() => {
                    setStatusUnrealized(!statusUnrealized);
                  }}
                />
                {TaskStatusCell(TaskStatus.UNREALIZED)}
              </div>
              <div className="flex items-center gap-2">
                <Checkbox
                  color="#40788B"
                  id="doneTask"
                  checked={statusCompleted}
                  onChange={() => {
                    setStatusCompleted(!statusCompleted);
                  }}
                />
                {TaskStatusCell(TaskStatus.COMPLETED)}
              </div>
            </div>
          )}
      </Modal.Body>
    </Modal>
  );
}
