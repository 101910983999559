import { Card, Modal, Textarea, TextInput } from "flowbite-react";
// eslint-disable-next-line import/no-cycle
import UserCell from "../../components/table/cell-component/UserCell";
import { UserRole } from "../../API/models/types";
import { FrameworkAgreementData } from "../models/models";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  item: FrameworkAgreementData;
}
export default function ModalFrameworkAgreementRow(props: Props) {
  // modale consultation row
  const { open, setOpen, item } = props;
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Modal
      className="!items-start"
      size="xl"
      show={open}
      popup
      onClose={handleClick}
    >
      <Modal.Header>
        <div className="w-full border-b-2 border-gray-200">
          <h2 className="font-semibold">
            Contrat cadre {item ? item.label : ""}{" "}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Body>
        {item ? (
          <Card className="mb-4 w-full flex-col">
            <h2 className="font-semibold">
              Référence : {item.reference ? item.reference : "non renseigné"}
            </h2>

            <div className="flex flex-row w-full">
              <div className="basis-3/12 mb-auto mt-auto">
                <h2 className="font-semibold basis-1/3">Client : </h2>
              </div>
              <div>
                {item && item.user
                  ? UserCell(
                      {
                        userName: `${item.user.last_name.toUpperCase()} ${
                          item.user.first_name
                        }`,
                        avatar: item.user.avatar_url as string,
                        email: item.user.email,
                        id: item.user.id as string,
                        role: item.user.role as UserRole,
                      },
                      false,
                    )
                  : "non renseigné"}
              </div>
            </div>

            <div className="basis-1/4">
              <h2 className="font-semibold">
                Lien URL vers le contrat cadre :{" "}
              </h2>{" "}
            </div>
            <div className="basis-1/2">
              <TextInput
                type="text"
                disabled
                value={item.url ? item.url : "non renseigné"}
              />
            </div>

            <div className="basis-1/4">
              <h2 className="font-semibold">Description : </h2>
            </div>
            <div className="basis-1/2">
              <Textarea
                id="description"
                placeholder="Laissez un commentaire..."
                rows={5}
                value={item.description ? item.description : "non renseigné"}
                disabled
              />
            </div>

            <div className="basis-1/4">
              <h2 className="font-semibold">Ce contrat est il actif? : </h2>
            </div>
            <div className="basis-1/2 flex flew-row">
              {!item.isActive ? (
                <>
                  <div className="font-bold bg-red-600 w-4 h-4 relative rounded-full items-center justify-center mr-1" />

                  <span className="ml-2 text-sm font-medium text-gray-900">
                    Désactivé
                  </span>
                </>
              ) : (
                <>
                  {" "}
                  <div className="font-bold bg-green-600 w-4 h-4 relative  rounded-full items-center justify-center mr-1" />
                  <span className="ml-2 text-sm font-medium text-gray-900">
                    Actif
                  </span>
                </>
              )}
            </div>
          </Card>
        ) : null}

        <div className="flex justify-left">
          <button
            type="button"
            className="px-4 py-2 button-add bg-add-blue-dark"
            onClick={handleClick}
          >
            OK
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
