import { Modal } from "flowbite-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FaClipboardList } from "react-icons/fa";
import Avatar from "../../../components/shared/Avatar";
import ColorPicker from "../../../components/shared/ColorPicker";
import { EDIT_TASK } from "../../../API/modules/task/resolvers/mutations";
import {
  FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks,
  FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks,
  TaskStatus,
  UpdateTaskInput,
} from "../../../API/models/types";

import { useUserContext } from "../../../context/userContext";
import DateAndHourPicker from "../components/DateAndHourPicker";
import { FilterTask } from "../../models/models";
import { colorsClasses } from "../../utils/constants";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../context/FilterContext";
import {
  FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
  FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
  FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
} from "../../../API/modules/task/resolvers/queries";
import getAvatarUrl from "../../utils/getAvatarUrl";
import getFormattedHours from "../../Calendar/utils/getFormattedHours";
import SelectDuration from "../components/SelectDuration";

interface FormUpdateTask {
  id: string;
  startDate: Date;
  color: string;
  description: string;
  status?: TaskStatus;
  plannedDuration: string;
  realDuration: string;
}
interface UpdateTaskBySecretaryModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  data:
    | FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks
    | FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks;
}

function UpdateTaskBySecretaryModal({
  showModal,
  setShowModal,
  data,
}: UpdateTaskBySecretaryModalProps) {
  // contexte user
  const { user } = useUserContext();
  // contexte filter
  const { filters } = useContext<FilterTasksContextType>(FilterTasksContext);
  // formulaire
  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormUpdateTask>();

  const [selectedColor, setSelectedColor] = useState(
    data && (data.color as string),
  );
  const [plannedDurationSelected, setPlannedDurationSelected] =
    useState<string>(
      data && data.plannedDuration ? String(data.plannedDuration) : "",
    );

  const setPlannedDuration = useCallback(
    (value: string) => {
      setPlannedDurationSelected(value);
      setValue("plannedDuration", value);
    },
    [setValue],
  );

  const [dateAndHourSelected, setDateAndHourSelected] = useState<Date>(
    data && data.startDate ? data.startDate : new Date(),
  );
  const setDateAndHourPicker = useCallback(
    (value: Date) => {
      setDateAndHourSelected(value);
      setValue("startDate", value);
    },
    [setValue],
  );

  const [statusSelected, setStatusSelected] = useState<TaskStatus>(
    data && data.status ? (data.status as TaskStatus) : TaskStatus.TODO,
  );

  const [realDurationSelected, setRealDurationSelected] = useState<string>(
    data && data.realDuration ? String(data.realDuration) : "",
  );

  const setRealDuration = useCallback(
    (value: string) => {
      setRealDurationSelected(value);
      setValue("realDuration", value);
    },
    [setValue, setRealDurationSelected],
  );

  const setStatus = useCallback(
    (value: TaskStatus) => {
      setStatusSelected(value);
      setValue("status", value);
    },
    [setValue],
  );
  // Set défault values
  useEffect(() => {
    if (data) {
      setSelectedColor(data.color);
      setPlannedDuration(String(data.plannedDuration));
      setStatus(data.status);
      setDateAndHourPicker(data.startDate);
      setRealDuration(String(data.realDuration));

      reset({
        startDate: data.startDate,
        plannedDuration: String(data.plannedDuration),
        description: data.description ?? "",
        status: data.status,
        color: data.color,
        id: data.id,
        realDuration: data.realDuration ? String(data.realDuration) : "",
      });
    }
  }, [
    data,
    reset,
    setDateAndHourPicker,
    setPlannedDuration,
    setStatus,
    setRealDuration,
  ]);
  // Handle Submit
  const [updateTask] = useMutation(EDIT_TASK);
  const onSubmit: SubmitHandler<FormUpdateTask> = async (
    values: FormUpdateTask,
  ) => {
    const input: UpdateTaskInput = {
      id: data?.id,
      status:
        values && values.status
          ? (values.status as TaskStatus)
          : statusSelected,
      startDate:
        values && values.startDate ? values.startDate : dateAndHourSelected,
      description: values.description,
      plannedDuration: Number(values.plannedDuration),
      color: selectedColor,
      realDuration: values.realDuration ? Number(values.realDuration) : null,
      quoteAgreementId: data.quoteAgreement?.id,
    };

    try {
      if (
        user !== null &&
        user &&
        user.id !== null &&
        filters !== null &&
        filters
      ) {
        await updateTask({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
              variables: {
                userId: user.id,
                paginationArgs: filters as FilterTask,
              },
            },
            {
              query: FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
              variables: {
                userId: user.id,
                paginationArgs: filters as FilterTask,
              },
            },
            {
              query: FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
              variables: {
                input: { userId: user.id, startDate: new Date() },
              },
            },
          ],
        });
        setShowModal(false);
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Modal show={showModal} onClose={() => setShowModal(false)}>
      <Modal.Header className="pb-1">
        <div className="flex items-center datas-center">
          <strong className="text-base font-semibold mx-4 text-gray-800">
            Nouvelle tâche qui vous a été assignée par
          </strong>

          {data && data.author ? (
            <div className="flex text-sm datas-center">
              <Avatar
                imgUrl={getAvatarUrl(data.author.role, data.author.avatar_url)}
              />
              <div className="flex flex-col">
                <div className="font-bold">
                  {data.author.last_name.toUpperCase()} {data.author.first_name}
                </div>
                <span className="text-gray-500">{data.author.email}</span>
              </div>
            </div>
          ) : null}
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="space-y-6 py-1">
          <span
            className={`block rounded-full w-full p-1 bg-${selectedColor}-400 mb-4`}
            style={{ backgroundColor: `${colorsClasses[selectedColor]}` }}
          />

          {/* Titre et couleur */}
          <div className="flex justify-between">
            <div className="w-1/2">
              <label
                htmlFor="title"
                className="block font-semibold text-gray-800 text-sm"
              >
                Titre
              </label>
              <input
                type="text"
                id="title"
                className="border-none underline underline-offset-2 decoration-2 decoration-add-green-light text-gray-900 rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block p-2.5 w-full disabled:cursor-not-allowed"
                placeholder="Titre de la tâche"
                disabled
                value={data ? data.label : ""}
              />
            </div>
            {data && data.color ? (
              <div className="space-y-6">
                <ColorPicker
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  colorsClasses={colorsClasses}
                />
              </div>
            ) : null}
          </div>

          {/* Catégorie de type de tâche et type de tâche */}
          {data ? (
            <div>
              <div className="flex">
                {/* Category Task Type */}

                <div className="flex flex-col">
                  <label
                    htmlFor="CategoryTaskType"
                    className="block mb-2 text-sm font-semibold text-gray-800"
                  >
                    Type de tâche
                  </label>
                  <input
                    id="CategoryTaskType"
                    disabled
                    type="text"
                    value={
                      data.taskType && data.taskType.categoryTaskType
                        ? data.taskType.categoryTaskType.label
                        : "non renseigné"
                    }
                    className="border-none underline underline-offset-2 decoration-2 decoration-add-green-light text-gray-900 rounded-l-lg focus:ring-add-blue-dark focus:border-add-blue-dark block p-2.5 w-full disabled:cursor-not-allowed"
                  />
                </div>

                {/* Task Type */}

                <div className="flex flex-col w-1/2 text-gray-800">
                  <label
                    htmlFor="taskType"
                    className="invisible mb-2 text-sm font-semibold text-gray-800"
                  >
                    Selectionner une tâche
                  </label>
                  <input
                    id="taskType"
                    disabled
                    type="text"
                    value={
                      data.taskType ? data.taskType.label : "non renseigné"
                    }
                    className="border-none underline underline-offset-2 decoration-2 decoration-add-green-light text-gray-900 rounded-r-lg focus:ring-add-blue-dark focus:border-add-blue-dark block p-2.5 w-full disabled:cursor-not-allowed"
                  />
                </div>
              </div>
            </div>
          ) : null}

          {/* Date and Time picker */}
          <div className="my-3">
            <label
              htmlFor="startDate"
              className="block mb-2 sm:mr-5 text-sm font-semibold"
            >
              Date et heure
            </label>
            <Controller
              control={control}
              rules={{ required: true }}
              name="startDate"
              defaultValue={data && data.startDate}
              render={() => (
                <DateAndHourPicker
                  setDateAndHourPicker={setDateAndHourPicker}
                  dateDefaultValue={data && data.startDate}
                  showAllDate
                />
              )}
            />{" "}
            {errors.startDate && (
              <span className="text-red-600">
                une date et un horaire doivent être renseignés
              </span>
            )}
          </div>

          <div>
            <div className="flex flex-col w-1/2">
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={
                  data && data.plannedDuration
                    ? String(data.plannedDuration)
                    : ""
                }
                name="plannedDuration"
                render={() => (
                  <SelectDuration
                    id="estimated-duration"
                    label="Durée prévisionnelle *"
                    setDuration={setPlannedDuration}
                    defaultValue={
                      data && data.plannedDuration
                        ? String(data.plannedDuration)
                        : plannedDurationSelected
                    }
                  />
                )}
              />{" "}
              {errors.plannedDuration && (
                <span className="text-red-600">
                  une durée prévisionnelle de tâche doit être renseignée.
                </span>
              )}
            </div>
          </div>

          {/* Client Pour lequel est établi le contrat concernant cette tâche */}
          {data && data.isPrivate === false ? (
            <div className="flex flex-col w-1/2">
              <label
                htmlFor="tasks"
                className="mb-2 text-sm font-semibold text-gray-800"
              >
                Contrat avec le client
              </label>
              {data && data.customer ? (
                <div className="flex datas-center space-x-4">
                  <Avatar
                    imgUrl={getAvatarUrl(
                      data.customer.role,
                      data.customer.avatar_url,
                    )}
                  />
                  <div className="flex flex-col text-xs">
                    <div className="font-bold">
                      {data.customer.last_name.toUpperCase()}{" "}
                      {data.customer.first_name}
                    </div>
                    <span className="text-gray-500">{data.customer.email}</span>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          {/* Référence et temps restant sur le contrat devis */}
          {data && data.quoteAgreement && (
            <div className="flex space-x-4">
              <div className="flex space-x-1">
                <p className="text-sm text-gray-800">Contrat :</p>
                <span className="text-sm font-semibold">
                  {data.quoteAgreement.label}
                </span>
              </div>
              <div className="flex space-x-1">
                <p className="text-sm text-gray-800">
                  Temps restant sur le contrat devis :
                </p>
                <span className="text-sm font-semibold">
                  {getFormattedHours(data.quoteAgreement.currentAmount)}
                </span>
              </div>
            </div>
          )}

          {/* Description Text Area */}
          <div className="my-6">
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-semibold dark:text-white"
            >
              Description (facultatif)
            </label>
            <textarea
              id="description"
              defaultValue={data && data.description ? data.description : ""}
              rows={3}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
              {...register("description", { required: false })}
            />
          </div>

          {/* Task status */}

          <div className="my-6 flex space-x-6">
            <div>
              <label
                htmlFor="task-status"
                className="block mb-2 text-sm font-semibold text-gray-700"
              >
                Statut de la tâche
              </label>

              <Controller
                control={control}
                rules={{ required: true }}
                name="status"
                defaultValue={data && (data.status as TaskStatus)}
                render={() => (
                  <select
                    value={statusSelected}
                    id="task-status-secretary"
                    className="datas-center py-2.5 px-4 text-sm font-medium bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark p-2.5"
                    onChange={(e) => {
                      setStatus(e.target.value as TaskStatus);
                    }}
                  >
                    {data && data.status === TaskStatus.PENDING ? (
                      <option
                        value={TaskStatus.PENDING}
                        className="px-3 text-sm font-medium w-fit bg-purple-100 text-purple-800"
                      >
                        En attente
                      </option>
                    ) : null}
                    <option
                      value={TaskStatus.UNREALIZED}
                      className="px-3 text-sm font-medium w-fit bg-red-100 text-red-800"
                    >
                      Non réalisée
                    </option>
                    <option
                      value={TaskStatus.COMPLETED}
                      className="px-3 text-sm font-medium w-fit bg-green-100 text-green-800"
                    >
                      Complétée
                    </option>
                    <option
                      value={TaskStatus.TODO}
                      className="px-3 text-sm font-medium w-fit bg-purple-100 text-purple-800"
                    >
                      À réaliser
                    </option>
                  </select>
                )}
              />
            </div>
            {statusSelected === TaskStatus.COMPLETED ? (
              <div className="flex flex-col">
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="realDuration"
                  defaultValue={realDurationSelected}
                  render={() => (
                    <SelectDuration
                      id="real-duration"
                      label="Durée réelle *"
                      setDuration={setRealDuration}
                      defaultValue={realDurationSelected}
                    />
                  )}
                />
                {errors.realDuration && (
                  <span className="text-red-600 text-sm">
                    une durée réelle de tâche doit être renseignée
                  </span>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer className="py-1">
          <div className="flex justify-between my-6 w-full">
            <button
              type="submit"
              className="flex justify-center datas-center button-add bg-add-blue-dark p-2 font-semibold text-sm w-1/2 mx-2"
            >
              <FaClipboardList className="h-4 w-4 mr-2" />
              Enregistrer
            </button>
            <button
              type="button"
              className="flex justify-center datas-center button-add bg-gray-200 text-gray-900 hover:text-white p-2 font-semibold text-sm w-1/2 mx-2"
              onClick={() => setShowModal(false)}
            >
              Fermer
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default UpdateTaskBySecretaryModal;
