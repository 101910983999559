/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import UserDropdown from "./shared/UserDropdown";

import logoAdd from "../assets/logo/logo-add.png";

interface HeaderProps {
  setOpenSideBar: (openSideBar: boolean) => void;
  openSideBar: boolean;
}
function Header(props: HeaderProps) {
  // const context = useSidebarContext();
  const { openSideBar, setOpenSideBar } = props;

  return (
    <header className="z-50 w-full px-5 py-3 shadow-lg h-16">
      <div className="flex items-center justify-between">
        <div className="flex space-x-6">
          <button type="button" onClick={() => setOpenSideBar(!openSideBar)}>
            <HiMenuAlt1 className="h-8 w-8 text-gray-500" />
          </button>
          <img
            className="h-8 w-8"
            src={logoAdd}
            alt="logo atelier du dirigeant"
          />
        </div>
        <UserDropdown />
      </div>
    </header>
  );
}
export default Header;
