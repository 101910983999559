import { useState } from "react";
import { Outlet } from "react-router-dom";
import { ShepherdTour } from "react-shepherd";
import SideBar from "../components/SideBar";
import Header from "../components/Header";
import { isSmallScreen } from "../context/is-small-screen";
import { useSidebarContext } from "../context/SideBarContext";
import { UserRole } from "../API/models/types";
import tourOptions from "../components/Tutorial/tourOptions";
import getSteps from "../components/Tutorial/steps";
import IsTestingEnvironment from "../components/shared/IsTestingEnvironment";

interface LayoutProps {
  currentUserRole: UserRole;
  currentUserId: string;
}

function Layout({ currentUserRole, currentUserId }: LayoutProps) {
  const { width } = useSidebarContext();
  const [isOpenSideBar, setOpenSideBar] = useState<boolean>(
    !isSmallScreen(width),
  );

  const isPreprod = window.location.hostname.includes("preprod.");

  const steps = getSteps(width, currentUserRole);
  return (
    <div className="h-screen flex flex-col">
      {/* ShepherTour composant permettant de fournir le context pour le tutoriel */}
      <ShepherdTour steps={steps} tourOptions={tourOptions}>
        {isPreprod && <IsTestingEnvironment />}
        <div>
          <Header setOpenSideBar={setOpenSideBar} openSideBar={isOpenSideBar} />
        </div>
        <div className="flex flex-row w-full h-full overflow-auto">
          <SideBar
            showSideBar={isOpenSideBar}
            setShowSideBar={setOpenSideBar}
            currentUserRole={currentUserRole}
            currentUserId={currentUserId}
          />

          {isOpenSideBar ? (
            <div className="w-[calc(100vw-18rem)] overflow-auto hidden sm:block">
              <Outlet />
            </div>
          ) : (
            <div className="w-full overflow-auto">
              <Outlet />
            </div>
          )}
        </div>
      </ShepherdTour>
    </div>
  );
}
export default Layout;
