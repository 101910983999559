import { MdTask } from "react-icons/md";

/* eslint-disable prefer-template */
interface Props {
  label: string;
  handleAction: () => void;
}
function ButtonActionItem(props: Props) {
  const { label, handleAction } = props;
  return (
    <button
      type="button"
      id="action button"
      onClick={handleAction}
      className="w-30 h-10 mt-1 bg-add-blue-dark font-medium button-add rounded-lg text-xs px-2.5 py-2.5 text-center inline-flex items-center mr-2"
    >
      <MdTask className="w-4 h-4 mr-1" />
      {label}
    </button>
  );
}
export default ButtonActionItem;
