import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import React, { PropsWithChildren, useContext, useMemo, useState } from "react";

export interface CalendarContextType {
  yearIndex: number;
  setYearIndex: (value: number | ((prevYear: number) => number)) => void;
  monthIndex: number;
  setMonthIndex: (value: number | ((prevMonthIndex: number) => number)) => void;
  weekIndex: number;
  setWeekIndex: (value: number | ((prevWeekIndex: number) => number)) => void;
  dayIndex: number;
  setDayIndex: (value: number | ((prevDayIndex: number) => number)) => void;
}

const CalendarContext = React.createContext<CalendarContextType>({
  yearIndex: 0,
  setYearIndex: () => {},
  monthIndex: 0,
  setMonthIndex: () => {},
  weekIndex: 0,
  setWeekIndex: () => {},
  dayIndex: 0,
  setDayIndex: () => {},
});

export default CalendarContext;

export function CalendarContextProvider({ children }: PropsWithChildren<any>) {
  dayjs.extend(weekOfYear);

  const [yearIndex, setYearIndex] = useState<number>(dayjs().year());
  const [monthIndex, setMonthIndex] = useState<number>(dayjs().month());
  const [weekIndex, setWeekIndex] = useState<number>(dayjs().week());
  const [dayIndex, setDayIndex] = useState<number>(0);

  const contextValue = useMemo(
    () => ({
      yearIndex,
      setYearIndex,
      monthIndex,
      setMonthIndex,
      weekIndex,
      setWeekIndex,
      dayIndex,
      setDayIndex,
    }),
    [yearIndex, monthIndex, weekIndex, dayIndex],
  );

  return (
    <CalendarContext.Provider value={contextValue}>
      {children}
    </CalendarContext.Provider>
  );
}

// on prépare un hook custom qui nous permet de récupérer le contexte du calendrier
export const useCalendarContext = () =>
  useContext<CalendarContextType>(CalendarContext);
