import React, { useState } from "react";
import { Table } from "flowbite-react";
import TasksTypeOverviewChart from "./TasksTypeOverviewChart";
import { GetCustomerDashbouardData_getCustomerDashboardData_taskData } from "../../../../API/models/types";

interface TaskType {
  id: string;
  label: string;
  percentage: number;
}

interface TaskCategory {
  id: string;
  label: string;
  percentage: number;
  taskTypes: TaskType[];
}

interface TasksTypeKPIProps {
  taskData: GetCustomerDashbouardData_getCustomerDashboardData_taskData[];
}

function TasksTypeKPI({ taskData }: TasksTypeKPIProps) {
  const [colors] = useState([
    "#16BDCA",
    "#FDBA8C",
    "#1A56DB",
    "#D61F69",
    "#9061F9",
    "#6875F5",
  ]);

  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

  const toggleCategory = (categoryId: string) => {
    if (expandedCategories.includes(categoryId)) {
      setExpandedCategories(
        expandedCategories.filter((id) => id !== categoryId),
      );
    } else {
      setExpandedCategories([...expandedCategories, categoryId]);
    }
  };

  return (
    <div className="w-full md:w-1/2">
      <div className="rounded-lg bg-white p-4 shadow dark:bg-gray-800 xl:p-4">
        <h3 className="text-lg font-bold leading-none text-gray-900 dark:text-white">
          Vue d'ensemble des types de tâches demandées
        </h3>
        <div className="my-6">
          <TasksTypeOverviewChart taskData={taskData} colors={colors} />
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto rounded-lg">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow sm:rounded-lg">
                <Table className="min-w-full overflow-auto">
                  <Table.Head>
                    <Table.HeadCell className="whitespace-nowrap rounded-l border-x-0 bg-gray-50 py-3 px-4 text-left align-middle text-xs font-semibold uppercase text-gray-700 dark:bg-gray-700 dark:text-white">
                      Catégorie
                    </Table.HeadCell>
                    <Table.HeadCell className="min-w-[140px] whitespace-nowrap rounded-r border-x-0 bg-gray-50 py-3 px-4 text-left align-middle text-xs font-semibold uppercase text-gray-700 dark:bg-gray-700 dark:text-white">
                      Répartition
                    </Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y divide-gray-100 dark:divide-gray-700">
                    {taskData.map((category: TaskCategory, i) => (
                      <Table.Row
                        key={category.id}
                        onClick={() => toggleCategory(category.id)}
                        className="cursor-pointer"
                      >
                        <Table.Cell>{category.label}</Table.Cell>
                        <Table.Cell>
                          <div className="flex items-center">
                            <span className="mr-2 text-xs font-medium">
                              {category.percentage}%
                            </span>
                            <div className="relative flex-grow h-2 bg-gray-200 dark:bg-gray-700">
                              <div
                                className={`absolute h-2 bg-primary-700 ${
                                  category.percentage === 0 ? "hidden" : ""
                                }`}
                                style={{
                                  width: `${category.percentage}%`,
                                  backgroundColor: colors[i],
                                }}
                              />
                            </div>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TasksTypeKPI;
