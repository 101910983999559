import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/fr"; // importez la localisation française

export default function DateCell(value: string, isIso = false) {
  // utilisez DayJS pour analyser la chaîne de date
  const parsedDate = dayjs(value);

  // formatez la date et l'heure dans les chaînes souhaitées
  const formattedDate = parsedDate.format("DD/MM/YY");
  const formattedTime = parsedDate.format("H:mm");

  return (
    <>
      {!isIso && <div className="text-gray-500">{value}</div>}
      {isIso && (
        <div className="flex flex-col items-center text-gray-500 w-fit font-bold">
          <p className="text-sm">{formattedDate}</p>
          <p className="text-sm">{formattedTime}</p>
        </div>
      )}
    </>
  );
}
