import { ApolloError } from "@apollo/client";
import { Alert, Card } from "flowbite-react";
import React, { useCallback, useContext, useState } from "react";
import { HiEye, HiInformationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

import tasksIllustration from "../../../assets/illustrations/img-taches.png";
import {
  FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries,
  FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks,
  UserRole,
} from "../../../API/models/types";
import SpinnerComponent from "../../../components/shared/SpinnerComponent";
import { UserContext } from "../../../context/userContext";

import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../context/FilterContext";
import TableNotPendingTasks from "./table/TableNotPendingTasksList";
import { FilterTask, ItemNotPendingTask } from "../../models/models";
import { FindAllPaginateNotPendingTasksForSecretaries } from "../../utils/QueriesTasks";
import DownloadTasksCsvButton from "../../../components/shared/DownloadTasksCsvButton";
import SomethingHappened from "../../SomethingHappened";
import getFormattedHours from "../../Calendar/utils/getFormattedHours";

function SecretariesNotPendingTasksList() {
  const { user } = useContext(UserContext);
  // contexte filtre

  const { filters, setFilters, pageKey } =
    useContext<FilterTasksContextType>(FilterTasksContext);

  // construction de la donnée pour l'adapter à la table
  const {
    tasks,
    loading,
    error,
  }: {
    tasks: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries;
    loading: boolean;
    error: ApolloError | undefined;
  } =
    user !== null && user && user.id !== null && filters !== null && filters
      ? FindAllPaginateNotPendingTasksForSecretaries(filters, user.id as string)
      : {
          tasks: {
            tasks:
              [] as FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks[],
            total: 0,
            hasPendingTask: false,
            __typename: "OutputPaginateTasks",
          } as FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries,
          loading: false,
          error: undefined,
        };

  const notPendingTasksData = useCallback(() => {
    const items: ItemNotPendingTask[] = [];
    if (tasks && tasks.tasks && tasks.tasks.length > 0) {
      const tasksList =
        tasks &&
        (tasks.tasks as FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks[]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tasksList.length; i++) {
        const item: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks =
          tasksList[i];
        items.push({
          id: item.id,
          label: item.label,
          description: item.description,
          plannedDuration: item.plannedDuration
            ? getFormattedHours(item.plannedDuration)
            : "non renseignée",
          realDuration: item.realDuration
            ? getFormattedHours(item.realDuration)
            : "non renseignée",
          customer: {
            id: item.customer?.id as string,
            userName: `${item.customer?.last_name.toUpperCase()} ${
              item.customer?.first_name
            }`,
            avatar: item.customer?.avatar_url as string,
            email: item.customer?.email,
            role: item.customer?.role as UserRole,
          },
          author: {
            id: item.author?.id as string,
            userName: `${item.author?.last_name.toUpperCase()} ${
              item.author?.first_name
            }`,
            avatar: item.author?.avatar_url as string,
            email: item.author?.email,
            role: item.author?.role as UserRole,
          },
          startDate: item.startDate as Date,
          status: item.status,
          quoteAgreement: {
            label: item.quoteAgreement?.label as string,
            reference: item.quoteAgreement?.reference as string,
          },
          taskType: {
            label: item.taskType?.label,
          },
          category: item.taskType?.categoryTaskType.label,
          color: item.color,
        });
      }
    }
    return items;
  }, [tasks]);
  // nombre de ligne par page de tableau
  const ROWBYPAGE: number = 6;

  // calcul nombre de page
  const nbPageFloat: number = tasks && tasks.total && tasks.total / ROWBYPAGE;

  // nombre de page arrondi à l' entier supérieur
  const nbPage = Math.ceil(nbPageFloat);
  const myPendingPage: string | null = localStorage.getItem(pageKey);
  const [page, setPage] = useState<number>(
    (myPendingPage && JSON.parse(myPendingPage).page) || 1,
  );

  const handlePageChange = useCallback(
    (event: any) => {
      if (filters && setFilters && event) {
        setFilters({
          ...filters,
          page: event.selected + 1,
        });
      }

      localStorage.setItem(
        pageKey,
        JSON.stringify({ page: event.selected + 1 }),
      );
      setPage(event.selected + 1);
    },
    [filters, pageKey, setFilters],
  );
  return !error ? (
    <main className="flex flex-col">
      <header className="flex flex-col h-1/7 lg:max-w-[50%] justify-items-start items-center relative">
        <div className="basis-2/12 flex">
          <img src={tasksIllustration} alt="tasks list" className="w-full" />
        </div>
        <div className="basis-9/12 p-10 sm:p-0 absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-2xl xl:text-3xl">Liste des tâches</h1>
        </div>
      </header>
      {!loading ? (
        <main>
          <div className="flex w-full h-4/7 p-5">
            <Card className=" flex gap-1 w-full h-full p-3">
              <div className="flex min-h-3/4 relative">
                <TableNotPendingTasks
                  data={notPendingTasksData()}
                  getAllNotPendingTasks={
                    tasks &&
                    (tasks.tasks as FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks[])
                  }
                />
                {filters !== null && user !== null ? (
                  <div className="absolute md:relative xl:absolute right-0">
                    <DownloadTasksCsvButton
                      filters={filters as FilterTask}
                      fileName="liste-tâches"
                      userRole={UserRole.SECRETARY as UserRole}
                      userId={user.id as string}
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex flex-row justify-left text-center">
                <div className="basis-4/5 text-left">
                  <span className="items-center gap-1 flex flex-row text-xs p-4">
                    <p className="text-gray-400 text-xs">Page</p>
                    <strong className="flex color:#40788B">{page}</strong>
                    <p className="text-gray-400 text-xs">sur</p>
                    {nbPage === 0 ? (
                      <strong>1</strong>
                    ) : (
                      <strong> {nbPage}</strong>
                    )}
                  </span>
                </div>
                <div className="basis-1/5">
                  {nbPage !== 0 ? (
                    <ReactPaginate
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={ROWBYPAGE}
                      pageCount={nbPage}
                      forcePage={page - 1 < nbPage ? page - 1 : 0}
                      marginPagesDisplayed={2}
                      nextLabel=">"
                      containerClassName="pagination"
                      previousLinkClassName="pagination__link"
                      nextLinkClassName="pagination__link"
                      disabledClassName="pagination__link--disabled"
                      activeClassName="pagination__link--active"
                      previousLabel="<"
                      onPageActive={() => page}
                    />
                  ) : null}
                </div>
              </div>
            </Card>
          </div>

          {tasks.hasPendingTask ? (
            <div className="flex justify-center w-full mt-6">
              <Alert
                color="warning"
                additionalContent={
                  <>
                    <div className="mt-1 mb-2 text-sm text-yellow-700">
                      Les tâches en attentes de confirmation n'apparaissent pas
                      dans votre tableau de tâches en cours ou dans votre
                      agenda.
                    </div>
                    <div className="flex">
                      <Link to="/liste-des-taches-en-attente-d-acceptation">
                        <button
                          type="button"
                          className="button-add mr-2 inline-flex items-center rounded-lg bg-yellow-700 px-3 py-1.5 text-center text-xs font-medium text-white"
                        >
                          <HiEye className="-ml-0.5 mr-2 h-4 w-4" />
                          Voir la liste des tâches en attente de confirmation
                        </button>
                      </Link>
                    </div>
                  </>
                }
                icon={HiInformationCircle}
              >
                <h3 className="text-lg font-medium text-yellow-700">
                  Vous avez des tâches en attentes de confirmation
                </h3>
              </Alert>
            </div>
          ) : null}
        </main>
      ) : (
        <SpinnerComponent />
      )}
    </main>
  ) : (
    <SomethingHappened />
  );
}

export default SecretariesNotPendingTasksList;
