import { Card, Label, Modal, Textarea, TextInput } from "flowbite-react";
import TaskStatusCell from "../../../components/table/cell-component/TaskStatusCell";
import UserCell from "../../../components/table/cell-component/UserCell";
import { useUserContext } from "../../../context/userContext";
import { UserRole } from "../../../API/models/types";
import getFormattedHours from "../../Calendar/utils/getFormattedHours";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  item: any;
}
export default function ModalTaskRow(props: Props) {
  const { user } = useUserContext();
  // modale consultation row
  const { open, setOpen, item } = props;
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Modal
      className="!items-start"
      size="xl"
      show={open}
      popup
      onClose={handleClick}
    >
      <Modal.Header>
        <p className="w-full font-semibold">Tâche : {item ? item.label : ""}</p>
      </Modal.Header>
      <Modal.Body>
        {item ? (
          <Card className="mb-4 w-full flex-col">
            <div className="flex flex-col sm:flex-row">
              <Label
                className="mb-auto mt-auto mr-2 font-semibold basis-2/5"
                htmlFor="categoryTaskRow"
              >
                Catégorie :{" "}
              </Label>
              <div>
                <TextInput
                  id="categoryTaskRow"
                  type="text"
                  readOnly
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    item.taskType.categoryTaskType
                      ? item.taskType.categoryTaskType.label
                      : item.category
                      ? item.category
                      : "non renseignée"
                  }
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row">
              <Label
                className="mb-auto mt-auto mr-2 font-semibold basis-2/5"
                htmlFor="categoryTaskType"
              >
                Type de Tâche :{" "}
              </Label>
              <TextInput
                id="categoryTaskType"
                type="text"
                readOnly
                value={
                  item.taskType.label ? item.taskType.label : "non renseigné"
                }
              />
            </div>
            <div className="flex flex-col sm:flex-row">
              <Label
                className="mb-auto mt-auto mr-2 font-semibold basis-2/5"
                htmlFor="dateStartTaskRow"
              >
                Date :{" "}
              </Label>
              <TextInput
                id="dateStartTaskRow"
                type="text"
                readOnly
                value={
                  item.startDate
                    ? new Date(item.startDate).toLocaleDateString("fr-fr", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    : "non renseignée"
                }
              />
            </div>
            {/* {item && !item.isPrivate ? (
              <div className="flex flex-col sm:flex-row">
                <Label
                  className="mb-auto mt-auto mr-2 font-semibold basis-2/5"
                  htmlFor="dateEndTaskRow"
                >
                  Date de fin :{" "}
                </Label>
                <TextInput
                  id="dateEndTaskRow"
                  type="text"
                  readOnly
                  value={
                    item.endDate
                      ? new Date(item.endDate).toLocaleDateString("fr-fr", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      : "non renseigné"
                  }
                />
              </div>
            ) : null} */}
            <div className="flex flex-col sm:flex-row">
              <Label
                className="mb-auto mt-auto mr-2 font-semibold basis-2/5"
                htmlFor="plannedDurationTaskRow"
              >
                Durée prévisionnelle :{" "}
              </Label>
              <TextInput
                id="plannedDurationTaskRow"
                type="text"
                readOnly
                value={
                  item.plannedDuration
                    ? getFormattedHours(item.plannedDuration)
                    : "non renseignée"
                }
              />
            </div>
            <div className="flex flex-col sm:flex-row">
              <Label
                className="mb-auto mt-auto mr-2 font-semibold basis-2/5"
                htmlFor="realDurationTaskRow"
              >
                Durée réelle de réalisation :{" "}
              </Label>
              <TextInput
                id="realDurationTaskRow"
                type="text"
                readOnly
                value={
                  item.realDuration
                    ? getFormattedHours(item.realDuration)
                    : "non renseignée"
                }
              />
            </div>
            {/* VOLUME HORAIRE */}
            {/* {item && !item.isPrivate ? (
              <div className="flex flex-col sm:flex-row">
                <Label
                  className="mb-auto mt-auto mr-2 font-semibold basis-2/5"
                  htmlFor="hourlyAmountTaskRow"
                >
                  Volume horaire restant :{" "}
                </Label>
                <TextInput
                  id="hourlyAmountTaskRow"
                  type="text"
                  readOnly
                  value={
                    item.quoteAgreement && item.quoteAgreement.hourlyAmount
                      ? `${item.quoteAgreement.hourlyAmount} h`
                      : "non renseigné"
                  }
                />
              </div>
            ) : null} */}
            {item && !item.isPrivate ? (
              <div className="flex flex-col sm:flex-row">
                <Label
                  className="basis-2/5 mb-auto mt-auto mr-2 font-semibold"
                  htmlFor="customerTaskRow"
                >
                  Client :{" "}
                </Label>
                <div>
                  {item && item.customer
                    ? UserCell(
                        {
                          userName: item.customer.userName
                            ? item.customer.userName
                            : `${item.customer.last_name.toUpperCase()} ${
                                item.customer.first_name
                              }`,
                          avatar: item.customer.avatar_url as string,
                          email: item.customer.email,
                          id: item.customer.id as string,
                          role: item.customer.role as UserRole,
                        },
                        false,
                      )
                    : "non renseigné"}
                </div>
              </div>
            ) : null}
            {user.role !== UserRole.SECRETARY && (
              <div className="flex flex-col sm:flex-row">
                <Label
                  className="mb-auto mt-auto mr-2 font-semibold basis-2/5"
                  htmlFor="secretaryTaskRow"
                >
                  Secrétaire :{" "}
                </Label>

                <div>
                  {item && item.secretary
                    ? UserCell(
                        {
                          userName: `${item.secretary.last_name.toUpperCase()} ${
                            item.secretary.first_name
                          }`,
                          avatar: item.secretary.avatar_url as string,
                          email: item.secretary.email,
                          id: item.secretary.id as string,
                          role: item.secretary.role as UserRole,
                        },
                        false,
                      )
                    : "non renseignée"}
                </div>
              </div>
            )}
            <Label
              className="mb-auto mt-auto mr-2 font-semibold"
              htmlFor="descriptionTaskRow"
            >
              Description :{" "}
            </Label>
            <div className="basis-1/2 items-end">
              <Textarea
                id="descriptionTaskRow"
                placeholder="Laissez un commentaire..."
                rows={5}
                value={item.description ? item.description : "non renseignée"}
                readOnly
              />
            </div>

            <div className="flex flex-row basis-1/4">
              <Label
                className="mb-auto mt-auto mr-2 font-semibold"
                htmlFor="statusTaskRow"
              >
                Statut :{" "}
              </Label>
              {item.status ? TaskStatusCell(item.status) : "non renseigné"}
            </div>
          </Card>
        ) : null}

        <div className="flex justify-left">
          <button
            type="button"
            className="px-4 py-2 button-add bg-add-blue-dark"
            onClick={handleClick}
          >
            OK
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
