import { useQuery } from "@apollo/client";
import {
  getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer,
  GetAllQuoteAgreements_getAllQuoteAgreements,
  GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary,
  GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin,
  UserRole,
} from "../../API/models/types";
import {
  GET_ALL_QUOTE_AGREEMENTS,
  GET_ALL_QUOTE_AGREEMENTS_FOR_ADMIN,
  GET_ALL_QUOTE_AGREEMENTS_FOR_CUSTOMER,
  GET_ALL_QUOTE_AGREEMENTS_FOR_SECRETARY,
} from "../../API/modules/quote-agreement/resolvers/queries";

// liste contrat devis pour role admin
export default function GetAllQuoteAgreement() {
  const { data, loading, error } = useQuery(GET_ALL_QUOTE_AGREEMENTS, {});
  const quoteAgreements = data
    ? ([
        ...data.getAllQuoteAgreements,
      ] as GetAllQuoteAgreements_getAllQuoteAgreements[])
    : ([] as GetAllQuoteAgreements_getAllQuoteAgreements[]);
  return { quoteAgreements, loading, error };
}

// contrat devis pour role admin
export const getOneQuoteAgreement = (
  id: string,
  getAllQuoteAgreements: GetAllQuoteAgreements_getAllQuoteAgreements[],
): GetAllQuoteAgreements_getAllQuoteAgreements => {
  return getAllQuoteAgreements.filter(
    (element: GetAllQuoteAgreements_getAllQuoteAgreements) => element.id === id,
  )[0];
};

// liste contrat devis pour role client
export function GetAllQuoteAgreementForCustomer(userId: string) {
  const { data, loading, error } = useQuery(
    GET_ALL_QUOTE_AGREEMENTS_FOR_CUSTOMER,
    {
      variables: { customerId: userId },
    },
  );
  const quoteAgreements = data
    ? ([
        ...data.getAllQuoteAgreementsForCustomer,
      ] as getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer[])
    : ([] as getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer[]);

  return { quoteAgreements, loading, error };
}

// contrat devis pour role client
export const getOneQuoteAgreementForCustomer = (
  id: string,
  getAllQuoteAgreementsForCustomer: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer[],
): getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer => {
  return getAllQuoteAgreementsForCustomer.filter(
    (
      element: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer,
    ) => element.id === id,
  )[0];
};
// liste contrat devis pour role secrétaire
export function GetAllQuoteAgreementForSecretary(userId: string) {
  const { data, loading, error } = useQuery(
    GET_ALL_QUOTE_AGREEMENTS_FOR_SECRETARY,
    {
      variables: { secretaryId: userId },
    },
  );

  const quoteAgreements = data
    ? ([
        ...data.getAllQuoteAgreementsForSecretary,
      ] as GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary[])
    : ([] as GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary[]);
  return { quoteAgreements, loading, error };
}
// contrat devis pour role admin
export const getOneQuoteAgreementForAdmin = (
  id: string,
  getAllQuoteAgreementForAdmin: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin[],
): GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin => {
  return getAllQuoteAgreementForAdmin.filter(
    (element: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin) =>
      element.id === id,
  )[0];
};
// liste contrat devis pour role admin
export function GetAllQuoteAgreementForAdmin(adminId: string) {
  const { data, loading, error } = useQuery(
    GET_ALL_QUOTE_AGREEMENTS_FOR_ADMIN,
    {
      variables: { adminId },
    },
  );

  const quoteAgreements = data
    ? ([
        ...data.getAllQuoteAgreementsForAdmin,
      ] as GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin[])
    : ([] as GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin[]);
  return { quoteAgreements, loading, error };
}
// contrat devis pour role secrétaire
export const getOneQuoteAgreementForSecretary = (
  id: string,
  getAllQuoteAgreementsForSecretary: GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary[],
): GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary => {
  return getAllQuoteAgreementsForSecretary.filter(
    (element) => element.id === id,
  )[0];
};

// refetch method en fonction du role
export const refetchQueries = (role: UserRole) => {
  if (role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN) {
    return GET_ALL_QUOTE_AGREEMENTS;
  }
  if (role === UserRole.SECRETARY) {
    return GET_ALL_QUOTE_AGREEMENTS_FOR_CUSTOMER;
  }
  if (role === UserRole.CUSTOMER) {
    return GET_ALL_QUOTE_AGREEMENTS_FOR_SECRETARY;
  }
  return GET_ALL_QUOTE_AGREEMENTS;
};
