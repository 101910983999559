import { useCallback, useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import Select from "react-tailwindcss-select";
import {
  Modal,
  Label,
  ToggleSwitch,
  Checkbox,
  Select as FlowbiteSelect,
} from "flowbite-react";
import TaskStatusCell from "../../../components/table/cell-component/TaskStatusCell";
import { useUserContext } from "../../../context/userContext";
import {
  GetAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
  TaskStatus,
  UserRole,
} from "../../../API/models/types";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../context/FilterContext";
import { GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES } from "../../../API/modules/category-task-type/resolvers/queries";
import {
  FilterTask,
  InitialFilter,
  OptionsSelect,
  Option,
  ItemTask,
} from "../../models/models";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  datas: ItemTask[];
}
export default function ModalFilterTaskForSuperAdmin(props: Props) {
  // ouverture fermeture modale + data à filtrer
  const { open, setOpen, datas } = props;

  const { filters, setFilters } =
    useContext<FilterTasksContextType>(FilterTasksContext);
  const myFilters: string | null = localStorage.getItem("myFilters");

  const [initialFilter, setInitialFilter] = useState<InitialFilter>(
    myFilters
      ? (JSON.parse(myFilters) as InitialFilter)
      : ({
          secretaryId: undefined,
          customerId: undefined,
          statusToDo: true,
          statusUnrealized: false,
          statusPending: false,
          statusCompleted: false,
          toggleClient: true,
          toggleSecretary: true,
          // label: "Toutes les tâches",
          taskTypeIds: [] as Option[],
        } as InitialFilter),
  );

  // state formulaire filtrage

  const [statusToDo, setStatusToDo] = useState<boolean>(
    initialFilter.statusToDo,
  );
  const [statusPending, setStatusPending] = useState<boolean>(
    initialFilter.statusPending,
  );
  const [statusCompleted, setStatusCompleted] = useState<boolean>(
    initialFilter.statusCompleted,
  );
  const [statusUnrealized, setStatusUnrealized] = useState<boolean>(
    initialFilter.statusUnrealized,
  );
  const [secretaryId, setSecretaryId] = useState<string | undefined>(
    initialFilter.secretaryId ? initialFilter.secretaryId : undefined,
  );
  const [customerId, setCustomerId] = useState<string | undefined>(
    initialFilter.customerId ? initialFilter.customerId : undefined,
  );
  // toggle pour filtrer la liste en fonction du rôle
  const [toggleClient, setToggleClient] = useState<boolean>(
    initialFilter.toggleClient,
  );
  const [toggleSecretary, setToggleSecretary] = useState<boolean>(
    initialFilter.toggleSecretary,
  );
  const [taskTypeIds, setTaskTypeIds] = useState<Option[]>(
    initialFilter.taskTypeIds ? initialFilter.taskTypeIds : [],
  );

  // fonction initialiser le filtre
  const handleClick = () => {
    setStatusCompleted(true);
    setStatusUnrealized(true);
    setStatusToDo(true);

    setStatusPending(true);
    setSecretaryId(undefined);
    setCustomerId(undefined);
    // reinitialise le tableau des categories
    // buildCategoriesTasks();
    setToggleClient(true);
    setToggleSecretary(true);
    setTaskTypeIds([] as Option[]);
  };

  // application du filtre sur la donnée
  const handleSubmit = useCallback(() => {
    const filterDatas: FilterTask = { ...filters };

    // filtre client et secretaire
    if (
      secretaryId &&
      secretaryId !== "" &&
      toggleSecretary &&
      secretaryId !== "Toutes les secrétaires"
    ) {
      filterDatas.secretaryId = secretaryId;
    } else {
      filterDatas.secretaryId = undefined;
    }

    if (
      customerId &&
      customerId !== "" &&
      toggleClient &&
      customerId !== "Tous les clients"
    ) {
      filterDatas.customerId = customerId;
    } else {
      filterDatas.customerId = undefined;
    }
    // filtre statut
    const roles: TaskStatus[] = [];
    if (statusToDo === true) {
      const index = roles.indexOf(TaskStatus.TODO);
      if (index === -1) {
        roles.push(TaskStatus.TODO);
      }
    }
    if (statusToDo === false) {
      const index = roles.indexOf(TaskStatus.TODO);
      if (index !== -1) {
        // Supprimer l'élément à l'indice trouvé
        roles.splice(index, 1);
      }
    }

    if (statusCompleted === true) {
      const index = roles.indexOf(TaskStatus.COMPLETED);
      if (index === -1) {
        roles.push(TaskStatus.COMPLETED);
      }
    }
    if (statusCompleted === false) {
      const index = roles.indexOf(TaskStatus.COMPLETED);
      if (index !== -1) {
        // Supprimer l'élément à l'indice trouvé
        roles.splice(index, 1);
      }
    }
    if (statusPending === true) {
      const index = roles.indexOf(TaskStatus.PENDING);
      if (index === -1) {
        roles.push(TaskStatus.PENDING);
      }
    }
    if (statusPending === false) {
      const index = roles.indexOf(TaskStatus.PENDING);
      if (index !== -1) {
        // Supprimer l'élément à l'indice trouvé
        roles.splice(index, 1);
      }
    }
    if (statusUnrealized === true) {
      const index = roles.indexOf(TaskStatus.UNREALIZED);
      if (index === -1) {
        roles.push(TaskStatus.UNREALIZED);
      }
    }
    if (statusUnrealized === false) {
      const index = roles.indexOf(TaskStatus.UNREALIZED);
      if (index !== -1) {
        // Supprimer l'élément à l'indice trouvé
        roles.splice(index, 1);
      }
    }

    filterDatas.status = roles;

    // filtre category
    const array: string[] = [];
    if (taskTypeIds && taskTypeIds.length > 0) {
      taskTypeIds.map((cat: Option) => {
        const index = array.indexOf(cat.value);
        if (index === -1) {
          array.push(cat.value);
        }
        return cat;
      });
      filterDatas.taskTypeIds = array;
    }
    filterDatas.taskTypeIds = array;
    // reinitialisation de la page à 1
    localStorage.setItem("myTasksListPage", JSON.stringify({ page: 1 }));
    filterDatas.page = 1;

    localStorage.setItem(
      "myFilters",
      JSON.stringify({
        taskTypeIds,
        statusCompleted,
        statusPending,
        statusToDo,
        statusUnrealized,
        secretaryId,
        customerId,
        toggleClient,
        toggleSecretary,
      }),
    );
    setInitialFilter({
      // label,
      statusCompleted,
      taskTypeIds,
      statusPending,
      statusToDo,
      statusUnrealized,
      secretaryId,
      customerId,
      toggleClient,
      toggleSecretary,
    });

    setFilters(filterDatas);
  }, [
    customerId,
    filters,
    secretaryId,
    setFilters,
    statusCompleted,
    statusPending,
    statusToDo,
    statusUnrealized,
    taskTypeIds,
    toggleClient,
    toggleSecretary,
  ]);
  // useLayoutEffect(() => {
  //   // Fonction de soumission et modification de l'affichage ici
  //   handleSubmit();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // contexte user
  const { user } = useUserContext();

  const { data: dataFromQuery } = useQuery(
    GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES,
  );
  const getAllCategoryTaskTypeWithTaskTypes = dataFromQuery
    ? ([
        ...dataFromQuery.getAllCategoryTaskTypeWithTaskTypes,
      ] as GetAllCategoryTaskTypeWithTaskTypes[])
    : ([] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[]);

  // options select en fonction du role
  const optionsCustomerSelect = () => {
    // fonction ppur avoir la liste des clients sélectionnables
    const customers = datas.map((element) => {
      return element.client?.id;
    });
    // fonction pour avoir un tableau d' id avec des clients uniques
    let uniqueCustomers = customers.filter(
      (element, index) => customers.indexOf(element) === index,
    );
    // construction du tableau pour les champs de séléction des clients
    const customersOptions: ItemTask[] = [];
    datas.map((element: ItemTask) => {
      if (
        uniqueCustomers.includes(element.client?.id) &&
        uniqueCustomers.length > 0
      ) {
        const index: number = uniqueCustomers.indexOf(element.client?.id);
        if (uniqueCustomers.length > 1) {
          uniqueCustomers.splice(index, 1);
        } else {
          uniqueCustomers = [];
        }
        customersOptions.push(element);
      }
      return element;
    });
    // retour options
    return (
      customersOptions.length > 0 &&
      customersOptions.map((element: ItemTask) => {
        return (
          <option
            key={element.id}
            value={element.client ? element.client.id : undefined}
          >
            {element.client && element.client.userName}
          </option>
        );
      })
    );
  };
  const optionsSecretarySelect = () => {
    // fonction ppur avoir la liste des secrétaires sélectionnables
    const secretaries = datas.map((element) => {
      return element.secretary?.id;
    });
    // fonction pour avoir un tableau avec des donnees uniques
    let uniqueSecretaries = secretaries.filter(
      (element, index) => secretaries.indexOf(element) === index,
    );

    // construction du tableau pour les champs de sélection des secrétaires
    const secretariesOptions: ItemTask[] = [];
    datas.map((element: ItemTask) => {
      if (
        uniqueSecretaries.includes(element.secretary?.id) &&
        uniqueSecretaries.length > 0
      ) {
        const index: number = uniqueSecretaries.indexOf(element.secretary?.id);
        if (uniqueSecretaries.length > 1) {
          uniqueSecretaries.splice(index, 1);
        } else {
          uniqueSecretaries = [];
        }
        secretariesOptions.push(element);
      }
      return element;
    });
    // retour options
    return (
      secretariesOptions.length > 0 &&
      secretariesOptions.map((element: ItemTask) => {
        return (
          <option
            key={element.id}
            value={element.secretary ? element.secretary.id : ""}
          >
            {element.secretary && element.secretary.userName}
          </option>
        );
      })
    );
  };

  // TaskType Select options
  const getOptions = (
    getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
  ) => {
    const optionsToDisplay: OptionsSelect[] = [];
    const categoryArray = [
      ...getAllCategory,
    ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    categoryArray &&
      categoryArray.map(
        (
          element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
        ) => {
          const subOptionsArray: Option[] = [];
          const tasktypes = [
            ...element.taskTypes,
          ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasktypes &&
            tasktypes.map(
              (
                tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
              ) => {
                subOptionsArray.push({
                  value: tasktype.id,
                  label: tasktype.label,
                });
                return tasktype;
              },
            );
          optionsToDisplay.push({
            label: element.label,
            options: subOptionsArray,
          });
          return element;
        },
      );
    return optionsToDisplay;
  };
  const getOptionsForOneCategory = (
    getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
    categoryLabel: string,
  ) => {
    const optionsToDisplay: OptionsSelect[] = [];
    const categoryArray = [
      ...getAllCategory,
    ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const filterCategoryArray = categoryArray.filter(
      (element) => element.label === categoryLabel,
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    filterCategoryArray.length > 0 &&
      filterCategoryArray.map(
        (
          element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
        ) => {
          const subOptionsArray: Option[] = [];
          const tasktypes = [
            ...element.taskTypes,
          ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasktypes &&
            tasktypes.map(
              (
                tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
              ) => {
                subOptionsArray.push({
                  value: tasktype.id,
                  label: tasktype.label,
                });
                return tasktype;
              },
            );
          optionsToDisplay.push({
            label: element.label,
            options: subOptionsArray,
          });
          return element;
        },
      );

    return optionsToDisplay[0].options.flat();
  };
  return (
    <Modal size="3xl" show={open} popup onClose={() => setOpen(false)}>
      <div className="w-full border-b-2 border-gray-200 mb-5">
        <Modal.Header>
          <p className="font-semibold">Filtres</p>
        </Modal.Header>
      </div>
      <Modal.Body>
        <div className="flex justify-left">
          <button
            type="button"
            className="button-add bg-add-blue-dark mr-2 text-sm"
            onClick={() => {
              handleSubmit();
              setOpen(!open);
            }}
          >
            Appliquer
          </button>

          <button
            type="button"
            className="px-2 py-1 button-add bg-add-blue-dark text-sm"
            onClick={handleClick}
          >
            Vider
          </button>
        </div>
        <div className="mb-3">
          <div className="mb-2 block">
            <Label
              htmlFor="contractNameSelectFilterQuoteAgreement"
              value="Sélectionnez un type de tâche"
            />
          </div>
          <Select
            value={taskTypeIds}
            onChange={(newValue: any) => {
              setTaskTypeIds(newValue);
            }}
            options={
              getOptions(getAllCategoryTaskTypeWithTaskTypes as any) as any
            }
            isMultiple
            isClearable
            isSearchable
            // eslint-disable-next-line react/no-unstable-nested-components
            formatGroupLabel={(data: OptionsSelect) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                className="py-2 text-xs flex items-center justify-between"
                onClick={() => {
                  if (
                    taskTypeIds !== null &&
                    taskTypeIds &&
                    taskTypeIds.length > 0
                  ) {
                    setTaskTypeIds(
                      taskTypeIds.concat(
                        getOptionsForOneCategory(
                          getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                          data.label,
                        ),
                      ) as any,
                    );
                  } else {
                    setTaskTypeIds(
                      getOptionsForOneCategory(
                        getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                        data.label,
                      ) as any,
                    );
                  }
                }}
              >
                <span className="font-bold">{data.label}</span>
                <span className="bg-gray-200 h-5 p-1.5 flex items-center justify-center rounded-full">
                  {data.options.length}
                </span>
              </div>
            )}
            primaryColor="teal"
            placeholder="Sélectionnez ..."
          />
        </div>
        <div className="flex flex-col gap-4 mb-3" id="toggle">
          {user.role !== UserRole.CUSTOMER ? (
            <ToggleSwitch
              checked={toggleClient}
              label="Client"
              onChange={() => setToggleClient(!toggleClient)}
            />
          ) : null}
          {user.role !== UserRole.SECRETARY ? (
            <ToggleSwitch
              checked={toggleSecretary}
              label="Secrétaire"
              onChange={() => setToggleSecretary(!toggleSecretary)}
            />
          ) : null}
        </div>
        {user.role !== UserRole.CUSTOMER ? (
          <div className="mb-3">
            <div className="mb-2 block">
              <Label
                htmlFor="customerSelectFilterQuoteAgreement"
                value="Sélectionnez un client"
              />
            </div>
            <FlowbiteSelect
              value={customerId}
              onChange={(e: any) => {
                if (e.target.value === "") {
                  // si "tous les clients" on initialise l id à undefined
                  setCustomerId(undefined);
                } else {
                  setCustomerId(e.target.value);
                }
              }}
              id="customerSelectFilterQuoteAgreement"
              disabled={!toggleClient}
            >
              <option value="">Tous les clients</option>
              {optionsCustomerSelect()}
            </FlowbiteSelect>
          </div>
        ) : null}
        {user.role !== UserRole.SECRETARY ? (
          <div className="mb-3">
            <div className="mb-2 block">
              <Label
                htmlFor="secretarySelectFilterQuoteAgreement"
                value="Selectionnez une secrétaire"
              />
            </div>
            <FlowbiteSelect
              value={secretaryId}
              disabled={!toggleSecretary}
              defaultValue={secretaryId}
              id="secretarySelectFilterQuoteAgreement"
              onChange={(e: any) => {
                if (e.target.value === "") {
                  // si "Toutes les secrétaires" on initialise l id à undefined
                  setSecretaryId(undefined);
                } else {
                  setSecretaryId(e.target.value);
                }
              }}
            >
              <option value="">Toutes les secrétaires</option>
              {optionsSecretarySelect()}
            </FlowbiteSelect>
          </div>
        ) : null}
        <div className="flex flex-col gap-4" id="checkboxfilterActivityTask">
          <Label htmlFor="checkboxfilterActivityTask">Statut</Label>
          <div className="flex items-center gap-2">
            <Checkbox
              id="toDoTask"
              checked={statusToDo}
              onChange={() => {
                setStatusToDo(!statusToDo);
              }}
            />
            {TaskStatusCell(TaskStatus.TODO)}
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              color="#40788B"
              id="refusedTask"
              checked={statusUnrealized}
              onChange={() => {
                setStatusUnrealized(!statusUnrealized);
              }}
            />
            {TaskStatusCell(TaskStatus.UNREALIZED)}
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              color="#40788B"
              id="doneTask"
              checked={statusCompleted}
              onChange={() => {
                setStatusCompleted(!statusCompleted);
              }}
            />
            {TaskStatusCell(TaskStatus.COMPLETED)}
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              color="#40788B"
              id="doneTask"
              checked={statusPending}
              onChange={() => {
                setStatusPending(!statusPending);
              }}
            />
            {TaskStatusCell(TaskStatus.PENDING)}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
