import { Card, Table } from "flowbite-react";

import { Link } from "react-router-dom";
import "./secretaryCharts.css";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import ReactApexChart from "react-apexcharts";

import { GET_DATA_FOR_SECRETARY_DASHBOARD } from "../../API/modules/dashboard/queries";

import Avatar from "../../components/shared/Avatar";
import ProfileButton from "../../components/table/cell-component/ProfileButton";
import DateCell from "../../components/table/cell-component/DateCell";
import {
  GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard,
  GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard_tasks,
  TaskStatus,
  UserRole,
} from "../../API/models/types";
import TaskStatusCell from "../../components/table/cell-component/TaskStatusCell";
import getAvatarUrl from "../utils/getAvatarUrl";
import { User } from "../../hooks/useLocalStorage";
import getFormattedHours from "../Calendar/utils/getFormattedHours";

interface Props {
  secretaryId: string;
}

export default function SecretaryDashboard(props: Props) {
  const { secretaryId } = props;
  const { data } = useQuery(GET_DATA_FOR_SECRETARY_DASHBOARD, {
    variables: { input: { secretaryId } },
  });

  const getDataForSecretaryDashBoard =
    data?.getDataForSecretaryDashBoard ??
    ({} as GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard);

  const {
    graphData,
    contractPercentInRelationToLastMonth,
    tasks,
    users,
    totalQuoteAgreements,
    graphX,
  } = getDataForSecretaryDashBoard || {
    graphData: [],
    contractPercentInRelationToLastMonth: 0,
    graphX: [],
    totalQuoteAgreements: 0,
    tasks:
      [] as GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard_tasks[],
    users: [] as User[],
  };

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: graphX && graphX.length > 0 && graphX,
      labels: {
        style: {
          colors: "#888888",
          fontSize: "18px",
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      opposite: true,
      show: false,
      axisBorder: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val: any) {
          return `${val}`;
        },
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      labels: {
        colors: ["#FFA500", "#0000FF"],
      },
    },
  };

  return (
    <div className="w-full h-full">
      <div className="h-1/2 p-6">
        <Card className="h-full flex flex-col overflow-auto">
          <div className="h-1/6 justify-between">
            <h2 className="font-semibold">Tâches</h2>
            <span className="text-xs text-gray-400">
              Liste des dernières tâches en cours
            </span>
            <hr className="h-px mb-4 mt-4 bg-gray-200 border-0 dark:bg-gray-700" />
          </div>

          <div className="h-4/6 max-h-4/6 overflow-auto">
            <Table>
              <Table.Head>
                <Table.HeadCell>Tâches </Table.HeadCell>
                <Table.HeadCell>Date</Table.HeadCell>
                <Table.HeadCell>Heures prévues</Table.HeadCell>
                <Table.HeadCell>Statut</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y h-full">
                {tasks &&
                  tasks.length > 0 &&
                  tasks.map(
                    (
                      task: GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard_tasks,
                    ) => {
                      const date = new Date(task.startDate);
                      return (
                        <Table.Row key={task.id} className="bg-white">
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {task.label}
                          </Table.Cell>
                          <Table.Cell>
                            {DateCell(
                              date.toLocaleDateString("fr-fr", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              }),
                              false,
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {" "}
                            {getFormattedHours(task.plannedDuration)}
                          </Table.Cell>
                          <Table.Cell>
                            {TaskStatusCell(task.status as TaskStatus)}
                          </Table.Cell>
                        </Table.Row>
                      );
                    },
                  )}
              </Table.Body>
            </Table>
          </div>
          <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 pt-3 sm:pt-6 h-1/6">
            <div className="shrink-0">
              <Link
                className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
                to="/liste-des-taches-en-cours"
              >
                Liste des tâches en cours
                <svg
                  className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
            <div className="shrink-0">
              <Link
                className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
                to="/liste-des-taches-en-attente-d-acceptation"
              >
                Liste des tâches en attente d'acceptation
                <svg
                  className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </Card>
      </div>
      <div className="w-full flex flex-col md:flex-row h-1/2">
        <div className="w-full md:w-1/2">
          <div className="p-7 sm:p-10 place-self-end">
            <div className="mb-4 rounded-lg bg-white py-6 px-4 md:px-10 shadow sm:p-6 w-fit">
              <div className="mb-4 flex items-center justify-between">
                <h3 className="font-bold leading-none dark:text-white">
                  Derniers clients affectés
                </h3>
              </div>
              <div className="flow-root">
                <ul className="divide-y divide-gray-200 max-h-96 overflow-y-auto scrollbar-thumb-add-blue-dark scrollbar-track-gray-100 scrollbar-thin">
                  {users &&
                    users.length > 0 &&
                    users.map((user: any) => (
                      <li className="py-3 sm:py-4 w-full text-sm" key={user.id}>
                        <div className="flex flex-row justify-between items-center w-full">
                          <div className="flex flex-row">
                            <Avatar
                              imgUrl={getAvatarUrl(
                                UserRole.CUSTOMER,
                                user.avatar_url,
                              )}
                            />
                            <div className="flex flex-col">
                              <div className="font-bold">
                                {user.last_name.toUpperCase()} {user.first_name}
                              </div>
                              <span className="text-gray-500 pr-2">
                                {user.email}
                              </span>
                            </div>
                          </div>
                          <div className="font-bold mr-3">
                            {ProfileButton(`/profil/${user.id}`)}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 pt-3 sm:pt-6">
                <div className="shrink-0">
                  <Link
                    to="/liste-des-clients"
                    className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
                  >
                    Liste des clients
                    <svg
                      className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-7">
          <Card className="min-h-full">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div className="flex flex-col w-5/6">
                  <span className="font-semibold text-lg">
                    {totalQuoteAgreements && totalQuoteAgreements}
                  </span>
                  <div className="text-gray-400 mb-3">
                    Nouveaux contrats affectés ce mois-ci
                  </div>
                </div>
                <div className="w-1/6 flex flex-row">
                  <span
                    className={`font-semibold ${
                      contractPercentInRelationToLastMonth &&
                      contractPercentInRelationToLastMonth.includes("+")
                        ? "text-green-500"
                        : "text-red-500 text-lg"
                    }`}
                  >
                    {contractPercentInRelationToLastMonth &&
                      contractPercentInRelationToLastMonth}{" "}
                    %
                  </span>
                  {contractPercentInRelationToLastMonth &&
                  contractPercentInRelationToLastMonth.includes("+") > 0 ? (
                    <FaArrowUp className="mt-1" color="green" />
                  ) : (
                    <FaArrowDown className="mt-1" color="red" />
                  )}
                </div>
              </div>

              <div className="flex flex-col w-full xl:w-6/12 sm:h-auto mr-auto ml-auto">
                {data ? (
                  <ReactApexChart
                    options={options || {}}
                    series={graphData || []}
                    type="bar"
                    // height={350}
                    // width={700}
                  />
                ) : null}
              </div>
            </div>
            <div className="flex items-center justify-between border-t border-gray-200 pt-3 sm:pt-6">
              {/* <div className="shrink-0">
                <Link
                  className="inline-flex items-center rounded-lg p-2 text-xs font-medium hover:bg-gray-100 sm:text-sm"
                  to="/"
                >
                  Les 4 dernières semaines
                </Link>
              </div> */}
              <div className="shrink-0">
                <Link
                  className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
                  to="/contrat-devis"
                >
                  Liste des contrats
                  <svg
                    className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
