import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { Checkbox, Label, TextInput } from "flowbite-react";
import { useMutation } from "@apollo/client";
import appIllustration from "../assets/illustrations/img-connection.png";
import logo from "../assets/logo/logo-add-with-text.png";
import { LOGIN } from "../API/modules/auth/mutation";
import useLocalStorageService from "../hooks/useLocalStorage";
import { UserContext } from "../context/userContext";
import ResetPasswordModal from "../components/resetPasswordModal";
import IsTestingEnvironment from "../components/shared/IsTestingEnvironment";

interface LoginFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

function Login() {
  const { setUser } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  // Queries or mutations
  // LOGIN
  const [login, { error: BadCredentialsError }] = useMutation(LOGIN);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<LoginFormValues>();
  // local Storage initialisation
  localStorage.clear();

  // Initialisé remberMe a true pour que l'utilisateur sauvegarde sa connexion par défaut
  useEffect(() => {
    setValue("rememberMe", true);
  }, [setValue]);

  // Methods
  // HANDLE LOGIN
  const { saveUser } = useLocalStorageService();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<LoginFormValues> = async (values) => {
    try {
      const { data } = await login({
        variables: {
          username: values.email,
          password: values.password,
        },
      });

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { accessToken, isActive, role, id, avatar_url } = data.authLogin;
      // Si l'utilisateur a choisi "se rappeler de moi" => on garde les infos user dans le localstorage
      // Sinon je le passe au context (le token n'existera plus en dehors de la session)
      if (values.rememberMe) {
        saveUser({
          token: accessToken,
          email: values.email,
          isActive,
          role,
          id,
          avatarUrl: avatar_url,
        });
        setUser({
          token: accessToken,
          email: values.email,
          isActive,
          role,
          id,
          avatarUrl: avatar_url,
        });
      } else {
        // On met à jour le context
        setUser({
          token: accessToken,
          email: values.email,
          isActive,
          role,
          id,
          avatarUrl: avatar_url,
        });
      }
      if (isActive === false) {
        navigate("/etapes-inscription");
      } else {
        navigate("/");
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const isPreprod = window.location.hostname.includes("preprod.");

  return (
    <main className="min-w-screen min-h-screen flex items-center justify-center px-5 py-5">
      {isPreprod && <IsTestingEnvironment />}
      <div className="rounded-3xl shadow-xl lg:w-1/2 overflow-hidden h-full">
        <div className="md:flex w-full h-full">
          <img
            alt="Atelier du dirigeant app illustration"
            className="lg:w-1/3 w-full lg:h-auto h-64 object-contain object-left bg-add-dark hidden lg:block p-2"
            src={appIllustration}
          />
          <form
            className="w-full py-6 md:py-14 lg:py-20 px-6 md:px-14"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="mb-6 text-2xl md:text-3xl lg:text-4xl text-center lg:text-start">
              Se connecter à la plateforme
            </h1>
            <div className="flex justify-center">
              <img
                alt="logo Atelier du dirigeant"
                className="w-20 h-20 lg:hidden my-4"
                src={logo}
              />
            </div>
            <div className="mb-6 flex flex-col gap-y-3">
              <Label htmlFor="emailLogin">Votre email</Label>
              <TextInput
                id="emailLogin"
                placeholder="name@example.com"
                type="email"
                required
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="text-red-600">L'email est requis</span>
              )}
            </div>
            <div className="mb-6 flex flex-col gap-y-3">
              <Label htmlFor="password">Votre mot de passe</Label>
              <TextInput
                id="password"
                placeholder="••••••••"
                type="password"
                required
                {...register("password", { required: true })}
              />
              {errors.password && (
                <span className="text-red-600">Le mot de passe est requis</span>
              )}
            </div>
            {BadCredentialsError && (
              <span className="text-red-600 block mb-6">
                {BadCredentialsError.message.includes(
                  'Could not find any entity of type "User" matching',
                ) ||
                BadCredentialsError.message.includes(
                  "Email ou le mot de passe incorrect(s)",
                )
                  ? "Votre email ou mot de passe est incorrect."
                  : "Une erreur est survenue. Veuillez réessayer plus tard."}
              </span>
            )}
            <div className="mb-6 flex items-center justify-between">
              <div className="flex items-center gap-x-3">
                <Checkbox id="rememberMe" {...register("rememberMe")} />
                <Label htmlFor="rememberMe">Rester connecté</Label>
              </div>
              <button
                type="button"
                className="w-1/2 text-right text-sm text-add-blue-dark hover:text-cyan-700 hover:underline hover:underline-offset-2"
                onClick={() => setIsOpen(true)}
              >
                Mot de passe oublié?
              </button>
            </div>
            <ResetPasswordModal setIsOpen={setIsOpen} isOpen={isOpen} />
            <div className="mb-6">
              <button
                type="submit"
                className="w-full bg-add-blue-dark p-2 uppercase font-semibold button-add"
              >
                Se connecter
              </button>
            </div>
            <p className="text-sm text-add-dark">
              Pas encore enregistré?&nbsp;
              <Link
                to="/inscription"
                className="text-add-blue-dark hover:text-cyan-700 hover:underline hover:underline-offset-2"
              >
                Créer un compte
              </Link>
            </p>
          </form>
        </div>
      </div>
    </main>
  );
}

export default Login;
