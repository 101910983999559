/* eslint-disable import/no-cycle */
import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import SubscriptionsChart from "./SubscriptionsChart";
import DatePickerUsersSubscriptionChart from "./DatePickerUsersSubscriptionChart";
import { DateRangeProvider } from "./context/DateRangeContext";
import { GET_SUBSCRIPTIONS_DATA } from "../../../../../API/modules/dashboard/resolvers/queries";
import EvolutionUsersValue from "./EvolutionUsersValue";
import NumberOfUsersJoined from "./NumberOfUsersJoined";
import {
  GetSubscriptionsData,
  GetSubscriptionsDataVariables,
} from "../../../../../API/models/types";

function UsersSubscriptionChart() {
  const { data } = useQuery<
    GetSubscriptionsData,
    GetSubscriptionsDataVariables
  >(GET_SUBSCRIPTIONS_DATA, {
    variables: { dateRange: { dateRange: [7, 30] } },
  });

  const subscriptionsData = data?.getSubscriptionsData;

  return (
    <DateRangeProvider>
      <div className="rounded-lg bg-white p-2 shadow dark:bg-gray-800 sm:px-6 xl:px-8">
        <div className="mb-4 flex flex-col md:flex-row items-center text-center md:text-start justify-between">
          {subscriptionsData ? (
            <NumberOfUsersJoined data={subscriptionsData} />
          ) : null}
          {subscriptionsData ? (
            <EvolutionUsersValue data={subscriptionsData} />
          ) : null}
        </div>

        {subscriptionsData ? (
          <SubscriptionsChart data={subscriptionsData} />
        ) : null}
        <div className="mt-2 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 sm:pt-2 pt-3">
          <DatePickerUsersSubscriptionChart values={["7", "30"]} />
          <div className="flex flex-col sm:flex-row items-center justify-between">
            <div className="shrink-0">
              <Link
                to="/liste-des-secretaires"
                className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
              >
                Liste des secrétaires
                <svg
                  className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
            <div className="shrink-0">
              <Link
                to="/liste-des-clients"
                className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
              >
                Liste des clients
                <svg
                  className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </DateRangeProvider>
  );
}

export default UsersSubscriptionChart;
