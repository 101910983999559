import { DocumentNode, gql } from "@apollo/client";

export const EDIT_USER: DocumentNode = gql`
  mutation UserUpdate($userId: ID!, $input: UserUpdateInput!) {
    userUpdate(userId: $userId, input: $input) {
      user {
        id
        createdAt
        updatedAt
        first_name
        last_name
        avatar_url
        phone_number
        activity_sector
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        admin {
          id
        }
        taskTypes {
          id
          createdAt
          updatedAt
          label
          categoryTaskType {
            id
            label
          }
        }
      }
    }
  }
`;

export const REMOVE_USER: DocumentNode = gql`
  mutation UserDelete($userId: ID!) {
    userDelete(userId: $userId) {
      userId
    }
  }
`;

export const CREATE_USER: DocumentNode = gql`
  mutation UserCreate($input: UserCreateInput!) {
    userCreate(input: $input) {
      user {
        id
        createdAt
        updatedAt
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        activity_sector
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
      }
    }
  }
`;

export const SEND_PASSWORD_RESET_REQUEST: DocumentNode = gql`
  mutation SendPasswordResetRequest($email: String!) {
    sendPasswordResetRequest(email: $email) {
      resetPasswordToken
    }
  }
`;
export const RESET_PASSWORD: DocumentNode = gql`
  mutation ResetPassword($resetPasswordToken: String!, $newPassword: String!) {
    resetPassword(
      resetPasswordToken: $resetPasswordToken
      newPassword: $newPassword
    )
  }
`;
export const RESET_PASSWORD_FOR_CONNECTED_USER: DocumentNode = gql`
  mutation ResetPasswordForConnectedUser(
    $resetPasswordToken: String!
    $newPassword: String!
    $currentPassword: String!
  ) {
    resetPasswordForConnectedUser(
      resetPasswordToken: $resetPasswordToken
      newPassword: $newPassword
      currentPassword: $currentPassword
    )
  }
`;
export const SEND_VERIFICATION_EMAIL: DocumentNode = gql`
  mutation SendVerificationEmail($email: UserSendEmailVerificationInput!) {
    sendVerificationEmail(email: $email)
  }
`;

export const VERIFY_USER_EMAIL: DocumentNode = gql`
  mutation userEmailVerification($input: UserEmailVerificationDto!) {
    userEmailVerification(input: $input)
  }
`;

export const CHANGE_USER_ROLE: DocumentNode = gql`
  mutation changeUserRole($adminId: String!) {
    changeUserRole(adminId: $adminId)
  }
`;

export const SEND_ADMIN_REGISTER_LINK: DocumentNode = gql`
  mutation SendAdminRegisterLink($email: String!) {
    sendAdminRegisterLink(email: $email)
  }
`;

export const VERIFY_TOKEN_ADMIN_REGISTARTION: DocumentNode = gql`
  mutation VerifyTokenAdminRegistration($token: String!) {
    verifyTokenAdminRegistration(token: $token) {
      message
      email
    }
  }
`;
export const UPLOAD_AVATAR = gql`
  mutation UploadFile($file: Upload!, $userId: String!) {
    uploadFile(file: $file, userId: $userId)
  }
`;
export const REMOVE_AVATAR = gql`
  mutation RemoveAvatarUrl($userId: String!) {
    removeAvatarUrl(userId: $userId)
  }
`;
