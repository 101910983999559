import { useMutation } from "@apollo/client";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { HiInformationCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import {
  CreateTaskInput,
  Recurrence,
  TaskStatus,
} from "../../API/models/types";
import { CREATED_TASK } from "../../API/modules/task/resolvers/mutations";
import {
  FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
  FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
  FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
} from "../../API/modules/task/resolvers/queries";

import ColorPicker from "../../components/shared/ColorPicker";
import SpinnerComponent from "../../components/shared/SpinnerComponent";
import TaskStatusCell from "../../components/table/cell-component/TaskStatusCell";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../context/FilterContext";
import { useUserContext } from "../../context/userContext";
import { FilterTask } from "../models/models";
import { colorsClasses } from "../utils/constants";
import DateAndHourPicker from "./components/DateAndHourPicker";
import SelectDuration from "./components/SelectDuration";
import SelectTaskType from "./components/SelectTaskType";

export default function CreateSecretariesPrivateTask() {
  // formulaire
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormCreateTask>();
  // routeur
  const navigate = useNavigate();

  const [selectedColor, setSelectedColor] = useState<string>("blue");
  // contexte user
  const { user } = useUserContext();
  const { filters } = useContext<FilterTasksContextType>(FilterTasksContext);
  // state selected
  const [plannedDurationSelected, setPlanDurationSelected] =
    useState<string>("");
  const [dataAndHourSelected, setDateAndHourSelected] = useState<Date>();

  // boolean nouvelle tâche après submit formulaire
  const [isNewTask, setNewTask] = useState<boolean>(false);

  // recurrence
  const [recurrenceSelected, setRecurrenceSelected] = useState<Recurrence>(
    Recurrence.ONE_TIME,
  );
  const [endDateRecurrenceSelected, setEndDateRecurrenceSelected] =
    useState<string>("");

  // RESET STATE (pour le "enregister + nouvelle")
  const resetState = () => {
    setSelectedColor("blue");
    setPlanDurationSelected("");
    setDateAndHourSelected(undefined);
    setNewTask(false);
    setRecurrenceSelected(Recurrence.ONE_TIME);
    setEndDateRecurrenceSelected("");
  };

  // interface Formulaire de création de tâche
  interface FormCreateTask {
    label: string;
    description: string;
    taskTypeId: string;
    startDate: Date;
    plannedDuration: string;
  }
  // mutation création task
  const [createTask, { loading }] = useMutation(CREATED_TASK);

  // validation formulaire
  const submitTask: SubmitHandler<FormCreateTask> = async (
    values: FormCreateTask,
  ) => {
    if (user.id) {
      const input: CreateTaskInput = {
        authorId: user.id,
        label: values.label,
        isPrivate: true,
        startDate: values.startDate,
        endDate: null,
        endRecurenceDate:
          endDateRecurrenceSelected &&
          recurrenceSelected !== Recurrence.ONE_TIME
            ? new Date(Date.parse(endDateRecurrenceSelected))
            : null,
        recurrence: recurrenceSelected as Recurrence,
        plannedDuration: values.plannedDuration
          ? Number(values.plannedDuration)
          : null,
        quoteAgreementId: null,
        realDuration: null,
        status: TaskStatus.TODO,
        description: values.description ? values.description : null,
        taskTypeId: values.taskTypeId,
        secretaryId: user.id,
        parentTaskId: undefined,
        color: selectedColor,
      };
      if (user !== null && user && user.id && filters !== null && filters) {
        try {
          await createTask({
            variables: {
              input,
            },
            refetchQueries: [
              {
                query: FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
                variables: {
                  userId: user.id,
                  paginationArgs: filters as FilterTask,
                },
              },
              {
                query: FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
                variables: {
                  userId: user.id,
                  paginationArgs: filters as FilterTask,
                },
              },
              {
                query: FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
                variables: {
                  input: { userId: user.id, startDate: new Date() },
                },
              },
            ],
          });
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
      if (isNewTask) {
        // ENREGISTRER TACHE + NOUVELLE
        resetState();
        reset(); // reset de react hook form
      } else {
        // ENREGISTRER TACHE
        navigate("/liste-des-taches-en-cours");
      }
    }
  };
  // Création de deux fonctions distinctes pour gérer les différents cas de soumission
  const onSubmit: SubmitHandler<FormCreateTask> = (values) => {
    submitTask(values);
  };

  // Déclaration d'un état qui va nous permettre de stocker les valeurs saisies dans le formulaire
  // Afin de pouvoir les passer dans le useEffect
  const [formValues, setFormValues] = useState<FormCreateTask | null>(null);
  // Nécessité d'un useEffect pour déclencher le submit uniquement une fois la valeur de isNewTask
  // Evaluée à true
  useEffect(() => {
    if (isNewTask && formValues) {
      submitTask(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewTask, formValues]);

  const onSubmitAndNew: SubmitHandler<FormCreateTask> = (values) => {
    setNewTask(true);
    setFormValues(values);
  };

  const setTaskTypeId = (value: string) => {
    setValue("taskTypeId", value);
  };

  const setPlannedDuration = (value: string) => {
    setPlanDurationSelected(value);
    setValue("plannedDuration", value);
  };
  const setDateAndHourPicker = (value: Date) => {
    setDateAndHourSelected(value);
    setValue("startDate", value);
  };

  // Gestion de la récurrence
  // affichage select recurrence
  const days: string[] = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  const getNumberOfWeekInTheCurrentMonth = useCallback(() => {
    if (dataAndHourSelected) {
      const currentMonth = dataAndHourSelected.getMonth();
      let weekOfMonth: string = "";
      // Obtenir le nombre de jours dans le mois actuel
      const daysInMonth = new Date(
        dataAndHourSelected.getFullYear(),
        currentMonth + 1,
        0,
      ).getDate();

      // Obtenir le jour du mois actuel
      const currentDay = dataAndHourSelected.getDate();

      // Calculer le numéro de la semaine
      const weekNumber: Number = Math.ceil(currentDay / 7);

      // Vérifier si nous sommes dans la dernière semaine du mois
      if ((daysInMonth - currentDay) / 7 < 1) {
        weekOfMonth = "dernier";
      }

      // Afficher le numéro de la semaine avec le suffixe approprié
      // eslint-disable-next-line default-case
      if (weekOfMonth !== "dernier") {
        switch (weekNumber) {
          case 1:
            weekOfMonth = "premier";
            break;
          case 2:
            weekOfMonth = "deuxième";
            break;
          case 3:
            weekOfMonth = "troisième";
            break;
          case 4:
            weekOfMonth = "quatrième";
            break;
          default:
            weekOfMonth = "dernier";
            break;
        }
      }
      return weekOfMonth;
    }
    return "";
  }, [dataAndHourSelected]);

  return (
    <div>
      <div className="sm:fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      {!loading ? (
        <div className="max-h-full overflow-auto min-w-full sm:fixed sm:left-0 sm:flex sm:justify-center sm:items-start">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white rounded-lg shadow-2xl p-6 max-h-full max-w-2xl md:w-5/6 lg:w-2/3 xl:w-7/12"
          >
            <header className="flex flex-col sm:flex-row sm:justify-between items-start border-b pb-1 sm:w-full">
              <h3 className="text-base font-semibold mx-4">Nouvelle tâche</h3>

              <button
                onClick={() => navigate(-1)}
                type="button"
                className="mx-4 hidden sm:block"
              >
                {" "}
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </header>
            <div className="flex justify-between items-center rounded-lg text-sm text-blue-800 bg-blue-100 p-2 mx-4 mt-3 mb-3">
              <HiInformationCircle className="w-5 h-5" />
              <p className="w-full ml-2">
                Vous ne pouvez ajouter que des tâches privées
              </p>
            </div>
            <span
              className={`block rounded-full w-full p-1 bg-${selectedColor}-400 mb-2`}
              style={{ backgroundColor: `${selectedColor}` }}
            />
            {/* Form body */}
            <div>
              <span className="font-light text-gray-500 text-sm">
                (*) champs obligatoires
              </span>
              {/* title and color picker */}

              <div className="flex flex-col sm:flex-row justify-between items-start sm:mb-5 sm:items-end my-6">
                <div className="w-full sm:mb-3 flex flex-col">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-semibold"
                  >
                    Titre
                    <span className="font-normal"> *</span>
                  </label>
                  <div className="flex flex-row w-full">
                    <div className="sm:w-4/5">
                      <input
                        type="text"
                        id="title"
                        className="bg-gray-50 mr-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        placeholder="Gestion des mails"
                        {...register("label", { required: true })}
                      />
                    </div>
                    <div className="w-1/5">
                      <ColorPicker
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                        colorsClasses={colorsClasses}
                      />
                    </div>
                  </div>
                  {errors.label && (
                    <span className="text-red-600 text-sm">
                      un titre de tâche doit être renseigné
                    </span>
                  )}
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  {/* double select */}
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="taskTypeId"
                    render={() => (
                      <SelectTaskType setTaskTypeId={setTaskTypeId} />
                    )}
                  />{" "}
                  {errors.taskTypeId && (
                    <span className="text-red-600 text-sm">
                      un type de tâche doit être renseigné
                    </span>
                  )}
                </div>
                {/* Date and Time picker */}
                <div className="my-3 flex flex-col">
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="startDate"
                    render={() => (
                      <DateAndHourPicker
                        setDateAndHourPicker={setDateAndHourPicker}
                      />
                    )}
                  />{" "}
                  {errors.startDate && (
                    <span className="text-red-600 text-sm">
                      une date et un horaire doivent être renseignés
                    </span>
                  )}
                </div>
                {/* Recurrence and estimated duration */}
                <div className="flex flex-col sm:flex-row w-full">
                  <div className="w-full sm:w-1/3 mr-3">
                    <label
                      htmlFor="recurrence"
                      className="block mb-2 text-sm font-semibold"
                    >
                      Récurrence
                    </label>
                    <select
                      disabled={!dataAndHourSelected}
                      id="recurrence"
                      className="w-full flex-shrink-0 z-10 mb-3 sm:mb-0 mr-3 inline-flex items-center py-2.5 px-4 text-sm font-medium bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                      onChange={(e) => {
                        setRecurrenceSelected(e.target.value as Recurrence);
                      }}
                      defaultValue={recurrenceSelected}
                    >
                      <option value={Recurrence.ONE_TIME}>
                        Une seule fois
                      </option>
                      <option value={Recurrence.ALL_THE_DAY}>
                        Tous les jours
                      </option>
                      <option value={Recurrence.ALL_THE_WEEK}>
                        Toutes les semaines, le{" "}
                        {dataAndHourSelected &&
                          days[dataAndHourSelected?.getDay()]}
                      </option>
                      <option value={Recurrence.ALL_THE_MONTH}>
                        Tous les mois, le{" "}
                        {dataAndHourSelected &&
                          days[dataAndHourSelected?.getDay()]}{" "}
                        de la {getNumberOfWeekInTheCurrentMonth()} semaine
                      </option>
                    </select>
                  </div>
                  {recurrenceSelected !== Recurrence.ONE_TIME ? (
                    <div className="mb-3">
                      <label
                        htmlFor="recurrenceEndDate"
                        className="block mb-2 sm:mr-5 text-sm font-semibold"
                      >
                        Date fin de récurrence
                      </label>
                      <input
                        className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                        type="date"
                        id="recurrenceEndDate"
                        value={endDateRecurrenceSelected}
                        min={
                          dataAndHourSelected &&
                          dataAndHourSelected.toISOString().substr(0, 10)
                        }
                        onChange={(e) => {
                          setEndDateRecurrenceSelected(e.target.value);
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="flex flex-col">
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      defaultValue={plannedDurationSelected}
                      name="plannedDuration"
                      render={() => (
                        <SelectDuration
                          id="estimated-duration"
                          label="Durée prévisionnelle *"
                          setDuration={setPlannedDuration}
                        />
                      )}
                    />{" "}
                    {errors.plannedDuration && (
                      <span className="text-red-600 text-sm">
                        une durée prévisonelle de tâche doit être renseignée.
                      </span>
                    )}
                  </div>
                </div>

                {/* Description Text Area */}
                <div className="my-6">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-semibold"
                  >
                    Description (facultatif)
                  </label>
                  <textarea
                    id="description"
                    rows={6}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                    placeholder="Écrivez ici"
                    {...register("description", { required: false })}
                  />
                </div>
                {/* Task status */}
                <div className="my-6">
                  <div className="flex flex-row">
                    <label
                      htmlFor="createTaskStatus"
                      className="block mb-5 mr-3 text-sm font-semibold"
                    >
                      Statut de la tâche
                    </label>
                    <div id="createTaskStatus">
                      {TaskStatusCell(TaskStatus.TODO)}
                    </div>
                  </div>
                </div>
                {/* Buttons Area */}
                <div className="flex justify-between my-6">
                  <button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="text-white bg-[#306476] hover:bg-[#224956] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center w-1/3 mx-2"
                  >
                    Enregistrer
                  </button>
                  <button
                    onClick={handleSubmit(onSubmitAndNew)}
                    type="button"
                    className="sm:block text-white bg-[#306476] hover:bg-[#224956] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center w-1/3 mx-2"
                  >
                    Enregistrer + Nouvelle
                  </button>
                  <button
                    className="focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center border w-1/3 mx-2"
                    onClick={() => navigate(-1)}
                    type="button"
                  >
                    Fermer
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <SpinnerComponent />
      )}
    </div>
  );
}
