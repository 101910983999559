import { DocumentNode, gql } from "@apollo/client";

export const EDIT_QUOTE_AGREEMENT: DocumentNode = gql`
  mutation UpdateQuoteAgreement($input: UpdateQuoteAgreementInput!) {
    updateQuoteAgreement(input: $input) {
      quoteAgreement {
        id
        createdAt
        updatedAt
        label
        hourlyAmount
        currentAmount
        reference
        description
        isActive
        url
        user {
          id
          createdAt
          updatedAt
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
        frameworkAgreement {
          id
          createdAt
          updatedAt
          label
          reference
          url
          description
          isActive
          user {
            id
            createdAt
            updatedAt
            first_name
            last_name
            avatar_url
            phone_number
            email
            geographical_area
            siret
            business_name
            experience
            criticity_request
            commentary
            isActive
            isVerified
            role
            taskTypes {
              id
              label
              categoryTaskType {
                id
                label
              }
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_QUOTE_AGREEMENT: DocumentNode = gql`
  mutation RemoveQuoteAgreement($id: String!) {
    removeQuoteAgreement(id: $id)
  }
`;

export const CREATED_QUOTE_AGREEMENT: DocumentNode = gql`
  mutation CreateQuoteAgreement($input: CreateQuoteAgreementInput!) {
    createQuoteAgreement(input: $input) {
      quoteAgreement {
        id
        createdAt
        updatedAt
        label
        hourlyAmount
        currentAmount
        reference
        description
        isActive
        url
        user {
          id
          createdAt
          updatedAt
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
        frameworkAgreement {
          id
          createdAt
          updatedAt
          label
          reference
          url
          description
          isActive
          user {
            id
            createdAt
            updatedAt
            first_name
            last_name
            avatar_url
            phone_number
            email
            geographical_area
            siret
            business_name
            experience
            criticity_request
            commentary
            isActive
            isVerified
            role
            taskTypes {
              id
              label
              categoryTaskType {
                id
                label
              }
            }
          }
        }
      }
    }
  }
`;
