import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Avatar from "../../../components/shared/Avatar";
import { UserRole } from "../../../API/models/types";
import getAvatarUrl from "../../utils/getAvatarUrl";
import { UserContext } from "../../../context/userContext";
import {
  GetInactiveUsersForSuperAdmin,
  GetNewAffiliatedUsersForAdmin,
} from "../../utils/QueriesUser";

function NewUsersList() {
  const { user } = useContext(UserContext);

  const { usersList } =
    // eslint-disable-next-line no-nested-ternary
    user.role !== null && user.role === UserRole.SUPER_ADMIN
      ? GetInactiveUsersForSuperAdmin()
      : // eslint-disable-next-line no-nested-ternary
      user.role !== null && user.role === UserRole.ADMIN
      ? GetNewAffiliatedUsersForAdmin(user.id as string)
      : { usersList: [] };

  return (
    <div className="mb-4 rounded-lg bg-white py-6 px-10 shadow dark:bg-gray-800 sm:p-6 w-fit h-fit">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="font-bold leading-none dark:text-white">
          {user.role === UserRole.SUPER_ADMIN
            ? "Derniers utilisateurs en attente de validation"
            : "Derniers utilisateurs qui vous ont été affiliés"}
        </h3>
      </div>
      <div className="flow-root">
        <ul className="divide-y divide-gray-200 dark:divide-gray-700 max-h-96 overflow-y-auto scrollbar-thumb-add-blue-dark scrollbar-track-gray-100 scrollbar-thin">
          {usersList &&
            usersList.map((userToDisplay) => (
              <li
                className="py-3 sm:py-4 w-full text-sm"
                key={userToDisplay.id}
              >
                <div className="flex justify-between items-center w-full">
                  <div className="flex">
                    <Avatar
                      imgUrl={
                        userToDisplay.role &&
                        (getAvatarUrl(
                          userToDisplay.role,
                          userToDisplay.avatar_url,
                        ) as string)
                      }
                    />
                    <div className="flex flex-col">
                      <div className="font-bold">
                        {userToDisplay.last_name.toUpperCase()}{" "}
                        {userToDisplay.first_name}
                      </div>
                      <span className="text-gray-500 pr-2">
                        {userToDisplay.email}
                      </span>
                    </div>
                  </div>
                  <div className="font-bold mr-6">
                    {userToDisplay.role !== UserRole.CUSTOMER
                      ? "secrétaire"
                      : "client"}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 pt-3 sm:pt-6">
        <div className="shrink-0">
          <Link
            to="/liste-des-secretaires"
            className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
          >
            Liste des secrétaires
            <svg
              className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Link>
        </div>
        <div className="shrink-0">
          <Link
            to="/liste-des-clients"
            className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
          >
            Liste des clients
            <svg
              className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NewUsersList;
