import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  ReactElement,
} from "react";

type DateRangeContextType = [string, Dispatch<SetStateAction<string>>];

// Initialiser le contexte avec une valeur par défaut indéfinie
// La définition du contexte avec undefined comme valeur par défaut est une convention de conception
// L'idée est que si on essaye d'utiliser le contexte en dehors d'un fournisseur (Provider),
// Cela va déclencher l'erreur définie ci-dessous et ce sera plus facile de debugger
const DateRangeContext = createContext<DateRangeContextType | undefined>(
  undefined,
);

type DateRangeProviderProps = {
  children: React.ReactNode;
};

// Custom hook pour utiliser le contexte facilement
export const useDateRange = () => {
  const context = useContext(DateRangeContext);
  if (context === undefined) {
    throw new Error("useDateRange must be used within a DateRangeProvider");
  }
  return context;
};

export function DateRangeProvider({
  children,
}: DateRangeProviderProps): ReactElement {
  // State initialiser à 7 comme la première valeur du select
  const state = useState<string>("7");

  return (
    <DateRangeContext.Provider value={state}>
      {children}
    </DateRangeContext.Provider>
  );
}
