import React from "react";
import { Select } from "flowbite-react";
import { useDateRange } from "./context/DateRangeContext";

interface DatePickerUsersSubscriptionChartProps {
  values: string[];
}

function DatePickerUsersSubscriptionChart({
  values,
}: DatePickerUsersSubscriptionChartProps) {
  const [selectedDateRange, setSelectedDateRange] = useDateRange();

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDateRange(event.target.value);
  };

  const options = values.map((value) => ({
    label: `Les ${value} derniers jours`,
    value,
  }));

  return (
    <span className="text-sm text-gray-600">
      <label htmlFor="select-date-range-users-subscription-chart">
        <Select
          id="select-date-range-users-subscription-chart"
          value={selectedDateRange as string}
          onChange={handleSelectChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </label>
    </span>
  );
}

export default DatePickerUsersSubscriptionChart;
