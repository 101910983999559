import { Routes, Route, Outlet } from "react-router-dom";
import { useContext } from "react";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import { UserContext, UserContextType } from "./context/userContext";
import ProtectedRoute from "./services/ProtectedRoutes";
import ResetPassword from "./pages/ResetPassword";
import FrameworkAgreement from "./pages/framework-agreement/FrameworkAgreement";
import CreateFrameworkAgreement from "./pages/framework-agreement/CreateFrameworkAgreement";
import EditFrameworkAgreement from "./pages/framework-agreement/EditFrameworkAgreement";
import QuoteAgreement from "./pages/quote-agreement/QuoteAgreement";
import CreateQuoteAgreement from "./pages/quote-agreement/CreateQuoteAgreement";
import EditQuoteAgreement from "./pages/quote-agreement/EditQuoteAgreement";
import { SidebarProvider } from "./context/SideBarContext";
import RegistrationSteps from "./pages/RegistrationSteps";
import OnBoarding from "./pages/OnBoarding";
import EmailConfirmed from "./pages/EmailConfirmed";
import CustomersList from "./pages/User/CustomersList";
import SecretariesList from "./pages/User/SecretariesList";
import CreateTask from "./pages/task/CreateTask";
import AdminDashboard from "./pages/Dashboard/Admin/AdminDashboard";
import CustomersDashboard from "./pages/Dashboard/Customer/CustomersDashboard";
import EditTask from "./pages/task/EditTask";
import Calendar from "./pages/Calendar/Calendar";
import { UserRole } from "./API/models/types";
import { FilterTasksContextProvider } from "./context/FilterContext";
import AdminTask from "./pages/task/admin/AdminTask";
import PendingTasksList from "./pages/task/secretaries/PendingTasksList";
import SuperAdminTask from "./pages/task/super-admin/SuperAdminTask";
import CustomerTask from "./pages/task/customer/CustomerTask";
import SecretariesNotPendingTasksList from "./pages/task/secretaries/SecretariesNotPendingTasksList";
import AdminsList from "./pages/User/AdminsList";
import SendEmailAdminRegistrationForm from "./pages/SendEmailAdminRegistrationForm";
import AdminRegistration from "./pages/AdminRegistration";
import CreateSecretariesPrivateTask from "./pages/task/CreateSecretariesPrivateTask";
import Account from "./pages/user-profile/Account";
import EditMyAccount from "./pages/user-profile/EditMyAccount";
import EditPassword from "./pages/user-profile/EditPassword";
import SecretaryDashboard from "./pages/Dashboard/SecretaryDashboard";

function App() {
  const { user } = useContext<UserContextType>(UserContext);

  const ProtectedRouteDefaultProps = {
    user,
    redirectPath: "/se-connecter",
    outlet: <Outlet />,
    allowedRoles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.CUSTOMER,
      UserRole.SECRETARY,
    ],
    currentUserRole: user.role as UserRole,
  };

  // Fonction qui détermine vers quel Dashboard orienté l'utilisateur en fonction de son role
  function getDashboard(currentUserRole: UserRole) {
    switch (currentUserRole) {
      case UserRole.ADMIN:
      case UserRole.SUPER_ADMIN:
        return <AdminDashboard />;
      case UserRole.CUSTOMER:
        return <CustomersDashboard customerId={user.id as string} />;
      case UserRole.SECRETARY:
        return <SecretaryDashboard secretaryId={user.id as string} />;
      default:
        return <NotFound />;
    }
  }

  return (
    <Routes>
      {/* Routes accessibles par tous les utilisateurs (connectés ou non) */}
      <Route path="/inscription" element={<OnBoarding />} />
      <Route path="/se-connecter" element={<Login />} />
      <Route
        path="/reinitialisation-mdp/:resetPasswordToken"
        element={<ResetPassword />}
      />
      <Route
        path="/confirmation-mail/:verifyEmailToken"
        element={<EmailConfirmed />}
      />
      <Route
        path="/inscription-admin/:verifyAdminRegistrationToken"
        element={<AdminRegistration />}
      />
      {/* Routes accessibles par tous les utilisateurs connectés peu importe le rôle */}
      <Route element={<ProtectedRoute {...ProtectedRouteDefaultProps} />}>
        <Route
          element={
            <SidebarProvider>
              <Layout
                currentUserRole={ProtectedRouteDefaultProps.currentUserRole}
                currentUserId={user.id as string}
              />
            </SidebarProvider>
          }
        >
          <Route path="/" element={getDashboard(user.role as UserRole)} />
          {user !== null && user.id !== null && user.role !== null && (
            <Route path="/profil/:id" element={<Account />} />
          )}
          {user !== null && user.id !== null && user.role !== null && (
            <Route
              path="/modifier-mon-compte/:id"
              element={<EditMyAccount />}
            />
          )}

          <Route path="/modifier-mon-mot-de-passe" element={<EditPassword />} />

          <Route path="/agenda" element={<Calendar />} />
          <Route path="/etapes-inscription" element={<RegistrationSteps />} />

          {/* Contrats devis */}
          <Route
            path="/contrat-devis"
            element={
              <QuoteAgreement
                role={ProtectedRouteDefaultProps.currentUserRole as UserRole}
              />
            }
          />

          {/* inscription utilisateur */}
          <Route path="/etapes-inscription" element={<RegistrationSteps />} />

          {/* liste des tâches */}
          <Route
            path="/liste-des-taches"
            element={
              // eslint-disable-next-line no-nested-ternary
              user.role === UserRole.CUSTOMER ? (
                <FilterTasksContextProvider
                  filtersKey="myFilters"
                  dateFiltersKey="myFiltersTaksDate"
                  pageKey="myTasksListPage"
                  searchKey="mySearchFilter"
                >
                  <CustomerTask />
                </FilterTasksContextProvider>
              ) : user.role === UserRole.SUPER_ADMIN ? (
                <FilterTasksContextProvider
                  filtersKey="myFilters"
                  dateFiltersKey="myFiltersTaksDate"
                  pageKey="myTasksListPage"
                  searchKey="mySearchFilter"
                >
                  <SuperAdminTask />
                </FilterTasksContextProvider>
              ) : (
                <FilterTasksContextProvider
                  filtersKey="myFilters"
                  dateFiltersKey="myFiltersTaksDate"
                  pageKey="myTasksListPage"
                  searchKey="mySearchFilter"
                >
                  <AdminTask />
                </FilterTasksContextProvider>
              )
            }
          />
        </Route>
      </Route>

      {/* Routes auxquels ont accès seulement les admins ou super admin */}
      <Route
        element={
          <ProtectedRoute
            {...ProtectedRouteDefaultProps}
            {...{
              allowedRoles: ProtectedRouteDefaultProps.allowedRoles.filter(
                (role) =>
                  role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN,
              ),
            }}
          />
        }
      >
        <Route
          element={
            <SidebarProvider>
              <Layout
                currentUserRole={ProtectedRouteDefaultProps.currentUserRole}
                currentUserId={user.id as string}
              />
            </SidebarProvider>
          }
        >
          {/* Création et édition contrats devis */}
          <Route
            path="/contrat-devis/creation"
            element={<CreateQuoteAgreement />}
          />
          <Route
            path="/contrat-devis/edition"
            element={<EditQuoteAgreement />}
          />
          {/* création et édition de contrat cadre */}
          <Route
            path="/contrat-cadre/creation"
            element={<CreateFrameworkAgreement />}
          />
          <Route
            path="/contrat-cadre/edition"
            element={<EditFrameworkAgreement />}
          />
          <Route path="/liste-des-admins" element={<AdminsList />} />
          <Route
            path="/listes-admin/creation-admin"
            element={<SendEmailAdminRegistrationForm />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>

      {/* Routes auxquels n'ont pas accès les clients */}
      <Route
        element={
          <ProtectedRoute
            {...ProtectedRouteDefaultProps}
            {...{
              allowedRoles: ProtectedRouteDefaultProps.allowedRoles.filter(
                (role) => role !== UserRole.CUSTOMER,
              ),
            }}
          />
        }
      >
        <Route
          element={
            <SidebarProvider>
              <Layout
                currentUserRole={ProtectedRouteDefaultProps.currentUserRole}
                currentUserId={user.id as string}
              />
            </SidebarProvider>
          }
        >
          {UserRole.SECRETARY ? (
            <Route
              path="/liste-des-taches-en-cours/creation-tache-privee"
              element={<CreateSecretariesPrivateTask />}
            />
          ) : null}

          <Route path="/liste-des-clients" element={<CustomersList />} />
        </Route>
      </Route>

      {/* Routes auxquels n'ont pas accès les secrétaires */}
      <Route
        element={
          <ProtectedRoute
            {...ProtectedRouteDefaultProps}
            {...{
              allowedRoles: ProtectedRouteDefaultProps.allowedRoles.filter(
                (role) => role !== UserRole.SECRETARY,
              ),
            }}
          />
        }
      >
        <Route
          element={
            <SidebarProvider>
              <Layout
                currentUserRole={ProtectedRouteDefaultProps.currentUserRole}
                currentUserId={user.id as string}
              />
            </SidebarProvider>
          }
        >
          <Route path="/liste-des-secretaires" element={<SecretariesList />} />
          {/* Création d'une tâche */}
          <Route
            path="/liste-des-taches/creation-tache"
            element={<CreateTask />}
          />
          {/* Edition d'une tâche */}
          <Route
            path="/liste-des-taches/edition-tache"
            element={<EditTask />}
          />

          {/* contrats cadres */}
          <Route path="/contrat-cadre" element={<FrameworkAgreement />} />
        </Route>
      </Route>

      {/* Route accessibles uniquement par les secrétaires ET les admins */}
      <Route
        element={
          <ProtectedRoute
            {...ProtectedRouteDefaultProps}
            {...{
              allowedRoles: ProtectedRouteDefaultProps.allowedRoles.filter(
                (role) =>
                  role === UserRole.SECRETARY || role === UserRole.ADMIN,
              ),
            }}
          />
        }
      >
        <Route
          element={
            <SidebarProvider>
              <Layout
                currentUserRole={ProtectedRouteDefaultProps.currentUserRole}
                currentUserId={user.id as string}
              />
            </SidebarProvider>
          }
        >
          <Route
            path="/liste-des-taches-en-cours"
            element={
              <FilterTasksContextProvider
                filtersKey="myFilters"
                dateFiltersKey="myFiltersTaksDate"
                pageKey="myTasksListPage"
                searchKey="mySearchFilter"
              >
                {user.role === UserRole.SECRETARY ? (
                  <SecretariesNotPendingTasksList />
                ) : (
                  <AdminTask />
                )}
              </FilterTasksContextProvider>
            }
          />
          <Route
            path="/liste-des-taches-en-attente-d-acceptation"
            element={
              <FilterTasksContextProvider
                filtersKey="myPendingFilters"
                dateFiltersKey="myPendingFiltersTaksDate"
                pageKey="myPendingTasksListPage"
                searchKey="myPendingSearchFilter"
              >
                <PendingTasksList />
              </FilterTasksContextProvider>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}
export default App;
