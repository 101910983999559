import React from "react";

interface NumberOfContractHoursProps {
  totalHours: number;
}

function NumberOfContractHours({ totalHours }: NumberOfContractHoursProps) {
  return (
    <span className="text-2xl font-bold leading-none text-gray-900 dark:text-white sm:text-3xl">
      {totalHours}
    </span>
  );
}

export default NumberOfContractHours;
