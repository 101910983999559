/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import React from "react";
import { Link } from "react-router-dom";
import { FieldValues, useForm, UseFormReturn } from "react-hook-form";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import {
  UserCreateInput,
  UserSendEmailVerificationInput,
} from "../API/models/types";
import imgParlezNous from "../assets/illustrations/img-parlez-nous.png";
import logoWithText from "../assets/logo/logo-add-with-text.png";
import FirstStepForm from "../components/OnBoardingSteps/FirstStepForm";
import SecondStepForm from "../components/OnBoardingSteps/SecondStepForm";
import Steps from "../components/OnBoardingSteps/Steps";
import ThirdStepForm from "../components/OnBoardingSteps/ThirdStepForm";
import useMultistepForm from "../hooks/useMultistepForm";
import {
  CREATE_USER,
  SEND_VERIFICATION_EMAIL,
} from "../API/modules/user/resolvers/mutation";
import { CHECK_IF_EMAIL_ALREADY_EXIST } from "../API/modules/user/resolvers/queries";

type UseFormReturnWithErrors<T extends FieldValues> = UseFormReturn<T> & {
  errors: Record<keyof T, any>;
};

interface TaskType {
  value: string;
  label: string;
  disabled: boolean;
}

function OnBoarding() {
  const {
    register,
    getValues,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onChange",
  }) as UseFormReturnWithErrors<FieldValues>;

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <FirstStepForm register={register} errors={errors} />,
      <SecondStepForm
        register={register}
        control={control}
        watch={watch}
        errors={errors}
      />,
      <ThirdStepForm getValues={getValues} />,
    ]);

  const buttonTexts = [
    "Prochaine étape: Informations du compte",
    "Prochaine étape: Confirmation",
    "Renvoyer un email de confirmation",
  ];

  // Query
  const [checkIfEmailAlreadyExist] = useLazyQuery(CHECK_IF_EMAIL_ALREADY_EXIST);
  const emailErrors = {
    emailAlreadyTaken: "Cet email est déjà pris",
  };

  // Mutations
  const [createUser, { error, loading: isLoading }] =
    useMutation<UserCreateInput>(CREATE_USER);
  const [
    sendVerificationEmail,
    { error: ErrorVerificationEmail, loading: emailSendLoading },
  ] = useMutation<UserSendEmailVerificationInput>(SEND_VERIFICATION_EMAIL);

  async function onSubmit(data: FieldValues) {
    // First step
    // On vérifie au passage si l'email est déjà utilisé
    if (isFirstStep) {
      try {
        const { data: isEmailTaken } = await checkIfEmailAlreadyExist({
          variables: { email: data.email },
        });
        if (isEmailTaken.checkIfEmailAlreadyExist) {
          setError("email", {
            type: "manual",
            message: emailErrors.emailAlreadyTaken,
          });
        } else {
          return next();
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
    // Second step
    // On créé l'utilisateur en lui envoyant un email
    // pour confirmer son adresse mail en même temps
    if (currentStepIndex === 1) {
      const userData: UserCreateInput = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone_number: data.phoneNumber,
        email: data.email,
        role: data.isSecretaryOrClient,
        password: data.password,
        commentary: data.commentary,
        business_name: data.businessName,
        siret: data.siret,
        criticity_request: data.criticity,
        activity_sector: data.activitySector,
        experience: data.experience,
        geographical_area: data.geographicalArea,
        taskTypeIds: data.taskTypes.map((taskType: TaskType) => taskType.value),
        isVerified: false,
        isConsentingMarketing: data.isConsentingMarketing,
      };
      try {
        // CREATE USER
        await createUser({
          variables: { input: userData },
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      return next();
    }
    // Last step
    // On permet à l'utilisateur de redemander un mail de confirmation
    if (isLastStep) {
      try {
        await sendVerificationEmail({
          variables: { email: { email: data.email } },
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
    const successMessage = "Votre email est en attente de confirmation";
    return successMessage;
  }

  return (
    <main className="lg:h-screen flex">
      <div className="h-full relative w-1/3 hidden lg:block">
        <Link to="/se-connecter">
          <img
            src={logoWithText}
            alt="logo add"
            className="absolute w-12 lg:w-24 left-1/2 lg:top-14 transform -translate-x-1/2 -translate-y-1/2 lg:-translate-y-1/3"
          />
        </Link>
        <div className="bg-add-green h-full">
          <img
            src={imgParlezNous}
            alt="illustration inscription"
            className="h-full object-contain object-center p-6 xl:p-12"
          />
        </div>
      </div>
      <div
        className={`flex flex-col items-center ${
          currentStepIndex === 1 ? "" : "justify-center"
        } w-full h-full px-6 lg:px-0 overflow-y-auto`}
      >
        <div>
          <Steps currentStepIndex={currentStepIndex} />
        </div>
        <form className="lg:w-3/4" onSubmit={handleSubmit(onSubmit)}>
          {step}
          <div className="py-6 flex flex-col gap-2 justify-end">
            {!isFirstStep && (
              <button
                type="button"
                className="button-add bg-add-blue-dark p-2 font-semibold"
                onClick={back}
              >
                Précédent
              </button>
            )}
            <button
              type="submit"
              className="button-add bg-add-blue-dark p-2 font-semibold mb-10 lg:mb-0"
              disabled={isLoading}
            >
              {isLoading
                ? "Création de votre compte ..."
                : emailSendLoading
                ? "Nouvel envoi de l'email ..."
                : buttonTexts[currentStepIndex]}
            </button>
            {error && (error.networkError as ApolloError) && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                "Une erreur est survenue. Veuillez réessayer plus tard."
              </span>
            )}
            {ErrorVerificationEmail && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                "Une erreur est survenue lors du renvoie de l'email. Veuillez
                réessayer plus tard."
              </span>
            )}
          </div>
        </form>
      </div>
    </main>
  );
}

export default OnBoarding;
