import React, { useContext, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { BiHide } from "react-icons/bi";
import { Task } from "../../hook/useTasks";
import {
  getRGBColor,
  getHexColorFromClassOrValue,
} from "../../utils/getColorValue";
import { getTaskHeightClassStyle } from "../../utils/getTaskHeightClass";
import getTaskTopPosition from "../../utils/getTaskTopPosition";
import UserAvatarTooltip from "../../../../components/shared/UserAvatarTooltip";
import ModalTaskRow from "../../../task/modale/ModalTaskRow";
import { UserRole } from "../../../../API/models/types";
import { UserContext } from "../../../../context/userContext";

interface TaskWeekViewCellProps {
  task: Task;
}

function TaskWeekViewCell(props: TaskWeekViewCellProps) {
  const { task } = props;

  const { user } = useContext(UserContext);

  const [openModalTaskRow, setOpenModalTaskRow] = useState<boolean>(false);
  const handleClick = () => {
    // Si la tâche est privée
    // et que le rôle est secrétaire on peut voir la tâche
    if (
      !task.isPrivate ||
      (task.isPrivate && user.role === UserRole.SECRETARY)
    ) {
      setOpenModalTaskRow(true);
    }
  };

  dayjs.extend(utc);
  const taskDate = dayjs(task.startDate);

  const taskHeightClassStyle = getTaskHeightClassStyle(
    task.plannedDuration,
    taskDate,
  );
  const taskTopPosition = getTaskTopPosition(taskDate);

  return (
    <>
      <ModalTaskRow
        open={openModalTaskRow}
        setOpen={setOpenModalTaskRow}
        item={task}
      />
      <button
        type="button"
        onClick={handleClick}
        aria-label="Détail d'une tache"
        className="absolute h-full border-2 hover:border-2 border-collapse border-transparent hover:border-add-blue-dark hover:shadow-lg w-full z-10"
        style={{
          top: taskTopPosition,
          height: `${taskHeightClassStyle}px`,
        }}
      >
        <div
          className={`flex flex-col bg-${task.color}-200 text-${task.color}-800 rounded py-1 px-2 w-full`}
          style={{
            top: taskTopPosition,
            backgroundColor: getRGBColor(
              getHexColorFromClassOrValue(task.color) || task.color,
            ),
            height: `${taskHeightClassStyle}px`,
          }}
        >
          {/* Task informations */}
          <div className="flex items-center justify-center md:justify-between">
            <p
              className={`text-sm py-1 md:text-current text-${task.color}-600 font-bold md:font-normal`}
            >
              {taskDate.format("H:mm")}
            </p>
            {/* Secretary and Customer avatars */}
            <div className="hidden md:flex pl-1">
              {/* Secretary avatar tooltip */}
              {task && task.secretary ? (
                <UserAvatarTooltip
                  id={task.secretary.id}
                  email={task.secretary.email}
                  firstName={task.secretary.first_name}
                  lastName={task.secretary.last_name}
                  imgUrl={task.secretary.avatar_url ?? null}
                  role={task.secretary.role}
                />
              ) : null}
              {/* Customer avatar tooltip */}
              {task && task.customer ? (
                <UserAvatarTooltip
                  id={task.customer.id}
                  email={task.customer.email}
                  firstName={task.customer.first_name}
                  lastName={task.customer.last_name}
                  imgUrl={task.customer.avatar_url ?? null}
                  role={task.customer.role}
                />
              ) : null}
            </div>
          </div>
          <div className="flex gap-x-2">
            {task.isPrivate ? (
              <>
                <BiHide className="h-5 w-5 fill-add-blue-dark hidden md:block" />
                <p className="font-bold text-sm truncate hover:overflow-visible px-1 hidden xl:block">
                  Tâche privée{" "}
                </p>
              </>
            ) : (
              <div className="flex w-full">
                <p className="font-bold text-sm hidden md:block">
                  {task.label}
                </p>
                <p className="text-sm flex-grow text-end hidden md:block">
                  {task.customer?.first_name}
                </p>
              </div>
            )}
          </div>
        </div>
      </button>
    </>
  );
}

export default TaskWeekViewCell;
