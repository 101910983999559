import React from "react";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";

export default function ProfileButton(pathName: string) {
  return (
    <Link to={pathName} className="flex items-center">
      <button
        type="button"
        className="flex items-center button-add bg-add-blue-dark p-2 font-semibold"
      >
        <CgProfile className="w-4 h-4" />
        <span className="ml-2">Profil</span>
      </button>
    </Link>
  );
}
