/* eslint-disable react/no-unstable-nested-components */
import { Label, TextInput } from "flowbite-react";
import { useQuery } from "@apollo/client";
import Select from "react-tailwindcss-select";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  FieldValues,
  UseFormRegister,
  Controller,
  Control,
  UseFormWatch,
  FieldErrors,
} from "react-hook-form";
import geographicalAreaOptions from "../../API/data/departements-region.json";
import activitySectors from "../../API/data/activity-sectors.json";
import { GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES } from "../../API/modules/category-task-type/resolvers/queries";
import {
  GetAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
  UserRole,
} from "../../API/models/types";
import { OptionsSelect, Option } from "../../pages/models/models";

export interface Area {
  num_dep: string | number;
  dep_name: string;
  region_name: string;
}

export enum Experience {
  LESS_THAN_1_YEAR = "moins d'un an",
  ONE_TO_TWO_YEARS = "1 à 2 ans",
  TWO_TO_FIVE_YEARS = "2 à 5 ans",
  FIVE_YEARS_AND_MORE = "5 ans et plus",
}

type SecondStepFormProps = {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

function SecondStepForm({
  register,
  control,
  watch,
  errors,
}: SecondStepFormProps) {
  // User has select secretary or customer
  const userType = watch("isSecretaryOrClient");

  // Query
  const { data: dataFromQuery } = useQuery(
    GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES,
  );
  const getAllCategoryTaskTypeWithTaskTypes = dataFromQuery
    ? ([
        ...dataFromQuery.getAllCategoryTaskTypeWithTaskTypes,
      ] as GetAllCategoryTaskTypeWithTaskTypes[])
    : ([] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[]);

  // TaskType Select options
  const getOptions = (
    getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
  ) => {
    const optionsToDisplay: OptionsSelect[] = [];
    const categoryArray = [
      ...getAllCategory,
    ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    categoryArray &&
      categoryArray.map(
        (
          element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
        ) => {
          const subOptionsArray: Option[] = [];
          const tasktypes = [
            ...element.taskTypes,
          ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasktypes &&
            tasktypes.map(
              (
                tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
              ) => {
                subOptionsArray.push({
                  value: tasktype.id,
                  label: tasktype.label,
                });
                return tasktype;
              },
            );
          optionsToDisplay.push({
            label: element.label,
            options: subOptionsArray,
          });
          return element;
        },
      );
    return optionsToDisplay;
  };
  const getOptionsForOneCategory = (
    getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
    categoryLabel: string,
  ) => {
    const optionsToDisplay: OptionsSelect[] = [];
    const categoryArray = [
      ...getAllCategory,
    ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const filterCategoryArray = categoryArray.filter(
      (element) => element.label === categoryLabel,
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    filterCategoryArray.length > 0 &&
      filterCategoryArray.map(
        (
          element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
        ) => {
          const subOptionsArray: Option[] = [];
          const tasktypes = [
            ...element.taskTypes,
          ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasktypes &&
            tasktypes.map(
              (
                tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
              ) => {
                subOptionsArray.push({
                  value: tasktype.id,
                  label: tasktype.label,
                });
                return tasktype;
              },
            );
          optionsToDisplay.push({
            label: element.label,
            options: subOptionsArray,
          });
          return element;
        },
      );

    return optionsToDisplay[0].options.flat();
  };
  // Criticity Range slider
  const criticity = watch("criticity");
  const [criticityColor, setCriticityColor] = useState("orange");

  useEffect(() => {
    switch (criticity) {
      case "0":
        setCriticityColor("green");
        break;
      case "1":
        setCriticityColor("orange");
        break;
      case "2":
        setCriticityColor("red");
        break;
      default:
        setCriticityColor("green");
    }
  }, [criticity]);

  return (
    <div>
      <h1 className="mb-6 text-2xl md:text-3xl lg:text-4xl">
        Informations du compte
      </h1>
      <span className="font-light text-gray-500 text-xs">
        (*) champs obligatoires
      </span>
      {/* PASSWORD */}
      <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <Label htmlFor="password" className="font-semibold">
            Votre mot de passe *
          </Label>
          <TextInput
            id="password"
            placeholder="••••••••"
            type="password"
            autoFocus
            {...register("password", {
              required: true,
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
            })}
          />
          {errors.password && errors.password.type === "required" && (
            <span
              className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              Ce champ est requis
            </span>
          )}
          {errors.password && errors.password.type === "pattern" && (
            <span
              className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              Le mot de passe doit contenir au moins 8 caractères, dont une
              majuscule, une minuscule et un chiffre.
            </span>
          )}
        </div>

        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <Label htmlFor="confirmPassword" className="font-semibold">
            Confirmez votre mot de passe *
          </Label>
          <TextInput
            id="confirmPassword"
            placeholder="••••••••"
            type="password"
            {...register("confirmPassword", {
              required: true,
              validate: (value) => value === watch("password"),
            })}
          />
          {errors.confirmPassword &&
            errors.confirmPassword.type === "required" && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Ce champ est requis
              </span>
            )}
          {errors.confirmPassword &&
            errors.confirmPassword.type === "validate" && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Les mots de passe ne correspondent pas
              </span>
            )}
        </div>
      </div>

      {/* Raison sociale + siret */}
      <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <Label htmlFor="businessName" className="font-semibold">
            Raison sociale
          </Label>
          <TextInput
            id="businessName"
            placeholder="..."
            type="text"
            {...register("businessName")}
          />
        </div>
        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <Label htmlFor="siret" className="font-semibold">
            SIRET
          </Label>
          <TextInput
            id="siret"
            placeholder="55217863900132"
            type="text"
            {...register("siret")}
          />
        </div>
      </div>

      {/* Secteur d'activité ou Expérience + Zone géographique */}
      <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
        {userType === UserRole.CUSTOMER && (
          <div className="mb-6 flex flex-col gap-y-3 w-full">
            <label
              htmlFor="activitySector"
              className="block text-sm text-gray-900 font-semibold"
            >
              Secteur d'activité
            </label>
            <select
              id="activitySector"
              {...register("activitySector")}
              className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
            >
              <option value="">...</option>
              {Object.values(activitySectors)
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((activity) => (
                  <option key={activity.label} value={activity.label}>
                    {activity.label}
                  </option>
                ))}
            </select>
          </div>
        )}
        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <label
            htmlFor="geographicalArea"
            className="block text-sm text-gray-900 dark:text-white font-semibold"
          >
            Zone géographique *
          </label>
          <select
            id="geographicalArea"
            className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
            {...register("geographicalArea", { required: true })}
          >
            <option value="">...</option>
            {geographicalAreaOptions &&
              geographicalAreaOptions.map((area: Area) => (
                <option
                  key={area.num_dep}
                  value={`${area.num_dep.toString()} - ${area.dep_name} ${
                    area.region_name
                  }`}
                >
                  {area.num_dep.toString()} - {area.dep_name} (
                  {area.region_name})
                </option>
              ))}
          </select>
          {errors.geographicalArea &&
            errors.geographicalArea.type === "required" && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Ce champ est requis
              </span>
            )}
        </div>
        {userType === UserRole.SECRETARY && (
          <div className="mb-6 flex flex-col gap-y-3 w-full">
            <label
              htmlFor="experience"
              className="block text-sm text-gray-900 font-semibold"
            >
              Expérience *
            </label>
            <select
              id="experience"
              className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
              {...register(
                "experience",
                userType === UserRole.SECRETARY
                  ? { required: true }
                  : { required: false },
              )}
            >
              <option value="">...</option>
              {Object.values(Experience).map((exp) => (
                <option key={exp} value={exp}>
                  {exp}
                </option>
              ))}
            </select>
            {errors.experience && errors.experience.type === "required" && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Ce champ est requis
              </span>
            )}
          </div>
        )}
      </div>

      {/* Type de taches + Niveau d’urgence de la demande */}
      <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <label
            htmlFor="taskTypes"
            className="block text-sm text-gray-900 font-semibold"
          >
            {userType === UserRole.CUSTOMER
              ? "Compétences recherchées *"
              : "Types de tâches que vous pouvez accomplir *"}
          </label>
          <Controller
            name="taskTypes"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(newValue: any) => {
                  onChange(newValue);
                }}
                options={getOptions(
                  getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                )}
                isMultiple
                isClearable
                isSearchable
                formatGroupLabel={(data: OptionsSelect) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <div
                    className="py-2 text-xs flex items-center justify-between"
                    onClick={() => {
                      if (value !== null && value && value.length > 0) {
                        onChange(
                          value.concat(
                            getOptionsForOneCategory(
                              getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                              data.label,
                            ),
                          ) as any,
                        );
                      } else {
                        onChange(
                          getOptionsForOneCategory(
                            getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                            data.label,
                          ) as any,
                        );
                      }
                    }}
                  >
                    <span className="font-bold">{data.label}</span>
                    <span className="bg-gray-200 h-5 p-1.5 flex items-center justify-center rounded-full">
                      {data.options.length}
                    </span>
                  </div>
                )}
                primaryColor="teal"
                placeholder="Sélectionnez ..."
                noOptionsMessage="Aucune option disponible"
              />
            )}
          />
          {errors.taskTypes && errors.taskTypes.type === "required" && (
            <span
              className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              Sélectionnez au moins une compétence
            </span>
          )}
        </div>
        {userType === UserRole.CUSTOMER && (
          <div className="mb-6 flex flex-col gap-y-3 w-full lg:w-80">
            <label
              htmlFor="criticity"
              className="block text-sm text-gray-900 font-semibold"
            >
              Niveau d’urgence de votre demande
            </label>
            <input
              id="criticity"
              type="range"
              min="0"
              max="2"
              step="1"
              defaultValue="1"
              className={`w-full ${
                criticityColor ? `bg-${criticityColor}-200` : "bg-orange-200"
              } h-2 hover:bg-${criticityColor}-200 rounded-lg appearance-none cursor-pointer `}
              {...register("criticity", { required: true })}
            />
            <div className="flex justify-between">
              <span
                className={`${
                  criticity === "0"
                    ? `text-add-blue-dark font-bold`
                    : "text-gray-900 "
                }`}
              >
                Faible
              </span>
              <span
                className={`${
                  criticity === "1"
                    ? `text-add-blue-dark font-bold`
                    : "text-gray-900 "
                }`}
              >
                Moyen
              </span>
              <span
                className={`${
                  criticity === "2"
                    ? `text-add-blue-dark font-bold`
                    : "text-gray-900 "
                }`}
              >
                Haut
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Commentaire */}
      <div className="mb-6 flex flex-col gap-y-3 w-full">
        <label
          htmlFor="commentary"
          className="block text-sm text-gray-900 font-semibold"
        >
          Ajouter un commentaire
        </label>
        <textarea
          id="commentary"
          rows={4}
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-add-blue-dark focus:border-add-blue-dark"
          placeholder="Écrivez votre commentaire ici..."
          {...register("commentary")}
        />
      </div>

      {/* Agreements */}
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col">
          <div className="flex">
            <input
              id="termsOfUse"
              type="checkbox"
              className="w-4 h-4 text-add-blue-dark bg-gray-100 border-gray-300 rounded focus:ring-add-blue-dark"
              {...register("termsOfUse", { required: true })}
            />
            <label
              htmlFor="termsOfUse"
              className="ml-2 text-sm font-medium text-gray-600"
            >
              En vous inscrivant, vous créez un compte Atelier Du Dirigeant, et
              vous acceptez les{" "}
              <Link
                to="/"
                className="text-add-blue-dark hover:text-cyan-700 hover:underline hover:underline-offset-2"
              >
                conditions d'utilisation{" "}
              </Link>
              de notre application et sa{" "}
              <Link
                to="/"
                className="text-add-blue-dark hover:text-cyan-700 hover:underline hover:underline-offset-2"
              >
                politique de confidentialité.
              </Link>
            </label>
          </div>
          {errors.termsOfUse && errors.termsOfUse.type === "required" && (
            <span
              className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              Vous devez accepter nos conditions d'utilisations
            </span>
          )}
        </div>
        <div className="flex">
          <input
            id="isConsentingMarketing"
            type="checkbox"
            className="w-4 h-4 text-add-blue-dark bg-gray-100 border-gray-300 rounded focus:ring-add-blue-dark"
            {...register("isConsentingMarketing")}
          />
          <label
            htmlFor="isConsentingMarketing"
            className="ml-2 text-sm font-medium text-gray-600"
          >
            Je ne veux pas recevoir de communications marketing de la part de
            l’Atelier du Dirigeant.
          </label>
        </div>
      </div>
    </div>
  );
}

export default SecondStepForm;
