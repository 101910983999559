import React, { PropsWithChildren, useEffect, useState } from "react";

import { FilterTask, InitialFilter } from "../pages/models/models";
import {
  parseSelectDateToServer,
  parseStatusToServer,
  parseTaskTypeIdToServer,
} from "../pages/utils/parseDatatoServer";
import { TaskStatus } from "../API/models/types";

export interface FilterTasksContextType {
  filters: FilterTask;
  setFilters: (value: FilterTask) => void;
  pageKey: string;
  dateFiltersKey: string;
  filtersKey: string;
  searchKey: string;
}
const FilterTasksContext: any = React.createContext<FilterTasksContextType>({
  filters: {},
  setFilters: (value: FilterTask) => {
    FilterTasksContext.filters = value;
  },
  pageKey: "",
  dateFiltersKey: "",
  filtersKey: "",
  searchKey: "",
});

export default FilterTasksContext;

export function FilterTasksContextProvider({
  children,
  filtersKey,
  dateFiltersKey,
  pageKey,
  searchKey,
}: PropsWithChildren & {
  filtersKey: string;
  dateFiltersKey: string;
  pageKey: string;
  searchKey: string;
}) {
  // récuperation filtres localStorage
  const myFilters: string | null = localStorage.getItem(filtersKey);
  const dateFilters: string | null = localStorage.getItem(dateFiltersKey);
  const searchFilters: string | null = localStorage.getItem(searchKey);
  const myPageFilters: string | null = localStorage.getItem(pageKey);
  const initialFilter: FilterTask = {
    page: 1,
    search: undefined,
    date: undefined,
    taskDateFilter: undefined,
    status: [TaskStatus.TODO],
    taskTypeIds: undefined,
    customerId: undefined,
    secretaryId: undefined,
  };
  const [filters, setFilters] = useState<FilterTask>(initialFilter);
  useEffect(() => {
    if (filters !== null && filters !== initialFilter) {
      const updatedFilter = { ...filters };
      // init search filter
      if (searchFilters !== null && JSON.parse(searchFilters).search) {
        updatedFilter.search = JSON.parse(searchFilters).search;
      }
      // init search Date
      if (dateFilters !== null && JSON.parse(dateFilters)) {
        updatedFilter.date = parseSelectDateToServer(
          JSON.parse(dateFilters).selectedDateFilterValue,
        )?.date as Date;
      }
      if (dateFilters !== null && JSON.parse(dateFilters)) {
        updatedFilter.taskDateFilter = parseSelectDateToServer(
          JSON.parse(dateFilters).selectedDateFilterValue,
        )?.taskDateFilter as "COME" | "PASSED" | undefined;
      }
      // init page filter
      if (myPageFilters !== null && JSON.parse(myPageFilters).search) {
        updatedFilter.page = JSON.parse(myPageFilters).page;
      }

      // init filters box
      if (myFilters !== null && JSON.parse(myFilters)) {
        updatedFilter.status = parseStatusToServer(
          JSON.parse(myFilters) as InitialFilter,
        );
        updatedFilter.taskTypeIds = parseTaskTypeIdToServer(
          JSON.parse(myFilters).taskTypeIds as string[],
        );
        updatedFilter.customerId = JSON.parse(myFilters).customerId;
        updatedFilter.secretaryId = JSON.parse(myFilters).secretaryId;
      }
      setFilters(updatedFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilters, myFilters, myPageFilters, searchFilters]);

  return (
    <FilterTasksContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        filters,
        setFilters,
        filtersKey,
        pageKey,
        dateFiltersKey,
        searchKey,
      }}
    >
      {children} {/*  represent the whole application */}
    </FilterTasksContext.Provider>
  );
}
