import React from "react";
import { useQuery } from "@apollo/client";
import LastInprogressTasks from "./LastInprogressTasks";
import {
  GetCustomerDashbouardData_getCustomerDashboardData,
  GetCustomerDashbouardData_getCustomerDashboardData_tasks_30_next_days,
  GetCustomerDashbouardData_getCustomerDashboardData_tasks_7_next_days,
  GetCustomerDashbouardData_getCustomerDashboardData_tasks_all,
} from "../../../API/models/types";
import { GET_DATA_FOR_CUSTOMER_DASHBOARD } from "../../../API/modules/dashboard/resolvers/queries";
import LastAffiliatedSecretaries from "./LastAffiliatedSecretaries";
import TasksTypeKPI from "./KPI/TasksTypeKPI";

interface CustomersDashboardProps {
  customerId: string;
}

function CustomersDashboard({ customerId }: CustomersDashboardProps) {
  const { data, loading } = useQuery(GET_DATA_FOR_CUSTOMER_DASHBOARD, {
    variables: { input: { customerId } },
  });

  const defaultData: GetCustomerDashbouardData_getCustomerDashboardData = {
    __typename: "CustomerDashboardOutput",
    tasks_all: [],
    tasks_7_next_days: [],
    tasks_30_next_days: [],
    newAffiliatedSecretaries: [],
    taskData: [],
  };

  const {
    tasks_all: tasksAll,
    tasks_7_next_days: tasks7NextDays,
    tasks_30_next_days: tasks30NextDays,
    newAffiliatedSecretaries,
    taskData,
  } = data?.getCustomerDashboardData ?? defaultData;

  return (
    <main className="flex flex-col h-full">
      <div className="h-1/2 p-6">
        <LastInprogressTasks
          isLoading={loading}
          tasks_all={
            tasksAll as GetCustomerDashbouardData_getCustomerDashboardData_tasks_all[]
          }
          tasks_7_next_days={
            tasks7NextDays as GetCustomerDashbouardData_getCustomerDashboardData_tasks_7_next_days[]
          }
          tasks_30_next_days={
            tasks30NextDays as GetCustomerDashbouardData_getCustomerDashboardData_tasks_30_next_days[]
          }
        />
      </div>
      <div className="p-4 flex flex-col md:flex-row justify-between">
        <LastAffiliatedSecretaries secretaries={newAffiliatedSecretaries} />
        {taskData ? <TasksTypeKPI taskData={taskData} /> : null}
      </div>
    </main>
  );
}

export default CustomersDashboard;
