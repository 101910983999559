import dayjs from "dayjs";
import { TaskStatus } from "../../API/models/types";
import { InitialFilter } from "../models/models";

export const addDaysToDate = (date: Date, days: number) => {
  let res: Date = new Date();
  if (days >= 0) {
    res = dayjs(date).add(days, "day").toDate();
  } else {
    res = dayjs(date).subtract(Math.abs(days), "day").toDate();
  }
  return res;
};
export const parseSelectDateToServer = (selectedDateFilterValue: string) => {
  switch (selectedDateFilterValue) {
    case "0":
      return { taskDateFilter: undefined, date: undefined };

    case ">0":
      return { date: undefined, taskDateFilter: "COME" };

    case "<0":
      return { date: undefined, taskDateFilter: "PASSED" };

    case "30":
      return {
        date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
        taskDateFilter: "COME",
      };

    case "60":
      return {
        date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
        taskDateFilter: "COME",
      };

    case "90":
      return {
        date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
        taskDateFilter: "COME",
      };

    case "-30":
      return {
        date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
        taskDateFilter: "PASSED",
      };

    case "-60":
      return {
        date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
        taskDateFilter: "PASSED",
      };

    case "-90":
      return {
        date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
        taskDateFilter: "PASSED",
      };

    default:
      return { taskDateFilter: undefined, date: null };
  }
};
export const parseStatusToServer = (filter: InitialFilter) => {
  const array: TaskStatus[] = [];
  if (filter.statusCompleted) {
    array.push(TaskStatus.COMPLETED);
  }
  if (filter.statusToDo) {
    array.push(TaskStatus.TODO);
  }
  if (filter.statusPending) {
    array.push(TaskStatus.PENDING);
  }
  if (filter.statusUnrealized) {
    array.push(TaskStatus.UNREALIZED);
  }
  return array;
};

export const parseTaskTypeIdToServer = (filter: any[]) => {
  const array: string[] = [];
  if (filter && filter.length > 0) {
    filter.map((fi: any) => array.push(fi.value));
  }
  return array;
};
