import React from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import {
  GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_12,
  GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_6,
} from "../../../../../API/models/types";

interface ContractedHoursChartProps {
  data:
    | GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_6
    | GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_12;
}

function ContractedHoursChart({ data }: ContractedHoursChartProps) {
  const backgroundBarColors = [
    "#E5E7EB",
    "#E5E7EB",
    "#E5E7EB",
    "#E5E7EB",
    "#E5E7EB",
    "#E5E7EB",
    "#E5E7EB",
  ];

  // Extraire les données numériques et les labels pour l'axe x
  const numericData = data.months
    .map((m) => m.totalQuoteAgreementAmount)
    .reverse();

  const labels = data.months
    .map((m) => {
      const [month, year] = m.month.split("-");
      const date = new Date(Number(year), Number(month) - 1);
      const formattedLabel = date.toLocaleDateString("fr-FR", {
        month: "long",
        year: "numeric",
      });
      const capitalizedLabel =
        formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
      return capitalizedLabel;
    })
    .reverse();

  const series = [
    {
      name: "Heures",
      data: numericData.map((value, index) => ({
        x: labels[index],
        // Attention : Si la valeur en y vaut 0 ou null
        // cela n'affichera pas le svg du graphique
        // Alors on change la valeur par 0.1 et on fait attention a modifier
        // le label du tooltip dans les options
        y: value !== 0 ? value : 0.1,
      })),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      foreColor: "#4B5563",
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: false,
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#1A56DB",
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "25%",
        borderRadius: 3,
        colors: {
          backgroundBarColors,
          backgroundBarRadius: 3,
        },
      },
    },
    xaxis: {
      floating: true,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
      y: {
        formatter(val) {
          return val === 0.1 ? "0" : String(val); // => pour éviter le bug si la valeur = 0
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.8,
        },
      },
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      show: false,
      min: 1,
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return <Chart height={305} options={options} series={series} type="bar" />;
}

export default ContractedHoursChart;
