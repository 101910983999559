import { ApolloError } from "@apollo/client";
import { Card } from "flowbite-react";
import React, { useCallback, useContext, useState } from "react";
import ReactPaginate from "react-paginate";

import tasksIllustration from "../../../assets/illustrations/img-taches.png";
import {
  FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries,
  FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks,
  UserRole,
} from "../../../API/models/types";
import SpinnerComponent from "../../../components/shared/SpinnerComponent";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../context/FilterContext";
import { UserContext } from "../../../context/userContext";
import { ItemPendingTask } from "../../models/models";

import TablePendingTasks from "./table/TablePendingTasks";
import { FindAllPaginatePendingTasksForSecretaries } from "../../utils/QueriesTasks";
import SomethingHappened from "../../SomethingHappened";
import getFormattedHours from "../../Calendar/utils/getFormattedHours";

function PendingTasksList() {
  const { user } = useContext(UserContext);
  // contexte filtre

  const { filters, setFilters, pageKey } =
    useContext<FilterTasksContextType>(FilterTasksContext);

  const {
    tasks,
    loading,
    error,
  }: {
    tasks: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries;
    loading: boolean;
    error: ApolloError | undefined;
  } =
    user !== null && user && user.id !== null && filters !== null && filters
      ? FindAllPaginatePendingTasksForSecretaries(filters, user.id as string)
      : {
          tasks: {
            tasks:
              [] as FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks[],
            total: 0,
            __typename: "OutputPaginateTasks",
          },
          loading: false,
          error: undefined,
        };

  const pendingTasksData = useCallback(() => {
    const items: ItemPendingTask[] = [];
    if (tasks && tasks.tasks && tasks.tasks.length > 0) {
      const tasksList =
        tasks &&
        (tasks.tasks as FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks[]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tasksList.length; i++) {
        const item: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks =
          tasksList[i];
        items.push({
          id: item.id,
          label: item.label,
          description: item.description,
          plannedDuration: getFormattedHours(item.plannedDuration),
          customer: {
            id: item.customer?.id as string,
            userName: `${item.customer?.last_name.toUpperCase()} ${
              item.customer?.first_name
            }`,
            avatar: item.customer?.avatar_url as string,
            email: item.customer?.email,
            role: item.customer?.role as UserRole,
          },
          author: {
            id: item.author?.id as string,
            userName: `${item.author?.last_name.toUpperCase()} ${
              item.author?.first_name
            }`,
            avatar: item.author?.avatar_url as string,
            email: item.author?.email,
            role: item.author?.role as UserRole,
          },
          startDate: item.startDate,
          status: item.status,
          quoteAgreement: {
            label: item.quoteAgreement?.label as string,
            reference: item.quoteAgreement?.reference as string,
          },
          taskType: {
            label: item.taskType?.label,
          },
          category: item.taskType?.categoryTaskType.label,
          color: item.color,
        });
      }
    }
    return items;
  }, [tasks]);
  // nombre de ligne par page de tableau
  const ROWBYPAGE: number = 6;

  // calcul nombre de page
  const nbPageFloat: number = tasks && tasks.total && tasks.total / ROWBYPAGE;

  // nombre de page arrondi à l'entier supérieur
  const nbPage = (nbPageFloat && Math.ceil(nbPageFloat)) || 0;
  const myPendingPage: string | null = localStorage.getItem(pageKey);
  const [page, setPage] = useState(
    (myPendingPage && JSON.parse(myPendingPage).page) || 1,
  );

  const handlePageChange = (event: any) => {
    if (filters && setFilters) {
      setFilters({
        ...filters,
        page: event.selected + 1,
      });
    }
    localStorage.setItem(pageKey, JSON.stringify({ page: event.selected + 1 }));
    setPage(event.selected + 1);
    // localStorage.removeItem("myTasksListPage");
  };
  return !error ? (
    <main className="flex flex-col">
      <header className="flex flex-col h-1/7 lg:max-w-[50%] justify-items-start items-center relative">
        <div className="basis-2/12 flex">
          <img
            src={tasksIllustration}
            alt="pending tasks list"
            className="w-full"
          />
        </div>
        <div className="basis-9/12 p-10 sm:p-0 absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="md:ml-2 text-base md:text-lg lg:text-xl xl:text-3xl max-w-[80%]">
            Liste des tâches en attente d'acceptation
          </h1>
        </div>
      </header>

      {!loading ? (
        <main className="w-full h-4/7 p-5">
          <div className="flex justify-center w-full">
            <div
              className="flex p-4 mb-4 text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 md:w-1/2 lg:w-1/2"
              role="alert"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span>
                  Une fois vos tâches confirmées, celles-ci seront ajoutées à
                  votre agenda et à vos tâches en cours.
                </span>
              </div>
            </div>
          </div>
          <div className="flex w-full h-4/7">
            <Card className=" flex gap-1 w-full h-full p-3">
              <div className="flex min-h-3/4">
                <TablePendingTasks
                  data={pendingTasksData()}
                  getAllPendingTasks={
                    tasks &&
                    (tasks.tasks as FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks[])
                  }
                />
              </div>
              <div className="flex flex-row justify-left text-center">
                <div className="basis-4/5 text-left">
                  <span className="items-center gap-1 flex flex-row text-xs p-4">
                    <p className="text-gray-400 text-xs">Page</p>
                    <strong className="flex color:#40788B">{page}</strong>
                    <p className="text-gray-400 text-xs">sur</p>
                    {nbPage === 0 ? (
                      <strong>1</strong>
                    ) : (
                      <strong> {nbPage}</strong>
                    )}
                  </span>
                </div>
                <div className="basis-1/5">
                  {nbPage !== 0 ? (
                    <ReactPaginate
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={ROWBYPAGE}
                      pageCount={nbPage}
                      forcePage={page - 1 < nbPage ? page - 1 : 0}
                      marginPagesDisplayed={2}
                      nextLabel=">"
                      containerClassName="pagination"
                      previousLinkClassName="pagination__link"
                      nextLinkClassName="pagination__link"
                      disabledClassName="pagination__link--disabled"
                      activeClassName="pagination__link--active"
                      previousLabel="<"
                      onPageActive={() => page}
                    />
                  ) : null}
                </div>
              </div>
            </Card>
          </div>
        </main>
      ) : (
        <SpinnerComponent />
      )}
    </main>
  ) : (
    <SomethingHappened />
  );
}

export default PendingTasksList;
