import React from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaKeyboard } from "react-icons/fa";
import { GetUserByID_getUserByID } from "../../API/models/types";
import UserProfileView from "./UserProfileView";

interface Props {
  data: GetUserByID_getUserByID;
  userId: string;
}
export default function MyProfileView(props: Props) {
  const { data, userId } = props;
  return (
    <div className="flex-flex-col w-full">
      <div>
        <UserProfileView data={data} />
      </div>
      <div className="flex flex-col sm:flex-row sm:mb-10 w-full sm:w-1/3 p-5 sm:p-1">
        <div className="sm:mx-2">
          <Link to={`/modifier-mon-compte/${userId}`}>
            <button
              className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm mx-2 mb-3 w-full"
              type="button"
            >
              <FaEdit className="h-4 w-4 mr-2" />
              Modifier mon compte
            </button>
          </Link>
        </div>
        <div className="sm:mx-2">
          <Link to="/modifier-mon-mot-de-passe">
            <button
              className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm mx-2 mb-3 w-full"
              type="button"
            >
              <FaKeyboard className="h-4 w-4 mr-2" />
              Modifier mon mot de passe
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
