/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { DaysMatrix, getWeek } from "../../utils/getWeek";
import { Task } from "../../hook/useTasks";
import getCurrentDayClass from "../../utils/getCurrentDayClass";
import TaskWeekViewCell from "./TaskWeekViewCell";
import shouldDisplayTask from "../../utils/shouldDisplayTask";
import { useSidebarContext } from "../../../../context/SideBarContext";
import { isSmallScreen } from "../../../../context/is-small-screen";

interface WeekViewProps {
  tasks: Task[];
  week: ReturnType<typeof getWeek>;
}

function WeekView({ tasks, week }: WeekViewProps) {
  const [daysMatrix, setDaysMatrix] = useState<DaysMatrix>([]);
  useEffect(() => {
    setDaysMatrix(week);
  }, [week]);

  // Filtrer pour enlever le dimanche
  const filteredDaysMatrix = daysMatrix.filter(
    (dayObj) => dayObj.day.day() !== 0,
  );

  // vérification de la taille de l'écran
  const { width } = useSidebarContext();
  const [smallScreen, setSmallScreen] = useState<boolean>(isSmallScreen(width));
  // vérification de la taille de l'écran pour formatter la date en fonction
  useEffect(() => {
    setSmallScreen(isSmallScreen(width));
  }, [width]);

  return (
    <div className="flex bg-white p-2 shadow">
      <div className="w-auto pr-1 md:pr-4">
        <div className="h-10 md:h-6 mb-2" />
        {new Array(14).fill(0).map((_, i) => (
          <div
            key={i}
            className="h-16 border-b border-gray-200 flex items-center"
          >
            <span className="text-gray-600 text-xs sm:text-base">
              {7 + i}:00
            </span>
          </div>
        ))}
      </div>
      <div className="flex w-full">
        {(smallScreen ? filteredDaysMatrix : daysMatrix).map(
          (dayObj, dayIndex) => (
            <div key={dayIndex} className="flex flex-col w-full">
              <div className="text-center mb-2 flex flex-col md:flex-row items-center justify-center md:space-x-2 border-l border-dashed">
                <span
                  className={`font-bold text-gray-800 md:text-base text-sm ${getCurrentDayClass(
                    dayObj.day,
                  )}`}
                >
                  {dayObj.day.format("D")}
                </span>
                <span className="first-letter:uppercase text-gray-800 md:text-base text-sm">
                  {smallScreen
                    ? dayObj.day.format("dd")
                    : dayObj.day.format("dddd")}
                </span>
              </div>
              {dayObj.hours.map((hour, hourIndex) => (
                <div
                  key={hourIndex}
                  className="h-16 border-b border-gray-200 flex items-center border-l border-dashed relative"
                >
                  {tasks.map((task) => {
                    if (shouldDisplayTask(task.startDate, hour)) {
                      return <TaskWeekViewCell key={task.id} task={task} />;
                    }
                    return null;
                  })}
                </div>
              ))}
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default WeekView;
