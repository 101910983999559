/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_authLogin {
  __typename: "AuthLoginOutput";
  accessToken: string;
  isActive: boolean;
  role: UserRole;
  id: string;
  avatar_url: string | null;
}

export interface login {
  authLogin: login_authLogin;
}

export interface loginVariables {
  username: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkJwt
// ====================================================

export interface checkJwt {
  checkToken: boolean;
}

export interface checkJwtVariables {
  token: CheckTokenDto;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCategoryTaskType
// ====================================================

export interface UpdateCategoryTaskType_updateCategoryTaskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface UpdateCategoryTaskType_updateCategoryTaskType {
  __typename: "CategoryTaskTypeOutput";
  categoryTaskType: UpdateCategoryTaskType_updateCategoryTaskType_categoryTaskType;
}

export interface UpdateCategoryTaskType {
  updateCategoryTaskType: UpdateCategoryTaskType_updateCategoryTaskType;
}

export interface UpdateCategoryTaskTypeVariables {
  input: UpdateCategoryTaskTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveCategoryTaskType
// ====================================================

export interface RemoveCategoryTaskType {
  removeCategoryTaskType: string;
}

export interface RemoveCategoryTaskTypeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCategoryTaskType
// ====================================================

export interface CreateCategoryTaskType_createCategoryTaskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface CreateCategoryTaskType_createCategoryTaskType {
  __typename: "CategoryTaskTypeOutput";
  categoryTaskType: CreateCategoryTaskType_createCategoryTaskType_categoryTaskType;
}

export interface CreateCategoryTaskType {
  createCategoryTaskType: CreateCategoryTaskType_createCategoryTaskType;
}

export interface CreateCategoryTaskTypeVariables {
  input: CreateCategoryTaskTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCategoriesTaskType
// ====================================================

export interface GetAllCategoriesTaskType_getAllCategoriesTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface GetAllCategoriesTaskType {
  getAllCategoriesTaskType: GetAllCategoriesTaskType_getAllCategoriesTaskType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOneCategoryTaskType
// ====================================================

export interface GetOneCategoryTaskType_getOneCategoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface GetOneCategoryTaskType {
  getOneCategoryTaskType: GetOneCategoryTaskType_getOneCategoryTaskType;
}

export interface GetOneCategoryTaskTypeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCategoryTaskTypeWithTaskTypes
// ====================================================

export interface GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
}

export interface GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes {
  __typename: "CategoryTaskTypeWithTaskTypesDTO";
  id: string;
  label: string;
  taskTypes: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
}

export interface GetAllCategoryTaskTypeWithTaskTypes {
  getAllCategoryTaskTypeWithTaskTypes: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDataForSecretaryDashBoard
// ====================================================

export interface GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard_tasks {
  __typename: "Task";
  id: string;
  label: string;
  startDate: any;
  plannedDuration: number | null;
  status: TaskStatus;
}

export interface GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard_users {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string | null;
}

export interface GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard_graphData {
  __typename: "GraphData";
  name: string;
  data: number[];
}

export interface GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard {
  __typename: "SecretaryDashboardOutput";
  tasks: GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard_tasks[];
  totalQuoteAgreements: number;
  contractPercentInRelationToLastMonth: string;
  users: GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard_users[];
  graphData: GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard_graphData[];
  graphX: string[];
}

export interface GetDataForSecretaryDashBoard {
  getDataForSecretaryDashBoard: GetDataForSecretaryDashBoard_getDataForSecretaryDashBoard;
}

export interface GetDataForSecretaryDashBoardVariables {
  input: SecretaryDashboardDto;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomerDashbouardData
// ====================================================

export interface GetCustomerDashbouardData_getCustomerDashboardData_tasks_all {
  __typename: "Task";
  id: string;
  label: string;
  startDate: any;
  plannedDuration: number | null;
  status: TaskStatus;
}

export interface GetCustomerDashbouardData_getCustomerDashboardData_tasks_7_next_days {
  __typename: "Task";
  id: string;
  label: string;
  startDate: any;
  plannedDuration: number | null;
  status: TaskStatus;
}

export interface GetCustomerDashbouardData_getCustomerDashboardData_tasks_30_next_days {
  __typename: "Task";
  id: string;
  label: string;
  startDate: any;
  plannedDuration: number | null;
  status: TaskStatus;
}

export interface GetCustomerDashbouardData_getCustomerDashboardData_newAffiliatedSecretaries {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string | null;
}

export interface GetCustomerDashbouardData_getCustomerDashboardData_taskData_taskTypes {
  __typename: "TaskTypeCountOutput";
  id: string;
  label: string;
  percentage: number;
}

export interface GetCustomerDashbouardData_getCustomerDashboardData_taskData {
  __typename: "CategoryTaskTypeForCustomerDashboardOutput";
  id: string;
  label: string;
  percentage: number;
  taskTypes: GetCustomerDashbouardData_getCustomerDashboardData_taskData_taskTypes[];
}

export interface GetCustomerDashbouardData_getCustomerDashboardData {
  __typename: "CustomerDashboardOutput";
  tasks_all: GetCustomerDashbouardData_getCustomerDashboardData_tasks_all[];
  tasks_7_next_days: GetCustomerDashbouardData_getCustomerDashboardData_tasks_7_next_days[];
  tasks_30_next_days: GetCustomerDashbouardData_getCustomerDashboardData_tasks_30_next_days[];
  newAffiliatedSecretaries: GetCustomerDashbouardData_getCustomerDashboardData_newAffiliatedSecretaries[];
  taskData: GetCustomerDashbouardData_getCustomerDashboardData_taskData[];
}

export interface GetCustomerDashbouardData {
  getCustomerDashboardData: GetCustomerDashbouardData_getCustomerDashboardData;
}

export interface GetCustomerDashbouardDataVariables {
  input: CustomerDashboardDto;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubscriptionsData
// ====================================================

export interface GetSubscriptionsData_getSubscriptionsData_option_7_subscriptions {
  __typename: "SubscriptionDto";
  date: string;
  secretaries: number;
  customers: number;
}

export interface GetSubscriptionsData_getSubscriptionsData_option_7 {
  __typename: "IntervalDataDto";
  totalSecretaries: number;
  totalCustomers: number;
  finalTotalUsers: number;
  percentageChange: string;
  subscriptions: GetSubscriptionsData_getSubscriptionsData_option_7_subscriptions[];
}

export interface GetSubscriptionsData_getSubscriptionsData_option_30_subscriptions {
  __typename: "SubscriptionDto";
  date: string;
  secretaries: number;
  customers: number;
}

export interface GetSubscriptionsData_getSubscriptionsData_option_30 {
  __typename: "IntervalDataDto";
  totalSecretaries: number;
  totalCustomers: number;
  finalTotalUsers: number;
  percentageChange: string;
  subscriptions: GetSubscriptionsData_getSubscriptionsData_option_30_subscriptions[];
}

export interface GetSubscriptionsData_getSubscriptionsData {
  __typename: "SubscriptionsDataDto";
  option_7: GetSubscriptionsData_getSubscriptionsData_option_7 | null;
  option_30: GetSubscriptionsData_getSubscriptionsData_option_30 | null;
}

export interface GetSubscriptionsData {
  getSubscriptionsData: GetSubscriptionsData_getSubscriptionsData;
}

export interface GetSubscriptionsDataVariables {
  dateRange: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTotalHourlyAmountOnQuoteAgreements
// ====================================================

export interface GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_6_months {
  __typename: "MonthlyTotal";
  month: string;
  totalQuoteAgreementAmount: number;
}

export interface GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_6 {
  __typename: "PeriodTotal";
  totalQuoteAgreementAmount: number;
  progression: string;
  months: GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_6_months[];
}

export interface GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_12_months {
  __typename: "MonthlyTotal";
  month: string;
  totalQuoteAgreementAmount: number;
}

export interface GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_12 {
  __typename: "PeriodTotal";
  totalQuoteAgreementAmount: number;
  progression: string;
  months: GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_12_months[];
}

export interface GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements {
  __typename: "GetTotalHourlyAmountOutput";
  option_6: GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_6 | null;
  option_12: GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements_option_12 | null;
}

export interface GetTotalHourlyAmountOnQuoteAgreements {
  getTotalHourlyAmountOnQuoteAgreements: GetTotalHourlyAmountOnQuoteAgreements_getTotalHourlyAmountOnQuoteAgreements;
}

export interface GetTotalHourlyAmountOnQuoteAgreementsVariables {
  dateRange: DateRangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFrameworkAgreement
// ====================================================

export interface UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement_user;
}

export interface UpdateFrameworkAgreement_updateFrameworkAgreement {
  __typename: "FrameworkAgreementOutput";
  frameworkAgreement: UpdateFrameworkAgreement_updateFrameworkAgreement_frameworkAgreement;
}

export interface UpdateFrameworkAgreement {
  updateFrameworkAgreement: UpdateFrameworkAgreement_updateFrameworkAgreement;
}

export interface UpdateFrameworkAgreementVariables {
  input: FrameworkAgreementUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveFrameworkAgreement
// ====================================================

export interface RemoveFrameworkAgreement {
  removeFrameworkAgreement: string;
}

export interface RemoveFrameworkAgreementVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFrameworkAgreement
// ====================================================

export interface CreateFrameworkAgreement_createFrameworkAgreement_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface CreateFrameworkAgreement_createFrameworkAgreement_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: CreateFrameworkAgreement_createFrameworkAgreement_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface CreateFrameworkAgreement_createFrameworkAgreement_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | CreateFrameworkAgreement_createFrameworkAgreement_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface CreateFrameworkAgreement_createFrameworkAgreement_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: CreateFrameworkAgreement_createFrameworkAgreement_frameworkAgreement_user;
}

export interface CreateFrameworkAgreement_createFrameworkAgreement {
  __typename: "FrameworkAgreementOutput";
  frameworkAgreement: CreateFrameworkAgreement_createFrameworkAgreement_frameworkAgreement;
}

export interface CreateFrameworkAgreement {
  createFrameworkAgreement: CreateFrameworkAgreement_createFrameworkAgreement;
}

export interface CreateFrameworkAgreementVariables {
  input: FrameworkAgreementCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DownloadCsvFrameworkAgreementsTasksList
// ====================================================

export interface DownloadCsvFrameworkAgreementsTasksList_downloadCsvFrameworkAgreementsList {
  __typename: "CsvOutput";
  csvData: string;
}

export interface DownloadCsvFrameworkAgreementsTasksList {
  downloadCsvFrameworkAgreementsList: DownloadCsvFrameworkAgreementsTasksList_downloadCsvFrameworkAgreementsList;
}

export interface DownloadCsvFrameworkAgreementsTasksListVariables {
  role: UserRole;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllFrameworkAgreements
// ====================================================

export interface GetAllFrameworkAgreements_getAllFrameworkAgreements_user_admin {
  __typename: "User";
  id: string;
}

export interface GetAllFrameworkAgreements_getAllFrameworkAgreements_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetAllFrameworkAgreements_getAllFrameworkAgreements_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetAllFrameworkAgreements_getAllFrameworkAgreements_user_taskTypes_categoryTaskType;
}

export interface GetAllFrameworkAgreements_getAllFrameworkAgreements_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetAllFrameworkAgreements_getAllFrameworkAgreements_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetAllFrameworkAgreements_getAllFrameworkAgreements_user_taskTypes[]
    | null;
}

export interface GetAllFrameworkAgreements_getAllFrameworkAgreements {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: GetAllFrameworkAgreements_getAllFrameworkAgreements_user;
}

export interface GetAllFrameworkAgreements {
  getAllFrameworkAgreements: GetAllFrameworkAgreements_getAllFrameworkAgreements[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllFrameworkAgreementsForUser
// ====================================================

export interface getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser_user_admin {
  __typename: "User";
  id: string;
}

export interface getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser_user_taskTypes_categoryTaskType;
}

export interface getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser_user_taskTypes[]
    | null;
}

export interface getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser_user;
}

export interface getAllFrameworkAgreementsForUser {
  getAllFrameworkAgreementsForUser: getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser[];
}

export interface getAllFrameworkAgreementsForUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllFrameworkAgreementsForAdmin
// ====================================================

export interface GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin_user_admin {
  __typename: "User";
  id: string;
}

export interface GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin_user_taskTypes_categoryTaskType;
}

export interface GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin_user {
  __typename: "User";
  id: string;
  createdAt: any;
  admin: GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin_user_admin | null;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin_user_taskTypes[]
    | null;
}

export interface GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin_user;
}

export interface GetAllFrameworkAgreementsForAdmin {
  getAllFrameworkAgreementsForAdmin: GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin[];
}

export interface GetAllFrameworkAgreementsForAdminVariables {
  adminId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOneFrameworkAgreement
// ====================================================

export interface GetOneFrameworkAgreement_getOneFrameworkAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface GetOneFrameworkAgreement_getOneFrameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetOneFrameworkAgreement_getOneFrameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetOneFrameworkAgreement_getOneFrameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface GetOneFrameworkAgreement_getOneFrameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetOneFrameworkAgreement_getOneFrameworkAgreement_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetOneFrameworkAgreement_getOneFrameworkAgreement_user_taskTypes[]
    | null;
}

export interface GetOneFrameworkAgreement_getOneFrameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: GetOneFrameworkAgreement_getOneFrameworkAgreement_user;
}

export interface GetOneFrameworkAgreement {
  getOneFrameworkAgreement: GetOneFrameworkAgreement_getOneFrameworkAgreement;
}

export interface GetOneFrameworkAgreementVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: frameworkAgreementPagination
// ====================================================

export interface frameworkAgreementPagination_frameworkAgreementPagination_nodes_user_admin {
  __typename: "User";
  id: string;
}

export interface frameworkAgreementPagination_frameworkAgreementPagination_nodes_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface frameworkAgreementPagination_frameworkAgreementPagination_nodes_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: frameworkAgreementPagination_frameworkAgreementPagination_nodes_user_taskTypes_categoryTaskType;
}

export interface frameworkAgreementPagination_frameworkAgreementPagination_nodes_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: frameworkAgreementPagination_frameworkAgreementPagination_nodes_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | frameworkAgreementPagination_frameworkAgreementPagination_nodes_user_taskTypes[]
    | null;
}

export interface frameworkAgreementPagination_frameworkAgreementPagination_nodes {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: frameworkAgreementPagination_frameworkAgreementPagination_nodes_user;
}

export interface frameworkAgreementPagination_frameworkAgreementPagination {
  __typename: "FrameworkAgreementPagination";
  nodes: frameworkAgreementPagination_frameworkAgreementPagination_nodes[];
}

export interface frameworkAgreementPagination {
  frameworkAgreementPagination: frameworkAgreementPagination_frameworkAgreementPagination;
}

export interface frameworkAgreementPaginationVariables {
  skip: number;
  take: number;
  sortBy?: FrameworkAgreementPaginationSortBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateQuoteAgreement
// ====================================================

export interface UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_user_taskTypes_categoryTaskType;
}

export interface UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_user_taskTypes[]
    | null;
}

export interface UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_frameworkAgreement_user;
}

export interface UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
  user: UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_user;
  frameworkAgreement: UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement_frameworkAgreement;
}

export interface UpdateQuoteAgreement_updateQuoteAgreement {
  __typename: "QuoteAgreementOutput";
  quoteAgreement: UpdateQuoteAgreement_updateQuoteAgreement_quoteAgreement;
}

export interface UpdateQuoteAgreement {
  updateQuoteAgreement: UpdateQuoteAgreement_updateQuoteAgreement;
}

export interface UpdateQuoteAgreementVariables {
  input: UpdateQuoteAgreementInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveQuoteAgreement
// ====================================================

export interface RemoveQuoteAgreement {
  removeQuoteAgreement: string;
}

export interface RemoveQuoteAgreementVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateQuoteAgreement
// ====================================================

export interface CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_user_taskTypes_categoryTaskType;
}

export interface CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_user_taskTypes[]
    | null;
}

export interface CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_frameworkAgreement_user;
}

export interface CreateQuoteAgreement_createQuoteAgreement_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
  user: CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_user;
  frameworkAgreement: CreateQuoteAgreement_createQuoteAgreement_quoteAgreement_frameworkAgreement;
}

export interface CreateQuoteAgreement_createQuoteAgreement {
  __typename: "QuoteAgreementOutput";
  quoteAgreement: CreateQuoteAgreement_createQuoteAgreement_quoteAgreement;
}

export interface CreateQuoteAgreement {
  createQuoteAgreement: CreateQuoteAgreement_createQuoteAgreement;
}

export interface CreateQuoteAgreementVariables {
  input: CreateQuoteAgreementInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DownloadCsvQuoteAgreementsTasksList
// ====================================================

export interface DownloadCsvQuoteAgreementsTasksList_downloadCsvQuoteAgreementsList {
  __typename: "CsvOutput";
  csvData: string;
}

export interface DownloadCsvQuoteAgreementsTasksList {
  downloadCsvQuoteAgreementsList: DownloadCsvQuoteAgreementsTasksList_downloadCsvQuoteAgreementsList;
}

export interface DownloadCsvQuoteAgreementsTasksListVariables {
  role: UserRole;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllQuoteAgreements
// ====================================================

export interface GetAllQuoteAgreements_getAllQuoteAgreements_user_admin {
  __typename: "User";
  id: string;
}

export interface GetAllQuoteAgreements_getAllQuoteAgreements_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetAllQuoteAgreements_getAllQuoteAgreements_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetAllQuoteAgreements_getAllQuoteAgreements_user_taskTypes_categoryTaskType;
}

export interface GetAllQuoteAgreements_getAllQuoteAgreements_user {
  __typename: "User";
  id: string;
  createdAt: any;
  first_name: string;
  admin: GetAllQuoteAgreements_getAllQuoteAgreements_user_admin | null;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetAllQuoteAgreements_getAllQuoteAgreements_user_taskTypes[]
    | null;
}

export interface GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement_user {
  __typename: "User";
  id: string;
  admin: GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement_user_admin | null;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement_user;
}

export interface GetAllQuoteAgreements_getAllQuoteAgreements {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
  user: GetAllQuoteAgreements_getAllQuoteAgreements_user;
  frameworkAgreement: GetAllQuoteAgreements_getAllQuoteAgreements_frameworkAgreement;
}

export interface GetAllQuoteAgreements {
  getAllQuoteAgreements: GetAllQuoteAgreements_getAllQuoteAgreements[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOneActiveQuoteAgreementBetweenCustomerAndSecretary
// ====================================================

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_user_admin {
  __typename: "User";
  id: string;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_user_taskTypes_categoryTaskType;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_user {
  __typename: "User";
  id: string;
  createdAt: any;
  admin: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_user_taskTypes[]
    | null;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement_user;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
  user: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_user;
  frameworkAgreement: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary_frameworkAgreement;
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretary {
  getOneActiveQuoteAgreementBetweenCustomerAndSecretary: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary[];
}

export interface getOneActiveQuoteAgreementBetweenCustomerAndSecretaryVariables {
  customerId: string;
  secretaryId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllQuoteAgreementsForCustomer
// ====================================================

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_user_admin {
  __typename: "User";
  id: string;
}

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_user_taskTypes_categoryTaskType;
}

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_user {
  __typename: "User";
  id: string;
  createdAt: any;
  admin: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_user_taskTypes[]
    | null;
}

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement_user;
}

export interface getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
  user: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_user;
  frameworkAgreement: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer_frameworkAgreement;
}

export interface getAllQuoteAgreementsForCustomer {
  getAllQuoteAgreementsForCustomer: getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer[];
}

export interface getAllQuoteAgreementsForCustomerVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllQuoteAgreementsForAdmin
// ====================================================

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_user_admin {
  __typename: "User";
  id: string;
}

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_user_taskTypes_categoryTaskType;
}

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_user {
  __typename: "User";
  id: string;
  createdAt: any;
  admin: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_user_taskTypes[]
    | null;
}

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement_user;
}

export interface GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
  user: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_user;
  frameworkAgreement: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin_frameworkAgreement;
}

export interface GetAllQuoteAgreementsForAdmin {
  getAllQuoteAgreementsForAdmin: GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin[];
}

export interface GetAllQuoteAgreementsForAdminVariables {
  adminId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllQuoteAgreementsForSecretary
// ====================================================

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_user_admin {
  __typename: "User";
  id: string;
}

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_user_taskTypes_categoryTaskType;
}

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_user {
  __typename: "User";
  id: string;
  createdAt: any;
  admin: GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_user_taskTypes[]
    | null;
}

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement_user;
}

export interface GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
  user: GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_user;
  frameworkAgreement: GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary_frameworkAgreement;
}

export interface GetAllQuoteAgreementsForSecretary {
  getAllQuoteAgreementsForSecretary: GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary[];
}

export interface GetAllQuoteAgreementsForSecretaryVariables {
  secretaryId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOneQuoteAgreement
// ====================================================

export interface GetOneQuoteAgreement_getOneQuoteAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface GetOneQuoteAgreement_getOneQuoteAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetOneQuoteAgreement_getOneQuoteAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetOneQuoteAgreement_getOneQuoteAgreement_user_taskTypes_categoryTaskType;
}

export interface GetOneQuoteAgreement_getOneQuoteAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetOneQuoteAgreement_getOneQuoteAgreement_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes: GetOneQuoteAgreement_getOneQuoteAgreement_user_taskTypes[] | null;
}

export interface GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement_user;
}

export interface GetOneQuoteAgreement_getOneQuoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
  user: GetOneQuoteAgreement_getOneQuoteAgreement_user;
  frameworkAgreement: GetOneQuoteAgreement_getOneQuoteAgreement_frameworkAgreement;
}

export interface GetOneQuoteAgreement {
  getOneQuoteAgreement: GetOneQuoteAgreement_getOneQuoteAgreement;
}

export interface GetOneQuoteAgreementVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTaskType
// ====================================================

export interface UpdateTaskType_updateTaskType_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface UpdateTaskType_updateTaskType_taskType {
  __typename: "TaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  categoryTaskType: UpdateTaskType_updateTaskType_taskType_categoryTaskType;
}

export interface UpdateTaskType_updateTaskType {
  __typename: "TaskTypeOutput";
  taskType: UpdateTaskType_updateTaskType_taskType;
}

export interface UpdateTaskType {
  updateTaskType: UpdateTaskType_updateTaskType;
}

export interface UpdateTaskTypeVariables {
  input: UpdateTaskTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveTaskType
// ====================================================

export interface RemoveTaskType {
  removeTaskType: string;
}

export interface RemoveTaskTypeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTaskType
// ====================================================

export interface CreateTaskType_createTaskType_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface CreateTaskType_createTaskType_taskType {
  __typename: "TaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  categoryTaskType: CreateTaskType_createTaskType_taskType_categoryTaskType;
}

export interface CreateTaskType_createTaskType {
  __typename: "TaskTypeOutput";
  taskType: CreateTaskType_createTaskType_taskType;
}

export interface CreateTaskType {
  createTaskType: CreateTaskType_createTaskType;
}

export interface CreateTaskTypeVariables {
  input: CreateTaskTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllTasksType
// ====================================================

export interface GetAllTasksType_getAllTasksType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface GetAllTasksType_getAllTasksType {
  __typename: "TaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  categoryTaskType: GetAllTasksType_getAllTasksType_categoryTaskType;
}

export interface GetAllTasksType {
  getAllTasksType: GetAllTasksType_getAllTasksType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOneTaskType
// ====================================================

export interface GetOneTaskType_getOneTaskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface GetOneTaskType_getOneTaskType {
  __typename: "TaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  categoryTaskType: GetOneTaskType_getOneTaskType_categoryTaskType;
}

export interface GetOneTaskType {
  getOneTaskType: GetOneTaskType_getOneTaskType;
}

export interface GetOneTaskTypeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DownloadCsvTasksList
// ====================================================

export interface DownloadCsvTasksList_downloadCsvTasksList {
  __typename: "CsvOutput";
  csvData: string;
}

export interface DownloadCsvTasksList {
  downloadCsvTasksList: DownloadCsvTasksList_downloadCsvTasksList;
}

export interface DownloadCsvTasksListVariables {
  paginationArgs: TaskPaginationDto;
  role: UserRole;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTask
// ====================================================

export interface UpdateTask_updateTask_task {
  __typename: "Task";
  id: string;
}

export interface UpdateTask_updateTask {
  __typename: "TaskOutput";
  task: UpdateTask_updateTask_task;
}

export interface UpdateTask {
  updateTask: UpdateTask_updateTask;
}

export interface UpdateTaskVariables {
  input: UpdateTaskInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveTask
// ====================================================

export interface RemoveTask {
  removeTask: string;
}

export interface RemoveTaskVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTask
// ====================================================

export interface CreateTask_createTask_task {
  __typename: "Task";
  id: string;
}

export interface CreateTask_createTask {
  __typename: "TaskOutput";
  task: CreateTask_createTask_task;
}

export interface CreateTask {
  createTask: CreateTask_createTask;
}

export interface CreateTaskVariables {
  input: CreateTaskInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTasks
// ====================================================

export interface UpdateTasks_updateTasks {
  __typename: "Task";
  id: string;
  label: string;
  status: TaskStatus;
}

export interface UpdateTasks {
  updateTasks: UpdateTasks_updateTasks[];
}

export interface UpdateTasksVariables {
  updateTasksInput: UpdateTasksInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAgendaTasksForSuperAdmin
// ====================================================

export interface FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_taskType_categoryTaskType;
}

export interface FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
}

export interface FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin {
  __typename: "Task";
  id: string;
  color: string;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  author: FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_author;
  secretary: FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_secretary;
  customer: FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_customer | null;
  taskType: FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_taskType;
  quoteAgreement: FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin_quoteAgreement | null;
}

export interface FindAgendaTasksForSuperAdmin {
  findAgendaTasksForSuperAdmin: FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin[];
}

export interface FindAgendaTasksForSuperAdminVariables {
  startDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAllPaginateTasksForSuperAdmin
// ====================================================

export interface FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_taskType_categoryTaskType;
}

export interface FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
}

export interface FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks {
  __typename: "Task";
  id: string;
  color: string;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  secretary: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_secretary;
  customer: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_customer | null;
  author: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_author;
  taskType: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_taskType;
  quoteAgreement: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks_quoteAgreement | null;
}

export interface FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin {
  __typename: "OutputPaginateTasks";
  tasks: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks[];
  total: number;
}

export interface FindAllPaginateTasksForSuperAdmin {
  findAllPaginateTasksForSuperAdmin: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin;
}

export interface FindAllPaginateTasksForSuperAdminVariables {
  paginationArgs: TaskPaginationDto;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAgendaTasksForCustomers
// ====================================================

export interface FindAgendaTasksForCustomers_findAgendaTasksForCustomers_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAgendaTasksForCustomers_findAgendaTasksForCustomers_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAgendaTasksForCustomers_findAgendaTasksForCustomers_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAgendaTasksForCustomers_findAgendaTasksForCustomers_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
}

export interface FindAgendaTasksForCustomers_findAgendaTasksForCustomers_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindAgendaTasksForCustomers_findAgendaTasksForCustomers_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindAgendaTasksForCustomers_findAgendaTasksForCustomers_taskType_categoryTaskType;
}

export interface FindAgendaTasksForCustomers_findAgendaTasksForCustomers {
  __typename: "Task";
  id: string;
  color: string;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  secretary: FindAgendaTasksForCustomers_findAgendaTasksForCustomers_secretary;
  customer: FindAgendaTasksForCustomers_findAgendaTasksForCustomers_customer | null;
  author: FindAgendaTasksForCustomers_findAgendaTasksForCustomers_author;
  quoteAgreement: FindAgendaTasksForCustomers_findAgendaTasksForCustomers_quoteAgreement | null;
  taskType: FindAgendaTasksForCustomers_findAgendaTasksForCustomers_taskType;
}

export interface FindAgendaTasksForCustomers {
  findAgendaTasksForCustomers: FindAgendaTasksForCustomers_findAgendaTasksForCustomers[];
}

export interface FindAgendaTasksForCustomersVariables {
  customerId: string;
  startDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAgendaTasksForAdmin
// ====================================================

export interface FindAgendaTasksForAdmin_findAgendaTasksForAdmin_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAgendaTasksForAdmin_findAgendaTasksForAdmin_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAgendaTasksForAdmin_findAgendaTasksForAdmin_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAgendaTasksForAdmin_findAgendaTasksForAdmin_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindAgendaTasksForAdmin_findAgendaTasksForAdmin_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindAgendaTasksForAdmin_findAgendaTasksForAdmin_taskType_categoryTaskType;
}

export interface FindAgendaTasksForAdmin_findAgendaTasksForAdmin_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
}

export interface FindAgendaTasksForAdmin_findAgendaTasksForAdmin {
  __typename: "Task";
  id: string;
  color: string;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  secretary: FindAgendaTasksForAdmin_findAgendaTasksForAdmin_secretary;
  customer: FindAgendaTasksForAdmin_findAgendaTasksForAdmin_customer | null;
  author: FindAgendaTasksForAdmin_findAgendaTasksForAdmin_author;
  taskType: FindAgendaTasksForAdmin_findAgendaTasksForAdmin_taskType;
  quoteAgreement: FindAgendaTasksForAdmin_findAgendaTasksForAdmin_quoteAgreement | null;
}

export interface FindAgendaTasksForAdmin {
  findAgendaTasksForAdmin: FindAgendaTasksForAdmin_findAgendaTasksForAdmin[];
}

export interface FindAgendaTasksForAdminVariables {
  adminId: string;
  startDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOneTask
// ====================================================

export interface GetOneTask_getOneTask_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface GetOneTask_getOneTask_secretary_admin {
  __typename: "User";
  id: string;
}

export interface GetOneTask_getOneTask_secretary_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface GetOneTask_getOneTask_secretary_taskTypes {
  __typename: "TaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  categoryTaskType: GetOneTask_getOneTask_secretary_taskTypes_categoryTaskType;
}

export interface GetOneTask_getOneTask_secretary {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetOneTask_getOneTask_secretary_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes: GetOneTask_getOneTask_secretary_taskTypes[] | null;
}

export interface GetOneTask_getOneTask_quoteAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface GetOneTask_getOneTask_quoteAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetOneTask_getOneTask_quoteAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetOneTask_getOneTask_quoteAgreement_user_taskTypes_categoryTaskType;
}

export interface GetOneTask_getOneTask_quoteAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetOneTask_getOneTask_quoteAgreement_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes: GetOneTask_getOneTask_quoteAgreement_user_taskTypes[] | null;
}

export interface GetOneTask_getOneTask_quoteAgreement_frameworkAgreement_user_admin {
  __typename: "User";
  id: string;
}

export interface GetOneTask_getOneTask_quoteAgreement_frameworkAgreement_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetOneTask_getOneTask_quoteAgreement_frameworkAgreement_user_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetOneTask_getOneTask_quoteAgreement_frameworkAgreement_user_taskTypes_categoryTaskType;
}

export interface GetOneTask_getOneTask_quoteAgreement_frameworkAgreement_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetOneTask_getOneTask_quoteAgreement_frameworkAgreement_user_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes:
    | GetOneTask_getOneTask_quoteAgreement_frameworkAgreement_user_taskTypes[]
    | null;
}

export interface GetOneTask_getOneTask_quoteAgreement_frameworkAgreement {
  __typename: "FrameworkAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  reference: string | null;
  url: string | null;
  description: string | null;
  isActive: boolean;
  user: GetOneTask_getOneTask_quoteAgreement_frameworkAgreement_user;
}

export interface GetOneTask_getOneTask_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
  user: GetOneTask_getOneTask_quoteAgreement_user;
  frameworkAgreement: GetOneTask_getOneTask_quoteAgreement_frameworkAgreement;
}

export interface GetOneTask_getOneTask_customer_admin {
  __typename: "User";
  id: string;
}

export interface GetOneTask_getOneTask_customer_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface GetOneTask_getOneTask_customer_taskTypes {
  __typename: "TaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  categoryTaskType: GetOneTask_getOneTask_customer_taskTypes_categoryTaskType;
}

export interface GetOneTask_getOneTask_customer {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  admin: GetOneTask_getOneTask_customer_admin | null;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes: GetOneTask_getOneTask_customer_taskTypes[] | null;
}

export interface GetOneTask_getOneTask_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface GetOneTask_getOneTask_taskType {
  __typename: "TaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  categoryTaskType: GetOneTask_getOneTask_taskType_categoryTaskType;
}

export interface GetOneTask_getOneTask_parentTask_secretary {
  __typename: "User";
  id: string;
}

export interface GetOneTask_getOneTask_parentTask_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
}

export interface GetOneTask_getOneTask_parentTask_customer {
  __typename: "User";
  id: string;
}

export interface GetOneTask_getOneTask_parentTask_taskType {
  __typename: "TaskType";
  id: string;
}

export interface GetOneTask_getOneTask_parentTask {
  __typename: "Task";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  secretary: GetOneTask_getOneTask_parentTask_secretary;
  quoteAgreement: GetOneTask_getOneTask_parentTask_quoteAgreement | null;
  customer: GetOneTask_getOneTask_parentTask_customer | null;
  taskType: GetOneTask_getOneTask_parentTask_taskType;
}

export interface GetOneTask_getOneTask_childrenTasks_secretary {
  __typename: "User";
  id: string;
}

export interface GetOneTask_getOneTask_childrenTasks_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
}

export interface GetOneTask_getOneTask_childrenTasks_customer {
  __typename: "User";
  id: string;
}

export interface GetOneTask_getOneTask_childrenTasks_taskType {
  __typename: "TaskType";
  id: string;
}

export interface GetOneTask_getOneTask_childrenTasks {
  __typename: "Task";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  secretary: GetOneTask_getOneTask_childrenTasks_secretary;
  quoteAgreement: GetOneTask_getOneTask_childrenTasks_quoteAgreement | null;
  customer: GetOneTask_getOneTask_childrenTasks_customer | null;
  taskType: GetOneTask_getOneTask_childrenTasks_taskType;
}

export interface GetOneTask_getOneTask {
  __typename: "Task";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  color: string;
  author: GetOneTask_getOneTask_author;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  secretary: GetOneTask_getOneTask_secretary;
  quoteAgreement: GetOneTask_getOneTask_quoteAgreement | null;
  customer: GetOneTask_getOneTask_customer | null;
  taskType: GetOneTask_getOneTask_taskType;
  parentTask: GetOneTask_getOneTask_parentTask | null;
  childrenTasks: GetOneTask_getOneTask_childrenTasks[] | null;
}

export interface GetOneTask {
  getOneTask: GetOneTask_getOneTask;
}

export interface GetOneTaskVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindPendingTasksByUserId
// ====================================================

export interface FindPendingTasksByUserId_findPendingTasksByUserId_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindPendingTasksByUserId_findPendingTasksByUserId_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindPendingTasksByUserId_findPendingTasksByUserId_taskType_categoryTaskType;
}

export interface FindPendingTasksByUserId_findPendingTasksByUserId_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  role: UserRole;
  email: string;
}

export interface FindPendingTasksByUserId_findPendingTasksByUserId_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindPendingTasksByUserId_findPendingTasksByUserId_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindPendingTasksByUserId_findPendingTasksByUserId_quoteAgreement {
  __typename: "QuoteAgreement";
  label: string;
  reference: string | null;
}

export interface FindPendingTasksByUserId_findPendingTasksByUserId {
  __typename: "Task";
  id: string;
  label: string;
  isPrivate: boolean;
  status: TaskStatus;
  startDate: any;
  plannedDuration: number | null;
  description: string | null;
  color: string;
  taskType: FindPendingTasksByUserId_findPendingTasksByUserId_taskType;
  secretary: FindPendingTasksByUserId_findPendingTasksByUserId_secretary;
  customer: FindPendingTasksByUserId_findPendingTasksByUserId_customer | null;
  author: FindPendingTasksByUserId_findPendingTasksByUserId_author;
  quoteAgreement: FindPendingTasksByUserId_findPendingTasksByUserId_quoteAgreement | null;
}

export interface FindPendingTasksByUserId {
  findPendingTasksByUserId: FindPendingTasksByUserId_findPendingTasksByUserId[];
}

export interface FindPendingTasksByUserIdVariables {
  input: FindTasksInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindNotPendingAgendaTasksForSecretaries
// ====================================================

export interface FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_taskType_categoryTaskType;
}

export interface FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  role: UserRole;
  email: string;
}

export interface FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_quoteAgreement {
  __typename: "QuoteAgreement";
  label: string;
  reference: string | null;
}

export interface FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries {
  __typename: "Task";
  id: string;
  label: string;
  isPrivate: boolean;
  status: TaskStatus;
  startDate: any;
  plannedDuration: number | null;
  realDuration: number | null;
  description: string | null;
  color: string;
  taskType: FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_taskType;
  secretary: FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_secretary;
  customer: FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_customer | null;
  author: FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_author;
  quoteAgreement: FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries_quoteAgreement | null;
}

export interface FindNotPendingAgendaTasksForSecretaries {
  findNotPendingAgendaTasksForSecretaries: FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries[];
}

export interface FindNotPendingAgendaTasksForSecretariesVariables {
  input: FindTasksInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAllPaginateNotPendingTasksForSecretaries
// ====================================================

export interface FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_taskType_categoryTaskType;
}

export interface FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
}

export interface FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks {
  __typename: "Task";
  id: string;
  color: string;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  author: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_author;
  secretary: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_secretary;
  customer: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_customer | null;
  taskType: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_taskType;
  quoteAgreement: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks_quoteAgreement | null;
}

export interface FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries {
  __typename: "OutputPaginateTasks";
  tasks: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks[];
  total: number;
  hasPendingTask: boolean | null;
}

export interface FindAllPaginateNotPendingTasksForSecretaries {
  findAllPaginateNotPendingTasksForSecretaries: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries;
}

export interface FindAllPaginateNotPendingTasksForSecretariesVariables {
  userId: string;
  paginationArgs: TaskPaginationDto;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAllPaginatePendingTasksForSecretaries
// ====================================================

export interface FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_taskType_categoryTaskType;
}

export interface FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
}

export interface FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks {
  __typename: "Task";
  id: string;
  color: string;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  author: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_author;
  secretary: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_secretary;
  customer: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_customer | null;
  taskType: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_taskType;
  quoteAgreement: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks_quoteAgreement | null;
}

export interface FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries {
  __typename: "OutputPaginateTasks";
  tasks: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks[];
  total: number;
}

export interface FindAllPaginatePendingTasksForSecretaries {
  findAllPaginatePendingTasksForSecretaries: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries;
}

export interface FindAllPaginatePendingTasksForSecretariesVariables {
  userId: string;
  paginationArgs: TaskPaginationDto;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAllPaginateTasksForCustomers
// ====================================================

export interface FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_taskType_categoryTaskType;
}

export interface FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
}

export interface FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks {
  __typename: "Task";
  id: string;
  color: string;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  secretary: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_secretary;
  customer: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_customer | null;
  author: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_author;
  taskType: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_taskType;
  quoteAgreement: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks_quoteAgreement | null;
}

export interface FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers {
  __typename: "OutputPaginateTasks";
  tasks: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks[];
  total: number;
}

export interface FindAllPaginateTasksForCustomers {
  findAllPaginateTasksForCustomers: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers;
}

export interface FindAllPaginateTasksForCustomersVariables {
  userId: string;
  paginationArgs: TaskPaginationDto;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAllPaginateTasksForAdmins
// ====================================================

export interface FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_secretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_customer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_author {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  email: string;
  role: UserRole;
}

export interface FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_taskType_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_taskType {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_taskType_categoryTaskType;
}

export interface FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_quoteAgreement {
  __typename: "QuoteAgreement";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  hourlyAmount: number;
  currentAmount: number;
  reference: string | null;
  description: string | null;
  isActive: boolean;
  url: string | null;
}

export interface FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks {
  __typename: "Task";
  id: string;
  color: string;
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate: any | null;
  plannedDuration: number | null;
  realDuration: number | null;
  status: TaskStatus;
  description: string | null;
  secretary: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_secretary;
  customer: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_customer | null;
  author: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_author;
  taskType: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_taskType;
  quoteAgreement: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks_quoteAgreement | null;
}

export interface FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins {
  __typename: "OutputPaginateTasks";
  tasks: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks[];
  total: number;
  hasPendingTask: boolean | null;
}

export interface FindAllPaginateTasksForAdmins {
  findAllPaginateTasksForAdmins: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins;
}

export interface FindAllPaginateTasksForAdminsVariables {
  adminId: string;
  paginationArgs: TaskPaginationDto;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserUpdate
// ====================================================

export interface UserUpdate_userUpdate_user_admin {
  __typename: "User";
  id: string;
}

export interface UserUpdate_userUpdate_user_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  label: string;
}

export interface UserUpdate_userUpdate_user_taskTypes {
  __typename: "TaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
  categoryTaskType: UserUpdate_userUpdate_user_taskTypes_categoryTaskType;
}

export interface UserUpdate_userUpdate_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  activity_sector: string | null;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  admin: UserUpdate_userUpdate_user_admin | null;
  taskTypes: UserUpdate_userUpdate_user_taskTypes[] | null;
}

export interface UserUpdate_userUpdate {
  __typename: "UserUpdateOutput";
  user: UserUpdate_userUpdate_user;
}

export interface UserUpdate {
  userUpdate: UserUpdate_userUpdate;
}

export interface UserUpdateVariables {
  userId: string;
  input: UserUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserDelete
// ====================================================

export interface UserDelete_userDelete {
  __typename: "UserDeleteOutput";
  userId: string;
}

export interface UserDelete {
  userDelete: UserDelete_userDelete;
}

export interface UserDeleteVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserCreate
// ====================================================

export interface UserCreate_userCreate_user {
  __typename: "User";
  id: string;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  activity_sector: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
}

export interface UserCreate_userCreate {
  __typename: "UserCreateOutput";
  user: UserCreate_userCreate_user;
}

export interface UserCreate {
  userCreate: UserCreate_userCreate;
}

export interface UserCreateVariables {
  input: UserCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendPasswordResetRequest
// ====================================================

export interface SendPasswordResetRequest_sendPasswordResetRequest {
  __typename: "UserReqestResetPasswordOutput";
  resetPasswordToken: string;
}

export interface SendPasswordResetRequest {
  sendPasswordResetRequest: SendPasswordResetRequest_sendPasswordResetRequest;
}

export interface SendPasswordResetRequestVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPassword
// ====================================================

export interface ResetPassword {
  resetPassword: string;
}

export interface ResetPasswordVariables {
  resetPasswordToken: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPasswordForConnectedUser
// ====================================================

export interface ResetPasswordForConnectedUser {
  resetPasswordForConnectedUser: string;
}

export interface ResetPasswordForConnectedUserVariables {
  resetPasswordToken: string;
  newPassword: string;
  currentPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendVerificationEmail
// ====================================================

export interface SendVerificationEmail {
  sendVerificationEmail: string;
}

export interface SendVerificationEmailVariables {
  email: UserSendEmailVerificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: userEmailVerification
// ====================================================

export interface userEmailVerification {
  userEmailVerification: string;
}

export interface userEmailVerificationVariables {
  input: UserEmailVerificationDto;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeUserRole
// ====================================================

export interface changeUserRole {
  changeUserRole: string;
}

export interface changeUserRoleVariables {
  adminId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendAdminRegisterLink
// ====================================================

export interface SendAdminRegisterLink {
  sendAdminRegisterLink: string;
}

export interface SendAdminRegisterLinkVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyTokenAdminRegistration
// ====================================================

export interface VerifyTokenAdminRegistration_verifyTokenAdminRegistration {
  __typename: "AdminRegistrationVerification";
  message: string;
  email: string;
}

export interface VerifyTokenAdminRegistration {
  verifyTokenAdminRegistration: VerifyTokenAdminRegistration_verifyTokenAdminRegistration;
}

export interface VerifyTokenAdminRegistrationVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadFile
// ====================================================

export interface UploadFile {
  uploadFile: boolean;
}

export interface UploadFileVariables {
  file: any;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveAvatarUrl
// ====================================================

export interface RemoveAvatarUrl {
  removeAvatarUrl: boolean;
}

export interface RemoveAvatarUrlVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersList
// ====================================================

export interface UsersList_usersList_admin {
  __typename: "User";
  id: string;
}

export interface UsersList_usersList_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface UsersList_usersList_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: UsersList_usersList_taskTypes_categoryTaskType;
}

export interface UsersList_usersList {
  __typename: "User";
  id: string;
  admin: UsersList_usersList_admin | null;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes: UsersList_usersList_taskTypes[] | null;
}

export interface UsersList {
  usersList: UsersList_usersList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUserByEmail
// ====================================================

export interface getUserByEmail_getUserByEmail_admin {
  __typename: "User";
  id: string;
}

export interface getUserByEmail_getUserByEmail {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: UserRole;
  avatar_url: string | null;
  admin: getUserByEmail_getUserByEmail_admin | null;
  isActive: boolean;
}

export interface getUserByEmail {
  getUserByEmail: getUserByEmail_getUserByEmail;
}

export interface getUserByEmailVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserByID
// ====================================================

export interface GetUserByID_getUserByID_admin {
  __typename: "User";
  id: string;
}

export interface GetUserByID_getUserByID_taskTypes_categoryTaskType {
  __typename: "CategoryTaskType";
  id: string;
  createdAt: any;
  updatedAt: any;
  label: string;
}

export interface GetUserByID_getUserByID_taskTypes {
  __typename: "TaskType";
  id: string;
  label: string;
  categoryTaskType: GetUserByID_getUserByID_taskTypes_categoryTaskType;
}

export interface GetUserByID_getUserByID {
  __typename: "User";
  id: string;
  admin: GetUserByID_getUserByID_admin | null;
  createdAt: any;
  updatedAt: any;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  phone_number: string;
  email: string;
  activity_sector: string | null;
  geographical_area: string;
  siret: string | null;
  business_name: string | null;
  experience: string | null;
  criticity_request: string | null;
  commentary: string | null;
  isActive: boolean;
  isVerified: boolean;
  role: UserRole;
  taskTypes: GetUserByID_getUserByID_taskTypes[] | null;
}

export interface GetUserByID {
  getUserByID: GetUserByID_getUserByID;
}

export interface GetUserByIDVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllUser
// ====================================================

export interface getAllUser_usersList_admin {
  __typename: "User";
  id: string;
}

export interface getAllUser_usersList {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  role: UserRole;
  email: string;
  admin: getAllUser_usersList_admin | null;
}

export interface getAllUser {
  usersList: getAllUser_usersList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: secretariesAndAdminList
// ====================================================

export interface secretariesAndAdminList_secretariesAndAdminList_admin {
  __typename: "User";
  id: string;
}

export interface secretariesAndAdminList_secretariesAndAdminList {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  role: UserRole;
  email: string;
  admin: secretariesAndAdminList_secretariesAndAdminList_admin | null;
}

export interface secretariesAndAdminList {
  secretariesAndAdminList: secretariesAndAdminList_secretariesAndAdminList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckEmail
// ====================================================

export interface CheckEmail {
  checkIfEmailAlreadyExist: boolean;
}

export interface CheckEmailVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomersList
// ====================================================

export interface CustomersList_customersList_admin {
  __typename: "User";
  id: string;
}

export interface CustomersList_customersList {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string | null;
  phone_number: string;
  role: UserRole;
  geographical_area: string;
  business_name: string | null;
  isActive: boolean;
  admin: CustomersList_customersList_admin | null;
}

export interface CustomersList {
  customersList: CustomersList_customersList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomersListForAdmin
// ====================================================

export interface CustomersListForAdmin_customersListForAdmin_admin {
  __typename: "User";
  id: string;
}

export interface CustomersListForAdmin_customersListForAdmin {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  avatar_url: string | null;
  role: UserRole;
  geographical_area: string;
  business_name: string | null;
  isActive: boolean;
  admin: CustomersListForAdmin_customersListForAdmin_admin | null;
}

export interface CustomersListForAdmin {
  customersListForAdmin: CustomersListForAdmin_customersListForAdmin[];
}

export interface CustomersListForAdminVariables {
  adminId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomersListForSecretary
// ====================================================

export interface CustomersListForSecretary_customersListForSecretary_admin {
  __typename: "User";
  id: string;
}

export interface CustomersListForSecretary_customersListForSecretary {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string | null;
  phone_number: string;
  role: UserRole;
  geographical_area: string;
  business_name: string | null;
  isActive: boolean;
  admin: CustomersListForSecretary_customersListForSecretary_admin | null;
}

export interface CustomersListForSecretary {
  customersListForSecretary: CustomersListForSecretary_customersListForSecretary[];
}

export interface CustomersListForSecretaryVariables {
  secretaryId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminsList
// ====================================================

export interface AdminsList_adminsList_admin {
  __typename: "User";
  id: string;
}

export interface AdminsList_adminsList {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string | null;
  phone_number: string;
  role: UserRole;
  geographical_area: string;
  isActive: boolean;
  admin: AdminsList_adminsList_admin | null;
}

export interface AdminsList {
  adminsList: AdminsList_adminsList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersListWithActiveFrameworkAgreement
// ====================================================

export interface UsersListWithActiveFrameworkAgreement_usersListWithActiveFrameworkAgreement_admin {
  __typename: "User";
  id: string;
}

export interface UsersListWithActiveFrameworkAgreement_usersListWithActiveFrameworkAgreement {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string | null;
  role: UserRole;
  geographical_area: string;
  isActive: boolean;
  phone_number: string;
  admin: UsersListWithActiveFrameworkAgreement_usersListWithActiveFrameworkAgreement_admin | null;
}

export interface UsersListWithActiveFrameworkAgreement {
  usersListWithActiveFrameworkAgreement: UsersListWithActiveFrameworkAgreement_usersListWithActiveFrameworkAgreement[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersListWithActiveQuoteAgreement
// ====================================================

export interface UsersListWithActiveQuoteAgreement_usersListWithActiveQuoteAgreement_admin {
  __typename: "User";
  id: string;
}

export interface UsersListWithActiveQuoteAgreement_usersListWithActiveQuoteAgreement {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string | null;
  role: UserRole;
  geographical_area: string;
  isActive: boolean;
  phone_number: string;
  admin: UsersListWithActiveQuoteAgreement_usersListWithActiveQuoteAgreement_admin | null;
}

export interface UsersListWithActiveQuoteAgreement {
  usersListWithActiveQuoteAgreement: UsersListWithActiveQuoteAgreement_usersListWithActiveQuoteAgreement[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SecretariesListWithActiveQuoteAgreementByCustomer
// ====================================================

export interface SecretariesListWithActiveQuoteAgreementByCustomer_secretariesListWithActiveQuoteAgreementByCustomer_admin {
  __typename: "User";
  id: string;
}

export interface SecretariesListWithActiveQuoteAgreementByCustomer_secretariesListWithActiveQuoteAgreementByCustomer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  avatar_url: string | null;
  role: UserRole;
  geographical_area: string;
  isActive: boolean;
  admin: SecretariesListWithActiveQuoteAgreementByCustomer_secretariesListWithActiveQuoteAgreementByCustomer_admin | null;
}

export interface SecretariesListWithActiveQuoteAgreementByCustomer {
  secretariesListWithActiveQuoteAgreementByCustomer: SecretariesListWithActiveQuoteAgreementByCustomer_secretariesListWithActiveQuoteAgreementByCustomer[];
}

export interface SecretariesListWithActiveQuoteAgreementByCustomerVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SecretariesList
// ====================================================

export interface SecretariesList_secretariesList_admin {
  __typename: "User";
  id: string;
}

export interface SecretariesList_secretariesList {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string | null;
  phone_number: string;
  role: UserRole;
  geographical_area: string;
  isActive: boolean;
  admin: SecretariesList_secretariesList_admin | null;
}

export interface SecretariesList {
  secretariesList: SecretariesList_secretariesList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SecretariesListForAdmin
// ====================================================

export interface SecretariesListForAdmin_secretariesListForAdmin_admin {
  __typename: "User";
  id: string;
}

export interface SecretariesListForAdmin_secretariesListForAdmin {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  avatar_url: string | null;
  role: UserRole;
  geographical_area: string;
  isActive: boolean;
  admin: SecretariesListForAdmin_secretariesListForAdmin_admin | null;
}

export interface SecretariesListForAdmin {
  secretariesListForAdmin: SecretariesListForAdmin_secretariesListForAdmin[];
}

export interface SecretariesListForAdminVariables {
  adminId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SecretariesListForCustomer
// ====================================================

export interface SecretariesListForCustomer_secretariesListForCustomer_admin {
  __typename: "User";
  id: string;
}

export interface SecretariesListForCustomer_secretariesListForCustomer {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url: string | null;
  phone_number: string;
  role: UserRole;
  geographical_area: string;
  isActive: boolean;
  admin: SecretariesListForCustomer_secretariesListForCustomer_admin | null;
}

export interface SecretariesListForCustomer {
  secretariesListForCustomer: SecretariesListForCustomer_secretariesListForCustomer[];
}

export interface SecretariesListForCustomerVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInactiveUsers
// ====================================================

export interface GetInactiveUsers_getInactiveUsers {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  role: UserRole;
  isActive: boolean;
  email: string;
  avatar_url: string | null;
}

export interface GetInactiveUsers {
  getInactiveUsers: GetInactiveUsers_getInactiveUsers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNewAffiliatedUsersForAdmin
// ====================================================

export interface GetNewAffiliatedUsersForAdmin_getNewAffiliatedUsersForAdmin {
  __typename: "User";
  id: string;
  first_name: string;
  last_name: string;
  role: UserRole;
  isActive: boolean;
  email: string;
  avatar_url: string | null;
}

export interface GetNewAffiliatedUsersForAdmin {
  getNewAffiliatedUsersForAdmin: GetNewAffiliatedUsersForAdmin_getNewAffiliatedUsersForAdmin[];
}

export interface GetNewAffiliatedUsersForAdminVariables {
  adminId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportSecretariesListToXlsx
// ====================================================

export interface ExportSecretariesListToXlsx_exportSecretariesListToXlsx {
  __typename: "ExportSecretaryAndAdminDto";
  id: string;
  userName: string;
  email: string;
  phone_number: string;
  geographical_area: string;
  commentary: string;
  experience: string;
  taskTypesStr: string | null;
}

export interface ExportSecretariesListToXlsx {
  exportSecretariesListToXlsx: ExportSecretariesListToXlsx_exportSecretariesListToXlsx[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportCustomersListToXlsx
// ====================================================

export interface ExportCustomersListToXlsx_exportCustomersListToXlsx {
  __typename: "ExportCustomersDto";
  id: string;
  userName: string;
  email: string;
  phone_number: string;
  geographical_area: string;
  commentary: string;
  business_name: string;
  taskTypesStr: string | null;
}

export interface ExportCustomersListToXlsx {
  exportCustomersListToXlsx: ExportCustomersListToXlsx_exportCustomersListToXlsx[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Recurrence {
  ALL_THE_DAY = "ALL_THE_DAY",
  ALL_THE_MONTH = "ALL_THE_MONTH",
  ALL_THE_WEEK = "ALL_THE_WEEK",
  ONE_TIME = "ONE_TIME",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TaskStatus {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  TODO = "TODO",
  UNREALIZED = "UNREALIZED",
}

export enum UserRole {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  SECRETARY = "SECRETARY",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export interface CheckTokenDto {
  token: string;
}

export interface CreateCategoryTaskTypeInput {
  label: string;
}

export interface CreateQuoteAgreementInput {
  label: string;
  hourlyAmount: number;
  reference?: string | null;
  url?: string | null;
  description?: string | null;
  isActive: boolean;
  customerId: string;
  secretaryId: string;
}

export interface CreateTaskInput {
  label: string;
  isPrivate: boolean;
  startDate: any;
  endDate?: any | null;
  endRecurenceDate?: any | null;
  recurrence: Recurrence;
  plannedDuration?: number | null;
  realDuration?: number | null;
  status: TaskStatus;
  description?: string | null;
  secretaryId: string;
  color: string;
  quoteAgreementId?: string | null;
  customerId?: string | null;
  authorId: string;
  taskTypeId: string;
  parentTaskId?: string | null;
}

export interface CreateTaskTypeInput {
  label: string;
  categoryTaskTypeId: string;
}

export interface CustomerDashboardDto {
  customerId: string;
}

export interface DateRangeInput {
  dateRange: number[];
}

export interface FindTasksInput {
  userId: string;
  startDate?: any | null;
}

export interface FrameworkAgreementCreateInput {
  label: string;
  reference?: string | null;
  url?: string | null;
  description?: string | null;
  isActive: boolean;
  userId: string;
}

export interface FrameworkAgreementPaginationSortBy {
  createdAt?: SortDirection | null;
  label?: SortDirection | null;
}

export interface FrameworkAgreementUpdateInput {
  id: string;
  label?: string | null;
  reference?: string | null;
  url?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  userId?: string | null;
}

export interface SecretaryDashboardDto {
  secretaryId: string;
}

export interface TaskPaginationDto {
  limit?: number | null;
  page?: number | null;
  search?: string | null;
  sortField?: string | null;
  sortOrder?: string | null;
  secretaryId?: string | null;
  customerId?: string | null;
  label?: string | null;
  taskTypeIds?: string[] | null;
  status?: TaskStatus[] | null;
  date?: any | null;
  taskDateFilter?: string | null;
}

export interface UpdateCategoryTaskTypeInput {
  label: string;
  id: string;
}

export interface UpdateQuoteAgreementInput {
  id: string;
  label?: string | null;
  hourlyAmount?: number | null;
  reference?: string | null;
  url?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  customerId: string;
  secretaryId: string;
}

export interface UpdateTaskInput {
  id: string;
  label?: string | null;
  isPrivate?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
  color?: string | null;
  plannedDuration?: number | null;
  realDuration?: number | null;
  status?: TaskStatus | null;
  description?: string | null;
  secretaryId?: string | null;
  quoteAgreementId?: string | null;
  customerId?: string | null;
  taskTypeId?: string | null;
  parentTaskId?: string | null;
  authorId?: string | null;
}

export interface UpdateTaskTypeInput {
  label?: string | null;
  categoryTaskTypeId?: string | null;
  id: string;
}

export interface UpdateTasksInput {
  taskIds: string[];
}

export interface UserCreateInput {
  first_name: string;
  last_name: string;
  avatar_url?: string | null;
  phone_number: string;
  email: string;
  geographical_area: string;
  business_name?: string | null;
  activity_sector?: string | null;
  siret?: string | null;
  experience?: string | null;
  criticity_request?: string | null;
  commentary?: string | null;
  password: string;
  role: UserRole;
  taskTypeIds?: string[] | null;
  isVerified: boolean;
  isConsentingMarketing: boolean;
}

export interface UserEmailVerificationDto {
  token: string;
}

export interface UserSendEmailVerificationInput {
  email: string;
}

export interface UserUpdateInput {
  first_name?: string | null;
  last_name?: string | null;
  avatar_url?: string | null;
  phone_number?: string | null;
  email?: string | null;
  activity_sector?: string | null;
  geographical_area?: string | null;
  business_name?: string | null;
  siret?: string | null;
  experience?: string | null;
  criticity_request?: string | null;
  commentary?: string | null;
  isActive: boolean;
  password?: string | null;
  role?: string | null;
  taskTypeIds?: string[] | null;
  adminId?: string | null;
  isVerified: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
