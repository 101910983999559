import { Link } from "react-router-dom";
import Avatar from "../../shared/Avatar";
import { UserRole } from "../../../API/models/types";
import { UserInformation } from "../../../pages/models/models";
import getAvatarUrl from "../../../pages/utils/getAvatarUrl";

export default function UserCell(value: UserInformation, disableNav: boolean) {
  const { userName, email, role, avatar, id } = value;

  let defaultOptionClassName = "";
  let avatarUrl: string = "";
  if (role) {
    switch (role) {
      case UserRole.SUPER_ADMIN:
        avatarUrl = getAvatarUrl(role, avatar);
        defaultOptionClassName +=
          "flex flex-row bg-purple-100 w-full items-center p-1 justify-start rounded-md";
        break;
      case UserRole.ADMIN:
        avatarUrl = getAvatarUrl(role, avatar);
        defaultOptionClassName +=
          "flex flex-row bg-orange-100 w-full items-center p-1 justify-start rounded-md";
        break;
      case UserRole.CUSTOMER:
        avatarUrl = getAvatarUrl(role, avatar);
        defaultOptionClassName +=
          "flex flex-row bg-gray-100 w-full items-center p-1 justify-start rounded-md";
        break;
      case UserRole.SECRETARY:
        avatarUrl = getAvatarUrl(role, avatar);
        defaultOptionClassName +=
          "flex flex-row bg-green-100 w-full items-center p-1 justify-start rounded-md";
        break;
      default:
        break;
    }
  }
  const path: string = `/profil/${id}`;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    disableNav === false ? (
      <Link to={path}>
        <div role="button" tabIndex={0} className={defaultOptionClassName}>
          <Avatar imgUrl={avatarUrl} />

          <div className="w-full flex flex-col truncate">
            <p className="font-bold text-xs first-letter:uppercase">
              {userName}
            </p>
            {email ? <p className="text-gray-500 text-xs">{email}</p> : null}
          </div>
        </div>
      </Link>
    ) : (
      <div className={defaultOptionClassName}>
        <Avatar imgUrl={avatarUrl} />

        <div className="w-full flex flex-col truncate">
          <p className="font-bold text-xs first-letter:uppercase">{userName}</p>
          {email ? <p className="text-gray-500 text-xs">{email}</p> : null}
        </div>
      </div>
    )
  );
}
