import dayjs from "dayjs";

export default function getTaskTopPosition(taskDate: dayjs.Dayjs) {
  const cellHeight = 64; // La hauteur d'une cellule en pixels (correspond à la classe h-16)

  // Extraire les minutes de l'heure de début de la tâche
  const taskMinutes = taskDate.minute();

  // Calculer la position top en fonction de la hauteur de la cellule et des minutes
  const topPosition = (cellHeight * taskMinutes) / 60;

  // Si le début de la tâche dépasse 20h, alors on veut que sa top position soit à 20h
  // Et retourner une position top 0, comme ça elle ne dépassera jamais le calendrier
  if (taskDate.hour() >= 20) {
    return 0;
  }

  return topPosition;
}
