import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { EDIT_USER } from "../../API/modules/user/resolvers/mutation";

interface ActiveToggleProps {
  value: boolean;
  userId: string;
  adminId: string;
}

export default function ActiveToggle({
  value,
  userId,
  adminId,
}: ActiveToggleProps) {
  const [isActive, setActive] = useState(value);

  const location = useLocation();
  const [editUser] = useMutation(EDIT_USER);
  useEffect(() => {
    if (adminId === null || adminId === "") {
      setActive(false);
    }
  }, [adminId]);

  const handleClick = async () => {
    if (adminId && adminId !== "") {
      // si un admin est affecté je peux changer l' activation ou la désactivation d' un utilisateur
      const newValue = !isActive;
      setActive(newValue);
      if (
        location.pathname === "/liste-des-clients" ||
        location.pathname === "/liste-des-secretaires" ||
        location.pathname === "/liste-des-responsables-add"
      ) {
        try {
          await editUser({
            variables: {
              userId,
              input: { isActive: !isActive, adminId, isVerified: true },
            },
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err); // Handle the error
        }
      }
    }
  };

  return (
    <label className="inline-flex relative items-center mr-5 cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={isActive}
        readOnly
        onClick={handleClick}
      />
      <div className="mr-2 w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600" />

      {!isActive ? (
        <>
          <div className="font-bold bg-red-600 w-4 h-4 relative rounded-full items-center justify-center mr-1" />

          <span className="ml-2 text-sm font-medium text-gray-900">
            Désactivé
          </span>
        </>
      ) : (
        <>
          {" "}
          <div className="font-bold bg-green-600 w-4 h-4 relative  rounded-full items-center justify-center mr-1" />
          <span className="ml-2 text-sm font-medium text-gray-900">Actif</span>
        </>
      )}
    </label>
  );
}
