import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Select, Table } from "flowbite-react";
import {
  GetCustomerDashbouardData_getCustomerDashboardData_tasks_all,
  GetCustomerDashbouardData_getCustomerDashboardData_tasks_7_next_days,
  GetCustomerDashbouardData_getCustomerDashboardData_tasks_30_next_days,
  TaskStatus,
} from "../../../API/models/types";
import DateCell from "../../../components/table/cell-component/DateCell";
import TaskStatusCell from "../../../components/table/cell-component/TaskStatusCell";
import getFormattedHours from "../../Calendar/utils/getFormattedHours";

interface LastInprogressTasksProps {
  tasks_all: GetCustomerDashbouardData_getCustomerDashboardData_tasks_all[];
  tasks_7_next_days: GetCustomerDashbouardData_getCustomerDashboardData_tasks_7_next_days[];
  tasks_30_next_days: GetCustomerDashbouardData_getCustomerDashboardData_tasks_30_next_days[];
  isLoading: boolean;
}

type TaskPeriods = "all" | "7_days" | "30_days";

function LastInprogressTasks({
  tasks_all,
  tasks_7_next_days,
  tasks_30_next_days,
  isLoading,
}: LastInprogressTasksProps) {
  // Gestion du select de la période
  const [selectedTaskPeriod, setSelectedTaskPeriod] =
    useState<TaskPeriods>("all");

  const tasksMap: Record<TaskPeriods, any[]> = {
    all: tasks_all,
    "7_days": tasks_7_next_days,
    "30_days": tasks_30_next_days,
  };

  // Gestion du Sort sur la date
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Liste des tâches à rendre
  const tasksToRender = tasksMap[selectedTaskPeriod].slice().sort((a, b) => {
    const dateA = new Date(a.startDate);
    const dateB = new Date(b.startDate);
    return sortOrder === "asc"
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  });

  return (
    <Card className="h-full flex flex-col overflow-auto">
      <div className="h-1/6 justify-between">
        <h2 className="font-semibold">Tâches</h2>
        <span className="text-xs text-gray-400">
          Liste des dernières tâches en cours
        </span>
        <hr className="h-px mb-4 mt-4 bg-gray-200 border-0 dark:bg-gray-700" />
      </div>
      <div className="h-4/6 max-h-4/6 overflow-auto scrollbar-thumb-add-blue-dark scrollbar-track-gray-100 scrollbar-thin">
        <Table>
          <Table.Head>
            <Table.HeadCell>Tâches </Table.HeadCell>
            <Table.HeadCell
              onClick={handleSort}
              className="cursor-pointer flex items-center"
            >
              Date{" "}
              <span className="ml-1 text-xl text-add-blue-dark">
                {sortOrder === "asc" ? "↓" : "↑"}
              </span>
            </Table.HeadCell>
            <Table.HeadCell>Heures prévues</Table.HeadCell>
            <Table.HeadCell>Statut</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y h-full">
            {isLoading ? (
              <Table.Row>
                <Table.Cell colSpan={4}>Loading...</Table.Cell>
              </Table.Row>
            ) : (
              tasksToRender.map((task) => {
                const date = new Date(task.startDate);
                return (
                  <Table.Row
                    key={task.id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {task.label}
                    </Table.Cell>
                    <Table.Cell>
                      {DateCell(
                        date.toLocaleDateString("fr-fr", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }),
                        false,
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {" "}
                      {getFormattedHours(task.plannedDuration)}
                    </Table.Cell>
                    <Table.Cell>
                      {TaskStatusCell(task.status as TaskStatus)}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
      </div>
      <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 pt-3 sm:pt-6 h-1/6">
        <div className="shrink-0">
          <span className="text-sm text-gray-600">
            <label htmlFor="select-date-range-customer-dashboard">
              <Select
                id="select-date-range-customer-dashboard"
                value={selectedTaskPeriod}
                onChange={(e) =>
                  setSelectedTaskPeriod(e.target.value as TaskPeriods)
                }
              >
                <option value="all">Toutes les tâches</option>
                <option value="7_days">Les 7 prochains jours</option>
                <option value="30_days">Les 30 prochains jours</option>
              </Select>
            </label>
          </span>
        </div>
        <div className="shrink-0">
          <Link
            className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
            to="/liste-des-taches"
          >
            Liste des tâches
            <svg
              className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Link>
        </div>
      </div>
    </Card>
  );
}

export default LastInprogressTasks;
