import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Avatar } from "flowbite-react";
import { useQuery } from "@apollo/client";
import { emptyUser, UserContext } from "../../context/userContext";
import { GET_USER_BY_EMAIL } from "../../API/modules/user/resolvers/queries";
import useLocalStorageService from "../../hooks/useLocalStorage";
import {
  UserRole,
  getUserByEmail,
  getUserByEmail_getUserByEmail,
} from "../../API/models/types";
import getAvatarUrl from "../../pages/utils/getAvatarUrl";

function UserDropdown() {
  const navigate = useNavigate();
  const { user: userFromContext, setUser } = useContext(UserContext);
  const { removeUser, getUser } = useLocalStorageService();
  const userFromLocalStorage = getUser();
  const userEmail = userFromContext.email || userFromLocalStorage?.email;
  const [userFromAPI, setUserFromAPI] =
    useState<getUserByEmail_getUserByEmail>();

  // Query
  const { data } = useQuery<getUserByEmail>(GET_USER_BY_EMAIL, {
    variables: { email: userEmail },
  });

  useEffect(() => {
    if (data) {
      const user = data.getUserByEmail as getUserByEmail_getUserByEmail;
      setUserFromAPI(user);
    }
  }, [data]);

  // Détection du changement utilisateur actif/inactif à l'actualisation de la page
  useEffect(() => {
    // Extraire isActive de userAPI pour utilisation comme dépendance
    const isActiveFromAPI = data?.getUserByEmail?.isActive;

    if (typeof isActiveFromAPI === "boolean") {
      // S'assurer que isActiveFromAPI est bien défini et est un booléen
      const currentUserString = localStorage.getItem("add-user");

      if (currentUserString) {
        const currentUser = JSON.parse(currentUserString);

        // Vérifier si isActive a changé avant de mettre à jour
        if (currentUser.isActive !== isActiveFromAPI) {
          // Mise à jour de la propriété isActive uniquement
          currentUser.isActive = isActiveFromAPI;

          // Update user localstorage
          localStorage.setItem("add-user", JSON.stringify(currentUser));

          // Update user context
          const updatedUserContext = {
            ...userFromContext,
            isActive: isActiveFromAPI,
          };
          setUser(updatedUserContext);
        }
      }
    }
  }, [data?.getUserByEmail?.isActive, setUser, navigate, userFromContext]);

  // Logout
  function handleLogout() {
    // effacer options filtrage à la déconnexion
    localStorage.clear();
    removeUser();
    setUser({ ...emptyUser });
    navigate("/se-connecter");
  }

  return userFromAPI ? (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <span className="h-10">
          <span className="sr-only">User menu</span>
          <Avatar
            alt="Avatar"
            img={getAvatarUrl(
              userFromAPI.role as UserRole,
              userFromAPI.avatar_url,
            )}
            rounded
            size="sm"
          />
        </span>
      }
    >
      {userFromAPI && (
        <Dropdown.Header>
          <span className="block text-sm">
            {userFromAPI.last_name.toUpperCase()} {userFromAPI.first_name}
          </span>
          <span className="block truncate text-sm font-medium">
            {userFromAPI.email}
          </span>
        </Dropdown.Header>
      )}
      <Link to="/" className="w-full h-full">
        <Dropdown.Item>Dashboard</Dropdown.Item>
      </Link>
      <Dropdown.Divider />
      <button type="button" onClick={handleLogout} className="w-full h-full">
        <Dropdown.Item>Se déconnecter</Dropdown.Item>
      </button>
    </Dropdown>
  ) : null;
}

export default UserDropdown;
