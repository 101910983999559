import { Card } from "flowbite-react";
import ReactPaginate from "react-paginate";
import { useEffect, useState, useCallback } from "react";
import * as XLSX from "xlsx";
import { FiDownload } from "react-icons/fi";
import {
  ExportSecretariesListToXlsx_exportSecretariesListToXlsx,
  SecretariesList_secretariesList,
  UserRole,
} from "../../API/models/types";
import { useUserContext } from "../../context/userContext";
import TableSecretariesList from "./table/TableSecretariesList";
import SpinnerComponent from "../../components/shared/SpinnerComponent";
import {
  GetAllSecretaries,
  GetAllSecretariesForAdmin,
  GetAllSecretariesForCustomer,
  ExportSecretariesListToXlsx,
} from "../utils/QueriesUser";
import {
  ExportDataItem,
  ExportToXlsxColumn,
  ItemSecretariesList,
} from "../models/models";
import SomethingHappened from "../SomethingHappened";
import generateFileNameWithDate from "../utils/generateFileNameWithDate";

function SecretariesList() {
  const { user } = useUserContext();
  // methode retourne la liste des secrétaires en fonction du role
  const { secretariesList, loading, error } =
    // eslint-disable-next-line no-nested-ternary
    user.role !== null && user.role === UserRole.SUPER_ADMIN
      ? GetAllSecretaries()
      : // eslint-disable-next-line no-nested-ternary
      user.role !== null && user.role === UserRole.ADMIN
      ? GetAllSecretariesForAdmin(user.id as string)
      : user.role !== null && user.role === UserRole.CUSTOMER
      ? GetAllSecretariesForCustomer(user.id as string)
      : { secretariesList: [], loading: false, error: undefined };

  // data transfomée pour la table
  const secretariesListData = (): ItemSecretariesList[] => {
    const items: ItemSecretariesList[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < secretariesList.length; i++) {
      const item: SecretariesList_secretariesList = secretariesList[i];
      items.push({
        id: item.id,
        secretary: {
          userName: `${item.last_name.toUpperCase()} ${item.first_name}`,
          avatar: item.avatar_url as string,
          id: item.id,
          email: item.email,
          role: item.role as UserRole,
        },
        geographicalArea: item.geographical_area,
        isActive: item.isActive,
        profilePath: `/profile/${item.id}`,
        userNameSecretary: `${item.last_name.toUpperCase()} ${item.first_name}`,
        adminId: item.admin ? item.admin.id : "",
        phone_number: item.phone_number,
      });
    }
    return items;
  };
  // nombre de ligne par page de tableau
  const ROWBYPAGE: number = 6;

  // calcul nombre de page
  const nbPageFloat: number =
    secretariesList && secretariesList.length / ROWBYPAGE;

  // nombre de page arrondi à l' entier supérieur
  const nbPage = Math.ceil(nbPageFloat);
  const myFilters: string | null = localStorage.getItem(
    "mySecretariesListPage",
  );
  const [page, setPage] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (myFilters && myFilters !== null) {
      setPage(myFilters && JSON.parse(myFilters) && JSON.parse(myFilters).page);
    } else {
      setPage(1);
    }
  }, [myFilters]);
  const handlePageChange = (event: any) => {
    setPage(event.selected + 1);
    localStorage.setItem(
      "mySecretariesListPage",
      JSON.stringify({ page: event.selected + 1 }),
    );
  };

  // Requête pour obtenir les données à exporter
  const { exportSecretariesListToXlsx } = ExportSecretariesListToXlsx();

  const transformToExportableData = useCallback(
    (
      items: ExportSecretariesListToXlsx_exportSecretariesListToXlsx[],
    ): ExportDataItem[] => {
      return items.map((item) => {
        return {
          name: item.userName,
          email: item.email,
          phoneNumber: item.phone_number,
          geographicalArea: item.geographical_area,
          commentary: item.commentary,
          experience: item.experience,
          taskTypesStr: item.taskTypesStr || "",
        };
      });
    },
    [],
  );

  const exportToExcel = useCallback(
    (columns: ExportToXlsxColumn[]) => {
      // Transformer la données reçue par l'API en une version exportable en xlsx
      const transformedData = transformToExportableData(
        exportSecretariesListToXlsx,
      );

      // Extraction des données appropriées définies dans columnsToExport
      const wsData = transformedData.map((item) => {
        return columns.map((column) => column.accessor(item as any)); // you might want to ensure types are properly aligned here
      });
      // Créer le header de la feuille de calcul excel
      wsData.unshift(columns.map((column) => column.header));

      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Calcul dynamique de la largeur maximale pour chaque colonne
      const columnWidths = wsData[0].map((_, columnIndex) => {
        return Math.max(
          ...wsData.map((row) =>
            row[columnIndex] ? row[columnIndex].toString().length : 0,
          ),
        );
      });
      // Définission de la largeur des colonnes
      ws["!cols"] = columnWidths.map((wch) => ({ wch }));

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(
        wb,
        generateFileNameWithDate("liste_de_secretaires", "xlsx"),
      );
    },
    [transformToExportableData, exportSecretariesListToXlsx],
  );

  const columnsToExport: ExportToXlsxColumn[] = [
    { header: "Nom", accessor: (item) => item.name },
    { header: "Email", accessor: (item) => item.email },
    { header: "Téléphone", accessor: (item) => item.phoneNumber },
    { header: "Zone géographique", accessor: (item) => item.geographicalArea },
    { header: "Commentaire", accessor: (item) => item.commentary },
    { header: "Expérience", accessor: (item) => item.experience },
    { header: "Types de tâches", accessor: (item) => item.taskTypesStr },
  ];

  return !error ? (
    <div className="p-5">
      {!loading ? (
        <Card className="overflow-auto flex gap-1 w-full p-3">
          {user.role === UserRole.SUPER_ADMIN ? (
            <div className="w-full flex flex-col md:flex-row items-center justify-between">
              <div
                className="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 md:w-1/2 lg:w-1/2"
                role="alert"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 inline w-5 h-5 mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">
                    Affectez un admin à un client / secrétaire pour pouvoir
                    l’activer.
                  </span>
                </div>
              </div>
              {/* Export to excel */}
              {user.role !== null &&
              (user.role as UserRole) !== UserRole.CUSTOMER ? (
                <div>
                  <button
                    type="button"
                    className="text-xs bg-add-blue-dark button-add font-medium rounded-lg px-4 p-3 text-center inline-flex items-center mb-4"
                    onClick={() => exportToExcel(columnsToExport)}
                  >
                    <FiDownload className="mr-1" />
                    Export
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
          <TableSecretariesList
            data={secretariesListData() as ItemSecretariesList[]}
            currentUserRole={user.role as UserRole}
            rowByPage={ROWBYPAGE}
            page={page as number}
          />
          <div className="flex flex-row justify-left text-center">
            <div className="basis-4/5 text-left">
              <span className="items-center gap-1 flex flex-row text-xs p-4">
                <p className="text-gray-400 text-xs">Page</p>
                <strong className="flex color:#40788B">{page}</strong>
                <p className="text-gray-400 text-xs">sur</p>
                {nbPage === 0 ? <strong>1</strong> : <strong> {nbPage}</strong>}
              </span>
            </div>
            <div className="basis-1/5">
              {nbPage !== 0 ? (
                <ReactPaginate
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={ROWBYPAGE}
                  pageCount={nbPage}
                  forcePage={page ? page - 1 : 0}
                  marginPagesDisplayed={2}
                  nextLabel=">"
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                  previousLabel="<"
                  onPageActive={() => page}
                />
              ) : null}
            </div>
          </div>
        </Card>
      ) : (
        <SpinnerComponent />
      )}
    </div>
  ) : (
    <SomethingHappened />
  );
}

export default SecretariesList;
