import { UserRole } from "../../API/models/types";

// customer avatars
import customer1Avatar from "../../assets/avatars/customers/Robert-Brown.png";
// import customer2Avatar from "../../assets/avatars/customers/Jese-Leos.png";
// import customer3Avatar from "../../assets/avatars/customers/Joseph-McFall.png";
// secertary avatars
import secretary1Avatar from "../../assets/avatars/secretaries/Helene-Engels.png";
// import secretary2Avatar from "../../assets/avatars/secretaries/Bonnie-Green.png";
// import secretary3Avatar from "../../assets/avatars/secretaries/Jeanne-Hills.png";
// admin avatars
import admin1Avatar from "../../assets/avatars/admins/Karen-Nelson.png";
// import admin2Avatar from "../../assets/avatars/admins/Neil-Sims.png";
// import admin3Avatar from "../../assets/avatars/admins/Roberta-Casas.png";
// Anonymous avatar
import anonymousAvatar from "../../assets/avatars/utilisateur-anonyme.png";

/**
 * Détermine l'avatar qui doit être affiché en fonction du role utilisateur
 *
 * @param {UserRole} role - Role de l'utilisateur dont l'avatar doit être affiché.
 * @param {string | null} avatarUrl - Url de l'avatar de l'utilisateur
 * @returns {string} - Retourne une chaîne de caractère représentant le chemin vers l'image d'avatar.
 */
export default function getAvatarUrl(role: UserRole, avatarUrl: string | null) {
  let avatars: string[] = [];
  let randomIndex;
  let finalAvatarUrl = "";
  const avatarPath = avatarUrl;
  switch (role) {
    case UserRole.CUSTOMER:
      avatars = [customer1Avatar];
      break;
    case UserRole.SECRETARY:
      avatars = [secretary1Avatar];
      break;
    case UserRole.ADMIN:
    case UserRole.SUPER_ADMIN:
      avatars = [admin1Avatar];
      break;
    default:
      break;
  }

  switch (avatarUrl) {
    case null:
      finalAvatarUrl = anonymousAvatar;
      break;
    case "testing-user":
      if (avatars) {
        randomIndex = Math.floor(Math.random() * avatars.length);
        finalAvatarUrl = `${avatars[randomIndex]}`;
      }
      break;
    default:
      finalAvatarUrl = avatarPath as string;
      break;
  }

  return finalAvatarUrl;
}
