import { Link, useLocation, LinkProps } from "react-router-dom";

interface StyledLinkProps extends LinkProps {
  className: string;
}

function StyledLink(props: StyledLinkProps) {
  const location = useLocation();
  const currentPage = location.pathname;
  const { to, className, ...rest } = props;

  return (
    <Link
      to={to}
      className={`${
        currentPage === to
          ? "underline underline-offset-4 decoration-add-green"
          : ""
      } ${className}`}
      {...rest}
    />
  );
}

export default StyledLink;
