import { DocumentNode, gql } from "@apollo/client";

export const GET_DATA_FOR_CUSTOMER_DASHBOARD: DocumentNode = gql`
  query GetCustomerDashbouardData($input: CustomerDashboardDto!) {
    getCustomerDashboardData(input: $input) {
      tasks_all {
        id
        label
        startDate
        plannedDuration
        status
      }
      tasks_7_next_days {
        id
        label
        startDate
        plannedDuration
        status
      }
      tasks_30_next_days {
        id
        label
        startDate
        plannedDuration
        status
      }
      newAffiliatedSecretaries {
        id
        first_name
        last_name
        email
        avatar_url
      }
      taskData {
        id
        label
        percentage
        taskTypes {
          id
          label
          percentage
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTIONS_DATA: DocumentNode = gql`
  query GetSubscriptionsData($dateRange: DateRangeInput!) {
    getSubscriptionsData(dateRange: $dateRange) {
      option_7 {
        totalSecretaries
        totalCustomers
        finalTotalUsers
        percentageChange
        subscriptions {
          date
          secretaries
          customers
        }
      }
      option_30 {
        totalSecretaries
        totalCustomers
        finalTotalUsers
        percentageChange
        subscriptions {
          date
          secretaries
          customers
        }
      }
    }
  }
`;

export const GET_TOTAL_HOURLY_AMOUNT_ON_QUOTE_AGREEMENTS_DATA: DocumentNode = gql`
  query GetTotalHourlyAmountOnQuoteAgreements($dateRange: DateRangeInput!) {
    getTotalHourlyAmountOnQuoteAgreements(dateRange: $dateRange) {
      option_6 {
        totalQuoteAgreementAmount
        progression
        months {
          month
          totalQuoteAgreementAmount
        }
      }
      option_12 {
        totalQuoteAgreementAmount
        progression
        months {
          month
          totalQuoteAgreementAmount
        }
      }
    }
  }
`;
