import { Label, TextInput } from "flowbite-react";
import { useForm, FieldValues, UseFormReturn } from "react-hook-form";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { useState } from "react";
import { UserCreateInput, UserRole } from "../API/models/types";
import { CREATE_USER } from "../API/modules/user/resolvers/mutation";
import geographicalAreaOptions from "../API/data/departements-region.json";
import { CHECK_IF_EMAIL_ALREADY_EXIST } from "../API/modules/user/resolvers/queries";

interface Area {
  num_dep: string | number;
  dep_name: string;
  region_name: string;
}
interface Props {
  email: string;
}
type UseFormReturnWithErrors<T extends FieldValues> = UseFormReturn<T> & {
  errors: Record<keyof T, any>;
};
export default function AdminRegistrationForm(props: Props) {
  const { email } = props;
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onChange",
  }) as UseFormReturnWithErrors<FieldValues>;
  const [createUser, { error, loading: isLoading }] =
    useMutation<UserCreateInput>(CREATE_USER);
  const [checkIfEmailAlreadyExist] = useLazyQuery(CHECK_IF_EMAIL_ALREADY_EXIST);
  const emailErrors = {
    emailAlreadyTaken: "Cet email est déjà pris",
  };
  const [validationAdminCreation, setValidationAdminCreaction] = useState<
    undefined | string
  >(undefined);
  async function onSubmit(data: FieldValues) {
    const userData: UserCreateInput = {
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      email,
      role: UserRole.ADMIN,
      password: data.password,
      geographical_area: data.geographicalArea,
      isVerified: true,
      isConsentingMarketing: false,
    };

    try {
      const { data: isEmailTaken } = await checkIfEmailAlreadyExist({
        variables: { email },
      });
      if (isEmailTaken.checkIfEmailAlreadyExist) {
        setError("email", {
          type: "manual",
          message: emailErrors.emailAlreadyTaken,
        });
      }
      await createUser({
        variables: { input: userData },
      });
      setValidationAdminCreaction(
        `Bonjour ${userData.first_name}, votre compte a bien été enregistré avec l' adresse ${email}. Vous pouvez vous connecter à l' application Atelier du dirigeant`,
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  return (
    <div className="p-14 mb-5">
      <h1 className="mb-6 text-3xl md:text-4xl lg:text-5xl">
        Parlez-nous de vous
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
          <div className="mb-6 flex flex-col gap-y-3 w-full">
            <Label htmlFor="lastName" className="font-semibold">
              Votre nom *
            </Label>
            <TextInput
              id="lastName"
              placeholder="nom"
              type="text"
              autoFocus
              {...register("lastName", { required: true })}
            />
            {errors.lastName && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Ce champ est requis
              </span>
            )}
          </div>
          <div className="mb-6 flex flex-col gap-y-3 w-full">
            <Label htmlFor="firstName" className="font-semibold">
              Votre prénom *
            </Label>
            <TextInput
              id="firstName"
              placeholder="prénom"
              type="text"
              {...register("firstName", { required: true })}
            />
            {errors.firstName && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Ce champ est requis
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
          <div className="mb-6 flex flex-col gap-y-3 w-full">
            <Label htmlFor="phoneNumber" className="font-semibold">
              Téléphone *
            </Label>
            <TextInput
              id="phoneNumber"
              placeholder="0XXXXXX"
              type="tel"
              min={10}
              {...register("phoneNumber", { required: true })}
            />
            {errors.phoneNumber && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Ce champ est requis
              </span>
            )}
          </div>
          <div className="mb-6 flex flex-col gap-y-3 w-full">
            <Label htmlFor="email" className="font-semibold">
              Email *
            </Label>
            <TextInput
              id="email"
              placeholder="name@example.com"
              type="email"
              value={email}
              readOnly
            />
            {/* {errors.email && errors.email.type === "required" && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Ce champ est requis
              </span>
            )} */}
            {errors.email && errors.email.type === "manual" && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Cet email est déjà pris
              </span>
            )}
          </div>
        </div>
        <h1 className="mb-6 text-3xl md:text-4xl lg:text-5xl">
          Informations du compte
        </h1>
        {/* PASSWORD */}
        <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
          <div className="mb-6 flex flex-col gap-y-3 w-full">
            <Label htmlFor="password" className="font-semibold">
              Votre mot de passe *
            </Label>
            <TextInput
              id="password"
              placeholder="••••••••"
              type="password"
              autoFocus
              {...register("password", {
                required: true,
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
              })}
            />
            {errors.password && errors.password.type === "required" && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Ce champ est requis
              </span>
            )}
            {errors.password && errors.password.type === "pattern" && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Le mot de passe doit contenir au moins 8 caractères, dont une
                majuscule, une minuscule et un chiffre.
              </span>
            )}
          </div>

          <div className="mb-6 flex flex-col gap-y-3 w-full">
            <Label htmlFor="confirmPassword" className="font-semibold">
              Confirmez votre mot de passe *
            </Label>
            <TextInput
              id="confirmPassword"
              placeholder="••••••••"
              type="password"
              {...register("confirmPassword", {
                required: true,
                validate: (value) => value === watch("password"),
              })}
            />
            {errors.confirmPassword &&
              errors.confirmPassword.type === "required" && (
                <span
                  className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  Ce champ est requis
                </span>
              )}
            {errors.confirmPassword &&
              errors.confirmPassword.type === "validate" && (
                <span
                  className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  Les mots de passe ne correspondent pas
                </span>
              )}
          </div>
          <div className="mb-6 flex flex-col gap-y-3 w-full">
            <label
              htmlFor="geographicalArea"
              className="block text-sm text-gray-900 dark:text-white font-semibold"
            >
              Zone géographique *
            </label>
            <select
              id="geographicalArea"
              className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
              {...register("geographicalArea", { required: true })}
            >
              <option value="">...</option>
              {geographicalAreaOptions &&
                geographicalAreaOptions.map((area: Area) => (
                  <option
                    key={area.num_dep}
                    value={`${area.num_dep.toString()} - ${area.dep_name} ${
                      area.region_name
                    }`}
                  >
                    {area.num_dep.toString()} - {area.dep_name} (
                    {area.region_name})
                  </option>
                ))}
            </select>
            {errors.geographicalArea &&
              errors.geographicalArea.type === "required" && (
                <span
                  className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  Ce champ est requis
                </span>
              )}
          </div>
        </div>

        {/* Agreements */}
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col">
            <div className="flex">
              <input
                id="termsOfUse"
                type="checkbox"
                className="w-4 h-4 text-add-blue-dark bg-gray-100 border-gray-300 rounded focus:ring-add-blue-dark"
                {...register("termsOfUse", { required: true })}
              />
              <label
                htmlFor="termsOfUse"
                className="ml-2 text-sm font-medium text-gray-600"
              >
                En vous inscrivant, vous créez un compte Atelier Du Dirigeant,
                et vous acceptez les{" "}
                <Link
                  to="/"
                  className="text-add-blue-dark hover:text-cyan-700 hover:underline hover:underline-offset-2"
                >
                  conditions d'utilisation{" "}
                </Link>
                de notre application et sa{" "}
                <Link
                  to="/"
                  className="text-add-blue-dark hover:text-cyan-700 hover:underline hover:underline-offset-2"
                >
                  politique de confidentialité.
                </Link>
              </label>
            </div>
            {errors.termsOfUse && errors.termsOfUse.type === "required" && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                Vous devez accepter nos conditions d'utilisations
              </span>
            )}
          </div>
          <div className="flex">
            <input
              id="marketing"
              type="checkbox"
              className="w-4 h-4 text-add-blue-dark bg-gray-100 border-gray-300 rounded focus:ring-add-blue-dark"
              {...register("marketing")}
            />
            <label
              htmlFor="marketing"
              className="ml-2 text-sm font-medium text-gray-600"
            >
              Je ne veux pas recevoir de communications marketing de la part de
              l’Atelier du Dirigeant.
            </label>
          </div>
        </div>
        {!validationAdminCreation ? (
          <button
            type="submit"
            className="button-add bg-add-blue-dark p-2 font-semibold mb-10 lg:mb-0 mt-10"
            disabled={isLoading}
          >
            {isLoading ? "Création de votre compte ..." : "Créer votre compte"}
          </button>
        ) : (
          <button
            type="button"
            className="button-add bg-add-blue-dark p-2 font-semibold mb-10 lg:mb-0 mt-5"
          >
            <Link to="/se-connecter">Se connecter</Link>
          </button>
        )}
        {error && (error.networkError as ApolloError) && (
          <span
            className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
            role="alert"
          >
            "Une erreur est survenue. Veuillez réessayer plus tard."
          </span>
        )}
      </form>
      {validationAdminCreation ? (
        <div className="flex flex-col mt-5">
          <span
            className="w-full p-2 text-sm text-green-800 rounded-lg bg-red-50"
            role="alert"
          >
            {validationAdminCreation}
          </span>
        </div>
      ) : null}
    </div>
  );
}
