import React from "react";

interface CurrentAmountProps {
  value: string;
}

export default function CurrentAmountComponent({ value }: CurrentAmountProps) {
  // Vérifie si la valeur est négative en cherchant le caractère "-"
  const isNegative = value.includes("-");

  // Choisit la classe CSS en fonction de la positivité/négativité de la valeur
  const className = isNegative ? "text-red-500 text-xs" : "text-xs";

  return <p className={className}>{value}</p>;
}
