import React from "react";

interface EvolutionHoursValueChartProps {
  evolutionHoursValue: string;
}

function EvolutionHoursValue({
  evolutionHoursValue,
}: EvolutionHoursValueChartProps) {
  let className = "flex flex-1 items-center justify-end text-base font-bold";

  switch (true) {
    case evolutionHoursValue && evolutionHoursValue.includes("+"):
      className += " text-green-600";
      break;
    case evolutionHoursValue && evolutionHoursValue.includes("-"):
      className += " text-red-600";
      break;
    default:
      break;
  }

  return <div className={className}>{evolutionHoursValue}</div>;
}

export default EvolutionHoursValue;
