import React, { useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import { useDateRange } from "./context/DateRangeContext";
import { GetSubscriptionsData_getSubscriptionsData } from "../../../../../API/models/types";

interface SubscriptionsChartProps {
  data: GetSubscriptionsData_getSubscriptionsData;
}

function SubscriptionsChart({ data }: SubscriptionsChartProps) {
  const [selectedDateRange] = useDateRange();
  const [options, setOptions] = useState<ApexCharts.ApexOptions>();
  // series = jeux de données à afficher dans le chart
  const [series, setSeries] = useState<
    { name: string; data: number[]; color: string }[]
  >([]);

  useEffect(() => {
    // Si besoin
    // Générer des données aléatoires pour les séries (clients/secrétaires)
    // const generateRandomData = () =>
    //   Array.from({ length: dateRange }, () => Math.floor(Math.random() * 5));

    // Vérifier si les données sont disponibles
    if (!data) return; // ou afficher un indicateur de chargement

    // Sélectionnez le tableau de données approprié en fonction de la plage de dates sélectionnée
    const selectedData =
      selectedDateRange === "7"
        ? data.option_7?.subscriptions
        : data.option_30?.subscriptions;

    // Générer les dates pour l'axe X
    // Extraire les dates directement des données
    const dates = selectedData
      ?.map((item) => {
        const date = new Date(item.date);
        return date.toLocaleDateString();
      })
      .reverse(); // Inverser l'ordre des dates

    // Créez des tableaux de dates, de clients et de secrétaires à partir des données
    const customers =
      selectedData?.map((item) => item.customers).reverse() ?? [];
    const secretaries =
      selectedData?.map((item) => item.secretaries).reverse() ?? [];

    // Style options
    const borderColor = "#F3F4F6";
    const labelColor = "#6B7280";
    const opacityFrom = 0.45;
    const opacityTo = 0;

    // Mettre à jour les options de graphique
    setOptions({
      stroke: {
        curve: "smooth",
      },
      chart: {
        type: "area",
        fontFamily: "Inter, sans-serif",
        foreColor: labelColor,
        toolbar: {
          show: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom,
          opacityTo,
          type: "vertical",
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        style: {
          fontSize: "14px",
          fontFamily: "Inter, sans-serif",
        },
      },
      grid: {
        show: true,
        borderColor,
        strokeDashArray: 1,
        padding: {
          left: 35,
          bottom: 15,
        },
      },
      markers: {
        size: 5,
        strokeColors: "#ffffff",
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
      xaxis: {
        categories: dates,
        labels: {
          style: {
            colors: [labelColor],
            fontSize: "14px",
            fontWeight: 500,
          },
        },
        axisBorder: {
          color: borderColor,
        },
        axisTicks: {
          color: borderColor,
        },
        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: borderColor,
            width: 1,
            dashArray: 10,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [labelColor],
            fontSize: "14px",
            fontWeight: 500,
          },
          formatter(value: number) {
            return `${value}`;
          },
        },
      },
      legend: {
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Inter, sans-serif",
        labels: {
          colors: [labelColor],
        },
        itemMargin: {
          horizontal: 10,
        },
      },
      responsive: [
        {
          breakpoint: 1024,
          options: {
            xaxis: {
              labels: {
                show: false,
              },
            },
          },
        },
      ],
    });

    // Mettre à jour les séries de graphique
    setSeries([
      {
        name: "Clients",
        data: customers,
        color: "#1A56DB",
      },
      {
        name: "Secrétaires",
        data: secretaries,
        color: "#FDBA8C",
      },
    ]);
  }, [data, selectedDateRange]);

  // S'assurer que les options et les séries sont disponibles avant de rendre le graphique
  if (!options || !series.length) {
    return null; // ou afficher un indicateur de chargement
  }

  return <Chart height={380} options={options} series={series} type="area" />;
}

export default SubscriptionsChart;
