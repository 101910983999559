import { useContext, useState } from "react";
import { Select } from "flowbite-react";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../context/FilterContext";
import {
  addDaysToDate,
  // parseSelectDateToServer,
} from "../../../pages/utils/parseDatatoServer";
import { FilterTask } from "../../../pages/models/models";
// import { UserContext } from "../../../context/userContext";
// import { UserRole } from "../../../API/models/types";

export default function SelectDateFilter() {
  const { filters, setFilters, dateFiltersKey, pageKey } =
    useContext<FilterTasksContextType>(FilterTasksContext);
  // const { user } = useContext(UserContext);
  const initialFilter: string =
    localStorage.getItem(dateFiltersKey) &&
    JSON.parse(localStorage.getItem(dateFiltersKey) as string)
      .selectedDateFilterValue;
  const [selectedDateFilterValue, setSelectedDateFilterValue] =
    useState<string>(initialFilter || "0");

  // méthode qui filtre par selection de date
  const filterBySelectDate = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    selectedDateFilterValue: string,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    filters: FilterTask,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setFilters: (value: FilterTask) => void,
  ) => {
    switch (selectedDateFilterValue) {
      case "0":
        setFilters({
          ...filters,
          date: undefined,
          taskDateFilter: undefined,
          page: 1,
        });
        break;
      case ">0":
        setFilters({
          ...filters,
          date: undefined,
          taskDateFilter: "COME",
          page: 1,
        });
        break;
      case "<0":
        setFilters({
          ...filters,
          date: undefined,
          taskDateFilter: "PASSED",
          page: 1,
        });
        break;
      case "30":
        setFilters({
          ...filters,
          date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
          taskDateFilter: "COME",
          page: 1,
        });
        break;
      case "60":
        setFilters({
          ...filters,
          date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
          taskDateFilter: "COME",
          page: 1,
        });
        break;
      case "90":
        setFilters({
          ...filters,
          date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
          taskDateFilter: "COME",
          page: 1,
        });
        break;
      case "-30":
        setFilters({
          ...filters,
          date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
          taskDateFilter: "PASSED",
          page: 1,
        });
        break;
      case "-60":
        setFilters({
          ...filters,
          date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
          taskDateFilter: "PASSED",
          page: 1,
        });
        break;
      case "-90":
        setFilters({
          ...filters,
          date: addDaysToDate(new Date(), Number(selectedDateFilterValue)),
          taskDateFilter: "PASSED",
          page: 1,
        });
        break;
      default:
        break;
    }
    localStorage.setItem(
      dateFiltersKey,
      JSON.stringify({
        selectedDateFilterValue,
      }),
    );
    localStorage.setItem(pageKey, JSON.stringify({ page: 1 }));
  };
  return (
    <Select
      id="custom-select"
      value={selectedDateFilterValue as string}
      onChange={(e) => {
        setSelectedDateFilterValue(e.target.value);
        filterBySelectDate(e.target.value, filters, setFilters);
      }}
    >
      <option value="0">toutes les tâches</option>
      <option value=">0">toutes les tâches à venir</option>
      <option value="<0">toutes les tâches passées</option>
      <option value="30">30 jours à venir</option>
      <option value="60">60 jours à venir</option>
      <option value="90">90 jours à venir</option>
      <option value="-30">30 jours passés</option>
      <option value="-60">60 jours passés</option>
      <option value="-90">90 jours passés</option>
    </Select>
  );
}
