import { Label, TextInput } from "flowbite-react";
import React from "react";
import { BiPencil } from "react-icons/bi";
import { CgArrowLongRight, CgProfile } from "react-icons/cg";
import { FieldErrors, FieldValues, useForm } from "react-hook-form";
import { UserRole } from "../../API/models/types";

type FirstStepFormProps = {
  register: ReturnType<typeof useForm>["register"];
  errors: FieldErrors<FieldValues>;
};

function FirstStepForm({ register, errors }: FirstStepFormProps) {
  return (
    <div>
      <h1 className="mb-6 text-3xl md:text-4xl lg:text-5xl">
        Parlez-nous de vous
      </h1>
      <span className="font-light text-gray-500 text-xs">
        (*) champs obligatoires
      </span>
      <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <Label htmlFor="lastName" className="font-semibold">
            Votre nom *
          </Label>
          <TextInput
            id="lastName"
            placeholder="nom"
            type="text"
            autoFocus
            {...register("lastName", { required: true })}
          />
          {errors.lastName && (
            <span
              className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              Ce champ est requis
            </span>
          )}
        </div>
        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <Label htmlFor="firstName" className="font-semibold">
            Votre prénom *
          </Label>
          <TextInput
            id="firstName"
            placeholder="prénom"
            type="text"
            {...register("firstName", { required: true })}
          />
          {errors.firstName && (
            <span
              className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              Ce champ est requis
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <Label htmlFor="phoneNumber" className="font-semibold">
            Téléphone *
          </Label>
          <TextInput
            id="phoneNumber"
            placeholder="0XXXXXX"
            type="tel"
            min={10}
            {...register("phoneNumber", { required: true })}
          />
          {errors.phoneNumber && (
            <span
              className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              Ce champ est requis
            </span>
          )}
        </div>
        <div className="mb-6 flex flex-col gap-y-3 w-full">
          <Label htmlFor="email" className="font-semibold">
            Email *
          </Label>
          <TextInput
            id="email"
            placeholder="name@example.com"
            type="email"
            {...register("email", { required: true })}
          />
          {errors.email && errors.email.type === "required" && (
            <span
              className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              Ce champ est requis
            </span>
          )}
          {errors.email && errors.email.type === "manual" && (
            <span
              className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              Cet email est déjà pris
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <h2 className="text-2xl">Quelle est votre situation ?</h2>
        {errors.isSecretaryOrClient && (
          <span
            className="p-2 text-sm text-red-800 rounded-lg bg-red-50 ml-6"
            role="alert"
          >
            Ce champ est requis
          </span>
        )}
      </div>
      <div className="py-4">
        <input
          type="radio"
          id="isClient"
          value={UserRole.CUSTOMER}
          className="hidden peer"
          {...register("isSecretaryOrClient", { required: true })}
        />
        <label
          htmlFor="isClient"
          className="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-add-blue-dark peer-checked:text-add-blue-dark hover:bg-blue-100"
        >
          <div className="flex items-center">
            <CgProfile className="w-6 h-6 mr-2" />
            <div className="w-full font-semibold">Je suis une entreprise</div>
          </div>
          <CgArrowLongRight className="w-6 h-6" />
        </label>
      </div>
      <div>
        <input
          type="radio"
          id="isSecretary"
          value={UserRole.SECRETARY}
          className="hidden peer"
          {...register("isSecretaryOrClient", { required: true })}
        />
        <label
          htmlFor="isSecretary"
          className="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-add-blue-dark peer-checked:text-add-blue-dark hover:bg-blue-100"
        >
          <div className="flex items-center">
            <BiPencil className="w-6 h-6 mr-2" />
            <div className="w-full font-semibold">Je suis une secrétaire</div>
          </div>
          <CgArrowLongRight className="w-6 h-6" />
        </label>
      </div>
    </div>
  );
}

export default FirstStepForm;
