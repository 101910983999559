/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FaClipboardList } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiInformationCircle } from "react-icons/hi";
import { useMutation } from "@apollo/client";
import ColorPicker from "../../components/shared/ColorPicker";

import {
  TaskStatus,
  UpdateTaskInput,
  UserRole,
  getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary,
} from "../../API/models/types";

import { EDIT_TASK } from "../../API/modules/task/resolvers/mutations";
import UserCell from "../../components/table/cell-component/UserCell";

import SpinnerComponent from "../../components/shared/SpinnerComponent";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../context/FilterContext";
import { UserContext, UserContextType } from "../../context/userContext";
import DateAndHourPicker from "./components/DateAndHourPicker";
import SelectDuration from "./components/SelectDuration";
import SelectTaskType from "./components/SelectTaskType";
import { UserInformation, FilterTask } from "../models/models";
import { colorsClasses } from "../utils/constants";
import {
  FIND_AGENDA_TASKS_FOR_ADMIN,
  FIND_AGENDA_TASKS_FOR_CUSTOMERS,
  FIND_AGENDA_TASKS_FOR_SUPER_ADMIN,
  FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
  FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
  FIND_ALL_PAGINATE_TASKS_FOR_ADMINS,
  FIND_ALL_PAGINATE_TASKS_FOR_CUSTOMERS,
  FIND_ALL_PAGINATE_TASKS_FOR_SUPER_ADMIN,
  FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
} from "../../API/modules/task/resolvers/queries";
import Avatar from "../../components/shared/Avatar";
import getAvatarUrl from "../utils/getAvatarUrl";
import InputReferenceContract from "./components/InputReferenceContract";
import SomethingHappened from "../SomethingHappened";
import getFormattedHours from "../Calendar/utils/getFormattedHours";

interface FormUpdateTask {
  id: string;
  startDate: Date;
  endDate: Date | null;
  color: string;
  description: string;
  status: TaskStatus;
  plannedDuration: string;
  realDuration: string;
  label: string;
  customerId: string;
  secretaryId: string;
  taskTypeId: string;
  quoteAgreementId: string;
}

function EditTask() {
  const navigate = useNavigate();
  const location = useLocation();
  // contexte filter
  const { filters } = useContext<FilterTasksContextType>(FilterTasksContext);
  // contexte user
  const { user } = useContext<UserContextType>(UserContext);
  // paramètres passé par le routeur
  const itemRow = {
    ...(location && location.state && location.state.item),
  } as any | null;

  // author
  const author: UserInformation | null =
    itemRow && itemRow.author
      ? {
          avatar: getAvatarUrl(itemRow.author.role, itemRow.author.avatar_url),
          role: itemRow.author.role as UserRole,
          userName: `${itemRow.author.last_name.toUpperCase()} ${
            itemRow.author.first_name
          }`,
          email: itemRow.author.email,
          id: itemRow.author.id,
        }
      : null;
  // state selected

  const [quoteAgreement, setQuoteAgreement] = useState<any | null>(
    itemRow && itemRow.quoteAgreement ? itemRow.quoteAgreement : null,
  );
  const [selectedColor, setSelectedColor] = useState(
    itemRow && itemRow.color ? itemRow.color : "red",
  );
  // TODO traiter récurrence
  // const [selectedEndDateRecurrence, setSelectedEndDateRecurrence] =
  //   useState<string>(itemRow && itemRow.endDate ? itemRow.endDate : "");

  // const [recurrenceSelected, setSelectedRecurrence] = useState<Recurrence>(
  //   Recurrence.ONE_TIME,
  // );
  const [selectedStartDate, setSelectedStartDateAndHour] = useState<Date>(
    itemRow && itemRow.startDate ? itemRow.startDate : new Date(),
  );

  const [selectedStatus, setSelectedStatus] = useState<TaskStatus>(
    itemRow && itemRow.status
      ? (itemRow.status as TaskStatus)
      : TaskStatus.TODO,
  );
  const [realDurationSelected, setRealDurationSelected] = useState<string>(
    itemRow && itemRow.realDuration ? String(itemRow.realDuration) : "",
  );
  const [selectedTaskType, setSelectedTaskType] = useState<string>(
    itemRow && itemRow.taskType ? itemRow.taskType.id : "",
  );
  const [selectedPlannedDuration, setSelectedPlanDuration] = useState<string>(
    itemRow && itemRow.plannedDuration ? String(itemRow.plannedDuration) : "",
  );

  // mutation TODO error
  const [
    updateTask,
    { loading, error: BadCredentialError },
    //  { error: BadCredentialsError }
  ] = useMutation(EDIT_TASK);
  // formulaire
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormUpdateTask>();

  // méthode mise à jour des states et du hook form
  const setQuoteAgreementId = (
    value: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary | null,
  ) => {
    setQuoteAgreement(value);
    if (value) setValue("quoteAgreementId", value.id);
    else setValue("quoteAgreementId", "");
  };

  const setPlannedDuration = (value: string) => {
    setSelectedPlanDuration(value);
    setValue("plannedDuration", value);
  };

  const setStartDateAndHourPicker = (value: Date) => {
    setSelectedStartDateAndHour(value);
    setValue("startDate", value);
  };

  // const setEndDateAndHourPicker = (value: Date) => {
  //   setSelectedEndDateAndHour(value);
  //   setValue("endDate", value);
  // };

  // const setSelectedCustomerId = (value: string) => {
  //   setValue("customerId", value);
  //   setSelectedCustomer(value);
  // };

  // const setSelectedSecretaryId = (value: string) => {
  //   setValue("secretaryId", value);
  //   setSelectedSecretary(value);
  // };

  const setStatus = (value: TaskStatus) => {
    setSelectedStatus(value);
    setValue("status", value);
  };

  const setRealDuration = (value: string) => {
    setRealDurationSelected(value);
    setValue("realDuration", value);
  };

  const setTaskTypeId = (value: string) => {
    setValue("taskTypeId", value);
    setSelectedTaskType(value);
  };

  // Handle Submit
  const onSubmit: SubmitHandler<FormUpdateTask> = async (
    values: FormUpdateTask,
  ) => {
    try {
      if (itemRow) {
        const input: UpdateTaskInput = {
          id: itemRow?.id,
          status: values.status ? values.status : selectedStatus,
          startDate: values.startDate,
          endDate: values.endDate ? values.endDate : null,
          color: values.color ? values.color : selectedColor,
          realDuration: values.realDuration
            ? Number(values.realDuration)
            : null,
          plannedDuration: Number(values.plannedDuration),
          description: values.description,
          label: values.label ? values.label : itemRow.label,
          customerId: values.customerId,
          secretaryId: values.secretaryId,
          taskTypeId: values.taskTypeId ? values.taskTypeId : selectedTaskType,
          quoteAgreementId: values.quoteAgreementId
            ? values.quoteAgreementId
            : quoteAgreement?.id,
        };

        await updateTask({
          variables: {
            input,
          },
          refetchQueries:
            user.role === null
              ? []
              : user.role === UserRole.ADMIN
              ? [
                  {
                    query: FIND_ALL_PAGINATE_TASKS_FOR_ADMINS,
                    variables: {
                      paginationArgs: filters as FilterTask,
                      adminId: user.id,
                    },
                  },
                  {
                    query: FIND_AGENDA_TASKS_FOR_ADMIN,
                    variables: {
                      startDate: new Date(),
                      adminId: user.id,
                    },
                  },
                ]
              : user.role === UserRole.SUPER_ADMIN
              ? [
                  {
                    query: FIND_ALL_PAGINATE_TASKS_FOR_SUPER_ADMIN,
                    variables: {
                      paginationArgs: filters as FilterTask,
                    },
                  },
                  {
                    query: FIND_AGENDA_TASKS_FOR_SUPER_ADMIN,
                    variables: {
                      startDate: new Date(),
                    },
                  },
                ]
              : user.role === UserRole.CUSTOMER
              ? [
                  {
                    query: FIND_ALL_PAGINATE_TASKS_FOR_CUSTOMERS,
                    variables: {
                      paginationArgs: filters as FilterTask,
                      userId: user.id,
                    },
                  },
                  {
                    query: FIND_AGENDA_TASKS_FOR_CUSTOMERS,
                    variables: {
                      customerId: user.id,
                      startDate: new Date(),
                    },
                  },
                ]
              : [
                  {
                    query: FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
                    variables: {
                      input: {
                        userId: user.id,
                        paginationArgs: filters as FilterTask,
                      },
                    },
                  },
                  {
                    query: FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
                    variables: {
                      input: {
                        userId: user.id,
                        paginationArgs: filters as FilterTask,
                      },
                    },
                  },
                  {
                    query: FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
                    variables: {
                      input: { userId: user.id, startDate: new Date() },
                    },
                  },
                ],
        });
        navigate("/liste-des-taches");
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error, BadCredentialError);
    }
  };
  return itemRow !== null ? (
    <div>
      <div className="sm:fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      {!loading ? (
        <div className="max-h-full overflow-auto md:min-w-full sm:fixed left-0 top-0 flex sm:justify-center items-start">
          <form
            className="bg-white rounded-lg shadow-2xl p-4 w-full sm:w-auto"
            onSubmit={handleSubmit(onSubmit)}
          >
            <header className="flex flex-col sm:items-center sm:flex-row sm:justify-between border-b pb-1 w-full">
              {/* Titre et couleur */}
              <div className="mb-2 sm:mb-0 sm:w-3/12 w-full">
                <h3 className="font-bold">Edition de tâche</h3>
              </div>
              <div className="mb-2 text-sm sm:mb-0 w-full sm:w-3/12 text-gray-800">
                Tâche qui a été crée par
              </div>
              <div className="mb-2 sm:mb-0 sm:w-4/12 w-full">
                {author ? UserCell(author, true) : null}
              </div>
              <div className="sm:w-1/12 w-full">
                <button type="button" className="mx-4 hidden sm:block">
                  <Link to="/liste-des-taches">
                    {" "}
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </Link>
                </button>
              </div>
            </header>

            <div className="space-y-6 py-1">
              <span
                className={`block rounded-full w-full p-1 bg-${selectedColor}-400 mb-2`}
                style={{ backgroundColor: `${selectedColor}` }}
              />
              <span className="font-light text-gray-500 text-sm">
                (*) champs obligatoires
              </span>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:mb-5 sm:items-end my-6">
                <div className="w-full sm:mb-3">
                  <label
                    htmlFor="title"
                    className="font-semibold text-gray-800 text-sm"
                  >
                    Titre
                    <span className="font-normal"> *</span>
                  </label>
                  <div className="flex flex-row w-full">
                    <div className="sm:w-4/5">
                      <input
                        type="text"
                        id="title"
                        defaultValue={itemRow.label ? itemRow.label : ""}
                        className="bg-gray-50 mr-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Gestion des mails"
                        {...register("label", { required: true })}
                      />
                    </div>
                    <div className="w-1/5">
                      <ColorPicker
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                        colorsClasses={colorsClasses}
                      />
                    </div>
                  </div>
                  {errors.label && (
                    <span className="text-red-600 text-sm">
                      un titre de tâche doit être renseigné
                    </span>
                  )}
                </div>
              </div>
              {/* Catégorie de type de tâche et type de tâche */}
              <div>
                {/* double select */}
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="taskTypeId"
                  defaultValue={
                    itemRow && itemRow.taskType && itemRow.taskType.id
                  }
                  render={() => (
                    <SelectTaskType
                      setTaskTypeId={setTaskTypeId}
                      defaultTaskType={
                        itemRow && itemRow.taskType && itemRow.taskType.id
                      }
                    />
                  )}
                />{" "}
                {errors.taskTypeId && (
                  <span className="text-red-600 text-sm">
                    un type de tâche doit être renseigné
                  </span>
                )}
                {/* start Date and Time picker */}
                <div className="my-3">
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="startDate"
                    defaultValue={selectedStartDate}
                    render={() => (
                      <DateAndHourPicker
                        setDateAndHourPicker={setStartDateAndHourPicker}
                        dateDefaultValue={selectedStartDate}
                        showAllDate
                      />
                    )}
                  />{" "}
                  {errors.startDate && (
                    <span className="text-red-600 text-sm">
                      une date et un horaire doivent être renseignés
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col sm:flex-row">
                {/* Recurrence and estimated duration */}

                {/* <div className="">
                <label
                  htmlFor="recurrence"
                  className="block mb-2 text-sm font-semibold dark:text-white"
                >
                  Récurrence
                </label>
                <select
                  id="recurrence"
                  className="flex-shrink-0 z-10 mb-3 sm:mb-0 mr-3 inline-flex items-center py-2.5 px-4 text-sm font-medium bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 disabled:cursor-not-allowed"
                  onChange={(e) => {
                    setSelectedRecurrence(e.target.value as Recurrence);
                  }}
                  defaultValue={recurrenceSelected}
                  disabled
                >
                  <option value={Recurrence.ONE_TIME}>
                    {Recurrence.ONE_TIME}
                  </option>
                  <option value={Recurrence.ALL_THE_DAY}>
                    {Recurrence.ALL_THE_DAY}
                  </option>
                  <option value={Recurrence.ALL_THE_WEEK}>
                    {Recurrence.ALL_THE_WEEK}
                  </option>
                  <option value={Recurrence.ALL_THE_MONTH}>
                    {Recurrence.ALL_THE_MONTH}
                  </option>
                </select>
              </div>
              {recurrenceSelected !== Recurrence.ONE_TIME ? (
                <div className="mb-3">
                  <label
                    htmlFor="recurrenceEndDate"
                    className="block mb-2 sm:mr-5 text-sm font-semibold dark:text-white"
                  >
                    Date fin de récurrence
                  </label>
                  <input
                    className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                    type="date"
                    id="recurrenceEndDate"
                    value={selectedEndDateRecurrence}
                    onChange={(e) => {
                      setSelectedEndDateRecurrence(e.target.value);
                    }}
                  />
                </div>
              ) : null} */}
                <div className="flex flex-col">
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="plannedDuration"
                    defaultValue={selectedPlannedDuration}
                    render={() => (
                      <SelectDuration
                        id="estimated-duration"
                        label="Durée prévisionnelle *"
                        setDuration={setPlannedDuration}
                        defaultValue={selectedPlannedDuration}
                      />
                    )}
                  />
                  {errors.plannedDuration && (
                    <span className="text-red-600 text-sm">
                      une durée prévisonelle de tâche doit être renseignée.
                    </span>
                  )}
                </div>
              </div>

              {/* Client/Secretaire Pour lequels est établi le contrat concernant cette tâche */}
              <div className="my-6">
                <div className="flex flex-col sm:flex-row">
                  <div className="flex flex-col w-[15em]">
                    <label
                      htmlFor="customer"
                      className="block mb-2 text-sm font-semibold dark:text-white"
                    >
                      Choisir un client,
                      <span className="font-normal"> *</span>
                    </label>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="customerId"
                      defaultValue={
                        itemRow.customer ? (itemRow.customer.id as string) : ""
                      }
                      render={() => (
                        <div className="flex flex-col w-1/2">
                          {itemRow && itemRow.customer ? (
                            <div className="flex datas-center space-x-4">
                              <Avatar
                                imgUrl={getAvatarUrl(
                                  itemRow.customer.role,
                                  itemRow.customer.avatar_url,
                                )}
                              />
                              <div className="flex flex-col text-xs">
                                <div className="font-bold">
                                  {itemRow.customer.last_name.toUpperCase()}{" "}
                                  {itemRow.customer.first_name}
                                </div>
                                <span className="text-gray-500">
                                  {itemRow.customer.email}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    />
                    {errors.customerId && (
                      <span className="text-red-600 text-sm">
                        un client doit être renseigné
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col w-[15em] mr-5">
                    <label
                      htmlFor="secretaries"
                      className="block mb-2 text-sm font-semibold dark:text-white"
                    >
                      puis une secrétaire,
                      <span className="font-normal"> *</span>
                    </label>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="secretaryId"
                      defaultValue={
                        itemRow.secretary ? itemRow.secretary.id : ""
                      }
                      render={() => (
                        <div className="flex flex-col w-1/2">
                          {itemRow && itemRow.secretary ? (
                            <div className="flex datas-center space-x-4">
                              <Avatar
                                imgUrl={getAvatarUrl(
                                  itemRow.secretary.role,
                                  itemRow.secretary.avatar_url,
                                )}
                              />
                              <div className="flex flex-col text-xs">
                                <div className="font-bold">
                                  {itemRow.secretary.last_name.toUpperCase()}{" "}
                                  {itemRow.secretary.first_name}
                                </div>
                                <span className="text-gray-500">
                                  {itemRow.secretary.email}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    />
                    {errors.secretaryId && (
                      <span className="text-red-600 text-sm">
                        une secrétaire doit être renseignée
                      </span>
                    )}
                  </div>
                  {/* Référence et temps restant sur le contrat devis */}
                  {/* Choose a contract if exist */}
                  <div className="flex flex-col w-[15em]">
                    <label
                      htmlFor="contract"
                      className="block mb-3 text-sm font-semibold dark:text-white"
                    >
                      contrat
                      <span className="font-normal"> *</span>
                    </label>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="quoteAgreementId"
                      render={() =>
                        itemRow && itemRow.secretary && itemRow.customer ? (
                          <InputReferenceContract
                            customerId={itemRow.customer.id}
                            secretaryId={itemRow.secretary.id}
                            setQuoteAgreement={setQuoteAgreementId}
                          />
                        ) : (
                          <input
                            type="text"
                            id="contract"
                            readOnly
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            disabled
                            onChange={() => setQuoteAgreementId(null)}
                          />
                        )
                      }
                    />
                    {errors.quoteAgreementId && (
                      <span className="text-red-600 text-sm">
                        un contrat devis actif doit être renseigné
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div>
                {itemRow && quoteAgreement === null ? (
                  <div className="flex justify-between items-center rounded-lg text-sm text-yellow-800 bg-yellow-100 p-2 my-4 animate-fade-in-down">
                    <HiInformationCircle className="w-5 h-5" />
                    <p className="w-full ml-2">
                      Ce client n'a pas encore de contrat avec une secrétaire
                      affectée.
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="flex space-x-4">
                <div className="flex space-x-1">
                  <p className="text-sm text-gray-800">
                    Temps restant sur le contrat devis :
                  </p>
                  <span className="text-sm font-semibold">
                    {itemRow && itemRow.quoteAgreement
                      ? getFormattedHours(itemRow.quoteAgreement.currentAmount)
                      : "non renseignée"}
                  </span>
                </div>
              </div>

              {/* Description Text Area */}
              <div className="my-6">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-semibold dark:text-white"
                >
                  Description (facultatif)
                </label>
                <textarea
                  id="description"
                  defaultValue={
                    itemRow && itemRow.description ? itemRow.description : ""
                  }
                  rows={3}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
                  {...register("description", { required: false })}
                />
              </div>

              {/* Task status */}
              <div className="my-6 flex flex-col sm:flex-row">
                <div className="mb-2 mr-5">
                  <label
                    htmlFor="task-status"
                    className="block mb-2 text-sm font-semibold text-gray-700"
                  >
                    Statut de la tâche
                  </label>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="status"
                    defaultValue={itemRow.status as TaskStatus}
                    render={() => (
                      <select
                        defaultValue={itemRow.status}
                        disabled={user.role === UserRole.CUSTOMER} // Le client ne peut pas changer le status de la tâche en edition
                        id="task-status"
                        className="items-center py-2.5 px-4 text-sm font-medium bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark p-2.5"
                        onChange={(e) => {
                          setStatus(e.target.value as TaskStatus);
                        }}
                      >
                        {itemRow.status === TaskStatus.PENDING ? (
                          <option
                            value={TaskStatus.PENDING}
                            className="px-3 text-sm font-medium w-fit bg-purple-100 text-purple-800"
                          >
                            En attente
                          </option>
                        ) : null}
                        <option
                          value={TaskStatus.UNREALIZED}
                          className="px-3 text-sm font-medium w-fit bg-red-100 text-red-800"
                        >
                          Non réalisée
                        </option>
                        <option
                          value={TaskStatus.COMPLETED}
                          className="px-3 text-sm font-medium w-fit bg-green-100 text-green-800"
                        >
                          Complétée
                        </option>
                        <option
                          value={TaskStatus.TODO}
                          className="px-3 text-sm font-medium w-fit bg-purple-100 text-purple-800"
                        >
                          À réaliser
                        </option>
                      </select>
                    )}
                  />
                </div>
                {selectedStatus === TaskStatus.COMPLETED ? (
                  <>
                    <div className="flex flex-col mr-5">
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="realDuration"
                        defaultValue={realDurationSelected}
                        render={() => (
                          <SelectDuration
                            id="real-duration"
                            label="Durée réelle *"
                            setDuration={setRealDuration}
                            defaultValue={realDurationSelected}
                          />
                        )}
                      />
                      {errors.realDuration && (
                        <span className="text-red-600 text-sm">
                          une durée réelle de tâche doit être renseignée
                        </span>
                      )}
                    </div>

                    {/* end Date and Time picker */}
                    {/* <div className="flex flex-col mr-5">
                      <label
                        htmlFor="endDate"
                        className="block mb-2 text-sm font-semibold text-gray-700"
                      >
                        Date de fin de tâche
                      </label>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="endDate"
                        defaultValue={selectedEndDate}
                        render={() => (
                          <DateAndHourPicker
                            setDateAndHourPicker={setEndDateAndHourPicker}
                            dateDefaultValue={selectedEndDate}
                          />
                        )}
                      />{" "}
                      {errors.endDate && (
                        <span className="text-red-600 text-sm">
                          une date et un horaire doivent être renseignés
                        </span>
                      )}
                    </div> */}
                  </>
                ) : null}
              </div>
            </div>
            {/* Buttons Area */}

            <div className="py-1">
              <div className="flex justify-between my-6 w-full">
                <button
                  type="submit"
                  className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm w-1/2 mx-2"
                >
                  <FaClipboardList className="h-4 w-4 mr-2" />
                  Enregistrer
                </button>
                <Link
                  className="flex justify-center items-center button-add bg-gray-200 text-gray-900 hover:text-white p-2 font-semibold text-sm w-1/2 mx-2"
                  to="/liste-des-taches"
                >
                  <button type="button">Fermer</button>
                </Link>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <SpinnerComponent />
      )}
    </div>
  ) : (
    <SomethingHappened />
  );
}
export default EditTask;
