import { Link } from "react-router-dom";

/* eslint-disable prefer-template */
interface ButtonAddItemProps {
  label: string;
  path: string;
  // eslint-disable-next-line react/require-default-props
  classNameCustom?: string;
}

function ButtonAddItem(props: ButtonAddItemProps) {
  // Affectation des valeurs par défaut des props au composant
  const {
    label,
    path,
    classNameCustom = "w-30 h-10 bg-add-blue-dark font-medium button-add rounded-lg text-xs px-3 py-2.5 text-center inline-flex items-center mr-2",
  } = props;

  return (
    <Link id="RouterNavLink" to={path} className={classNameCustom}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 4.5v15m7.5-7.5h-15"
        />
      </svg>
      {label}
    </Link>
  );
}
export default ButtonAddItem;
