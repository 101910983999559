import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import ReactPaginate from "react-paginate";
import { useQuery } from "@apollo/client";
import SpinnerComponent from "../../components/shared/SpinnerComponent";
import { AdminsList_adminsList, UserRole } from "../../API/models/types";
import { ItemAdminsList } from "../models/models";
import { ADMINS_LIST } from "../../API/modules/user/resolvers/queries";
import TableAdminsList from "./table/TableAdminsList";
import SomethingHappened from "../SomethingHappened";

export default function AdminsList() {
  // methode retourne la liste des des clients en fonction du role
  const { data, loading, error } = useQuery(ADMINS_LIST);
  const adminsList = data
    ? ([...data.adminsList] as AdminsList_adminsList[])
    : ([] as AdminsList_adminsList[]);
  // data transfomée pour la table
  const adminsListData = (): ItemAdminsList[] => {
    const items: ItemAdminsList[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < adminsList.length; i++) {
      const item: AdminsList_adminsList = adminsList[i];
      items.push({
        id: item.id,
        admin: {
          userName: `${item.last_name.toUpperCase()} ${item.first_name}`,
          avatar: item.avatar_url as string,
          id: item.id,
          email: item.email,
          role: item.role as UserRole,
        },
        phone_number: item.phone_number as string,
        geographicalArea: item.geographical_area,
        isActive: item.isActive,
        profilePath: `/profile/${item.id}`,
        userNameAdmin: `${item.last_name.toUpperCase()} ${item.first_name}`,
        adminId: item.admin ? item.admin.id : "",
      });
    }
    return items;
  };
  // nombre de ligne par page de tableau
  const ROWBYPAGE: number = 6;

  // calcul nombre de page
  const nbPageFloat: number = adminsList && adminsList.length / ROWBYPAGE;

  // nombre de page arrondi à l' entier supérieur
  const nbPage = Math.ceil(nbPageFloat);
  const myFilters: string | null = localStorage.getItem("myAdminsListPage");
  const [page, setPage] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (myFilters && myFilters !== null) {
      setPage(myFilters && JSON.parse(myFilters) && JSON.parse(myFilters).page);
    } else {
      setPage(1);
    }
  }, [myFilters]);

  const handlePageChange = (event: any) => {
    setPage(event.selected + 1);
    localStorage.setItem(
      "myAdminsListPage",
      JSON.stringify({ page: event.selected + 1 }),
    );
  };
  return !error ? (
    <div className="p-5">
      {!loading ? (
        <Card className="overflow-auto flex gap-1 w-full p-3">
          {page && (
            <TableAdminsList
              data={adminsListData() as ItemAdminsList[]}
              rowByPage={ROWBYPAGE}
              page={page as number}
            />
          )}

          <div className="flex flex-row justify-left text-center">
            <div className="basis-4/5 text-left">
              <span className="items-center gap-1 flex flex-row text-xs p-4">
                <p className="text-gray-400 text-xs">Page</p>
                <strong className="flex color:#40788B">{page}</strong>
                <p className="text-gray-400 text-xs">sur</p>
                {nbPage === 0 ? <strong>1</strong> : <strong> {nbPage}</strong>}
              </span>
            </div>
            <div className="basis-1/5">
              {nbPage !== 0 && page ? (
                <ReactPaginate
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={ROWBYPAGE}
                  pageCount={nbPage}
                  forcePage={page ? page - 1 : 0}
                  marginPagesDisplayed={2}
                  nextLabel=">"
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                  previousLabel="<"
                  onPageActive={() => page}
                />
              ) : null}
            </div>
          </div>
        </Card>
      ) : (
        <SpinnerComponent />
      )}
    </div>
  ) : (
    <SomethingHappened />
  );
}
