import React from "react";

type ThirdStepFormProps = {
  getValues: () => { [name: string]: any };
};

function ThirdStepForm({ getValues }: ThirdStepFormProps) {
  const userEmail = getValues().email;
  return (
    <div>
      <h1 className="mb-6 text-2xl md:text-3xl lg:text-4xl">
        Vérification de votre adresse email
      </h1>
      <p className="text-gray-600">
        Nous avons envoyé un email de confirmation à l’adresse{" "}
        <span className="text-add-dark font-semibold">{userEmail}</span>. Merci
        de cliquer sur le lien qu’il contient afin de confirmer votre adresse
        email.
      </p>
      <div
        className="p-4 my-6 text-sm text-gray-800 rounded-lg bg-blue-100"
        role="alert"
      >
        <p className="text-gray-600">
          Pensez à vérifier vos spams. Si vous n’avez pas reçu d'email, vous
          pouvez en redemander un grâce au bouton ci-dessous.
        </p>
      </div>
    </div>
  );
}

export default ThirdStepForm;
