import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip } from "flowbite-react";
import { TourMethods } from "react-shepherd";
import { MdLiveHelp, MdAdminPanelSettings } from "react-icons/md";
import { BsFillPeopleFill } from "react-icons/bs";
import { isSmallScreen } from "../context/is-small-screen";
import { useSidebarContext } from "../context/SideBarContext";
import contractsIcon from "../assets/icons/facture.png";
import homeIcon from "../assets/icons/home.png";
import taskIcon from "../assets/icons/task.png";
import accountIcon from "../assets/icons/user.png";
import secretariesIcon from "../assets/icons/secretaire.png";
import documents from "../assets/icons/documents.png";

import StyledLink from "./shared/StyledMenuItems";
import { UserRole } from "../API/models/types";

interface Props {
  showSideBar: boolean;
  setShowSideBar: (showSideBar: boolean) => void;
  currentUserRole: UserRole;
  currentUserId: string;
}
function Sidebar(props: Props) {
  const location = useLocation();
  const { showSideBar, setShowSideBar, currentUserRole, currentUserId } = props;
  const [currentPage, setCurrentPage] = useState<string>(location.pathname);
  const [isContractOpen, setContractOpen] = useState<boolean>(showSideBar);
  const [isTasksOpen, setTasksOpen] = useState<boolean>(showSideBar);
  const { width } = useSidebarContext();
  const [smallScreen, setSmallScreen] = useState<boolean>(isSmallScreen(width));
  // gestion side bar pour mobile, des que l' on clicke sur un champs de la sidebar, elle se ferme
  const handleClickLink = () => {
    if (smallScreen) {
      setShowSideBar(false);
    }
  };
  // vérification de la taille de l' ecran des que la sidebar s'ouvre et se ferme
  useEffect(() => {
    setSmallScreen(isSmallScreen(width));
  }, [showSideBar, width]);

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [setCurrentPage, location.pathname]);

  return showSideBar ? (
    <aside
      id="sidebar-multi-level-sidebar"
      className="w-72 h-full bg-add-blue-dark"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 py-4 overflow-y-auto bg-add-blue-dark text-gray-100">
        <ul className="space-y-2">
          {/* Dashboard */}
          <li>
            <StyledLink to="/" className="w-full">
              <button
                type="button"
                id="dashboard-menu-item"
                onClick={handleClickLink}
                className={`flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group
                  ${
                    currentPage === "/"
                      ? "bg-green-dark dark:bg-green-dark "
                      : ""
                  }
                `}
              >
                <img src={homeIcon} alt="dashboard icon" className="w-7 h-7" />
                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Dashboard
                </span>
              </button>
            </StyledLink>
          </li>
          {/* Mon Compte */}
          <li>
            <StyledLink to={`/profil/${currentUserId}`} className="w-full">
              <button
                type="button"
                id="account-menu-item"
                onClick={handleClickLink}
                className={`flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group
                  ${
                    currentPage === `/profil/${currentUserId}`
                      ? "bg-green-dark dark:bg-green-dark "
                      : ""
                  }
                `}
              >
                <img src={accountIcon} alt="account icon" className="w-7 h-7" />
                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Mon compte
                </span>
              </button>
            </StyledLink>
          </li>

          {/* Contrats */}
          <li>
            <button
              type="button"
              id="contracts-menu-item"
              className="flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group"
              aria-controls="dropdown-example"
              onClick={() => {
                setContractOpen(!isContractOpen);
              }}
            >
              <img
                src={contractsIcon}
                alt="contract icon"
                className="w-7 h-7"
              />
              <span className="flex-1 ml-3 text-left whitespace-nowrap">
                Contrats
              </span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {isContractOpen ? (
              <ul id="dropdown-example" className="py-2 px-8 space-y-2 text-xl">
                {currentUserRole !== UserRole.SECRETARY ? (
                  <StyledLink
                    onClick={handleClickLink}
                    to="/contrat-cadre"
                    className="w-full block"
                    id="contracts-framework-menu-item"
                  >
                    <li className="button-add ">Contrats Cadre</li>
                  </StyledLink>
                ) : null}
                <StyledLink
                  onClick={handleClickLink}
                  to="/contrat-devis"
                  className="w-full block"
                  id="contracts-quote-menu-item"
                >
                  <li className="button-add ">Contrats Devis</li>
                </StyledLink>
              </ul>
            ) : null}
          </li>

          {/* List des clients */}
          {currentUserRole !== UserRole.CUSTOMER && (
            <li>
              <StyledLink to="/liste-des-clients" className="w-full">
                <button
                  onClick={handleClickLink}
                  type="button"
                  id="customers-list-menu-item"
                  className={`flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group ml-1
                ${
                  currentPage === "/liste-des-clients"
                    ? "bg-green-dark dark:bg-green-dark "
                    : ""
                }
              `}
                >
                  <BsFillPeopleFill className="fill-add-green-glow" />
                  <span className="flex-1 ml-4 text-left whitespace-nowrap">
                    Clients
                  </span>
                </button>
              </StyledLink>
            </li>
          )}

          {/* List des admins */}
          {currentUserRole === UserRole.SUPER_ADMIN && (
            <li>
              <StyledLink to="/liste-des-admins" className="w-full">
                <button
                  onClick={handleClickLink}
                  type="button"
                  id="admins-list-menu-item"
                  className={`flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group
                ${
                  currentPage === "/liste-des-admins"
                    ? "bg-green-dark dark:bg-green-dark "
                    : ""
                }
              `}
                >
                  <MdAdminPanelSettings className="w-7 h-7 fill-add-green-glow" />
                  <span className="flex-1 ml-3 text-left whitespace-nowrap">
                    Admin
                  </span>
                </button>
              </StyledLink>
            </li>
          )}

          {/* List des secrétaires */}
          {currentUserRole !== UserRole.SECRETARY && (
            <li>
              <StyledLink to="/liste-des-secretaires" className="w-full">
                <button
                  onClick={handleClickLink}
                  type="button"
                  id="secretaries-list-menu-item"
                  className={`flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group
                ${
                  currentPage === "/liste-des-secretaires"
                    ? "bg-green-dark dark:bg-green-dark "
                    : ""
                }
              `}
                >
                  <img
                    src={secretariesIcon}
                    alt="secretaries icon"
                    className="w-7 h-7"
                  />
                  <span className="flex-1 ml-3 text-left whitespace-nowrap">
                    Secrétaires
                  </span>
                </button>
              </StyledLink>
            </li>
          )}

          {/* Tâches */}
          {currentUserRole === UserRole.SECRETARY ||
          currentUserRole === UserRole.ADMIN ? (
            <li>
              <button
                type="button"
                id="tasks-menu-item"
                className={`flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group
              ${
                currentPage === "/liste-des-taches"
                  ? "bg-green-dark dark:bg-green-dark "
                  : ""
              }
            `}
                onClick={() => {
                  setTasksOpen(!isTasksOpen);
                }}
              >
                <img src={taskIcon} alt="task icon" className="w-7 h-7" />
                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Tâches
                </span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {isTasksOpen ? (
                <ul
                  id="dropdown-example"
                  className="py-2 px-8 space-y-2 text-xl w-full"
                >
                  <StyledLink
                    to="/liste-des-taches-en-cours"
                    className="w-full block"
                    onClick={handleClickLink}
                  >
                    <li className="button-add w-full">Acceptées</li>
                  </StyledLink>
                  <StyledLink
                    onClick={handleClickLink}
                    to="/liste-des-taches-en-attente-d-acceptation"
                    className="w-full block"
                  >
                    <li className="button-add">En attente</li>
                  </StyledLink>
                </ul>
              ) : null}
            </li>
          ) : (
            <li>
              <StyledLink to="/liste-des-taches" className="w-full">
                <button
                  onClick={handleClickLink}
                  id="tasks-menu-item"
                  type="button"
                  className={`flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group
                ${
                  currentPage === "/liste-des-taches"
                    ? "bg-green-dark dark:bg-green-dark "
                    : ""
                }
              `}
                >
                  <img src={taskIcon} alt="task icon" className="w-7 h-7" />
                  <span className="flex-1 ml-3 text-left whitespace-nowrap">
                    Tâches
                  </span>
                </button>
              </StyledLink>
            </li>
          )}

          {/* Agenda */}
          <li>
            <StyledLink to="/agenda" className="w-full">
              <button
                type="button"
                id="calendar-menu-item"
                onClick={handleClickLink}
                className={`flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group
                  ${
                    currentPage === "/agenda"
                      ? "bg-green-dark dark:bg-green-dark "
                      : ""
                  }
                `}
              >
                <img src={homeIcon} alt="dashboard icon" className="w-7 h-7" />
                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Agenda
                </span>
              </button>
            </StyledLink>
          </li>

          {/* Accès aux ressources */}
          <li className="w-full" id="ressources">
            {currentUserRole === UserRole.SUPER_ADMIN ? (
              <Tooltip
                content="Nous vous fournirons bientôt une rubrique dédiée aux ressources et outils destinés à vous aider dans votre travail."
                placement={smallScreen ? "bottom" : "right"}
                // eslint-disable-next-line react/jsx-curly-brace-presence, react/style-prop-object
                style={"light"}
                className="w-44"
                trigger="click"
              >
                <button
                  type="button"
                  className="flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group"
                >
                  <img
                    src={documents}
                    alt="resources icon"
                    className="w-7 h-7"
                  />
                  <span className="flex-1 ml-3 text-left whitespace-nowrap">
                    Ressources
                  </span>
                </button>
              </Tooltip>
            ) : (
              <a
                href={
                  currentUserRole === UserRole.CUSTOMER
                    ? "https://trello.com/b/0JlzwnNB/ressources-client"
                    : "https://trello.com/b/V98G2HdN/ressources-assistantes"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="w-full"
              >
                <button
                  onClick={handleClickLink}
                  type="button"
                  className="flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group"
                >
                  <img
                    src={documents}
                    alt="resources icon"
                    className="w-7 h-7"
                  />
                  <span className="flex-1 ml-3 text-left whitespace-nowrap">
                    Ressources
                  </span>
                </button>
              </a>
            )}
          </li>

          {/* Accès au tutoriel (gestion du role dans le composant) */}
          <li className="w-full">
            <TourMethods>
              {(tourContext) => (
                <button
                  type="button"
                  className="flex button-add items-center w-full p-2 text-xl transition duration-75 rounded-lg group"
                  onClick={tourContext?.start}
                >
                  <MdLiveHelp className="fill-add-green-glow ml-1" />
                  <span className="flex-1 ml-3 text-left whitespace-nowrap">
                    Tutoriel
                  </span>
                </button>
              )}
            </TourMethods>
          </li>
        </ul>
      </div>
    </aside>
  ) : null;
}
export default Sidebar;
