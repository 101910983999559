import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export function getTaskHeightClassTailwind(
  duration: number | null,
  startDate: dayjs.Dayjs,
) {
  const utcStartDate = dayjs.utc(startDate);
  const standardCellHeight = "h-16"; // 64px
  if (duration === null || utcStartDate.hour() >= 20) {
    return standardCellHeight; // Fallback height if plannedDuration is null
  }

  // Vérifier si la startDate plus la duration dépasse 21h
  const endTime = dayjs.utc(utcStartDate.add(duration, "hour"));
  let effectiveDuration = duration; // Initialize effectiveDuration à la valeur de plannedDuration
  if (endTime.hour() >= 20) {
    // Si la tâche se termine aprés 21h, on veut que visuellement, la hauteur ne dépasse pas 21h
    const hoursUntil18 = 20 - utcStartDate.hour();
    effectiveDuration = hoursUntil18 < 0 ? 0 : hoursUntil18;
  }

  const tailwindHeight = Math.round(effectiveDuration * 16);
  return `h-${tailwindHeight}`;
}

export function getTaskHeightClassStyle(
  duration: number | null,
  startDate: dayjs.Dayjs,
) {
  const utcStartDate = dayjs.utc(startDate);
  const standardCellHeight = 64; // Correspond à la classe Tailwind h-16 (64px)

  if (duration === null) {
    return standardCellHeight; // Fallback height if plannedDuration is null
  }

  // Calculer l'heure de fin de la tâche
  const endTime = utcStartDate.clone().add(duration, "hour");

  // Calculer la durée en minute de la tâche
  let effectiveDurationInMinutes = duration * 60;

  // On vérifie si la tâche va dépasser 21h (et donc dépasser l'affichage de la cellule 20h)
  if (
    endTime.utc().hour() >= 21 ||
    endTime.utc().date() !== utcStartDate.utc().date()
  ) {
    // Si endTime est au delà de 19h ou si endTime est un autre jour que startDate
    // Calculer la différence en minutes de startDate avec un endTime à 19h
    effectiveDurationInMinutes = utcStartDate
      .clone()
      .local()
      .hour(21)
      .minute(0)
      .diff(utcStartDate, "minute");
  }

  // Calculer la height par minute
  const heightPerMinute = standardCellHeight / 60;

  // Calculer la valeur de la height en pixels en fonction des minutes effectives
  const pixelHeight = Math.round(effectiveDurationInMinutes * heightPerMinute);

  return pixelHeight;
}
