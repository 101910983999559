/* eslint-disable no-nested-ternary */
import { Alert, Card } from "flowbite-react";
import React, { useCallback, useContext } from "react";
import ReactPaginate from "react-paginate";
import { ApolloError } from "@apollo/client";
import { HiEye, HiInformationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import tasksIllustration from "../../../assets/illustrations/tasks.png";

import AdminTaskList from "./table/AdminTaskList";
import { useUserContext } from "../../../context/userContext";
import {
  UserRole,
  TaskStatus,
  FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks,
  FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins,
} from "../../../API/models/types";
import SpinnerComponent from "../../../components/shared/SpinnerComponent";

import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../context/FilterContext";
import { FilterTask, ItemTask } from "../../models/models";
import { FindAllPaginateTasksForAdmins } from "../../utils/QueriesTasks";
import DownloadTasksCsvButton from "../../../components/shared/DownloadTasksCsvButton";
import SomethingHappened from "../../SomethingHappened";
import getFormattedHours from "../../Calendar/utils/getFormattedHours";

export default function AdminTask() {
  // contexte user
  const { user } = useUserContext();
  // contexte filtre

  const { filters, setFilters } =
    useContext<FilterTasksContextType>(FilterTasksContext);
  // construction de la donnée pour l'adapter à la table
  const {
    tasks,
    loading,
    error,
  }: {
    tasks: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins;
    loading: boolean;
    error: ApolloError | undefined;
  } =
    user !== null && filters !== null
      ? FindAllPaginateTasksForAdmins(filters, user.id as string)
      : {
          tasks: {
            tasks:
              [] as FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks[],
            total: 0,
            hasPendingTask: false,
            __typename: "OutputPaginateTasks",
          },
          loading: false,
          error: undefined,
        };
  const tasksDataForTable = useCallback(() => {
    const items: ItemTask[] = [];
    if (tasks && tasks.tasks.length > 0) {
      const tasksList =
        tasks &&
        (tasks.tasks as FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks[]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tasksList.length; i++) {
        const item: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks =
          tasksList[i];

        items.push({
          id: item.id,
          label: `${item.label} : ${item.taskType.label}`,
          categoryTask: item.taskType.categoryTaskType.label as string,
          realDuration: item.realDuration
            ? getFormattedHours(item.realDuration)
            : "non renseignée",
          plannedDuration: item.plannedDuration
            ? getFormattedHours(item.plannedDuration)
            : "non renseignée",
          secretary: {
            id: item.secretary.id,
            userName: `${item.secretary.last_name.toUpperCase()} ${
              item.secretary.first_name
            }`,
            avatar: item.secretary.avatar_url as string,
            role: item.secretary.role as UserRole,
          },
          author: {
            id: item.author.id,
            userName: `${item.author.last_name.toUpperCase()} ${
              item.author.first_name
            }`,
            avatar: item.author.avatar_url as string,
            role: item.author.role as UserRole,
          },
          client: item.customer
            ? {
                id: item.customer.id,
                userName: `${item.customer.last_name.toUpperCase()} ${
                  item.customer.first_name
                }`,
                avatar: item.customer.avatar_url as string,
                role: item.customer.role as UserRole,
              }
            : null,
          date: new Date(item.startDate),
          status: item.status
            ? (item.status as TaskStatus)
            : TaskStatus.PENDING,
          // pour filtrer sur le nom colonne hidden
          userNameSecretary: `${item.secretary.last_name.toUpperCase()} ${
            item.secretary.first_name
          }`,
          userNameClient: item.customer
            ? `${item.customer.last_name.toUpperCase()} ${
                item.customer.first_name
              }`
            : "",
        });
      }
    }
    return items;
  }, [tasks]);
  // nombre de ligne par page de tableau
  const ROWBYPAGE: number = 6;

  // calcul nombre de page
  const nbPageFloat: number = (tasks && tasks.total / ROWBYPAGE) || 0;

  // nombre de page arrondi à l' entier supérieur
  const nbPage = Math.ceil(nbPageFloat);
  const handlePageChange = (event: any) => {
    if (filters && setFilters) {
      setFilters({
        ...filters,
        page: event.selected + 1,
      });
    }
    localStorage.setItem(
      "myTasksListPage",
      JSON.stringify({ page: event.selected + 1 }),
    );
  };
  return !error ? (
    <main className="flex flex-col p-5">
      <header className="flex flex-row h-1/7 justify-items-start items-center">
        <div className="basis-2/12 sm:flex hidden p-6">
          <img
            width={200}
            height={200}
            src={tasksIllustration}
            alt="pending tasks list"
          />
        </div>
        <div className="basis-9/12">
          <h1>Liste des tâches</h1>
        </div>
      </header>
      {!loading ? (
        <main className="flex flex-col w-full h-4/7">
          <Card className="overflow-auto flex gap-1 w-full p-3">
            <div className="flex min-h-3/4 relative">
              <AdminTaskList
                data={tasksDataForTable()}
                tasks={
                  tasks.tasks as FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks[]
                }
              />
              {filters && user ? (
                <div className="absolute md:relative xl:absolute right-0">
                  <DownloadTasksCsvButton
                    filters={filters as FilterTask}
                    fileName="liste-tâches"
                    userRole={UserRole.ADMIN as UserRole}
                    userId={user.id as string}
                  />
                </div>
              ) : null}
            </div>
            <div className="flex flex-row justify-left text-center">
              <div className="basis-4/5 text-left">
                <span className="items-center gap-1 flex flex-row text-xs p-4">
                  <p className="text-gray-400 text-xs">Page</p>
                  <strong className="flex color:#40788B">
                    {filters && filters.page}
                  </strong>
                  <p className="text-gray-400 text-xs">sur</p>
                  {nbPage === 0 ? (
                    <strong>1</strong>
                  ) : (
                    <strong> {nbPage}</strong>
                  )}
                </span>
              </div>
              <div className="basis-1/5">
                {nbPage !== 0 ? (
                  <ReactPaginate
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={ROWBYPAGE}
                    pageCount={nbPage}
                    forcePage={filters.page ? filters.page - 1 : 0}
                    marginPagesDisplayed={2}
                    nextLabel=">"
                    containerClassName="pagination"
                    previousLinkClassName="pagination__link"
                    nextLinkClassName="pagination__link"
                    disabledClassName="pagination__link--disabled"
                    activeClassName="pagination__link--active"
                    previousLabel="<"
                    onPageActive={() => filters.page}
                  />
                ) : null}
              </div>
            </div>
          </Card>
          {tasks.hasPendingTask ? (
            <div className="flex justify-center w-full mt-6">
              <Alert
                color="warning"
                additionalContent={
                  <>
                    <div className="mt-1 mb-2 text-sm text-yellow-700">
                      Les tâches en attentes de confirmation n'apparaissent pas
                      dans votre tableau de tâches en cours ou dans votre
                      agenda.
                    </div>
                    <div className="flex">
                      <Link to="/liste-des-taches-en-attente-d-acceptation">
                        <button
                          type="button"
                          className="button-add mr-2 inline-flex items-center rounded-lg bg-yellow-700 px-3 py-1.5 text-center text-xs font-medium text-white"
                        >
                          <HiEye className="-ml-0.5 mr-2 h-4 w-4" />
                          Voir la liste des tâches en attente de confirmation
                        </button>
                      </Link>
                    </div>
                  </>
                }
                icon={HiInformationCircle}
              >
                <h3 className="text-lg font-medium text-yellow-700">
                  Vous avez des tâches en attentes de confirmation
                </h3>
              </Alert>
            </div>
          ) : null}
        </main>
      ) : (
        <SpinnerComponent />
      )}
    </main>
  ) : (
    <SomethingHappened />
  );
}
