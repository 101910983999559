import React from "react";
import dayjs from "dayjs";

// eslint-disable-next-line import/no-cycle
import AgendaMonthTasks from "./AgendaMonthTasks";
// eslint-disable-next-line import/no-cycle
import { Task } from "../../hook/useTasks";
import getCurrentDayClass from "../../utils/getCurrentDayClass";

interface Props {
  day: dayjs.Dayjs;
  rowIdx: number;
  tasks: Task[];
}
function Day({ day, rowIdx, tasks }: Props) {
  return (
    <div className="border border-gray-200 flex flex-col h-36 max-w-full overflow-y-auto scrollbar-thumb-add-blue-dark scrollbar-track-gray-100 scrollbar-thin">
      <header className="flex flex-col items-center">
        {/* Display the Day only on first row */}
        {rowIdx === 0 && (
          <p className="text-sm mt-1">{day.format("ddd").toUpperCase()}</p>
        )}
        <p
          className={`text-sm p-1 my-1 text-center ${getCurrentDayClass(day)}`}
        >
          {day.format("DD")}
        </p>
      </header>
      <AgendaMonthTasks dayTasks={tasks} />
    </div>
  );
}

export default Day;
