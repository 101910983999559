import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { FilterFn } from "@tanstack/react-table";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

/* la fonction fuzzyFilter compare la valeur d'une colonne avec une valeur de filtrage et évalue
 le classement de l'élément. Elle stocke les informations de classement dans les métadonnées de la ligne
  et renvoie si l'élément doit être filtré ou non en fonction du classement.
 */
// eslint-disable-next-line import/prefer-default-export
export const createFuzzyFilter: FilterFn<any> = (
  row,
  columnId,
  value,
  addMeta,
) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};
