import React from "react";
import { useDateRange } from "./context/DateRangeContext";
import { GetSubscriptionsData_getSubscriptionsData } from "../../../../../API/models/types";

interface EvolutionUsersValueChartProps {
  data: GetSubscriptionsData_getSubscriptionsData;
}

function EvolutionUsersValue({ data }: EvolutionUsersValueChartProps) {
  const [selectedDateRange] = useDateRange();

  const percentageChange =
    selectedDateRange === "7"
      ? data?.option_7?.percentageChange
      : data?.option_30?.percentageChange;

  if (percentageChange === "- 100.00") {
    return null;
  }

  let className = "flex flex-1 items-center justify-end text-base font-bold";

  switch (true) {
    case percentageChange && percentageChange.includes("+"):
      className += " text-green-600";
      break;
    case percentageChange && percentageChange.includes("-"):
      className += " text-red-600";
      break;
    default:
      break;
  }

  return <div className={className}>{percentageChange}%</div>;
}

export default EvolutionUsersValue;
