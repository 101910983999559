import React from "react";
import NewUsersList from "./NewUsersList";
import UsersSubscriptionKPI from "./KPI/UsersChart/UsersSubscriptionKPI";
import ContractedHoursKPI from "./KPI/ContractedHoursChart/ContractedHoursKPI";

function AdminDashboard() {
  return (
    <main className="flex flex-col h-full">
      <div className="p-4">
        <UsersSubscriptionKPI />
      </div>
      <div className="p-4 flex flex-col md:flex-row justify-between">
        <NewUsersList />
        <ContractedHoursKPI />
      </div>
    </main>
  );
}

export default AdminDashboard;
