/* eslint-disable no-nested-ternary */
import { Card } from "flowbite-react";

import {
  GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin,
  GetAllFrameworkAgreements_getAllFrameworkAgreements,
  UserRole,
  getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser,
} from "../../API/models/types";
import imgContrat from "../../assets/illustrations/img-contrat.png";
import SpinnerComponent from "../../components/shared/SpinnerComponent";
import { useUserContext } from "../../context/userContext";
import { refetchQueries } from "../utils/QueriesQuoteAgreement";
import {
  GetAllFrameworkAgreement,
  GetAllFrameworkAgreementForAdmin,
  GetAllFrameworkAgreementsForUser,
  getOneFrameworkAgreement,
  getOneFrameworkAgreementForAdmin,
  getOneFrameworkAgreementForUser,
} from "../utils/QueriesFrameworkAgreement";
import TableFrameworkAgreement from "./TableFrameworkAgreement";
import {
  FrameworkAgreementData,
  ItemFrameworkAgreement,
} from "../models/models";
import DownloadFrameworkAgreementsCsvButton from "../../components/shared/DownloadFrameworkAgreementsCsvButton";
import SomethingHappened from "../SomethingHappened";

function FrameworkAgreement() {
  const { user } = useUserContext();
  // methode retourne la liste des contrats cadre en fonction du role
  const { frameworkAgreements, loading, error } =
    user.role !== null && user.role === UserRole.SUPER_ADMIN
      ? GetAllFrameworkAgreement()
      : user.role !== null && user.role === UserRole.ADMIN
      ? GetAllFrameworkAgreementForAdmin(user.id as string)
      : user.role !== null && user.role === UserRole.CUSTOMER
      ? GetAllFrameworkAgreementsForUser(user.id as string)
      : { frameworkAgreements: [], loading: false, error: undefined };
  // methode retourne un contrat cadre en fonction du role
  const getOneFrameworkAgreements = (
    id: string,
    array: FrameworkAgreementData[],
  ): FrameworkAgreementData => {
    if (user.role === UserRole.SUPER_ADMIN) {
      return getOneFrameworkAgreement(
        id as string,
        array as GetAllFrameworkAgreements_getAllFrameworkAgreements[],
      );
    }
    if (user.role === UserRole.ADMIN) {
      return getOneFrameworkAgreementForAdmin(
        id as string,
        array as GetAllFrameworkAgreementsForAdmin_getAllFrameworkAgreementsForAdmin[],
      );
    }
    return getOneFrameworkAgreementForUser(
      id as string,
      array as getAllFrameworkAgreementsForUser_getAllFrameworkAgreementsForUser[],
    );
  };
  // data transfomée pour la table
  const frameworkAgreementData = (): ItemFrameworkAgreement[] => {
    const items: ItemFrameworkAgreement[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < frameworkAgreements.length; i++) {
      const item: FrameworkAgreementData = frameworkAgreements[i];
      items.push({
        id: item.id,
        contract: item.label,
        client: {
          userName: `${item.user.last_name.toUpperCase()} ${
            item.user.first_name
          }`,
          avatar: item.user.avatar_url as string,
          id: item.user.id,
          role: item.user.role as UserRole,
        },
        date: new Date(item.createdAt),
        status: item.isActive ? "activé" : "désactivé",
        userName: `${item.user.last_name.toUpperCase()} ${
          item.user.first_name
        }`,
      });
    }

    return items;
  };

  return !error ? (
    <div className="flex flex-col">
      <header className="flex flex-col h-1/7 lg:max-w-[50%] justify-items-start items-center relative">
        <div className="basis-2/12 flex">
          <img
            src={imgContrat}
            alt="illustration contrats cadres"
            className="w-full"
          />
        </div>
        <div className="basis-9/12 p-10 sm:p-0 absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-2xl xl:text-3xl">Contrats cadres</h1>
        </div>
      </header>
      {!loading ? (
        <div className="flex w-full h-4/7 p-5">
          <Card className="overflow-auto flex gap-1 w-full p-3">
            <div className="flex min-h-3/4 relative">
              <TableFrameworkAgreement
                getAllFrameworkAgreements={frameworkAgreements}
                getOneFrameworkAgreements={
                  getOneFrameworkAgreements as (
                    value: string,
                    value1: FrameworkAgreementData[],
                  ) => FrameworkAgreementData
                }
                refetchQueries={refetchQueries(user.role as UserRole)}
                data={frameworkAgreementData()}
              />
              {user ? (
                <div className="absolute md:relative xl:absolute right-0">
                  <DownloadFrameworkAgreementsCsvButton
                    fileName="liste-contrats-cadre"
                    userRole={user.role as UserRole}
                    userId={user.id as string}
                  />
                </div>
              ) : null}
            </div>
          </Card>
        </div>
      ) : (
        <SpinnerComponent />
      )}
    </div>
  ) : (
    <SomethingHappened />
  );
}
export default FrameworkAgreement;
