/* eslint-disable react/require-default-props */
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  ColumnDef,
  getPaginationRowModel,
  getFilteredRowModel,
  SortingState,
  getSortedRowModel,
  VisibilityState,
} from "@tanstack/react-table";
import "./pagination.css";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies

import ButtonAddItem from "./button/ButtonAddItem";
import ButtonActionItem from "./button/ButtonActionItem";

import ButtonFilterTable from "./button/ButtonFilterTable";
// eslint-disable-next-line import/no-cycle
import SelectDateFilter from "./filter/SelectDateFilter";

import { CustomElements, CustomForm } from "../../pages/models/models";
import SearchBarForNotPaginateDataTable from "./button/SearchBarForNotPaginateDataTable";
import { createFuzzyFilter } from "./filter/FilterFunction";

// import { UserRole } from "../../API/models/types";
// import { UserContext } from "../../context/userContext";

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  // visibilité des informations sur les page (nombre, page actuelle ...)
  showFooter: boolean;
  // visibilité pagination
  showNavigation: boolean;
  // visibilité searchBar + filtre
  // TODO séparer les 2
  showGlobalFilter: boolean;
  // visibilité bouton d'ajout d'un item
  showButtonAddItem: boolean;
  // label bouton d' ajout d'un item
  labelButtonAddItem: string;
  // path vers formulaire création
  pathButtonAddItem: string;
  // visibilité bouton d'action
  showButtonActionItem: boolean;
  // label bouton d'action
  labelButtonActionItem?: string;
  // Méthode pour l'action onClick du bouton ButtonAction
  handleActionButtonActionItem?: () => void;

  // colonne cachée en fonction du role
  // eslint-disable-next-line react/require-default-props
  hiddenColumns?: VisibilityState;
  // affichage modale des filtres
  modalFilter?: boolean;
  // affichage bouton filter
  showFilterButton: boolean;
  showSelectDateFilter?: boolean;
  setModalFilter?: (modalFilter: boolean) => void;
  totalItem?: number;
  // si pas paginées
  page?: number;
  rowByPage?: number;
}

export default function TableNotPaginateData<T extends object>({
  data,
  columns,
  showFooter,
  showGlobalFilter = false,
  showButtonAddItem = false,
  showButtonActionItem = false,
  labelButtonActionItem = "",
  showFilterButton = true,
  labelButtonAddItem,
  pathButtonAddItem,
  handleActionButtonActionItem,
  hiddenColumns,
  modalFilter,
  showSelectDateFilter = false,
  setModalFilter,
  rowByPage,
  page,
}: ReactTableProps<T>) {
  // this is the search value
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnVisibility] = React.useState<VisibilityState>(
    hiddenColumns || {},
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    // permet de filtrer sur les colonnes souhaitées
    filterFns: {
      fuzzy: createFuzzyFilter,
    },

    initialState: {
      pagination: {
        pageIndex: page || undefined,
        pageSize: rowByPage || undefined,
      },
    },
    autoResetPageIndex: false,
    autoResetAll: false,
    state: {
      globalFilter,
      sorting,
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    // globalFilterFn: createFuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });
  // nombre de ligne par page de tableau
  const handleChangePage = useCallback(
    (pages: number) => {
      table.setPageIndex(pages - 1);
    },
    [table],
  );
  useEffect(() => {
    if (rowByPage) {
      table.setPageSize(rowByPage);
    }
    if (page) {
      handleChangePage(page);
    }
  }, [handleChangePage, page, rowByPage, table]);

  const onSubmit = (event: FormEvent<CustomForm>) => {
    event.preventDefault();
    // Validate form data
    const inputData: CustomElements =
      event && event.currentTarget && event.currentTarget.elements;
    const search = inputData && (inputData?.search?.value as string);
    setGlobalFilter(search && search.toLowerCase());
  };

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col">
        <div className="flex flex-col sm:flex-row sm:basis-10/12 xl:basis-8/12 xxl:basis-10/12 mr-2 ">
          {showSelectDateFilter ? (
            <div className="mr-2 mb-2 w-3/5 sm:w-2/5 lg:w-2/4 xl:w-1/6">
              <SelectDateFilter />
            </div>
          ) : null}
          {showGlobalFilter ? (
            <div className="mr-2 mb-2 w-3/5 sm:w-2/5 lg:w-1/3 xl:w-1/6">
              <SearchBarForNotPaginateDataTable
                value={globalFilter ?? ""}
                onSubmit={onSubmit}
              />
            </div>
          ) : null}
          {showFilterButton ? (
            <div className="mb-2">
              <ButtonFilterTable
                isOpen={modalFilter as boolean}
                setOpen={setModalFilter as (open: boolean) => void}
              />
            </div>
          ) : null}
        </div>

        {showButtonAddItem ? (
          <div>
            <div className="mb-2">
              <ButtonAddItem
                label={labelButtonAddItem}
                path={pathButtonAddItem}
              />
            </div>
          </div>
        ) : null}
        {showButtonActionItem ? (
          <div className="basis-2/12 p-1">
            <ButtonActionItem
              label={labelButtonActionItem}
              handleAction={handleActionButtonActionItem as () => void}
            />
          </div>
        ) : null}
      </div>
      <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full min-h-full divide-y divide-gray-200">
              <thead className="bg-gray-10">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="px-4 text-left text-xs font-medium text-gray-400 uppercase rounded-sm tracking-wider"
                      >
                        {header.isPlaceholder ? null : (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: "▲",
                              desc: "▼",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className='border-b" bg-white'>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        className="px-2 whitespace-nowrap h-full text-xs"
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              {showFooter ? (
                <tfoot className="border-t bg-gray-50">
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.footer,
                                header.getContext(),
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              ) : null}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
