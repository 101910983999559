import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  useForm,
  FieldValues,
  UseFormReturn,
  Controller,
} from "react-hook-form";
import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { Avatar, Card, Label, Modal, TextInput } from "flowbite-react";
import Select from "react-tailwindcss-select";
import { useNavigate } from "react-router-dom";
import { FaClipboardList } from "react-icons/fa";
import {
  GetAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
  GetUserByID_getUserByID,
  GetUserByID_getUserByID_taskTypes,
  UserRole,
  UserUpdateInput,
} from "../../API/models/types";
import {
  CHANGE_USER_ROLE,
  EDIT_USER,
} from "../../API/modules/user/resolvers/mutation";
import { GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES } from "../../API/modules/category-task-type/resolvers/queries";
import { OptionsSelect, Option } from "../models/models";
import {
  Area,
  Experience,
} from "../../components/OnBoardingSteps/SecondStepForm";
import geographicalAreaOptions from "../../API/data/departements-region.json";
import activitySectors from "../../API/data/activity-sectors.json";
import { UserContext } from "../../context/userContext";
import {
  ADMINS_LIST,
  CUSTOMERS_LIST,
  SECRETARIES_LIST,
  GET_ONE_USER_BY_ID,
  CHECK_IF_EMAIL_ALREADY_EXIST,
} from "../../API/modules/user/resolvers/queries";
import getAvatarUrl from "../utils/getAvatarUrl";
import useLocalStorageService, { User } from "../../hooks/useLocalStorage";

interface Props {
  data: GetUserByID_getUserByID;
  role: UserRole;
}
interface TaskType {
  value: string;
  label: string;
  disabled: boolean;
}
type UseFormReturnWithErrors<T extends FieldValues> = UseFormReturn<T> & {
  errors: Record<keyof T, any>;
};
export default function EditMyAccountForm(props: Props) {
  // data est la donnée du profil consulté
  const { data, role } = props;

  // contexte utilisateur connecté
  const { user, setUser } = useContext(UserContext);
  // user localstorage
  const { saveUser } = useLocalStorageService();
  // router
  const navigate = useNavigate();

  // role de profil consulté
  const [profileRole, setProfileRole] = useState("");
  // role de l' utilisateur connecté
  const [userRole, setUserRole] = useState("");
  // avatar du profil
  const [avatarUrl, setAvatarUrl] = useState("");

  // fonction pour définir si l'utilisateur connecté est l' admin du profil consulté
  const boolUserAdmin = useCallback(
    (adminId: string) => {
      if (user !== null && adminId && adminId === user.id) {
        return true;
      }
      return false;
    },
    [user],
  );

  // etat true => admin consulte le profil d'un de ses utilisateurs sinon false
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  // mise à jour => si l'utilisateur connecté consulte un profil si il en est l'admin
  useEffect(() => {
    if (user !== null && data !== null && user) {
      if (data && data.admin && user.role === UserRole.ADMIN) {
        setIsUserAdmin(boolUserAdmin(data?.admin?.id as string));
      }
      if (user.role === UserRole.SUPER_ADMIN) {
        setIsUserAdmin(true);
      }
    }
  }, [boolUserAdmin, data, user]);

  // mise a jour du role suivant si c'est une consultation de son propre profil ou d'un autre profil
  useEffect(() => {
    if (role && !isUserAdmin) {
      switch (role) {
        case UserRole.CUSTOMER:
          setAvatarUrl(getAvatarUrl(data.role, data.avatar_url));
          setUserRole("Client");

          break;
        case UserRole.SECRETARY:
          setAvatarUrl(getAvatarUrl(data.role, data.avatar_url));
          setUserRole("Secrétaire");

          break;
        case UserRole.ADMIN:
          setAvatarUrl(getAvatarUrl(data.role, data.avatar_url));
          setUserRole("Admin");

          break;
        case UserRole.SUPER_ADMIN:
          setAvatarUrl(getAvatarUrl(data.role, data.avatar_url));
          setUserRole("Super-Admin");

          break;
        default:
          break;
      }
    }

    if (data !== null && data && data.role && isUserAdmin) {
      switch (data.role) {
        case UserRole.CUSTOMER:
          setAvatarUrl(getAvatarUrl(data.role, data.avatar_url));
          setProfileRole("Client");

          break;
        case UserRole.SECRETARY:
          setAvatarUrl(getAvatarUrl(data.role, data.avatar_url));
          setProfileRole("Secrétaire");

          break;
        case UserRole.ADMIN:
          setAvatarUrl(getAvatarUrl(data.role, data.avatar_url));
          setProfileRole("Admin");

          break;
        case UserRole.SUPER_ADMIN:
          setAvatarUrl(getAvatarUrl(data.role, data.avatar_url));
          setProfileRole("Super-Admin");

          break;
        default:
          break;
      }
    }
  }, [data, isUserAdmin, role]);

  // Mutation pour update un utilisateur
  const [updateUser, { error, loading: isLoading }] =
    useMutation<UserUpdateInput>(EDIT_USER);

  // Mutation pour changer le role d'un admin à super-admin
  const [
    changeUserRole,
    { error: changeUserRoleError, loading: changeUserRoleLoading },
  ] = useMutation<UserUpdateInput>(CHANGE_USER_ROLE);

  // déclaration formulaire
  const {
    register,
    setValue,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onChange",
  }) as UseFormReturnWithErrors<FieldValues>;
  // Query
  const { data: dataFromQuery } = useQuery(
    GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES,
  );
  // query pour avoir la liste des types de tâches en fonction des catégories
  const getAllCategoryTaskTypeWithTaskTypes = dataFromQuery
    ? ([
        ...dataFromQuery.getAllCategoryTaskTypeWithTaskTypes,
      ] as GetAllCategoryTaskTypeWithTaskTypes[])
    : ([] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[]);

  // TaskType Select options
  const getOptions = (
    getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
  ) => {
    const optionsToDisplay: OptionsSelect[] = [];
    const categoryArray = [
      ...getAllCategory,
    ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    categoryArray &&
      categoryArray.map(
        (
          element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
        ) => {
          const subOptionsArray: Option[] = [];
          const tasktypes = [
            ...element.taskTypes,
          ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasktypes &&
            tasktypes.map(
              (
                tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
              ) => {
                subOptionsArray.push({
                  value: tasktype.id,
                  label: tasktype.label,
                });
                return tasktype;
              },
            );
          optionsToDisplay.push({
            label: element.label,
            options: subOptionsArray,
          });
          return element;
        },
      );
    return optionsToDisplay;
  };

  // méthode qui permet de selectionner tous les types de tâches quand on clique sur la catégorie
  const getOptionsForOneCategory = (
    getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
    categoryLabel: string,
  ) => {
    const optionsToDisplay: OptionsSelect[] = [];
    const categoryArray = [
      ...getAllCategory,
    ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const filterCategoryArray = categoryArray.filter(
      (element) => element.label === categoryLabel,
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    filterCategoryArray.length > 0 &&
      filterCategoryArray.map(
        (
          element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
        ) => {
          const subOptionsArray: Option[] = [];
          const tasktypes = [
            ...element.taskTypes,
          ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasktypes &&
            tasktypes.map(
              (
                tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
              ) => {
                subOptionsArray.push({
                  value: tasktype.id,
                  label: tasktype.label,
                });
                return tasktype;
              },
            );
          optionsToDisplay.push({
            label: element.label,
            options: subOptionsArray,
          });
          return element;
        },
      );

    return optionsToDisplay[0].options.flat();
  };
  // effet pour mettre à jour par défaut le formulaire
  useEffect(() => {
    if (data !== null) {
      // construction de la donnée entrante pour le formulaire
      if (data.taskTypes !== null) {
        const array: TaskType[] = [];
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        data?.taskTypes?.map((taskType: GetUserByID_getUserByID_taskTypes) => {
          array.push({
            value: taskType.id,
            label: taskType.label,
            disabled: false,
          });
          return taskType;
        });
        setValue("taskTypes", array);
      }
      if (data.activity_sector !== null) {
        setValue("activitySector", data.activity_sector);
      }
      if (data.last_name !== null) {
        setValue("lastName", data.last_name);
      }
      if (data.first_name !== null) {
        setValue("firstName", data.first_name);
      }
      if (data.geographical_area !== null) {
        setValue("geographicalArea", data.geographical_area);
      }
      if (data.siret !== null) {
        setValue("siret", data.siret);
      }
      if (data.business_name !== null) {
        setValue("businessName", data.business_name);
      }
      if (data.experience !== null) {
        setValue("experience", data.experience);
      }
      if (data.phone_number !== null) {
        setValue("phoneNumber", data.phone_number);
      }
    }
  }, [data, setValue]);
  const [modalUserRoleAdminToSuperAdmin, setModalUserRoleAdminToSuperAdmin] =
    useState<boolean>(false);

  // changement de role  a super admin pour un admin
  const changeRoleAdminToSuperAdmin = async () => {
    if (data !== null && data) {
      try {
        // Update USER
        await changeUserRole({
          variables: { adminId: data?.id },
          refetchQueries: [
            { query: ADMINS_LIST },
            { query: CUSTOMERS_LIST },
            { query: SECRETARIES_LIST },
            { query: GET_ONE_USER_BY_ID, variables: { id: data.id } },
          ],
        });
        setModalUserRoleAdminToSuperAdmin(false);
        navigate(`/profil/${data.id}`);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
    const successMessage = "Votre compte a bien été mis à jour";
    return successMessage;
  };

  // Check if email is already taken
  const [checkIfEmailAlreadyExist] = useLazyQuery(CHECK_IF_EMAIL_ALREADY_EXIST);
  const emailErrors = {
    emailAlreadyTaken: "Cet email est déjà pris",
  };

  // fonction de validation de formulaire pour mettre à jour un profil
  async function onSubmit(value: FieldValues) {
    const { email } = value;
    if (data !== null && data && value) {
      const userData: UserUpdateInput = {
        first_name: value.firstName ? value.firstName : data.first_name,
        last_name: value.lastName ? value.lastName : data.last_name,
        phone_number: value.phoneNumber ? value.phoneNumber : data.phone_number,
        email: value.email ? value.email : data.email,
        siret: value.siret ? value.siret : data.siret,
        activity_sector: value.activitySector
          ? value.activitySector
          : data.activity_sector,
        experience: value.experience ? value.experience : data.experience,
        business_name: value.businessName
          ? value.businessName
          : data.business_name,
        commentary: data.commentary,
        geographical_area: value.geographicalArea
          ? value.geographicalArea
          : data.geographical_area,
        taskTypeIds:
          value.taskTypes && value.taskTypes !== null
            ? value.taskTypes.map((taskType: TaskType) => taskType.value)
            : undefined,
        isVerified: data.isVerified,
        isActive: data.isActive,
        adminId: data.admin ? data.admin?.id : null,
      };

      try {
        // check if email is modified and already taken :
        if (email !== data.email) {
          const { data: isEmailTaken } = await checkIfEmailAlreadyExist({
            variables: { email },
          });
          if (isEmailTaken.checkIfEmailAlreadyExist) {
            setError("email", {
              type: "manual",
              message: emailErrors.emailAlreadyTaken,
            });
            return "L'email est déjà pris";
          }
        }

        // Update USER
        await updateUser({
          variables: { input: userData, userId: data?.id },
        });
        // Mise à jour du UserContext et du localStorage si l'email a été modifié
        if (email !== data.email) {
          const updatedUser = { ...user, email } as User;
          setUser(updatedUser);
          saveUser(updatedUser);
        }
        navigate(`/profil/${data.id}`);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return "Une erreur s'est produite lors de la mise à jour";
      }
    }
    return "Votre compte a bien été mis à jour";
  }

  // Définition des variables pour les textes du modal
  let confirmationText = "";
  let alertText = "";

  switch (data.role) {
    case UserRole.ADMIN:
      confirmationText = `Êtes-vous sûr(e) de vouloir passer à ${data.last_name.toUpperCase()} ${
        data.first_name
      } un rôle de super-admin?`;
      alertText = `Attention ceci implique que tous les utilisateurs qui avaient pour admin ${data.last_name.toUpperCase()} ${
        data.first_name
      } devront être réaffectés à un autre admin.`;
      break;
    case UserRole.SECRETARY:
      confirmationText = `Êtes-vous sûr(e) de vouloir passer à ${data.last_name.toUpperCase()} ${
        data.first_name
      } un rôle d'administrateur?`;
      alertText = `Les utilisateurs qui avaient pour secrétaire ${data.last_name.toUpperCase()} ${
        data.first_name
      } garderont ${data.last_name.toUpperCase()} ${
        data.first_name
      } en tant que secrétaire. Par contre l'administrateur de ces clients/secrétaires devra être changé, si nécessaire, manuellement grâce à l'intervention d'un super administrateur.`;
      break;
    default:
      // Valeurs par défaut ou rien
      break;
  }

  return data !== null && data ? (
    <div className="flex flex-col w-full p-5">
      <div className="mt-5 mb-3">
        <Card>
          <div className="flex flex-row">
            <div className="mr-10">
              <Avatar img={avatarUrl} alt={data?.role} size="xl" />
            </div>
            <div className="flex flex-col justify-center">
              <p className="font-medium text-xl">
                {data?.last_name.toUpperCase()} {data?.first_name}
              </p>
              <p className="text-gray-600 text-sm mb-3">
                Page de profil - {isUserAdmin ? profileRole : userRole}
              </p>
            </div>
          </div>
          {user.role === UserRole.SUPER_ADMIN && (
            <>
              {data.role === UserRole.ADMIN && (
                <button
                  type="button"
                  className="flex justify-center items-center button-add bg-red-400 p-2 font-semibold text-sm w-full sm:w-1/4"
                  onClick={() => setModalUserRoleAdminToSuperAdmin(true)}
                >
                  <FaClipboardList className="h-4 w-4 mr-2" />
                  {!changeUserRoleLoading
                    ? "passer en Super Admin"
                    : "Mise à jour ..."}
                </button>
              )}
              {data.role === UserRole.SECRETARY && (
                <button
                  type="button"
                  className="flex justify-center items-center button-add bg-red-400 p-2 font-semibold text-sm w-full sm:w-1/4"
                  onClick={() => setModalUserRoleAdminToSuperAdmin(true)}
                >
                  <FaClipboardList className="h-4 w-4 mr-2" />
                  {!changeUserRoleLoading
                    ? "passer en Admin"
                    : "Mise à jour ..."}
                </button>
              )}
            </>
          )}

          {changeUserRoleError ? <p>une erreur est survenue</p> : null}
          {/* modale de confirmation pour le changement de rôle d'un admin */}
          <Modal
            show={modalUserRoleAdminToSuperAdmin}
            popup
            onClose={() => setModalUserRoleAdminToSuperAdmin(false)}
          >
            <Modal.Body>
              <div className="flex flex-col p-5">
                <div className="flex flex-row">
                  <svg
                    aria-hidden="true"
                    fill="none"
                    stroke="red"
                    strokeWidth="1"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-10 h-10 mr-4"
                  >
                    <path
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="font-medium">{confirmationText}</p>
                </div>
                <div
                  className="flex p-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 mt-4"
                  role="alert"
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 inline w-5 h-5 mr-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Info</span>
                  <div>
                    <span className="font-medium">{alertText}</span>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm w-full sm:w-1/4"
                onClick={changeRoleAdminToSuperAdmin}
              >
                OK
              </button>
              <button
                type="button"
                className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm w-full sm:w-1/4"
                onClick={() => setModalUserRoleAdminToSuperAdmin(false)}
              >
                <p>Annuler</p>
              </button>
            </Modal.Footer>
          </Modal>
        </Card>
      </div>
      <div className="mb-3">
        <Card>
          <h3 className="font-bold"> Modification de vos informations</h3>
          <span className="font-light text-gray-500 text-xs">
            (*) champs obligatoires
          </span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
              <div className="mb-3 flex flex-col gap-y-3 w-full">
                <Label htmlFor="lastName" className="font-semibold">
                  Nom *
                </Label>
                <TextInput
                  id="lastName"
                  placeholder="nom"
                  type="text"
                  autoFocus
                  disabled={
                    (isUserAdmin &&
                      role === (UserRole.ADMIN || UserRole.SUPER_ADMIN)) ||
                    (user.role === UserRole.SUPER_ADMIN && user.id !== data.id)
                  }
                  defaultValue={data.last_name}
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <span
                    className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    Ce champ est requis
                  </span>
                )}
              </div>
              <div className="mb-3 flex flex-col gap-y-3 w-full">
                <Label htmlFor="firstName" className="font-semibold">
                  Prénom *
                </Label>
                <TextInput
                  id="firstName"
                  placeholder="prénom"
                  type="text"
                  defaultValue={data?.first_name}
                  disabled={
                    (isUserAdmin &&
                      role === (UserRole.ADMIN || UserRole.SUPER_ADMIN)) ||
                    (user.role === UserRole.SUPER_ADMIN && user.id !== data.id)
                  }
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <span
                    className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    Ce champ est requis
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
              <div className="mb-6 flex flex-col gap-y-3 w-full md:basis-1/2">
                <Label htmlFor="phoneNumber" className="font-semibold">
                  Téléphone *
                </Label>
                <TextInput
                  id="phoneNumber"
                  placeholder="0XXXXXX"
                  type="tel"
                  min={10}
                  disabled={
                    (isUserAdmin &&
                      role === (UserRole.ADMIN || UserRole.SUPER_ADMIN)) ||
                    (user.role === UserRole.SUPER_ADMIN && user.id !== data.id)
                  }
                  defaultValue={data?.phone_number}
                  {...register("phoneNumber", { required: true })}
                />
                {errors.phoneNumber && (
                  <span
                    className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    Ce champ est requis
                  </span>
                )}
              </div>
              {/* Adresse email */}
              <div className="mb-6 flex flex-col gap-y-3 w-full md:basis-1/2">
                <Label htmlFor="email" className="font-semibold">
                  Adresse Email
                </Label>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={data ? data.email : ""}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextInput
                      id="email"
                      placeholder="name@example.com"
                      type="email"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      disabled={user.role === UserRole.SECRETARY}
                    />
                  )}
                />
                {errors.email && typeof errors.email.message === "string" && (
                  <span
                    className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    {errors.email.message}
                  </span>
                )}
              </div>
            </div>
            {/* Raison sociale + siret */}
            {data.role === UserRole.ADMIN ||
            data.role === UserRole.SUPER_ADMIN ? null : (
              <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
                <div className="mb-6 flex flex-col gap-y-3 w-full">
                  <Label htmlFor="businessName" className="font-semibold">
                    Raison sociale
                  </Label>
                  <TextInput
                    id="businessName"
                    placeholder="..."
                    type="text"
                    disabled={
                      (isUserAdmin &&
                        role === (UserRole.ADMIN || UserRole.SUPER_ADMIN)) ||
                      (user.role === UserRole.SUPER_ADMIN &&
                        user.id !== data.id)
                    }
                    defaultValue={(data?.business_name as string) || ""}
                    {...register("businessName")}
                  />
                </div>
                <div className="mb-6 flex flex-col gap-y-3 w-full">
                  <Label htmlFor="siret" className="font-semibold">
                    SIRET
                  </Label>
                  <TextInput
                    id="siret"
                    placeholder="55217863900132"
                    type="text"
                    disabled={
                      (isUserAdmin &&
                        role === (UserRole.ADMIN || UserRole.SUPER_ADMIN)) ||
                      (user.role === UserRole.SUPER_ADMIN &&
                        user.id !== data.id)
                    }
                    defaultValue={(data?.siret as string) || ""}
                    {...register("siret")}
                  />
                </div>
              </div>
            )}

            {/* Zone géographique + Secteur d'activité (client) ou Expérience (secrétaire) */}
            <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
              {/* Zone géographique */}
              {data?.role !== UserRole.SUPER_ADMIN && (
                <div className="mb-3 flex flex-col gap-y-3 w-full md:basis-1/2">
                  <label
                    htmlFor="geographicalArea"
                    className="block text-sm text-gray-900 dark:text-white font-semibold"
                  >
                    Zone géographique *
                  </label>
                  <select
                    id="geographicalArea"
                    defaultValue={data?.geographical_area as string}
                    className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
                    {...register("geographicalArea", { required: true })}
                  >
                    <option value="">...</option>
                    {geographicalAreaOptions &&
                      geographicalAreaOptions.map((area: Area) => (
                        <option
                          key={area.num_dep}
                          value={`${area.num_dep.toString()} - ${
                            area.dep_name
                          } ${area.region_name}`}
                        >
                          {area.num_dep.toString()} - {area.dep_name} (
                          {area.region_name})
                        </option>
                      ))}
                  </select>
                  {errors.geographicalArea &&
                    errors.geographicalArea.type === "required" && (
                      <span
                        className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                        role="alert"
                      >
                        Ce champ est requis
                      </span>
                    )}
                </div>
              )}

              {/* Secteur d'activité (client) */}
              {(role === UserRole.CUSTOMER ||
                profileRole === "Client" ||
                (isUserAdmin &&
                  (role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN) &&
                  profileRole === "Client")) && (
                <div className="mb-3 flex flex-col gap-y-3 w-full md:basis-1/2">
                  <label
                    htmlFor="activitySector"
                    className="block text-sm text-gray-900 font-semibold"
                  >
                    Secteur d'activité
                  </label>
                  <select
                    id="activitySector"
                    defaultValue={data?.activity_sector as string}
                    className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
                    {...register("activitySector")}
                  >
                    <option value="">...</option>
                    {Object.values(activitySectors)
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((activity) => (
                        <option key={activity.label} value={activity.label}>
                          {activity.label}
                        </option>
                      ))}
                  </select>
                </div>
              )}

              {/* Expérience (secrétaire) */}
              {profileRole !== "Client" &&
                (role === UserRole.SECRETARY ||
                  profileRole === "Secrétaire" ||
                  role === UserRole.ADMIN ||
                  profileRole === "Admin" ||
                  (isUserAdmin &&
                    role === UserRole.SUPER_ADMIN &&
                    profileRole === "Secrétaire")) && (
                  <div className="mb-3 flex flex-col gap-y-3 w-full md:basis-1/2">
                    <label
                      htmlFor="experience"
                      className="block text-sm text-gray-900 font-semibold"
                    >
                      Expérience *
                    </label>
                    <select
                      id="experience"
                      defaultValue={data?.experience as string}
                      className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
                      {...register("experience", { required: true })}
                    >
                      <option value="">...</option>
                      {Object.values(Experience).map((exp) => (
                        <option key={exp} value={exp}>
                          {exp}
                        </option>
                      ))}
                    </select>
                    {errors.experience &&
                      errors.experience.type === "required" && (
                        <span
                          className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                          role="alert"
                        >
                          Ce champ est requis
                        </span>
                      )}
                  </div>
                )}
            </div>

            {/* Type de taches */}
            {data.role !== UserRole.SUPER_ADMIN ||
            (isUserAdmin &&
              role === (UserRole.ADMIN || UserRole.SUPER_ADMIN)) ? (
              <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
                <div className="mb-6 flex flex-col gap-y-3 w-full">
                  <label
                    htmlFor="taskTypes"
                    className="block text-sm text-gray-900 font-semibold"
                  >
                    {data?.role === UserRole.CUSTOMER
                      ? "Compétences recherchées *"
                      : `Type de tâche que ${data?.first_name} peut accomplir *`}
                  </label>
                  <Controller
                    name="taskTypes"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={data?.taskTypes || []}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        onChange={(newValue: any) => {
                          onChange(newValue);
                        }}
                        options={getOptions(
                          getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                        )}
                        isMultiple
                        isClearable
                        isSearchable
                        classNames={{
                          tagItemText: `text-gray-500 text-xs sm:text-sm truncate rounded-lg`,
                        }}
                        // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-shadow
                        formatGroupLabel={(data: OptionsSelect) => (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                          <div
                            className="py-2 text-xs flex items-center justify-between"
                            onClick={() => {
                              if (value !== null && value && value.length > 0) {
                                onChange(
                                  value.concat(
                                    getOptionsForOneCategory(
                                      getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                                      data.label,
                                    ),
                                  ) as any,
                                );
                              } else {
                                onChange(
                                  getOptionsForOneCategory(
                                    getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                                    data.label,
                                  ) as any,
                                );
                              }
                            }}
                          >
                            <span className="font-bold">{data.label}</span>
                            <span className="bg-gray-200 h-5 p-1.5 flex items-center justify-center rounded-full">
                              {data.options.length}
                            </span>
                          </div>
                        )}
                        primaryColor="teal"
                        placeholder="Sélectionnez ..."
                        noOptionsMessage="Aucune option disponible"
                      />
                    )}
                  />
                  {errors.taskTypes && errors.taskTypes.type === "required" && (
                    <span
                      className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                      role="alert"
                    >
                      Sélectionnez au moins une compétence
                    </span>
                  )}
                </div>
              </div>
            ) : null}

            {/* Commentaire */}
            {/* <div className="mb-6 flex flex-col gap-y-3 w-full">
              <label
                htmlFor="commentary"
                className="block text-sm text-gray-900 font-semibold"
              >
                Ajouter un commentaire
              </label>
              <textarea
                id="commentary"
                rows={4}
                defaultValue={(data?.commentary as string) || ""}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-add-blue-dark focus:border-add-blue-dark"
                placeholder="Écrivez votre commentaire ici..."
                {...register("commentary")}
              />
            </div> */}

            <button
              type="submit"
              className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm w-full sm:w-1/4 mt-10"
            >
              <FaClipboardList className="h-4 w-4 mr-2" />

              {isLoading ? "Modification de votre compte ..." : "Enregistrer"}
            </button>

            {error && (error.networkError as ApolloError) && (
              <span
                className="p-2 text-sm text-red-800 rounded-lg bg-red-50"
                role="alert"
              >
                "Une erreur est survenue. Veuillez réessayer plus tard."
              </span>
            )}
          </form>
        </Card>
      </div>
    </div>
  ) : null;
}
