/**
 * Convertit des heures décimales en un format d'heures et de minutes.
 *
 * @param {number | null} decimalHours - Les heures en format décimal (par exemple, 1.25 pour 1 heure et 15 minutes).
 * @returns {string} Les heures et minutes formatées sous la forme "1h15".
 */
export default function getFormattedHours(decimalHours: number | null = 0) {
  // Vérifie si decimalHours est null.
  if (decimalHours === null) {
    // Si c'est le cas, retourne "0h".
    return "0h";
  }

  // Obtient la partie entière des heures décimales, qui représente les heures complètes.
  const hours = Math.trunc(decimalHours);

  // Calcul des minutes en utilisant la valeur absolue de decimalHours et hours,
  // pour éviter d'obtenir un nombre négatif de minutes.
  const minutes = Math.round((Math.abs(decimalHours) - Math.abs(hours)) * 60);

  // Formate les heures et les minutes dans une chaîne de caractères, en ajoutant un zéro avant les minutes si nécessaire.
  // méthode padStart pour s'assurer que les minutes sont toujours affichées avec deux chiffres, même quand il y a moins de 10 minutes.
  // Vérifie si les minutes sont égales à zéro.
  if (minutes === 0) {
    // Si les minutes sont zéro, retourne juste les heures.
    return `${hours}h`;
  }
  // Sinon, formate les heures et les minutes dans une chaîne de caractères.
  return `${hours}h${minutes.toString().padStart(2, "0")}"`;
}

// Utilisation
// const formattedHourlyAmount = formatHours(item.hourlyAmount);
// console.log(formattedHourlyAmount);  // Affiche "1h15" si item.hourlyAmount vaut 1.25
