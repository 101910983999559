/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Card, Label, Modal, TextInput } from "flowbite-react";
import Select from "react-tailwindcss-select";
import { useMutation, useQuery } from "@apollo/client";
import { FaEdit, FaPhotoVideo, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  GetAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
  GetUserByID_getUserByID,
  UserRole,
} from "../../API/models/types";
import {
  Area,
  Experience,
} from "../../components/OnBoardingSteps/SecondStepForm";
import geographicalAreaOptions from "../../API/data/departements-region.json";
import activitySectors from "../../API/data/activity-sectors.json";
import { OptionsSelect, Option } from "../models/models";
import { GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES } from "../../API/modules/category-task-type/resolvers/queries";
import { UserContext } from "../../context/userContext";
import getAvatarUrl from "../utils/getAvatarUrl";
import { GET_ONE_USER_BY_ID } from "../../API/modules/user/resolvers/queries";
import {
  REMOVE_AVATAR,
  UPLOAD_AVATAR,
} from "../../API/modules/user/resolvers/mutation";

interface Props {
  data: GetUserByID_getUserByID;
}
export default function UserProfileView(props: Props) {
  const { data } = props;

  const { user } = useContext(UserContext);

  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  // profil à afficher
  const [profileRole, setProfileRole] = useState<string>("");
  // ouverture modale de confirmation pour supprimer l' avatar
  const [openRemoveAvatarModal, setOpenRemoveAvatarModal] =
    useState<boolean>(false);

  // ref input de type file
  const fileInputRef = useRef<HTMLInputElement>(null);

  // mutations
  const [uploadFileMutation] = useMutation(UPLOAD_AVATAR);
  const [removeAvatarUrl] = useMutation(REMOVE_AVATAR);

  useEffect(() => {
    if (data !== null && data && data.role !== null) {
      setAvatarUrl(getAvatarUrl(data.role, data.avatar_url));
      switch (data.role) {
        case UserRole.CUSTOMER:
          setProfileRole("Client");

          break;
        case UserRole.SECRETARY:
          setProfileRole("Secrétaire");

          break;
        case UserRole.ADMIN:
          setProfileRole("Admin");

          break;
        case UserRole.SUPER_ADMIN:
          setProfileRole("Super-Admin");

          break;
        default:
          break;
      }
    }
  }, [data]);

  const { data: dataFromQuery } = useQuery(
    GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES,
  );
  const getAllCategoryTaskTypeWithTaskTypes = dataFromQuery
    ? ([
        ...dataFromQuery.getAllCategoryTaskTypeWithTaskTypes,
      ] as GetAllCategoryTaskTypeWithTaskTypes[])
    : ([] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[]);
  // TaskType Select options
  const getOptions = (
    getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
  ) => {
    const optionsToDisplay: OptionsSelect[] = [];
    const categoryArray = [
      ...getAllCategory,
    ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    categoryArray &&
      categoryArray.map(
        (
          element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
        ) => {
          const subOptionsArray: Option[] = [];
          const tasktypes = [
            ...element.taskTypes,
          ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasktypes &&
            tasktypes.map(
              (
                tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
              ) => {
                subOptionsArray.push({
                  value: tasktype.id,
                  label: tasktype.label,
                });
                return tasktype;
              },
            );
          optionsToDisplay.push({
            label: element.label,
            options: subOptionsArray,
          });
          return element;
        },
      );

    return optionsToDisplay;
  };

  // REMOVE
  const handleTrashClick = async () => {
    try {
      const response = await removeAvatarUrl({
        variables: {
          userId: data.id,
        },
        refetchQueries: [
          { query: GET_ONE_USER_BY_ID, variables: { id: data.id } },
        ],
      });

      console.log("Suppression de l'avatar réussi.", response);
      setErrorMessage(undefined);
      setOpenRemoveAvatarModal(false);
    } catch (error: any) {
      console.error("Erreur lors de la suppression de l'avatar.", error);
      setErrorMessage(
        error.message || "Erreur lors du téléchargement du fichier.",
      );
    }
  };

  // UPLOAD
  // fonction qui vient upload le fichier sélectionner et enregistrer l'avatar-url de l'utilisateur dans la bdd
  const uploadSelectedFile = async (file: any) => {
    try {
      await uploadFileMutation({
        variables: {
          file,
          userId: data.id,
        },
        refetchQueries: [
          { query: GET_ONE_USER_BY_ID, variables: { id: data.id } },
        ],
      });
      setErrorMessage(undefined);
    } catch (error: any) {
      console.log("Erreur lors du téléchargement du fichier.", error);
      setErrorMessage(
        error.message || "Erreur lors du téléchargement du fichier.",
      );
    }
  };

  // fonction qui retourne le fichier sélectionné
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { files } = event.target;
    if (files) {
      const selectedFile = files[0];
      // Convertir la taille du fichier de bytes à megabytes
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      const MAX_FILE_SIZE = 2; // La taille maximale du fichier en MB
      if (fileSizeInMB > MAX_FILE_SIZE) {
        setErrorMessage("Le fichier dépasse la limite de poids autorisée. 2mo");
      } else if (selectedFile) {
        uploadSelectedFile(selectedFile);
      }
    }
  };

  // fonction qui déclenche la selection du fichier via la ref sur l'input de type file qui est caché.
  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (fileInputRef.current?.click) {
      fileInputRef.current.click();
    }
  };

  return data !== null ? (
    <div className="flex flex-col w-full p-5">
      <div className="mt-5 mb-3">
        <Card>
          <div className="flex flex-row">
            {avatarUrl !== null ? (
              <div className="mr-10">
                <Avatar img={avatarUrl as string} alt={data?.role} size="xl" />
              </div>
            ) : null}
            <div className="flex flex-col justify-center">
              <p className="font-medium text-xl">
                {data?.last_name.toUpperCase()} {data?.first_name}
              </p>
              <p className="text-gray-600 text-sm mb-3">
                Page de profil - {profileRole}
              </p>
              {user !== null && user.id === data.id ? (
                <div className="flex sm:flex-row items-center">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={(event) => handleFileChange(event)}
                  />

                  <button
                    className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm mr-5"
                    type="button"
                    onClick={(event) => handleButtonClick(event)}
                  >
                    <FaPhotoVideo className="h-4 w-4 mr-2" />
                    Changer de photo
                  </button>

                  {data.avatar_url !== null && (
                    <FaTrashAlt
                      className="text-add-blue-dark"
                      onClick={() => setOpenRemoveAvatarModal(true)}
                    />
                  )}
                </div>
              ) : null}
              {errorMessage ? (
                <p className="text-red-500 text-xs mt-6">{errorMessage}</p>
              ) : null}
            </div>
          </div>
        </Card>
      </div>
      <div className="mb-3">
        <Card>
          <h3 className="font-bold md:mb-8">Informations générales</h3>
          <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
            <div className="mb-3 flex flex-col gap-y-2 w-full">
              <Label htmlFor="lastName" className="font-semibold">
                Nom
              </Label>
              <TextInput
                id="lastName"
                placeholder="nom"
                type="text"
                value={data?.last_name ? data?.last_name : "non renseigné"}
                disabled
              />
            </div>
            <div className="mb-3 flex flex-col gap-y-2 w-full">
              <Label htmlFor="firstName" className="font-semibold">
                Prénom
              </Label>
              <TextInput
                id="firstName"
                placeholder="prénom"
                type="text"
                value={data?.first_name ? data?.first_name : "non renseigné"}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
            <div className="flex flex-col gap-y-2 w-full">
              <Label htmlFor="phoneNumber" className="font-semibold">
                Téléphone
              </Label>
              <TextInput
                id="phoneNumber"
                placeholder="0XXXXXX"
                type="tel"
                min={10}
                value={
                  data?.phone_number ? data?.phone_number : "non renseigné"
                }
                disabled
              />
            </div>
            <div className="flex flex-col gap-y-2 w-full">
              <Label htmlFor="email" className="font-semibold">
                Adresse Email
              </Label>
              <TextInput
                id="email"
                placeholder="name@example.com"
                type="email"
                value={data ? data.email : "non renseigné"}
                disabled
              />
            </div>
          </div>

          {/* Raison sociale + siret */}
          <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
            {/* Raison sociale */}
            {data?.role === UserRole.CUSTOMER ||
            data?.role === UserRole.SECRETARY ? (
              <div className="mb-3 flex flex-col gap-y-2 w-full">
                <Label htmlFor="businessName" className="font-semibold">
                  Raison sociale
                </Label>
                <TextInput
                  id="businessName"
                  placeholder="..."
                  type="text"
                  value={
                    data?.business_name
                      ? (data.business_name as string)
                      : "non renseignée"
                  }
                  disabled
                />
              </div>
            ) : null}

            {/* SIRET */}
            {data?.role === UserRole.CUSTOMER ||
            data?.role === UserRole.SECRETARY ? (
              <div className="mb-3 flex flex-col gap-y-2 w-full">
                <Label htmlFor="siret" className="font-semibold">
                  SIRET
                </Label>
                <TextInput
                  id="siret"
                  placeholder="55217863900132"
                  type="text"
                  value={
                    data?.siret ? (data?.siret as string) : "non renseigné"
                  }
                  disabled
                />
              </div>
            ) : null}
          </div>

          {/* Zone géographique + Secteur d'activité (client) ou Expérience (secrétaire) */}

          <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6justify-around">
            {/* Zone géographique */}
            {data?.role !== UserRole.SUPER_ADMIN && (
              <div className="mb-3 flex flex-col gap-y-2 w-full md:basis-1/2">
                <label
                  htmlFor="geographicalArea"
                  className="block text-sm text-gray-900 dark:text-white font-semibold"
                >
                  Zone géographique
                </label>
                <select
                  id="geographicalArea"
                  className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
                  value={data?.geographical_area ? data?.geographical_area : ""}
                  disabled
                >
                  <option value="">non renseignée</option>
                  {geographicalAreaOptions &&
                    geographicalAreaOptions.map((area: Area) => (
                      <option
                        key={area.num_dep}
                        value={`${area.num_dep.toString()} - ${area.dep_name} ${
                          area.region_name
                        }`}
                      >
                        {area.num_dep.toString()} - {area.dep_name} (
                        {area.region_name})
                      </option>
                    ))}
                </select>
              </div>
            )}

            {/* Secteur d'activité (client) */}
            {data?.role === UserRole.CUSTOMER && (
              <div className="mb-3 flex flex-col gap-y-2 w-full md:basis-1/2">
                <label
                  htmlFor="activitySector"
                  className="block text-sm text-gray-900 font-semibold"
                >
                  Secteur d'activité
                </label>
                <select
                  id="activitySector"
                  value={
                    data?.activity_sector
                      ? (data?.activity_sector as string)
                      : ""
                  }
                  disabled
                  className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
                >
                  <option value="">non renseigné</option>
                  {Object.values(activitySectors)
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((activity) => (
                      <option key={activity.label} value={activity.label}>
                        {activity.label}
                      </option>
                    ))}
                </select>
              </div>
            )}

            {/* Expérience (secrétaire) */}
            {data?.role === UserRole.SECRETARY ||
            data?.role === UserRole.ADMIN ? (
              <div className="mb-3 flex flex-col gap-y-2 w-full md:basis-1/2">
                <label
                  htmlFor="experience"
                  className="block text-sm text-gray-900 font-semibold"
                >
                  Expérience
                </label>
                <select
                  id="experience"
                  value={data?.experience ? (data?.experience as string) : ""}
                  disabled
                  className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-add-blue-dark focus:border-add-blue-dark block w-full p-2.5"
                >
                  <option value="">non renseignée</option>
                  {Object.values(Experience).map((exp) => (
                    <option key={exp} value={exp}>
                      {exp}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
          </div>
          {/* Type de taches */}
          {data?.role !== UserRole.SUPER_ADMIN ? (
            <div
              className="flex flex-col gap-x-3 lg:gap-x-6 justify-around"
              id="selectUserTasksType"
            >
              {data?.role === UserRole.CUSTOMER ? (
                <label
                  htmlFor="taskTypes"
                  className="block text-sm text-gray-900 font-semibold mb-3"
                >
                  Compétence(s) que {data?.first_name} recherche :
                </label>
              ) : (
                <label
                  htmlFor="taskTypes"
                  className="block text-sm text-gray-900 font-semibold mb-3"
                >
                  Type de tâche que {data?.first_name} peut accomplir :
                </label>
              )}
              {/* Non renseignée quand pas de type de tâche */}
              {data?.taskTypes !== null && data?.taskTypes.length > 0 && (
                <Select
                  classNames={{
                    tagItemText: `bg-green-200 border p-2 text-gray-500 text-xs rounded-lg`,
                    tagItemIcon: "hidden",

                    tagItem: ({ isDisabled }) =>
                      isDisabled
                        ? "border-none bg-white-100 mr-2"
                        : "bg-white-100",
                    tagItemIconContainer: "hidden",
                    searchContainer: "bg-white hidden",
                    searchIcon: "hidden opacity-0",
                    searchBox: "hidden",
                    closeIcon: "hidden",
                    ChevronIcon: ({ open }) =>
                      open ? "fill-transparent" : "fill-transparent",
                    menuButton: ({ isDisabled }) =>
                      isDisabled
                        ? "bg-white-100 border-none"
                        : "bg-white-100 hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20",
                  }}
                  value={data?.taskTypes as any}
                  isMultiple
                  isDisabled
                  isSearchable
                  isClearable
                  primaryColor="teal"
                  placeholder="Sélectionnez ..."
                  options={getOptions(
                    getAllCategoryTaskTypeWithTaskTypes as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
                  )}
                  onChange={() => null}
                />
              )}
              {data?.taskTypes !== null &&
                data?.taskTypes &&
                data?.taskTypes.length === 0 && (
                  <Select
                    classNames={{
                      tagItemText: `bg-green-200 border p-2 text-gray-500 text-xs rounded-lg`,
                      tagItemIcon: "hidden",

                      tagItem: ({ isDisabled }) =>
                        isDisabled
                          ? "border-none bg-white-100 mr-2"
                          : "bg-white-100",
                      tagItemIconContainer: "hidden",
                      searchContainer: "bg-white hidden",
                      searchIcon: "hidden opacity-0",
                      searchBox: "hidden",
                      closeIcon: "hidden",
                      ChevronIcon: ({ open }) =>
                        open ? "hidden opacity-0" : "",
                      menuButton: ({ isDisabled }) =>
                        isDisabled
                          ? "bg-white-100 border-none"
                          : "bg-white-100 hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20",
                    }}
                    isMultiple
                    isDisabled
                    isSearchable
                    isClearable
                    value={[{ value: "", label: "non renseigné" }]}
                    primaryColor="teal"
                    placeholder="Sélectionnez ..."
                    options={[{ value: "", label: "non renseigné" }]}
                    onChange={() => null}
                  />
                )}
            </div>
          ) : null}
          {(user !== null &&
            user.id !== data.id &&
            user.id === data.admin?.id) ||
          (user.role === UserRole.SUPER_ADMIN && user.id !== data.id) ? (
            <Link to={`/modifier-mon-compte/${data.id}`}>
              <button
                className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm mb-3 w-full sm:w-1/3 md:w-1/4 xl:w-1/6"
                type="button"
              >
                <FaEdit className="h-4 w-4 mr-2" />
                Modifier compte
              </button>
            </Link>
          ) : null}
        </Card>
      </div>
      {/* modale de confirmation pour la suppression de l'avatar */}
      <Modal
        show={openRemoveAvatarModal}
        popup
        onClose={() => setOpenRemoveAvatarModal(false)}
      >
        <Modal.Body>
          <div className="flex flex-col p-5">
            <div className="flex flex-row items-center">
              <svg
                aria-hidden="true"
                fill="none"
                stroke="red"
                strokeWidth="1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10 mr-4"
              >
                <path
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="font-medium">
                Êtes-vous sûr(e) de vouloir supprimer l'avatar actuel ?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm w-full sm:w-1/4"
            onClick={handleTrashClick}
          >
            OK
          </button>
          <button
            type="button"
            className="flex justify-center items-center button-add bg-add-blue-dark p-2 font-semibold text-sm w-full sm:w-1/4"
            onClick={() => setOpenRemoveAvatarModal(false)}
          >
            Annuler
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  ) : (
    <div className="flex flex-col w-full p-5" />
  );
}
