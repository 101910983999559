import { useState } from "react";
import { UserRole } from "../API/models/types";
// eslint-disable-next-line import/no-cycle
import { IContextUser, emptyUser } from "../context/userContext";

export interface User {
  token: string;
  email: string;
  isActive: boolean;
  role: UserRole;
  id: string;
  avatarUrl: string;
}

interface UserService {
  saveUser: (user: User) => void;
  getUser: () => IContextUser;
  removeUser: () => void;
}

function saveUserToLocalStorage(user: IContextUser) {
  localStorage.setItem("add-user", JSON.stringify(user));
}

function getUserFromLocalStorage(): IContextUser {
  const userString = localStorage.getItem("add-user");
  if (userString) {
    return JSON.parse(userString);
  }
  return emptyUser;
}

function removeUserFromLocalStorage() {
  localStorage.removeItem("add-user");
}

export default function useLocalStorageService(): UserService {
  const [user, setUser] = useState<IContextUser>(getUserFromLocalStorage());

  function saveUser(userToKeep: IContextUser) {
    saveUserToLocalStorage(userToKeep);
    setUser(userToKeep);
  }

  function getUser(): IContextUser {
    return user;
  }

  function removeUser() {
    removeUserFromLocalStorage();
    setUser(emptyUser);
  }

  return { saveUser, getUser, removeUser };
}
