import React from "react";

function IsTestingEnvironment() {
  return (
    <div className="fixed self-center top-0">
      <span className="px-2 py-1 bg-red-600 text-red-200 font-semibold rounded-b-md md:text-sm text-xs">
        Environnement de test
      </span>
    </div>
  );
}

export default IsTestingEnvironment;
