import React, { ChangeEvent, useState, useEffect } from "react";

interface Props {
  setDuration: (value: string) => void;
  // eslint-disable-next-line react/require-default-props
  defaultValue?: string;
  id: string;
  label: string;
}
export default function SelectDuration(props: Props) {
  const { setDuration, defaultValue, id, label } = props;

  const [durationSelected, setDurationSelected] = useState<string>("");

  // Mettre à jour l'état local lorsque defaultValue change
  useEffect(() => {
    setDurationSelected(defaultValue || "");
  }, [defaultValue]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setDurationSelected(value);
    setDuration(value);
  };

  // Création d'un tableau des valeurs d'heures en quarts d'heure
  const hoursArray = Array.from({ length: 33 }, (_, i) =>
    (i * 0.25).toFixed(2),
  ).filter((hour) => hour !== "0.00");

  return (
    <div className="flex flex-col">
      <label htmlFor={id} className="block mb-2 text-sm font-semibold">
        {label}
      </label>
      <select
        id="estimated-duration"
        className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
        onChange={handleChange}
        value={durationSelected}
      >
        <option value="" className="bg-add-green-light">
          Choisir une durée
        </option>
        {hoursArray.map((hourString) => {
          const hour = parseFloat(hourString);
          const wholeHours = Math.floor(hour);
          const minutes = (hour - wholeHours) * 60;
          const displayValue = `${wholeHours}h${minutes
            .toString()
            .padStart(2, "0")}"`;

          return (
            <option key={`${hour}`} value={hour}>
              {displayValue}
            </option>
          );
        })}
      </select>
    </div>
  );
}
