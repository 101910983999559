import { Card } from "flowbite-react";
import React, { useCallback, useContext } from "react";
import { ApolloError } from "@apollo/client";
import ReactPaginate from "react-paginate";
import tasksIllustration from "../../../assets/illustrations/img-taches.png";
import {
  UserRole,
  TaskStatus,
  FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin,
  FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks,
} from "../../../API/models/types";
import SpinnerComponent from "../../../components/shared/SpinnerComponent";

import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../context/FilterContext";
import SuperAdminTaskList from "./table/SuperAdminTaskList";
import { FilterTask, ItemTask } from "../../models/models";
import { FindAllPaginateTasksForSuperAdmin } from "../../utils/QueriesTasks";
import DownloadTasksCsvButton from "../../../components/shared/DownloadTasksCsvButton";
import { UserContext } from "../../../context/userContext";
import SomethingHappened from "../../SomethingHappened";
import getFormattedHours from "../../Calendar/utils/getFormattedHours";

export default function SuperAdminTask() {
  // contexte user
  const { user } = useContext(UserContext);
  // contexte filtre

  const { filters, setFilters } =
    useContext<FilterTasksContextType>(FilterTasksContext);

  const {
    tasks,
    loading,
    error,
  }: {
    tasks: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin;
    loading: boolean;
    error: ApolloError | undefined;
  } =
    filters !== null
      ? FindAllPaginateTasksForSuperAdmin(filters)
      : {
          tasks: {
            tasks:
              [] as FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks[],
            total: 0,
            __typename: "OutputPaginateTasks",
          },
          loading: false,
          error: undefined,
        };

  const tasksDataForTable = useCallback(() => {
    const items: ItemTask[] = [];

    if (tasks && tasks.tasks.length > 0) {
      const tasksList = tasks && tasks.tasks;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tasksList.length; i++) {
        const item: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks =
          tasksList[i];

        items.push({
          id: item.id,
          label: `${item.label} : ${item.taskType.label}`,
          categoryTask: item.taskType.categoryTaskType.label as string,
          realDuration: item.realDuration
            ? getFormattedHours(item.realDuration)
            : "non renseigné",
          plannedDuration: item.plannedDuration
            ? getFormattedHours(item.plannedDuration)
            : "non renseigné",
          secretary: {
            id: item.secretary.id,
            userName: `${item.secretary.last_name.toUpperCase()} ${
              item.secretary.first_name
            }`,
            avatar: item.secretary.avatar_url as string,
            role: item.secretary.role as UserRole,
          },
          client: item.customer
            ? {
                id: item.customer.id,
                userName: `${item.customer.last_name.toUpperCase()} ${
                  item.customer.first_name
                }`,
                avatar: item.customer.avatar_url as string,
                role: item.customer.role as UserRole,
              }
            : null,
          author: {
            id: item.author.id,
            userName: `${item.author.last_name.toUpperCase()} ${
              item.author.first_name
            }`,
            avatar: item.author.avatar_url as string,
            role: item.author.role as UserRole,
          },
          date: new Date(item.startDate),
          status: item.status
            ? (item.status as TaskStatus)
            : TaskStatus.PENDING,
          // pour filtrer sur le nom colonne hidden
          userNameSecretary: `${item.secretary.last_name.toUpperCase()} ${
            item.secretary.first_name
          }`,
          userNameClient: item.customer
            ? `${item.customer.last_name.toUpperCase()} ${
                item.customer.first_name
              }`
            : "",
        });
      }
    }
    return items;
  }, [tasks]);
  // nombre de ligne par page de tableau
  const ROWBYPAGE: number = 6;

  // calcul nombre de page
  const nbPageFloat: number = tasks.total && tasks.total / ROWBYPAGE;

  // nombre de page arrondi à l' entier supérieur
  const nbPage = Math.ceil(nbPageFloat);

  const handlePageChange = (event: any) => {
    if (filters && setFilters) {
      setFilters({
        ...filters,
        page: event.selected + 1,
      });
    }
    localStorage.setItem(
      "myTasksListPage",
      JSON.stringify({ page: event.selected + 1 }),
    );
  };
  return !error ? (
    <main className="flex flex-col">
      <header className="flex flex-col h-1/7 lg:max-w-[50%] justify-items-start items-center relative">
        <div className="basis-2/12 flex">
          <img src={tasksIllustration} alt="tasks list" className="w-full" />
        </div>
        <div className="basis-9/12 p-10 sm:p-0 absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-2xl xl:text-3xl">Liste des tâches</h1>
        </div>
      </header>

      {!loading ? (
        <main className="flex w-full h-4/7 p-5">
          <Card className="overflow-auto flex gap-1 w-full p-3 min-h-full">
            <div className="flex min-h-3/4 relative">
              <SuperAdminTaskList
                data={tasksDataForTable()}
                tasks={
                  tasks.tasks as FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks[]
                }
              />
              <div className="absolute top-0 right-1 sm:-right-40 lg:-right-28 xl:right-0">
                {filters && user ? (
                  <DownloadTasksCsvButton
                    filters={filters as FilterTask}
                    fileName="liste-tâches"
                    userRole={UserRole.SUPER_ADMIN as UserRole}
                    userId={user.id as string}
                  />
                ) : null}
              </div>
            </div>

            <div className="flex flex-row justify-left text-center">
              <div className="basis-4/5 text-left text-sm">
                <span className="items-center gap-1 flex flex-row p-4">
                  <p className="text-gray-400">Page</p>
                  <strong className="text-add-blue-dark">
                    {filters && filters.page}
                  </strong>
                  <p className="text-gray-400">sur</p>
                  {nbPage === 0 ? (
                    <strong className="text-add-blue-dark">1</strong>
                  ) : (
                    <strong className="text-add-blue-dark"> {nbPage}</strong>
                  )}
                </span>
              </div>
              <div className="basis-1/5">
                {nbPage !== 0 ? (
                  <ReactPaginate
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={ROWBYPAGE}
                    pageCount={nbPage}
                    forcePage={filters.page ? filters.page - 1 : 0}
                    marginPagesDisplayed={2}
                    nextLabel=">"
                    containerClassName="pagination"
                    previousLinkClassName="pagination__link"
                    nextLinkClassName="pagination__link"
                    disabledClassName="pagination__link--disabled"
                    activeClassName="pagination__link--active"
                    previousLabel="<"
                    onPageActive={() => filters.page}
                  />
                ) : null}
              </div>
            </div>
          </Card>
        </main>
      ) : (
        <SpinnerComponent />
      )}
    </main>
  ) : (
    <SomethingHappened />
  );
}
