// import React from "react";

// export default function TaskStatusCell(value: string) {
//   let className = "rounded-lg px-3 text-sm font-medium w-fit";

//   switch (value) {
//     case "À réaliser":
//       className += " bg-purple-100 text-purple-800";
//       break;
//     case "Complétée":
//       className += " bg-green-100 text-green-800";
//       break;
//     case "Non réalisée":
//       className += " bg-red-100 text-red-800";
//       break;
//     default:
//       break;
//   }

//   return (
//     <div className={className}>
//       <p>{value}</p>
//       </div>
//   );
// }
import { Badge } from "flowbite-react";
import { TaskStatus } from "../../../API/models/types";

export default function TaskStatusCell(status: TaskStatus) {
  return (
    <div className="flex item-center ">
      {status === TaskStatus.TODO ? (
        <Badge size="sm" color="purple">
          À réaliser
        </Badge>
      ) : null}

      {status === TaskStatus.UNREALIZED ? (
        <Badge size="sm" color="failure">
          Non réalisée
        </Badge>
      ) : null}
      {status === TaskStatus.COMPLETED ? (
        <Badge size="sm" color="success">
          Complétée
        </Badge>
      ) : null}
      {status === TaskStatus.PENDING ? (
        <Badge size="sm" color="info">
          En attente
        </Badge>
      ) : null}
    </div>
  );
}
