import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { IContextUser } from "../context/userContext";
import useLocalStorageService from "../hooks/useLocalStorage";
import { CHECK_JWT } from "../API/modules/auth/mutation";
import { UserRole } from "../API/models/types";

interface CheckJwtResult {
  checkToken: boolean;
}

interface ProtectedRouteProps {
  user: IContextUser;
  redirectPath: string;
  outlet: JSX.Element;
  allowedRoles: UserRole[];
  currentUserRole: UserRole;
}

export default function ProtectedRoute({
  user,
  redirectPath = "/se-connecter",
  outlet,
  allowedRoles = [],
  currentUserRole,
}: ProtectedRouteProps) {
  const navigate = useNavigate();
  const { getUser } = useLocalStorageService();
  const userFromLocalStorage = getUser();

  const [checkJwt] = useMutation<CheckJwtResult>(CHECK_JWT);
  const [token, setToken] = useState("");
  const [userTokenIsValid, setUserTokenIsValid] = useState(false);
  const [isActiveUser, setIsActiveUser] = useState(false);
  // const [userRole, setUserRole] = useState("");

  // On récupère la valeur du token provenant du localstorage ou du context
  useEffect(() => {
    if (user.token) {
      setToken(user.token);
    } else if (userFromLocalStorage?.token) {
      setToken(userFromLocalStorage?.token);
    }
  }, [user.token, userFromLocalStorage?.token, setToken]);

  // On vérifie la validité du token
  useEffect(() => {
    async function checkTokenValidity() {
      if (token) {
        try {
          const { data } = await checkJwt({
            variables: {
              token: { token },
            },
          });
          if (data && data.checkToken === true) {
            setUserTokenIsValid(true);
          }
        } catch (err: any) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
    checkTokenValidity();
  }, [token, checkJwt, setUserTokenIsValid, userTokenIsValid]);

  // On vérifie si le statut du user isActive est à true
  useEffect(() => {
    if (user.isActive === true || userFromLocalStorage?.isActive === true) {
      setIsActiveUser(true);
    } else {
      setIsActiveUser(false);
    }
  }, [user.isActive, userFromLocalStorage?.isActive]);

  // Si l'utilisateur a bien un token mais qu'il n'est pas actif
  // On le redirige vers la page des étapes d'inscription
  useEffect(() => {
    if (token && isActiveUser === false) {
      navigate("/etapes-inscription", { replace: true });
    }
  }, [token, isActiveUser, navigate]);

  // Si le role ne correspond aux rôles accessibles pour la route donnée
  // On redirige vers la page d'acceuil (dashboard)
  useEffect(() => {
    if (!allowedRoles.includes(currentUserRole) && currentUserRole !== null) {
      navigate("/");
    }
  }, [currentUserRole, allowedRoles, navigate]);

  // Si l'utilisateur n'a pas de token on le redirige vers la page login
  if (!user.token && !userFromLocalStorage?.token) {
    return <Navigate to={redirectPath} replace />;
  }

  return outlet || <Outlet />;
}
