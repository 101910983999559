import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { HiPhone } from "react-icons/hi";
import { ImSmile2 } from "react-icons/im";

interface Props {
  icon: string;
  label: string;
  commentary: string;
  text: string;
}

function RegistrationStep(props: Props) {
  const { icon, label, commentary, text } = props;

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        {icon === "check" && (
          <BsFillCheckCircleFill className="w-8 h-8 lg:w-12 lg:h-12 text-green-600 p-2 bg-green-100 rounded-full m-2" />
        )}
        {icon === "phone" && (
          <HiPhone className="w-8 h-8 lg:w-12 lg:h-12 text-blue-600 p-2 bg-blue-100 rounded-full m-2" />
        )}
        {icon === "happy-face" && (
          <ImSmile2 className="w-8 h-8 lg:w-12 lg:h-12 text-blue-600 p-2 bg-blue-100 rounded-full m-2" />
        )}
        <span className="border-b border-gray-200 w-full hidden lg:block" />
      </div>
      <div className="flex flex-col py-2">
        <h2 className="font-bold mx-4">{label}</h2>
        <span className="text-gray-600 text-sm mx-4">{commentary}</span>
        <p className="text-gray-600 mx-4">{text}</p>
      </div>
    </div>
  );
}

export default RegistrationStep;
