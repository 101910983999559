import React from "react";
import Step from "./Step";

interface StepsProps {
  currentStepIndex: number;
}

function Steps(props: StepsProps) {
  const { currentStepIndex } = props;

  const steps = [
    {
      step: 1,
      label: "Informations personnelles",
    },
    {
      step: 2,
      label: "Informations du compte",
    },
    {
      step: 3,
      label: "Confirmation",
    },
  ];

  return (
    <div className="flex items-center">
      {steps &&
        steps.map((step) => (
          <div
            key={step.step}
            className={`flex items-center ${
              step.step === currentStepIndex + 1 ? "flex" : "hidden"
            } lg:flex`}
          >
            <Step
              step={step.step}
              label={step.label}
              currentStepIndex={currentStepIndex}
            />
            {step.step < steps.length && (
              <span className="border-b border-gray-200 min-w-min w-10 hidden lg:block" />
            )}
          </div>
        ))}
    </div>
  );
}

export default Steps;
