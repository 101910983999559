import { colorsClasses } from "../../utils/constants";

export function getColorFromClassOrValue(color: string): string | null {
  const colorKeys = Object.keys(colorsClasses);

  // Recherche par clé
  if (colorKeys.includes(color)) {
    return color;
  }

  // Recherche par valeur
  const colorValue = Object.values(colorsClasses).find(
    (value) => value === color,
  );
  if (colorValue) {
    return colorKeys.find((key) => colorsClasses[key] === colorValue) || null;
  }

  return null;
}

export function getHexColorFromClassOrValue(color: string): string | null {
  const colorKeys = Object.keys(colorsClasses);

  // Recherche par clé
  if (colorKeys.includes(color)) {
    return colorsClasses[color] || null;
  }

  // Recherche par valeur
  const colorKey = Object.keys(colorsClasses).find(
    (key) => colorsClasses[key] === color,
  );
  if (colorKey) {
    return colorsClasses[colorKey] || null;
  }

  return null;
}

export const getRGBColor = (hex: string) => {
  const color = hex.replace(/#/g, "");
  // rgb values
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  return `rgb(${r}, ${g}, ${b}, 0.15)`;
};
