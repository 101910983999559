import {
  ApolloClient,
  InMemoryCache,
  // createHttpLink,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import React, { useContext } from "react";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider, UserContext } from "./context/userContext";
import { CalendarContextProvider } from "./context/CalendarContext";

// const httpLink = createHttpLink({
//   uri: process.env.REACT_APP_GRAPHQL_URI,
// });
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URI, // Remplacez par l'URL de votre serveur GraphQL
});
// Je créer un composant fonctionnel pour encapsuler le ApolloProvider
// Et configurer l'entête HTTP de mes requêtes grâce au context
function AuthProvider({ children }: { children: React.ReactNode }) {
  const { user } = useContext(UserContext);
  const { token } = user;
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
  const client = new ApolloClient({
    link: ApolloLink.from([authLink, uploadLink]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ContextProvider>
      <AuthProvider>
        <CalendarContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CalendarContextProvider>
      </AuthProvider>
    </ContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
