import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import ContractedHoursChart from "./ContractedHoursChart";
import DatePickerContractedHoursChart from "./DatePickerContractedHoursChart";
import {
  GetTotalHourlyAmountOnQuoteAgreements,
  GetTotalHourlyAmountOnQuoteAgreementsVariables,
} from "../../../../../API/models/types";
import { GET_TOTAL_HOURLY_AMOUNT_ON_QUOTE_AGREEMENTS_DATA } from "../../../../../API/modules/dashboard/resolvers/queries";
import NumberOfContractHours from "./NumberOfContractHours";
import EvolutionHoursValue from "./EvolutionHoursValue";

function ContractedHoursKPI() {
  const [selectedDateRange, setSelectedDateRange] = useState("6");

  const { data } = useQuery<
    GetTotalHourlyAmountOnQuoteAgreements,
    GetTotalHourlyAmountOnQuoteAgreementsVariables
  >(GET_TOTAL_HOURLY_AMOUNT_ON_QUOTE_AGREEMENTS_DATA, {
    variables: { dateRange: { dateRange: [6, 12] } },
  });

  const totalHourlyAmountOnQuoteAgreementsData =
    data?.getTotalHourlyAmountOnQuoteAgreements;

  const handleDateRangeChange = (value: string) => {
    setSelectedDateRange(value);
  };

  const chartData =
    selectedDateRange === "6"
      ? totalHourlyAmountOnQuoteAgreementsData?.option_6
      : totalHourlyAmountOnQuoteAgreementsData?.option_12;

  return (
    <div className="rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8">
      <div className="flex items-center">
        <div className="shrink-0">
          {chartData ? (
            <NumberOfContractHours
              totalHours={chartData?.totalQuoteAgreementAmount}
            />
          ) : null}
          <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
            Heures contractualisées cette semaine
          </h3>
        </div>
        {chartData ? (
          <EvolutionHoursValue evolutionHoursValue={chartData.progression} />
        ) : null}
      </div>
      {chartData ? <ContractedHoursChart data={chartData} /> : null}
      <div className="flex items-center justify-between space-x-2 border-t border-gray-200 pt-3 dark:border-gray-700 sm:pt-6">
        <DatePickerContractedHoursChart
          values={["6", "12"]}
          onChange={handleDateRangeChange}
        />
        <div className="shrink-0">
          <Link
            to="/contrat-devis"
            className="inline-flex items-center rounded-lg p-2 text-xs font-medium text-add-blue-dark hover:bg-gray-100 sm:text-sm"
          >
            Liste des contrats
            <svg
              className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ContractedHoursKPI;
