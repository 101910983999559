import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";

interface StepProps {
  step: number;
  label: string;
  currentStepIndex: number;
}

function Step(props: StepProps) {
  const { step, label, currentStepIndex } = props;
  return (
    <div
      className={`flex flex-col justify-center items-center p-10 min-w-fit ${
        step <= currentStepIndex + 1
          ? "text-add-blue-dark text-center"
          : "text-gray-800 hidden lg:flex"
      }`}
    >
      {step <= currentStepIndex + 1 ? (
        <BsFillCheckCircleFill className="mb-2" />
      ) : (
        <p>{step}</p>
      )}
      <p
        className={`text-center ${
          step <= currentStepIndex + 1 ? "font-bold" : ""
        }`}
      >
        {label}
      </p>
    </div>
  );
}

export default Step;
