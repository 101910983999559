import { Link, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { HiShieldCheck, HiShieldExclamation } from "react-icons/hi";
import { VERIFY_TOKEN_ADMIN_REGISTARTION } from "../API/modules/user/resolvers/mutation";
import AdminRegistrationForm from "./AdminRegistrationForm";
import logoWithText from "../assets/logo/logo-add-with-text.png";
import imgParlezNous from "../assets/illustrations/img-parlez-nous.png";

export default function AdminRegistration() {
  // Je récupère le verifyEmailToken dans l'url (le lien qui était dans l'email utilisateur)
  const { verifyAdminRegistrationToken } = useParams<string>();
  const [errors, setErrors] = useState<boolean>(false);
  const [verificationMessage, setVerificationMessage] = useState<string>("");
  const [verifyTokenAdminRegistration] = useMutation(
    VERIFY_TOKEN_ADMIN_REGISTARTION,
  );
  const [emailForForm, setEmailForForm] = useState<string>("");
  useEffect(() => {
    if (verifyAdminRegistrationToken) {
      verifyTokenAdminRegistration({
        variables: { token: verifyAdminRegistrationToken },
      })
        .then((response) => {
          const { message, email } = response.data.verifyTokenAdminRegistration;

          setVerificationMessage(message);
          setEmailForForm(email);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setErrors(true);
          setVerificationMessage("Le lien n'est pas valide ou expiré");
        });
    }
  }, [verifyAdminRegistrationToken, verifyTokenAdminRegistration]);

  return (
    <main className="h-screen flex">
      <div className="h-full relative w-1/3 hidden lg:block">
        <Link to="/se-connecter">
          <img
            src={logoWithText}
            alt="logo add"
            className="absolute w-12 lg:w-24 left-1/2 lg:top-14 transform -translate-x-1/2 -translate-y-1/2 lg:-translate-y-1/3"
          />
        </Link>
        <div className="bg-add-green h-full">
          <img
            src={imgParlezNous}
            alt="illustration inscription"
            className="h-full object-contain object-center p-6 xl:p-12"
          />
        </div>
      </div>
      <div className="flex flex-col items-center md:justify-center w-full h-full px-6 lg:px-0 overflow-y-auto">
        <div className="flex flex-col items-center justify-center">
          {!errors ? (
            <HiShieldCheck className="w-16 h-16 fill-green-400" />
          ) : (
            <HiShieldExclamation className="w-16 h-16 fill-red-400" />
          )}
          <h1 className="p-4 text-center text-2xl">{verificationMessage}</h1>
          {!errors ? (
            <>
              <div
                className="p-2 text-sm text-gray-800 rounded-lg bg-blue-100 text-center"
                role="alert"
              >
                <p>
                  Veuillez remplir le formulaire d'inscription pour un admin de
                  l'application.
                </p>
              </div>
              <img
                src={logoWithText}
                alt="logo add"
                className="h-32 lg:hidden py-4"
              />
            </>
          ) : null}
        </div>
        {!errors ? (
          <div className="h-3/4 items-center justify-center">
            <AdminRegistrationForm email={emailForForm} />
          </div>
        ) : null}{" "}
      </div>
    </main>
  );
}
