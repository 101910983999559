import { useMutation } from "@apollo/client";
import { Label, TextInput, Textarea } from "flowbite-react";
import React, { useContext, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { CreateQuoteAgreementInput, UserRole } from "../../API/models/types";
import { CREATED_QUOTE_AGREEMENT } from "../../API/modules/quote-agreement/resolvers/mutations";
import {
  GET_ALL_QUOTE_AGREEMENTS,
  GET_ALL_QUOTE_AGREEMENTS_FOR_ADMIN,
  GET_ALL_QUOTE_AGREEMENTS_FOR_CUSTOMER,
  GET_ALL_QUOTE_AGREEMENTS_FOR_SECRETARY,
} from "../../API/modules/quote-agreement/resolvers/queries";
import {
  GET_ALL_SECRETARIES_AND_ADMIN_ACTIVE,
  USERS_LIST_WITH_ACTIVE_FRAMEWORK_AGREEMENT,
} from "../../API/modules/user/resolvers/queries";
import SelectUser from "../../components/shared/SelectUser";
import { UserContext } from "../../context/userContext";

export default function CreateQuoteAgreement() {
  // formulaire
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateQuoteAgreementInput>();
  // routeur
  const navigate = useNavigate();
  // contexte user
  const { user } = useContext(UserContext);
  // mutation création contrat cadre
  const [createQuoteAgreement, { error: BadCredentialsError }] = useMutation(
    CREATED_QUOTE_AGREEMENT,
  );
  // validation formulaire
  const onSubmit: SubmitHandler<CreateQuoteAgreementInput> = async (
    values: CreateQuoteAgreementInput,
  ) => {
    try {
      if (user && user.role !== null) {
        await createQuoteAgreement({
          variables: {
            input: {
              label: values.label,
              reference: values.reference ? values.reference : null,
              url: values.url ? values.url : null,
              description: values.description ? values.description : null,
              isActive:
                Object.prototype.hasOwnProperty.call(values, "isActive") &&
                typeof values.isActive === "boolean"
                  ? values.isActive
                  : false,
              customerId: values.customerId,
              secretaryId: values.secretaryId,
              hourlyAmount: Number(values.hourlyAmount),
            },
          },
          refetchQueries:
            // eslint-disable-next-line no-nested-ternary
            user.role === UserRole.ADMIN
              ? [
                  {
                    query: GET_ALL_QUOTE_AGREEMENTS_FOR_ADMIN,
                    variables: {
                      adminId: user.id,
                    },
                  },
                ]
              : // eslint-disable-next-line no-nested-ternary
              user.role === UserRole.SUPER_ADMIN
              ? [
                  {
                    query: GET_ALL_QUOTE_AGREEMENTS,
                  },
                ]
              : user.role === UserRole.CUSTOMER
              ? [
                  {
                    query: GET_ALL_QUOTE_AGREEMENTS_FOR_CUSTOMER,
                    variables: {
                      customerId: user.id,
                    },
                  },
                ]
              : [
                  {
                    query: GET_ALL_QUOTE_AGREEMENTS_FOR_SECRETARY,
                    variables: {
                      secretaryId: user.id,
                    },
                  },
                ],
        });
        navigate("/contrat-devis");
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error, BadCredentialsError);
    }
  };
  // const [errorMessage, setErrorMessage] = useState("");
  // initialisation checkbox isActive
  const [isActive, setActive] = useState<boolean>(false);
  // fonction pour récupérer l'id du client (dans le select) lors de la création d'un contrat cadre
  const setSelectedCustomerId = (value: string) => {
    setValue("customerId", value);
  };
  const setSelectedSecretaryId = (value: string) => {
    setValue("secretaryId", value);
  };

  return (
    <div className="flex w-full h-full flex-col px-5 overflow-auto">
      <div className=" w-full border-b-2 border-gray-200 mt-6">
        <h2 className="font-semibold">Nouveau contrat devis</h2>
      </div>
      <span className="font-light text-gray-500 text-xs">
        (*) champs obligatoires
      </span>
      <form
        className="lg:flex lg:flex-col w-full lg:py-10 lg:px-14 sm:py-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="sm:py-2 mb-4  lg:w-1/3">
          <Label htmlFor="label">Nom du contrat *</Label>
          <TextInput
            id="label"
            placeholder="Contrat"
            type="text"
            {...register("label", { required: true })}
          />
          {errors.label && (
            <span className="text-red-600">
              un nom de contrat doit être renseigné
            </span>
          )}
        </div>
        <div className="sm:py-1 mb-4 lg:w-1/3 z-10">
          <Label htmlFor="customerId">
            Choisir un client, puis une secrétaire *
          </Label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="customerId"
            render={() => (
              <SelectUser
                setSelectedUserId={setSelectedCustomerId}
                userRole={UserRole.CUSTOMER}
                // Liste de clients avec un contrat cadre actif
                // prend aussi les clients qui n'ont pas été activés
                query={USERS_LIST_WITH_ACTIVE_FRAMEWORK_AGREEMENT}
                queryName="usersListWithActiveFrameworkAgreement"
              />
            )}
          />
          {errors.customerId && (
            <span className="text-red-600">un client doit être renseigné</span>
          )}
          <Controller
            control={control}
            rules={{ required: true }}
            name="secretaryId"
            render={() => (
              <SelectUser
                setSelectedUserId={setSelectedSecretaryId}
                // Liste de secrétaires et admin (qui sont aussi des secrétaires)
                // prend uniquement les secrétaires et admin activés (propriété user isActive true)
                query={GET_ALL_SECRETARIES_AND_ADMIN_ACTIVE}
                queryName="secretariesAndAdminList"
              />
            )}
          />
          {errors.secretaryId && (
            <span className="text-red-600">
              une secrétaire doit être renseignée
            </span>
          )}
        </div>
        <div className="sm:py-1 mb-4 lg:w-1/3 ">
          <Label htmlFor="hourlyAmount">
            Nombre d'heures contractualisées *
          </Label>
          <TextInput
            id="hourlyAmount"
            placeholder="51"
            type="number"
            {...register("hourlyAmount", { required: true })}
          />
          {errors.hourlyAmount && (
            <span className="text-red-600">
              un nombre d'heure contractualisées doit être renseignées
            </span>
          )}
        </div>
        <div className="sm:py-1 mb-4 lg:w-1/3 ">
          <Label htmlFor="reference">Référence du contrat devis</Label>
          <TextInput
            id="reference"
            placeholder="Devis13ADD"
            type="text"
            {...register("reference", { required: false })}
          />
        </div>
        <div className="sm:py-1 mb-4 lg:w-1/3 ">
          <Label htmlFor="url">Lien URL vers le contrat devis</Label>
          <TextInput
            id="url"
            placeholder="https://sharepoint.add/De123ADD"
            type="text"
            {...register("url", { required: false })}
          />
        </div>
        <div className="sm:py-1 mb-4 lg:w-1/2 h-64 ">
          <Label htmlFor="description">
            <h2 className="font-bold">Description (facultatif)</h2>
          </Label>

          <Textarea
            id="description"
            placeholder="Laissez un commentaire..."
            rows={9}
            {...register("description", { required: false })}
          />
        </div>
        <div className="mb-4 gap-y-3  sm:py-1 items-center justify-center  overflow-hidden">
          <Label htmlFor="reference">Ce contrat est il actif?</Label>
          <div className="flex">
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                defaultChecked={isActive}
                readOnly
                onClick={() => {
                  setActive(!isActive);
                  setValue("isActive", !isActive);
                }}
              />
              <div className="mr-2 w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600" />

              {!isActive ? (
                <>
                  <div className="font-bold bg-red-600 w-4 h-4 relative rounded-full items-center justify-center mr-1" />

                  <span className="ml-2 text-sm font-medium text-gray-900">
                    Désactivé
                  </span>
                </>
              ) : (
                <>
                  {" "}
                  <div className="font-bold bg-green-600 w-4 h-4 relative  rounded-full items-center justify-center mr-1" />
                  <span className="ml-2 text-sm font-medium text-gray-900">
                    Actif
                  </span>
                </>
              )}
            </label>
          </div>
        </div>
        <div
          className="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 md:w-1/2 lg:w-1/2"
          role="alert"
        >
          <svg
            aria-hidden="true"
            className="flex-shrink-0 inline w-5 h-5 mr-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <span className="font-medium">
              Un seul contrat devis peut être actif pour un client ayant un
              contrat cadre actif et une secrétaire. Le dernier contrat actif
              passera en statut "désactivé"
            </span>
          </div>
        </div>
        <div className="flex w-full flex-row sm:py-1 mb-6">
          <button
            className="w-28 bg-add-blue-dark p-2 font-semibold button-add mr-2"
            type="submit"
          >
            Enregistrer
          </button>

          <button
            type="button"
            className="w-28 bg-add-blue-dark p-2 font-semibold button-add"
          >
            <Link to="/contrat-devis">Fermer</Link>
          </button>
        </div>
        {BadCredentialsError && (
          <span className="text-red-600 block mb-6">
            {BadCredentialsError.message}
          </span>
        )}
      </form>
    </div>
  );
}
