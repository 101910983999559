import { ColumnDef } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import Table from "../../../../components/table/Table";
import {
  FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks,
  TaskStatus,
} from "../../../../API/models/types";
import {
  EDIT_TASK,
  REMOVE_TASK,
} from "../../../../API/modules/task/resolvers/mutations";

import DropdownActionItem from "../../../../components/table/button/DropdownActionItem";
import DateCell from "../../../../components/table/cell-component/DateCell";
import TaskStatusCell from "../../../../components/table/cell-component/TaskStatusCell";
import UserCell from "../../../../components/table/cell-component/UserCell";
import { getOneTask } from "../../../utils/QueriesTasks";
import ModalFilterTaskForSuperAdmin from "../../modale/ModalFilterTaskForSuperAdmin";
import ModalTaskRow from "../../modale/ModalTaskRow";
import { ItemTask, UserInformation } from "../../../models/models";
import { FIND_ALL_PAGINATE_TASKS_FOR_SUPER_ADMIN } from "../../../../API/modules/task/resolvers/queries";

interface Props {
  data: ItemTask[];
  tasks: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks[];
}
export default function TaskListForCustomers(props: Props) {
  const { data, tasks } = props;
  const [modalFilter, setModalFilter] = useState<boolean>(false);
  // modal view row table
  const [modalRow, setModalRow] = useState<boolean>(false);
  const [modalItemRow, setModalItemRow] = useState<any>();
  // Modal détail de la tâche : "edit"
  const [, setModalEditRow] = useState<boolean>(false);

  // construction colonnes de la table
  const cols = useMemo<ColumnDef<ItemTask>[]>(
    () => [
      {
        header: "Tâches",
        cell: (row) => row.renderValue(),
        accessorKey: "label",
        enableSorting: true,
      },
      {
        header: "Date",
        cell: (row) => {
          const value: Date = row.getValue() as Date;
          return DateCell(
            value.toLocaleDateString("fr-fr", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }),
            false,
          );
        },
        sortType: "datetime",
        accessorKey: "date",
      },
      {
        header: "Durée Prév.",
        cell: (row) => row.renderValue(),
        accessorKey: "plannedDuration",
        enableSorting: false,
      },
      {
        header: "Durée Réa.",
        cell: (row) => row.renderValue(),
        accessorKey: "realDuration",
        enableSorting: false,
      },
      {
        header: "Catégorie",
        cell: (row) => row.renderValue(),
        accessorKey: "categoryTask",
        enableSorting: true,
      },
      {
        header: "Secrétaire",
        cell: (row) => {
          const value: UserInformation = row.getValue() as UserInformation;
          return UserCell(value, false);
        },
        accessorKey: "secretary",
      },
      {
        header: "userNameSecretary",
        cell: (row) => row.renderValue(),
        accessorKey: "userNameSecretary",
      },
      {
        header: "Statut",
        cell: (row) => {
          const value: TaskStatus = row.getValue() as TaskStatus;
          return TaskStatusCell(value);
        },
        accessorKey: "status",
        enableSorting: false,
      },
      {
        header: " ",
        enableSorting: false,
        cell: (getContext) => {
          return DropdownActionItem({
            showDeleteRow: false,
            pathToEditRow: "/liste-des-taches/edition-tache",
            // Régle métier : Le client ne peut éditer que les tâches avec le statut "En attente"
            showEditRow: getContext.row.original.status === TaskStatus.PENDING,
            mutationToEditRow: EDIT_TASK,
            mutationToRemoveRow: REMOVE_TASK,
            refetchQueries: FIND_ALL_PAGINATE_TASKS_FOR_SUPER_ADMIN,
            showViewRow: true,
            item: getOneTask(getContext.row.original.id, tasks),
            setModal: setModalRow,
            setModalEdit: setModalEditRow,
            setItem: setModalItemRow,
          });
        },
        accessorKey: "",
      },
    ],
    [tasks],
  );

  return (
    <>
      <div className="w-full m-auto">
        <Table
          data={data as ItemTask[]}
          columns={cols}
          showFooter
          showNavigation
          showGlobalFilter
          showButtonAddItem
          labelButtonAddItem="Ajouter une tâche"
          pathButtonAddItem="/liste-des-taches/creation-tache"
          hiddenColumns={{
            userNameSecretary: false,
            userNameClient: false,
          }}
          modalFilter={modalFilter}
          setModalFilter={setModalFilter}
          showFilterButton
          showSelectDateFilter
          showButtonActionItem={false}
        />
      </div>
      <ModalTaskRow open={modalRow} setOpen={setModalRow} item={modalItemRow} />
      <ModalFilterTaskForSuperAdmin
        open={modalFilter}
        setOpen={setModalFilter}
        datas={data as ItemTask[]}
      />{" "}
    </>
  );
}
