/* eslint-disable no-nested-ternary */

import { Card } from "flowbite-react";
// import ReactApexChart from "react-apexcharts";
import { ApolloError } from "@apollo/client";
import imgContrat from "../../assets/illustrations/img-contrat.png";

import {
  GetAllQuoteAgreements_getAllQuoteAgreements,
  getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer,
  GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary,
  GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin,
  UserRole,
} from "../../API/models/types";

import GetAllQuoteAgreement, {
  GetAllQuoteAgreementForAdmin,
  GetAllQuoteAgreementForCustomer,
  GetAllQuoteAgreementForSecretary,
  getOneQuoteAgreement,
  getOneQuoteAgreementForAdmin,
  getOneQuoteAgreementForCustomer,
  getOneQuoteAgreementForSecretary,
  refetchQueries,
} from "../utils/QueriesQuoteAgreement";
import SpinnerComponent from "../../components/shared/SpinnerComponent";
import { useUserContext } from "../../context/userContext";
import { ItemQuoteAgreement, QuoteAgreementData } from "../models/models";
import TableQuoteAgreement from "./table/TableQuoteAgreement";
import DownloadQuoteAgreementsCsvButton from "../../components/shared/DownloadQuoteAgreementsCsvButton";
import SomethingHappened from "../SomethingHappened";
import getFormattedHours from "../Calendar/utils/getFormattedHours";

interface Props {
  role: UserRole;
}
export default function QuoteAgreement(props: Props) {
  const { role } = props;
  // contexte user
  const { user } = useUserContext();

  // methode retourne la liste des contrats devis en fonction du role
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const {
    quoteAgreements,
    loading,
    error,
  }: {
    quoteAgreements: QuoteAgreementData[];
    loading: boolean;
    error: ApolloError | undefined;
  } =
    // eslint-disable-next-line no-nested-ternary

    role !== null && role === UserRole.SUPER_ADMIN
      ? GetAllQuoteAgreement()
      : role !== null && role === UserRole.ADMIN
      ? GetAllQuoteAgreementForAdmin(user.id as string)
      : role !== null && role === UserRole.CUSTOMER
      ? GetAllQuoteAgreementForCustomer(user.id as string)
      : role !== null && role === UserRole.SECRETARY
      ? GetAllQuoteAgreementForSecretary(user.id as string)
      : { quoteAgreements: [], loading: false, error: undefined };

  // methode retourne un contrat devis en fonction du role
  const getOneQuoteAgreements = (id: string, array: QuoteAgreementData[]) => {
    if (role === UserRole.SUPER_ADMIN) {
      return getOneQuoteAgreement(
        id as string,
        array as GetAllQuoteAgreements_getAllQuoteAgreements[],
      );
    }
    if (role === UserRole.ADMIN) {
      return getOneQuoteAgreementForAdmin(
        id as string,
        array as GetAllQuoteAgreementsForAdmin_getAllQuoteAgreementsForAdmin[],
      );
    }
    if (role === UserRole.CUSTOMER) {
      return getOneQuoteAgreementForCustomer(
        id as string,
        array as getAllQuoteAgreementsForCustomer_getAllQuoteAgreementsForCustomer[],
      );
    }
    if (role === UserRole.SECRETARY) {
      return getOneQuoteAgreementForSecretary(
        id as string,
        array as GetAllQuoteAgreementsForSecretary_getAllQuoteAgreementsForSecretary[],
      );
    }
    return {};
  };
  // data
  const quoteAgreementData = () => {
    const items: ItemQuoteAgreement[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < quoteAgreements.length; i++) {
      const item: QuoteAgreementData = quoteAgreements[i];
      if (role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN) {
        items.push({
          id: item.id,
          contract: item.label,
          hourlyAmount: getFormattedHours(item.hourlyAmount),
          currentAmount: getFormattedHours(item.currentAmount),
          secretary: {
            id: item.user.id,
            userName: `${item.user.last_name.toUpperCase()} ${
              item.user.first_name
            }`,
            avatar: item.user.avatar_url as string,
            role: item.user.role as UserRole,
          },
          client: {
            id: item.frameworkAgreement.user.id,
            userName: `${item.frameworkAgreement.user.last_name.toUpperCase()} ${
              item.frameworkAgreement.user.first_name
            }`,
            avatar: item.frameworkAgreement.user.avatar_url as string,
            role: item.frameworkAgreement.user.role as UserRole,
          },
          date: new Date(item.createdAt),
          status: item.isActive ? "activé" : "désactivé",
          // pour filtrer sur le nom colonne hidden
          userNameSecretary: `${item.user.last_name.toUpperCase()} ${
            item.user.first_name
          }`,
          userNameClient: `${item.frameworkAgreement.user.last_name.toUpperCase()} ${
            item.frameworkAgreement.user.first_name
          }`,
        });
      }
      if (role === UserRole.SECRETARY) {
        items.push({
          id: item.id,
          contract: item.label,
          hourlyAmount: getFormattedHours(item.hourlyAmount),
          currentAmount: getFormattedHours(item.currentAmount),
          client: {
            id: item.frameworkAgreement.user.id,
            userName: `${item.frameworkAgreement.user.last_name.toUpperCase()} ${
              item.frameworkAgreement.user.first_name
            }`,
            avatar: item.frameworkAgreement.user.avatar_url as string,
            role: item.frameworkAgreement.user.role as UserRole,
          },
          date: new Date(item.createdAt),
          status: item.isActive ? "activé" : "désactivé",
          userNameClient: `${item.frameworkAgreement.user.last_name.toUpperCase()} ${
            item.frameworkAgreement.user.first_name
          }`,
        });
      }
      if (role === UserRole.CUSTOMER) {
        items.push({
          id: item.id,
          contract: item.label,
          hourlyAmount: getFormattedHours(item.hourlyAmount),
          currentAmount: getFormattedHours(item.currentAmount),
          secretary: {
            id: item.user.id,
            userName: `${item.user.last_name.toUpperCase()} ${
              item.user.first_name
            }`,
            avatar: item.user.avatar_url as string,
            role: item.user.role as UserRole,
          },
          date: new Date(item.createdAt),
          status: item.isActive ? "activé" : "désactivé",
          userNameSecretary: `${item.user.last_name.toUpperCase()} ${
            item.user.first_name
          }`,
        });
      }
    }

    return items;
  };

  return !error ? (
    <div className="flex flex-col">
      <header className="flex flex-col h-1/7 lg:max-w-[50%] justify-items-start items-center relative">
        <div className="basis-2/12 flex">
          <img
            src={imgContrat}
            alt="illustration contrats devis"
            className="w-full"
          />
        </div>
        <div className="basis-9/12 p-10 sm:p-0 absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-2xl xl:text-3xl">Contrats devis</h1>
        </div>
      </header>
      {!loading ? (
        <div className="flex w-full h-4/7 p-5">
          <Card className="flex gap-1 w-full p-3 overflow-auto">
            <div className="flex min-h-3/4 relative">
              <TableQuoteAgreement
                getAllQuoteAgreements={quoteAgreements}
                getOneQuoteAgreements={
                  getOneQuoteAgreements as (
                    value: string,
                    value1: QuoteAgreementData[],
                  ) => QuoteAgreementData
                }
                refetchQueries={refetchQueries(role as UserRole)}
                data={quoteAgreementData()}
              />
              {user ? (
                <div className="absolute md:relative xl:absolute right-0">
                  <DownloadQuoteAgreementsCsvButton
                    fileName="liste-devis"
                    userRole={user.role as UserRole}
                    userId={user.id as string}
                  />
                </div>
              ) : null}
            </div>
          </Card>
        </div>
      ) : (
        <SpinnerComponent />
      )}
    </div>
  ) : (
    <SomethingHappened />
  );
}
