import { DocumentNode, gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const FIND_AGENDA_TASKS_FOR_SUPER_ADMIN: DocumentNode = gql`
  query FindAgendaTasksForSuperAdmin($startDate: Date!) {
    findAgendaTasksForSuperAdmin(startDate: $startDate) {
      id
      color
      label
      isPrivate
      startDate
      endDate
      plannedDuration
      realDuration
      status
      description
      author {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      secretary {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      customer {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      taskType {
        id
        label
        categoryTaskType {
          id
          label
        }
      }
      quoteAgreement {
        id
        createdAt
        updatedAt
        label
        hourlyAmount
        currentAmount
        reference
        description
        isActive
        url
      }
    }
  }
`;
export const FIND_ALL_PAGINATE_TASKS_FOR_SUPER_ADMIN: DocumentNode = gql`
  query FindAllPaginateTasksForSuperAdmin($paginationArgs: TaskPaginationDto!) {
    findAllPaginateTasksForSuperAdmin(paginationArgs: $paginationArgs) {
      tasks {
        id
        color
        label
        isPrivate
        startDate
        endDate
        plannedDuration
        realDuration
        status
        description
        secretary {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        customer {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        author {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        taskType {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
        quoteAgreement {
          id
          createdAt
          updatedAt
          label
          hourlyAmount
          currentAmount
          reference
          description
          isActive
          url
        }
      }
      total
    }
  }
`;
export const FIND_AGENDA_TASKS_FOR_CUSTOMERS: DocumentNode = gql`
  query FindAgendaTasksForCustomers($customerId: String!, $startDate: Date!) {
    findAgendaTasksForCustomers(
      startDate: $startDate
      customerId: $customerId
    ) {
      id
      color
      label
      isPrivate
      startDate
      endDate
      plannedDuration
      realDuration
      status
      description
      secretary {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      customer {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      author {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      quoteAgreement {
        id
      }
      taskType {
        id
        label
        categoryTaskType {
          id
          label
        }
      }
    }
  }
`;
export const FIND_AGENDA_TASKS_FOR_ADMIN: DocumentNode = gql`
  query FindAgendaTasksForAdmin($adminId: String!, $startDate: Date!) {
    findAgendaTasksForAdmin(startDate: $startDate, adminId: $adminId) {
      id
      color
      label
      isPrivate
      startDate
      endDate
      plannedDuration
      realDuration
      status
      description
      secretary {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      customer {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      author {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      taskType {
        id
        label
        categoryTaskType {
          id
          label
        }
      }
      quoteAgreement {
        id
      }
    }
  }
`;
export const GET_ONE_TASK: DocumentNode = gql`
  query GetOneTask($id: String!) {
    getOneTask(id: $id) {
      id
      createdAt
      updatedAt
      label
      color
      author {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      isPrivate
      startDate
      endDate
      plannedDuration
      realDuration
      status
      description
      secretary {
        id
        createdAt
        updatedAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          createdAt
          updatedAt
          label
          categoryTaskType {
            id
            createdAt
            updatedAt
            label
          }
        }
      }
      quoteAgreement {
        id
        createdAt
        updatedAt
        label
        hourlyAmount
        reference
        description
        isActive
        url
        user {
          id
          createdAt
          updatedAt
          admin {
            id
          }
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
        frameworkAgreement {
          id
          createdAt
          updatedAt
          label
          reference
          url
          description
          isActive
          user {
            id
            createdAt
            updatedAt
            admin {
              id
            }
            first_name
            last_name
            avatar_url
            phone_number
            email
            geographical_area
            siret
            business_name
            experience
            criticity_request
            commentary
            isActive
            isVerified
            role
            taskTypes {
              id
              label
              categoryTaskType {
                id
                label
              }
            }
          }
        }
      }
      customer {
        id
        createdAt
        updatedAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          createdAt
          updatedAt
          label
          categoryTaskType {
            id
            label
          }
        }
      }
      taskType {
        id
        createdAt
        updatedAt
        label
        categoryTaskType {
          id
          createdAt
          updatedAt
          label
        }
      }
      parentTask {
        id
        createdAt
        updatedAt
        label
        isPrivate
        startDate
        endDate
        plannedDuration
        realDuration
        status
        description
        secretary {
          id
        }
        quoteAgreement {
          id
        }
        customer {
          id
        }
        taskType {
          id
        }
      }
      childrenTasks {
        id
        createdAt
        updatedAt
        label
        isPrivate
        startDate
        endDate
        plannedDuration
        realDuration
        status
        description
        secretary {
          id
        }
        quoteAgreement {
          id
        }
        customer {
          id
        }
        taskType {
          id
        }
      }
    }
  }
`;

export const FIND_PENDING_TASKS_BY_USER_ID: DocumentNode = gql`
  query FindPendingTasksByUserId($input: FindTasksInput!) {
    findPendingTasksByUserId(input: $input) {
      id
      label
      isPrivate
      status
      startDate
      plannedDuration
      description
      color
      taskType {
        id
        label
        categoryTaskType {
          id
          label
        }
      }
      secretary {
        id
        first_name
        last_name
        role
        email
      }
      customer {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      author {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      quoteAgreement {
        label
        reference
      }
    }
  }
`;

export const FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES: DocumentNode = gql`
  query FindNotPendingAgendaTasksForSecretaries($input: FindTasksInput!) {
    findNotPendingAgendaTasksForSecretaries(input: $input) {
      id
      label
      isPrivate
      status
      startDate
      plannedDuration
      realDuration
      description
      color
      taskType {
        id
        label
        categoryTaskType {
          id
          label
        }
      }
      secretary {
        id
        first_name
        last_name
        avatar_url
        role
        email
      }
      customer {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      author {
        id
        first_name
        last_name
        avatar_url
        email
        role
      }
      quoteAgreement {
        label
        reference
      }
    }
  }
`;

export const FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES: DocumentNode = gql`
  query FindAllPaginateNotPendingTasksForSecretaries(
    $userId: String!
    $paginationArgs: TaskPaginationDto!
  ) {
    findAllPaginateNotPendingTasksForSecretaries(
      paginationArgs: $paginationArgs
      userId: $userId
    ) {
      tasks {
        id
        color
        label
        isPrivate
        startDate
        endDate
        plannedDuration
        realDuration
        status
        description
        author {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        secretary {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        customer {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        taskType {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
        quoteAgreement {
          id
          createdAt
          updatedAt
          label
          hourlyAmount
          currentAmount
          reference
          description
          isActive
          url
        }
      }
      total
      hasPendingTask
    }
  }
`;

export const FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES: DocumentNode = gql`
  query FindAllPaginatePendingTasksForSecretaries(
    $userId: String!
    $paginationArgs: TaskPaginationDto!
  ) {
    findAllPaginatePendingTasksForSecretaries(
      paginationArgs: $paginationArgs
      userId: $userId
    ) {
      tasks {
        id
        color
        label
        isPrivate
        startDate
        endDate
        plannedDuration
        realDuration
        status
        description
        author {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        secretary {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        customer {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        taskType {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
        quoteAgreement {
          id
          createdAt
          updatedAt
          label
          hourlyAmount
          currentAmount
          reference
          description
          isActive
          url
        }
      }
      total
    }
  }
`;
export const FIND_ALL_PAGINATE_TASKS_FOR_CUSTOMERS: DocumentNode = gql`
  query FindAllPaginateTasksForCustomers(
    $userId: String!
    $paginationArgs: TaskPaginationDto!
  ) {
    findAllPaginateTasksForCustomers(
      paginationArgs: $paginationArgs
      userId: $userId
    ) {
      tasks {
        id
        color
        label
        isPrivate
        startDate
        endDate
        plannedDuration
        realDuration
        status
        description
        secretary {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        customer {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        author {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        taskType {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
        quoteAgreement {
          id
          createdAt
          updatedAt
          label
          hourlyAmount
          currentAmount
          reference
          description
          isActive
          url
        }
      }
      total
    }
  }
`;

export const FIND_ALL_PAGINATE_TASKS_FOR_ADMINS: DocumentNode = gql`
  query FindAllPaginateTasksForAdmins(
    $adminId: String!
    $paginationArgs: TaskPaginationDto!
  ) {
    findAllPaginateTasksForAdmins(
      paginationArgs: $paginationArgs
      adminId: $adminId
    ) {
      tasks {
        id
        color
        label
        isPrivate
        startDate
        endDate
        plannedDuration
        realDuration
        status
        description
        secretary {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        customer {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        author {
          id
          first_name
          last_name
          avatar_url
          email
          role
        }
        taskType {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
        quoteAgreement {
          id
          createdAt
          updatedAt
          label
          hourlyAmount
          currentAmount
          reference
          description
          isActive
          url
        }
      }
      total
      hasPendingTask
    }
  }
`;
