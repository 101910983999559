interface Props {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}
function ButtonFilterTable(props: Props) {
  const { isOpen, setOpen } = props;
  const handleClick = () => {
    setOpen(!isOpen);
  };
  return (
    <div className="dropdown items-center">
      <button
        type="button"
        className="text-xs bg-add-blue-dark button-add font-medium rounded-lg px-4 p-3 text-center inline-flex"
        onClick={handleClick}
      >
        Filtrer
        <svg
          className="ml-2 w-4 h-4"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
    </div>
  );
}
export default ButtonFilterTable;
