/* eslint-disable react/require-default-props */
import { useMutation } from "@apollo/client/react";
import { DocumentNode } from "graphql";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  FrameworkAgreementData,
  QuoteAgreementData,
} from "../../../pages/models/models";
import { useUserContext } from "../../../context/userContext";
import {
  FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
  FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
  FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
} from "../../../API/modules/task/resolvers/queries";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../context/FilterContext";

interface Props {
  // router path to edit
  pathToEditRow?: string;
  // mutation name to remove row
  mutationToRemoveRow: DocumentNode;
  // mutation name to update row
  mutationToEditRow: DocumentNode;
  // querie to refetch in case of list
  refetchQueries?: DocumentNode;
  // object generic
  item: FrameworkAgreementData | QuoteAgreementData | any;
  // action to show
  showDeleteRow: boolean;
  showEditRow: boolean;
  showViewRow: boolean;
  // modal view row
  setModal: (open: boolean) => void;
  setItem: (item: FrameworkAgreementData | QuoteAgreementData | any) => void;
  // modal edit
  setModalEdit: (open: boolean) => void;
}

export default function DropdownActionItem(props: Props) {
  // ouverture drowdown
  const [isOpen, setOpen] = useState<boolean>(false);
  // création reference element dom
  const dropdownRef = useRef<any>();
  // fonction pour fermer le dropdown
  const closeOpenMenus = useCallback(
    (e: any) => {
      if (
        dropdownRef.current &&
        isOpen &&
        !dropdownRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    },
    [isOpen, dropdownRef],
  );
  // contexte user
  const { user } = useUserContext();
  // contexte filter
  const { filters } = useContext<FilterTasksContextType>(FilterTasksContext);
  useEffect(() => {
    // au click exterieur on appelle la méthode poour fermer le dropdown
    document.addEventListener("mousedown", closeOpenMenus);
  }, [closeOpenMenus]);
  // props pour rendre générique le composant
  const {
    pathToEditRow,
    mutationToRemoveRow,
    refetchQueries,
    item,
    mutationToEditRow,
    setModal,
    setModalEdit,
    setItem,
    showDeleteRow = false,
    showEditRow = false,
    showViewRow = false,
  } = props;

  // mutation pour effacer la ligne du tableau
  const [removeRow] = useMutation(mutationToRemoveRow);

  const deleteRow = async () => {
    if (user !== null && user && user.id && filters !== null && filters) {
      try {
        await removeRow({
          variables: {
            id: item.id,
          },
          refetchQueries: [
            {
              query: FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
              variables: {
                userId: user.id,
                paginationArgs: filters,
              },
            },
            {
              query: FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
              variables: {
                userId: user.id,
                paginationArgs: filters,
              },
            },
            {
              query: FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
              variables: {
                input: { userId: user.id, startDate: new Date() },
              },
            },
          ],
        });
        setOpen(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);

        // eslint-disable-next-line no-console
        console.log(refetchQueries);
      }
    }
  };

  const handleDropDown = () => {
    setOpen(!isOpen);
  };

  // Test sur pathToEditRow pour éviter les problèmes d'hydratation
  // lors de l'affichage du bouton "Editer"
  // const [isPathToEditEmpty, setIsPathToEditEmpty] = useState(true);
  // useEffect(() => {
  //   setIsPathToEditEmpty(pathToEditRow?.length === 0);
  // }, [pathToEditRow, setIsPathToEditEmpty]);
  const handleViewClick = useCallback(() => {
    if (item) {
      setItem(item);
      setModal(true);
      setOpen(false);
    }
  }, [item, setItem, setModal]);
  const handleEditClick = useCallback(() => {
    if (item) {
      setItem(item);
      setModalEdit(true);
      setOpen(false);
    }
  }, [item, setItem, setModalEdit]);
  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="relative inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        type="button"
        onClick={handleDropDown}
      >
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
        </svg>
      </button>

      <div
        className={`z-10 w-fit bg-white rounded divide-y divide-gray-100 shadow ${
          isOpen ? "absolute" : "hidden"
        }`}
      >
        <ul className="flex flex-col">
          {showViewRow ? (
            <button
              type="button"
              onClick={handleViewClick}
              className="py-2 px-4 hover:bg-gray-100 w-full"
            >
              Voir
            </button>
          ) : null}
          {/* eslint-disable-next-line no-nested-ternary */}
          {showEditRow && pathToEditRow ? (
            <Link
              to={pathToEditRow as string}
              state={{
                mutationEditRow: mutationToEditRow
                  ? (mutationToEditRow as DocumentNode)
                  : null,
                item: item ? (item as any) : null,
              }}
            >
              <div className="py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                Editer
              </div>
            </Link>
          ) : showEditRow && !pathToEditRow ? (
            <button
              type="button"
              onClick={handleEditClick}
              className="py-2 px-4 hover:bg-gray-100 w-full"
            >
              Editer
            </button>
          ) : null}
          {showDeleteRow ? (
            <button
              type="button"
              className="py-2 px-4 hover:bg-gray-100 w-full"
              onClick={() => {
                deleteRow();
              }}
            >
              Supprimer
            </button>
          ) : null}
        </ul>
      </div>
    </div>
  );
}
