import dayjs from "dayjs";

/**
 * Détermine si une tâche doit être affichée dans la cellule d'heure spécifiée.
 *
 * @param {dayjs.Dayjs} taskDate - La date de début de la tâche.
 * @param {dayjs.Dayjs} cellHour - La date et l'heure représentant le début de la cellule d'heure.
 * @returns {boolean} - Retourne 'true' si la tâche doit être affichée dans la cellule d'heure, sinon 'false'.
 */
export default function shouldDisplayTask(
  taskDate: dayjs.Dayjs,
  cellHour: dayjs.Dayjs,
): boolean {
  // Convertit la date de début de la tâche en objet dayjs.
  const taskDateAsDayJs = dayjs(taskDate);

  // Vérifie si la tâche et la cellule d'heure sont le même jour.
  const sameDay = taskDateAsDayJs.isSame(cellHour, "day");

  // Vérifie si l'heure de la tâche est avant la première heure (7h) de la cellule.
  const beforeFirstHour = cellHour.hour() === 7 && taskDateAsDayJs.hour() < 7;

  // Vérifie si l'heure de la tâche est comprise dans la plage d'heures de la cellule.
  const withinHourRange =
    taskDateAsDayJs.hour() >= cellHour.hour() &&
    taskDateAsDayJs.hour() < cellHour.hour() + 1;

  // Vérifie si l'heure de la tâche est après la dernière heure (20h) de la cellule.
  const afterLastHour = cellHour.hour() === 20 && taskDateAsDayJs.hour() > 20;

  // Retourne 'true' si la tâche est le même jour que la cellule d'heure et si l'heure de la tâche est
  // dans la plage d'heures de la cellule ou après la dernière heure de la cellule.
  return sameDay && (withinHourRange || beforeFirstHour || afterLastHour);
}
