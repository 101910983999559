import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import SelectUser from "../../shared/SelectUser";
import { ADMINS_LIST } from "../../../API/modules/user/resolvers/queries";
import { EDIT_USER } from "../../../API/modules/user/resolvers/mutation";
import { UserRole } from "../../../API/models/types";

interface Props {
  adminId: string;
  userId: string;
  value: boolean;
}
export default function SelectAdmin(props: Props) {
  const { adminId, userId, value } = props;
  const [admin, setAdmin] = useState<string>(adminId);

  const [editUser] = useMutation(EDIT_USER);

  const updateAdmin = useCallback(async () => {
    try {
      if (userId !== null && userId !== "" && admin !== "" && admin !== null) {
        await editUser({
          variables: {
            userId,
            input: { adminId: admin, isActive: value, isVerified: true },
          },
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err); // Handle the error
    }
  }, [admin, editUser, userId, value]);

  useEffect(() => {
    if (admin !== null && admin !== "" && admin !== adminId) {
      updateAdmin();
    }
  }, [admin, adminId, updateAdmin]);

  return (
    <SelectUser
      query={ADMINS_LIST}
      queryName="adminsList"
      userRole={UserRole.ADMIN}
      setSelectedUserId={setAdmin}
      userId={admin}
    />
  );
}
