import React from "react";

interface Props {
  imgUrl: string | null;
}

function Avatar(props: Props) {
  const { imgUrl } = props;

  return imgUrl ? (
    <img
      className="w-10 h-10 p-1 rounded-full"
      src={imgUrl}
      alt="Bordered avatar"
    />
  ) : null;
}

export default Avatar;
