import React from "react";
import { Tooltip } from "flowbite-react";
import UserCell from "../table/cell-component/UserCell";
import { UserRole } from "../../API/models/types";
import getAvatarUrl from "../../pages/utils/getAvatarUrl";

interface UserAvatarTooltipProps {
  id: string;
  imgUrl: string | null;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
}

export default function UserAvatarTooltip({
  id,
  imgUrl,
  firstName,
  lastName,
  email,
  role,
}: UserAvatarTooltipProps) {
  function getUserInitials(userFirstName: string, userLastName: string) {
    const userInitials = `${userFirstName.charAt(0)}${userLastName.charAt(0)}`;
    return userInitials;
  }

  let avatarUrl = "";
  let typeOfUser = "";
  let userBadgeColor = "";
  let userBadgeTextColor = "";
  if (role) {
    switch (role) {
      case UserRole.CUSTOMER:
        avatarUrl = getAvatarUrl(role, imgUrl);
        typeOfUser = "Client";
        userBadgeColor = "bg-add-blue-dark";
        userBadgeTextColor = "text-white";
        break;
      case UserRole.SECRETARY:
        avatarUrl = getAvatarUrl(role, imgUrl);
        typeOfUser = "Secrétaire";
        userBadgeColor = "bg-add-green-light";
        userBadgeTextColor = "text-add-blue-dark";
        break;
      case UserRole.ADMIN:
        avatarUrl = getAvatarUrl(role, imgUrl);
        typeOfUser = "Secrétaire";
        userBadgeColor = "bg-orange-100";
        userBadgeTextColor = "text-add-blue-dark";
        break;
      default:
        break;
    }
  }

  return (
    <Tooltip
      content={
        <>
          <h4 className="text-xs font-bold border-b">{typeOfUser}</h4>
          {email
            ? UserCell(
                {
                  userName: `${lastName.toUpperCase()} ${firstName}`,
                  avatar: avatarUrl,
                  email,
                  role,
                  id,
                },
                false,
              )
            : "non renseigné"}
        </>
      }
      placement="top"
      // eslint-disable-next-line react/style-prop-object
      style="light"
    >
      <span
        className={`h-6 w-6 rounded-full font-bold text-xs p-1 cursor-pointer uppercase ${userBadgeTextColor} ${userBadgeColor} ${
          typeOfUser === "Client" && "ml-1"
        }`}
      >
        {getUserInitials(lastName, firstName)}
      </span>
    </Tooltip>
  );
}
