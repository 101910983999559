import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import locale from "dayjs/locale/fr";
import { HourMatrix } from "./getWeek";

export default function getDay(dayIndex: number): HourMatrix {
  dayjs().locale({ ...locale });
  dayjs.extend(utc);

  // Obtenir le jour courant et ajouter dayIndex
  const targetDay = dayjs().add(dayIndex, "day");

  // Créer un tableau contenant les heures de travail (de 8h00 à 18h00) pour le jour courant
  const hoursMatrix = new Array(14).fill(null).map((hour, hourIndex) => {
    // Obtenir l'heure en ajoutant hourIndex (0 à 10) à 8h00 du jour courant
    return targetDay.hour(7 + hourIndex);
  });

  // Retourner un objet contenant le jour courant et le tableau des heures de travail
  return {
    day: targetDay,
    hours: hoursMatrix,
  };
}
