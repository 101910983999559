import { Card, Label, TextInput } from "flowbite-react";
import React, { useContext, useState } from "react";
import { FaClipboardList } from "react-icons/fa";
import { useMutation } from "@apollo/client";
import { SubmitHandler, UseFormReturn, useForm } from "react-hook-form";
import { RESET_PASSWORD_FOR_CONNECTED_USER } from "../../API/modules/user/resolvers/mutation";
import { UserContext } from "../../context/userContext";

interface ResetMyPasswordUserVariables {
  resetPasswordToken: string;
  newPassword: string;
  currentPassword: string;
}
interface ResetMyPasswordUserFormData {
  resetPasswordToken: string;
  newPassword: string;
  currentPassword: string;
  confirmedPassword: string;
}

type ResetUserPasswordReturnWithErrors<T extends ResetMyPasswordUserFormData> =
  UseFormReturn<T> & {
    errors: Record<keyof T, any>;
  };
export default function EditPassword() {
  const [, setErrorMessage] = useState("");
  const [resetPasswordForConnectedUser, { loading, error: ApolloError }] =
    useMutation(RESET_PASSWORD_FOR_CONNECTED_USER);
  const { user } = useContext(UserContext);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetMyPasswordUserFormData>({
    mode: "onChange",
  }) as ResetUserPasswordReturnWithErrors<ResetMyPasswordUserFormData>;
  const onSubmit: SubmitHandler<ResetMyPasswordUserFormData> = async (
    data: ResetMyPasswordUserVariables,
  ) => {
    if (user !== null && user && data) {
      // initialisation du message de succès du changement de mot de passe
      setSuccessMessage("");
      try {
        const response = await resetPasswordForConnectedUser({
          variables: {
            resetPasswordToken: user.token,
            newPassword: data.newPassword,
            currentPassword: data.currentPassword,
          },
        });

        if (response && !ApolloError) {
          setSuccessMessage("Votre mot de passe a été changé avec succès.");
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      setErrorMessage("");
    }
  };

  return (
    <div className="flex flex-col w-full mt-5 mb-3 p-5">
      <Card>
        <form onSubmit={handleSubmit(onSubmit)} className="mb-10">
          <h3 className="font-bold mb-10">Gestion du mot de passe</h3>
          <span className="font-light text-gray-500 text-xs">
            (*) champs obligatoires
          </span>
          <div className="flex flex-col lg:flex-row gap-x-3 lg:gap-x-6 justify-around">
            <div className="mb-3 flex flex-col gap-y-3 w-full sm:w-1/2">
              <Label htmlFor="currentPassword" className="font-semibold">
                Mot de passe actuel *
              </Label>
              <TextInput
                id="currentPassword"
                placeholder="Entrez votre mot de passe actuel"
                type="password"
                // onChange={(e: any) => setCurrentPassword(e.target.value)}
                {...register("currentPassword", {
                  required: true,
                  pattern:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                })}
              />
              {errors.currentPassword &&
                errors.currentPassword.type === "required" && (
                  <span
                    className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    Ce champ est requis
                  </span>
                )}
              {errors.currentPassword &&
                errors.currentPassword.type === "pattern" && (
                  <span
                    className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    Le mot de passe doit contenir au moins 8 caractères, dont
                    une majuscule, une minuscule et un chiffre.
                  </span>
                )}
            </div>
            <div className="mb-3 flex flex-col gap-y-3 w-full sm:w-1/2">
              <Label htmlFor="newPassword" className="font-semibold">
                Nouveau mot de passe *
              </Label>
              <TextInput
                id="newPassword"
                placeholder="Entrez votre nouveau mot de passe"
                type="password"
                // onChange={(e: any) => setNewPassword(e.target.value)}
                {...register("newPassword", {
                  required: true,
                  pattern:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                })}
              />
              {errors.newPassword && errors.newPassword.type === "required" && (
                <span
                  className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  Ce champ est requis
                </span>
              )}
              {errors.newPassword && errors.newPassword.type === "pattern" && (
                <span
                  className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  Le mot de passe doit contenir au moins 8 caractères, dont une
                  majuscule, une minuscule et un chiffre.
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row mb-6 lg:pr-6 justify-start">
            <div className="mb-3 flex flex-col gap-y-3 w-full sm:w-1/2">
              <Label htmlFor="confirmedPassword" className="font-semibold">
                Confirmer le nouveau mot de passe *
              </Label>
              <TextInput
                id="confirmedPassword"
                placeholder="Confirmer le nouveau mot de passe"
                type="password"
                {...register("confirmedPassword", {
                  required: true,
                  validate: (value) => value === watch("newPassword"),
                })}
              />
              {errors.confirmedPassword &&
                errors.confirmedPassword.type === "required" && (
                  <span
                    className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    Ce champ est requis
                  </span>
                )}
              {errors.confirmedPassword &&
                errors.confirmedPassword.type === "pattern" && (
                  <span
                    className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    Le mot de passe doit contenir au moins 8 caractères, dont
                    une majuscule, une minuscule et un chiffre.
                  </span>
                )}
              {errors.confirmedPassword &&
                errors.confirmedPassword.type === "required" && (
                  <span
                    className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    Ce champ est requis
                  </span>
                )}
              {errors.confirmedPassword && (
                <span
                  className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  Les mots de passe ne correspondent pas
                </span>
              )}
              {ApolloError?.message && (
                <span
                  className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  {ApolloError.message.includes(
                    "le mot de passe actuel renseigné est incorrect",
                  )
                    ? ApolloError.message
                    : "Une erreur est survenue. Veuillez réessayer plus tard."}
                </span>
              )}
              {successMessage && (
                <span
                  className="p-4 text-sm text-green-800 rounded-lg bg-green-50"
                  role="alert"
                >
                  {successMessage}
                </span>
              )}
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="flex flex-row justify-center button-add bg-add-blue-dark p-2 font-semibold text-sm w-full sm:w-1/4"
            >
              <FaClipboardList className="h-4 w-4 mr-2" />
              {loading
                ? "Modification de votre mot de passe ..."
                : "Enregistrer"}
            </button>
          </div>
        </form>
        <div className="flex flex-col">
          <p className="font-semibold text-sm mb-3">Règles du mot de passe :</p>

          <span className="text-xs">
            Assurez vous que ces critères soient respectés
          </span>
          <span className="text-xs text-gray-500">Au moins 8 caractères</span>
          <span className="text-xs text-gray-500">
            Au moins 1 caractère en majuscule
          </span>
          <span className="text-xs text-gray-500">Au moins 1 chiffre</span>
          {/* <span className="text-xs text-gray-500">
            Au moins 1 caractère spécial
          </span> */}
        </div>
      </Card>
    </div>
  );
}
