import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { HiShieldCheck } from "react-icons/hi";
import imgParlezNous from "../assets/illustrations/img-parlez-nous.png";
import logoWithText from "../assets/logo/logo-add-with-text.png";
import { VERIFY_USER_EMAIL } from "../API/modules/user/resolvers/mutation";
import Steps from "../components/OnBoardingSteps/Steps";

type VerifyEmailParams = {
  token: string;
} & Record<string, string | undefined>;

function EmailConfirmed() {
  // Je récupère le verifyEmailToken dans l'url (le lien qui était dans l'email utilisateur)
  const { verifyEmailToken } = useParams<VerifyEmailParams>();
  const [verificationMessage, setVerificationMessage] = useState("");

  const [userEmailVerification] = useMutation(VERIFY_USER_EMAIL);

  useEffect(() => {
    if (verifyEmailToken) {
      userEmailVerification({
        variables: { input: { token: verifyEmailToken } },
      })
        .then((response) => {
          const message = response.data.userEmailVerification;
          setVerificationMessage(message);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setVerificationMessage(
            "Une erreur est survenue lors de la vérification de votre email.",
          );
        });
    }
  }, [verifyEmailToken, userEmailVerification]);

  return (
    <main className="lg:h-screen flex">
      <div className="h-full relative w-1/3 hidden lg:block">
        <Link to="/se-connecter">
          <img
            src={logoWithText}
            alt="logo add"
            className="absolute w-12 lg:w-24 left-1/2 lg:top-14 transform -translate-x-1/2 -translate-y-1/2 lg:-translate-y-1/3"
          />
        </Link>
        <img
          src={imgParlezNous}
          alt="illustration inscription"
          className="bg-add-green h-full object-contain object-center p-6 xl:p-12"
        />
      </div>
      <div className="flex flex-col items-center justify-center w-full">
        <Steps currentStepIndex={2} />
        <div className="flex flex-col items-center justify-center w-fit lg:relative px-6 lg:px-0 gap-y-6">
          <HiShieldCheck className="w-16 h-16 fill-green-400" />
          <h1 className="text-center">{verificationMessage}</h1>
          <div
            className="p-2 text-sm text-gray-800 rounded-lg bg-blue-100 text-center"
            role="alert"
          >
            <p>Un administrateur va prendre contact avec vous rapidement.</p>
          </div>
          <Link
            to="/login"
            className="button-add bg-add-blue-dark p-2 font-semibold uppercase"
          >
            Se connecter
          </Link>
          <img
            src={logoWithText}
            alt="logo add"
            className="w-32 h-32 mt-10 lg:hidden"
          />
        </div>
      </div>
    </main>
  );
}

export default EmailConfirmed;
