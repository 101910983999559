import React from "react";
import { useDateRange } from "./context/DateRangeContext";
import { GetSubscriptionsData_getSubscriptionsData } from "../../../../../API/models/types";

interface NumberOfUsersJoinedChartProps {
  data: GetSubscriptionsData_getSubscriptionsData;
}

function NumberOfUsersJoined({ data }: NumberOfUsersJoinedChartProps) {
  const [selectedDateRange] = useDateRange();

  const finalTotalUsers =
    selectedDateRange === "7"
      ? data?.option_7?.finalTotalUsers
      : data?.option_30?.finalTotalUsers;

  let content;
  if (finalTotalUsers && finalTotalUsers > 0) {
    content = `${finalTotalUsers} utilisateurs`;
  } else {
    content = "Aucun utilisateur n'a rejoint l'application";
  }

  return (
    <div className="shrink-0">
      <span className="text-xl font-bold leading-none text-add-dark sm:text-3xl">
        {content}
      </span>
      {finalTotalUsers && finalTotalUsers > 0 ? (
        <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
          Ont rejoint l'application
        </h3>
      ) : (
        <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
          Sur la période sélectionnée
        </h3>
      )}
    </div>
  );
}

export default NumberOfUsersJoined;
