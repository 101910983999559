import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import ProfileButton from "../../../components/table/cell-component/ProfileButton";

import UserCell from "../../../components/table/cell-component/UserCell";

import { ItemAdminsList, UserInformation } from "../../models/models";
import TableNotPaginateData from "../../../components/table/TableNotPaginateData";

interface Props {
  data: ItemAdminsList[];
  // currentUserRole: UserRole;
  rowByPage: number;
  page: number;
}
export default function TableAdminsList(props: Props) {
  const { data, rowByPage, page } = props;

  // header table
  const cols = useMemo<ColumnDef<ItemAdminsList>[]>(
    () =>
      [
        {
          header: "Admins",

          cell: (row: any) => {
            const value: UserInformation = row.getValue() as UserInformation;

            return UserCell(value, false);
          },
          accessorKey: "admin",
          // desactive a la recherche
          enableGlobalFilter: false,
        },
        {
          header: "userNameAdmin",
          cell: (row: any) => {
            return row.renderValue() as string;
          },
          accessorKey: "userNameAdmin",
          filter: "fuzzy",
        },
        {
          header: "Téléphone",
          cell: (row: any) => {
            return row.renderValue();
          },
          accessorKey: "phone_number",
          enableGlobalFilter: false,
        },
        {
          header: "Zone géographique",
          cell: (row: any) => {
            return row.getValue();
          },
          accessorKey: "geographicalArea",
          enableGlobalFilter: false,
        },
        {
          header: " ",
          enableSorting: false,
          cell: (getContext: any) => {
            const { id } = getContext.row.original;
            const path: string = `/profil/${id}`;
            return ProfileButton(path as string);
          },
          accessorKey: "profilePath",
        },
      ].filter((col) => col !== null),
    [],
  );

  return (
    <div>
      {" "}
      <TableNotPaginateData
        data={data}
        columns={cols}
        showFooter
        showNavigation
        showGlobalFilter
        showButtonAddItem
        labelButtonAddItem="Ajouter un admin"
        pathButtonAddItem="/listes-admin/creation-admin"
        showButtonActionItem={false}
        showFilterButton={false}
        hiddenColumns={{ userNameAdmin: false }}
        rowByPage={rowByPage}
        page={page}
      />{" "}
    </div>
  );
}
