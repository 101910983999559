/* eslint-disable react/require-default-props */
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  ColumnDef,
  getPaginationRowModel,
  getFilteredRowModel,
  SortingState,
  getSortedRowModel,
  VisibilityState,
} from "@tanstack/react-table";
import "./pagination.css";
import React, { FormEvent, useContext, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies

import ButtonAddItem from "./button/ButtonAddItem";
import ButtonActionItem from "./button/ButtonActionItem";

import SearchBar from "./button/SearchBar";
import ButtonFilterTable from "./button/ButtonFilterTable";
// eslint-disable-next-line import/no-cycle
import SelectDateFilter from "./filter/SelectDateFilter";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../context/FilterContext";
import { CustomElements, CustomForm } from "../../pages/models/models";
import { createFuzzyFilter } from "./filter/FilterFunction";

// import { UserRole } from "../../API/models/types";
// import { UserContext } from "../../context/userContext";

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  // visibilité des informations sur les page (nombre, page actuelle ...)
  showFooter: boolean;
  // visibilité pagination
  showNavigation: boolean;
  // visibilité searchBar + filtre
  // TODO séparer les 2
  showGlobalFilter: boolean;
  // visibilité bouton d'ajout d'un item
  showButtonAddItem: boolean;
  // label bouton d' ajout d'un item
  labelButtonAddItem: string;
  // path vers formulaire création
  pathButtonAddItem: string;
  // visibilité bouton d'action
  showButtonActionItem: boolean;
  // label bouton d'action
  labelButtonActionItem?: string;
  // Méthode pour l'action onClick du bouton ButtonAction
  handleActionButtonActionItem?: () => void;

  // colonne cachée en fonction du role
  // eslint-disable-next-line react/require-default-props
  hiddenColumns?: VisibilityState;
  // affichage modale des filtres
  modalFilter?: boolean;
  // affichage bouton filter
  showFilterButton: boolean;
  showSelectDateFilter?: boolean;
  setModalFilter?: (modalFilter: boolean) => void;
  totalItem?: number;
}

export default function Table<T extends object>({
  data,
  columns,
  showFooter,
  showGlobalFilter = false,
  showButtonAddItem = false,
  showButtonActionItem = false,
  labelButtonActionItem = "",
  showFilterButton = true,
  labelButtonAddItem,
  pathButtonAddItem,
  handleActionButtonActionItem,
  hiddenColumns,
  modalFilter,
  showSelectDateFilter = false,
  setModalFilter,
}: ReactTableProps<T>) {
  // this is the search value
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnVisibility] = React.useState<VisibilityState>(
    hiddenColumns || {},
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: createFuzzyFilter,
    },
    state: {
      globalFilter,
      sorting,
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const { filters, setFilters, pageKey, searchKey } =
    useContext<FilterTasksContextType>(FilterTasksContext);
  // const { user } = useContext(UserContext);
  const initialSearch =
    localStorage.getItem(searchKey) &&
    JSON.parse(localStorage.getItem(searchKey) as string).search;
  const [search, setSearch] = useState<string>(initialSearch || "");

  const onSubmit = (event: FormEvent<CustomForm>) => {
    event.preventDefault();
    // Validate form data
    const inputData: CustomElements =
      event && event.currentTarget && event.currentTarget.elements;
    if (filters && setFilters && inputData && inputData.search) {
      setFilters({
        ...filters,
        search: String(inputData.search.value).toLowerCase(),
        page: 1,
      });
      // reinitialisation de la page à 1
      localStorage.setItem(pageKey, JSON.stringify({ page: 1 }));
      // }
      localStorage.setItem(
        searchKey,
        JSON.stringify({
          search: String(inputData.search.value).toLowerCase(),
        }),
      );
      setGlobalFilter(String(inputData.search.value));

      setSearch(String(inputData.search.value).toLowerCase());
    }
  };
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col">
        <div className="flex flex-col sm:flex-row sm:basis-10/12 xl:basis-8/12 xxl:basis-10/12 mr-2 ">
          {showSelectDateFilter ? (
            <div className="mr-2 mb-2 w-3/5 sm:w-2/5 lg:w-2/4 xl:w-1/6">
              <SelectDateFilter />
            </div>
          ) : null}
          {showGlobalFilter ? (
            <div className="mr-2 mb-2 w-3/5 sm:w-2/5 lg:w-1/3 xl:w-1/6">
              <SearchBar
                value={search ?? ""}
                setValue={setSearch}
                onSubmit={onSubmit}
              />
            </div>
          ) : null}
          {showFilterButton ? (
            <div className="mb-2">
              <ButtonFilterTable
                isOpen={modalFilter as boolean}
                setOpen={setModalFilter as (open: boolean) => void}
              />
            </div>
          ) : null}
        </div>

        {showButtonAddItem ? (
          <div>
            <div className="mb-2">
              <ButtonAddItem
                label={labelButtonAddItem}
                path={pathButtonAddItem}
              />
            </div>
          </div>
        ) : null}
        {showButtonActionItem ? (
          <div className="basis-2/12 p-1">
            <ButtonActionItem
              label={labelButtonActionItem}
              handleAction={handleActionButtonActionItem as () => void}
            />
          </div>
        ) : null}
      </div>
      <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full min-h-full divide-y divide-gray-200">
              <thead className="bg-gray-10">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="px-4 text-left text-xs font-medium text-gray-400 uppercase rounded-sm tracking-wider"
                      >
                        {header.isPlaceholder ? null : (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: "▲",
                              desc: "▼",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className='border-b" bg-white'>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        className="px-2 whitespace-nowrap h-full text-xs"
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              {showFooter ? (
                <tfoot className="border-t bg-gray-50">
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.footer,
                                header.getContext(),
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              ) : null}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
