import { Modal, Label, Select, Checkbox } from "flowbite-react";
import React, { useCallback, useState } from "react";
import StatusCell from "../../components/table/cell-component/StatusCell";
import { useUserContext } from "../../context/userContext";
import { UserRole } from "../../API/models/types";
import { ItemFrameworkAgreement } from "../models/models";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  datas: ItemFrameworkAgreement[];
  setDatas: (data: ItemFrameworkAgreement[]) => void;
}
export default function ModalFilterTableFrameworkAgreement(props: Props) {
  // ouverture fermeture modale + data à filtrer
  const { open, setOpen, datas, setDatas } = props;
  // state formulaire filtrage
  const [contractName, setContractName] = useState<string>("Tous les contrats");
  const [activateStatus, setActivateStatus] = useState<boolean>(true);
  const [archivedStatus, setArchivedStatus] = useState<boolean>(true);
  const [customer, setCustomer] = useState<string>("Tous les clients");
  // fonction changement d'etat au click de l'ouverture de la modale
  // fonction initialiser le filtre
  const handleClick = () => {
    setContractName("Tous les contrats");
    setActivateStatus(true);
    setArchivedStatus(true);
    setCustomer("Tous les clients");
  };

  // application du filtre sur la donnée
  const handleSubmit = useCallback(() => {
    let filterDatas: ItemFrameworkAgreement[] = [...datas];

    // filtre client et secretaire

    if (customer !== "" && customer !== "Tous les clients") {
      filterDatas = filterDatas.filter(
        (element) => element.client?.id === customer,
      );
    }
    // filtre statut
    if (activateStatus === false) {
      filterDatas = filterDatas.filter(
        (element) => element.status !== "activé",
      );
    }
    if (archivedStatus === false) {
      filterDatas = filterDatas.filter(
        (element) => element.status !== "désactivé",
      );
    }
    // filtre nom du contrat
    if (contractName !== "" && contractName !== "Tous les contrats") {
      filterDatas = filterDatas.filter(
        (element) => element.contract === contractName,
      );
    }
    setDatas(filterDatas);
    setOpen(!open);
    localStorage.setItem(
      "myFrameworkAgreementsListPage",
      JSON.stringify({ page: 1 }),
    );
  }, [
    activateStatus,
    archivedStatus,
    contractName,
    customer,
    datas,
    open,
    setDatas,
    setOpen,
  ]);
  // contexte user
  const { user } = useUserContext();

  // options select nom du contrat
  const optionsContractSelect = () => {
    // fonction ppur avoir la liste des clients sélectionnables
    return (
      datas &&
      datas.map((element) => {
        return (
          <option key={element.id} value={element.contract}>
            {element.contract}
          </option>
        );
      })
    );
  };
  // options select en fonction du role
  const optionsCustomerSelect = () => {
    // fonction ppur avoir la liste des clients sélectionnables
    const customers = datas.map((element: ItemFrameworkAgreement) => {
      return element.client?.id;
    });
    // fonction pour avoir un tableau d' id avec des clients uniques
    let uniqueCustomers = customers.filter(
      (element, index) => customers.indexOf(element) === index,
    );
    // construction du tableau pour les champs de séléction des clients
    const customersOptions: ItemFrameworkAgreement[] = [];
    datas.map((element: ItemFrameworkAgreement) => {
      if (
        uniqueCustomers.includes(element.client?.id) &&
        uniqueCustomers.length > 0
      ) {
        const index: number = uniqueCustomers.indexOf(element.client?.id);
        if (uniqueCustomers.length > 1) {
          uniqueCustomers.splice(index, 1);
        } else {
          uniqueCustomers = [];
        }
        customersOptions.push(element);
      }
      return element;
    });
    // retour options
    return (
      customersOptions.length > 0 &&
      customersOptions.map((element: ItemFrameworkAgreement) => {
        return (
          <option key={element.id} value={element.client && element.client.id}>
            {element.client && element.client.userName}
          </option>
        );
      })
    );
  };

  return (
    <Modal size="sm" show={open} popup onClose={() => setOpen(false)}>
      <div className=" w-full border-b-2 border-gray-200 mb-5">
        <Modal.Header>
          <span className="font-semibold">Filtres</span>
        </Modal.Header>
      </div>
      <Modal.Body>
        <div className="flex justify-left mb-3">
          <button
            type="button"
            className="px-2 py-1 button-add bg-add-blue-dark text-sm mr-3"
            onClick={handleSubmit}
          >
            Appliquer
          </button>
          <button
            type="button"
            className="px-2 py-1 button-add bg-add-blue-dark text-sm"
            onClick={handleClick}
          >
            Vider
          </button>
        </div>
        <div className="mb-3">
          <div className="mb-2 block">
            <Label
              htmlFor="contractNameSelectFilterQuoteAgreement"
              value="Sélectionnez un nom de contrat"
            />
          </div>
          <Select
            value={contractName}
            id="contractNameSelectFilterQuoteAgreement"
            onChange={(e: any) => {
              setContractName(e.target.value);
            }}
          >
            <option value="Tous les contrats">Tous les contrats</option>
            {optionsContractSelect()}
          </Select>
        </div>

        {user.role !== UserRole.CUSTOMER ? (
          <div className="mb-3">
            <div className="mb-2 block">
              <Label
                htmlFor="customerSelectFilterQuoteAgreement"
                value="Sélectionnez un client"
              />
            </div>
            <Select
              value={customer}
              onChange={(e: any) => {
                setCustomer(e.target.value);
              }}
              id="customerSelectFilterQuoteAgreement"
            >
              <option value="">Tous les clients</option>
              {optionsCustomerSelect()}
            </Select>
          </div>
        ) : null}

        <div
          className="flex flex-col gap-4"
          id="checkboxfilterActivityQuoteAgreement"
        >
          <Label htmlFor="checkboxfilterActivityQuoteAgreement">Statut</Label>
          <div className="flex items-center gap-2">
            <Checkbox
              id="isActiveQuoteAgreements"
              checked={activateStatus}
              onChange={() => {
                setActivateStatus(!activateStatus);
              }}
            />
            {StatusCell("activé")}
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              id="isNotActiveQuoteAgreements"
              checked={archivedStatus}
              onChange={() => {
                setArchivedStatus(!archivedStatus);
              }}
            />
            {StatusCell("désactivé")}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
