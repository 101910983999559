import { DocumentNode, gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_CATEGORY_TASKS_TYPE: DocumentNode = gql`
  query GetAllCategoriesTaskType {
    getAllCategoriesTaskType {
      id
      createdAt
      updatedAt
      label
    }
  }
`;
export const GET_ONE_CATEGORY_TASK_TYPE: DocumentNode = gql`
  query GetOneCategoryTaskType($id: String!) {
    getOneCategoryTaskType(id: $id) {
      id
      createdAt
      updatedAt
      label
    }
  }
`;
export const GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES: DocumentNode = gql`
  query GetAllCategoryTaskTypeWithTaskTypes {
    getAllCategoryTaskTypeWithTaskTypes {
      id
      label
      taskTypes {
        id
        label
      }
    }
  }
`;
