import { useQuery } from "@apollo/client";
import React from "react";
import { FiDownload } from "react-icons/fi";
import { UserRole } from "../../API/models/types";
import { DOWNLOAD_CSV_FRAMEWORK_AGREEMENTS_LIST } from "../../API/modules/framework-agreement/resolvers/queries";
import SpinnerComponent from "./SpinnerComponent";
import generateFileNameWithDate from "../../pages/utils/generateFileNameWithDate";

interface Props {
  fileName: string;
  userRole: UserRole;
  userId: string;
}
export default function DownloadFrameworkAgreementsCsvButton(props: Props) {
  const { fileName, userRole, userId } = props;
  const {
    data: csvDataFromQuery,
    loading,
    error,
  } = useQuery(DOWNLOAD_CSV_FRAMEWORK_AGREEMENTS_LIST, {
    variables: {
      role: userRole,
      userId,
    },
  });

  const csvData = csvDataFromQuery
    ? csvDataFromQuery.downloadCsvFrameworkAgreementsList
    : { csvData: "" };

  const downloadCSV = async () => {
    const csvContent = `data:text/csv;charset=utf-8,${csvData.csvData}`; // Préparer le contenu CSV pour le téléchargement
    const encodedUri = encodeURI(csvContent); // Encoder le contenu CSV
    const link = document.createElement("a"); // Créer un élément <a> pour télécharger le CSV
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", generateFileNameWithDate(fileName, "csv"));
    document.body.appendChild(link); // Ajouter l'élément <a> à la page
    link.click(); // Simuler un clic sur l'élément <a> pour lancer le téléchargement
    document.body.removeChild(link); // Retirer l'élément <a> de la page
  };
  // eslint-disable-next-line no-nested-ternary
  return error ? (
    <p>problème de téléchargement du fichier csv</p>
  ) : loading ? (
    <SpinnerComponent />
  ) : (
    <button
      type="button"
      className="text-xs bg-add-blue-dark button-add font-medium rounded-lg px-4 p-3 text-center inline-flex items-center"
      onClick={downloadCSV}
    >
      <FiDownload className="mr-1" />
      Export
    </button>
  );
}
