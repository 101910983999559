import { ColumnDef } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import Table from "../../../../components/table/Table";
import {
  FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries,
  FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks,
  TaskStatus,
} from "../../../../API/models/types";
import {
  EDIT_TASK,
  REMOVE_TASK,
} from "../../../../API/modules/task/resolvers/mutations";
import DropdownActionItem from "../../../../components/table/button/DropdownActionItem";
import DateCell from "../../../../components/table/cell-component/DateCell";
import TaskStatusCell from "../../../../components/table/cell-component/TaskStatusCell";
import UserCell from "../../../../components/table/cell-component/UserCell";
import { getOneTask } from "../../../utils/QueriesTasks";
import ModalFilterSecretaryTableTasks from "../../modale/ModalFilterSecretaryTableTasks";
import ModalTaskRow from "../../modale/ModalTaskRow";
import UpdateTaskBySecretaryModal from "../../modale/UpdateTaskBySecretaryModal";
import { ItemNotPendingTask, UserInformation } from "../../../models/models";

interface TableNotPendingTasksProps {
  getAllNotPendingTasks: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks[];
  data: ItemNotPendingTask[];
}
export default function TableNotPendingTasks(props: TableNotPendingTasksProps) {
  const { data, getAllNotPendingTasks } = props;
  const [modalFilter, setModalFilter] = useState<boolean>(false);
  // Modal détail de la tâche : "voir"
  const [modalRow, setModalRow] = useState<boolean>(false);
  const [modalItemRow, setModalItemRow] = useState<
    | FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries
    | FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks
  >();

  // Modal détail de la tâche : "edit"
  const [modalEditRow, setModalEditRow] = useState<boolean>(false);

  const cols = useMemo<ColumnDef<ItemNotPendingTask>[]>(
    () => [
      {
        header: "Tâches",
        cell: (row) => row.renderValue(),
        accessorKey: "label",
      },
      {
        header: "Date et heure",
        cell: (row) => {
          const value: string = row.getValue() as string;
          const isIso = true;
          return DateCell(value, isIso);
        },
        accessorKey: "startDate",
      },
      {
        header: "Durée prév.",
        cell: (row) => row.renderValue(),
        accessorKey: "plannedDuration",
        enableSorting: false,
      },
      {
        header: "Durée réelle",
        cell: (row) => row.renderValue(),
        accessorKey: "realDuration",
        enableSorting: false,
      },
      {
        header: "Catégorie",
        cell: (row) => row.renderValue(),
        accessorKey: "category",
      },
      {
        header: "Client",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => {
          const value: UserInformation = row.getValue() as UserInformation;
          return value && value.id ? (
            UserCell(value, false)
          ) : (
            <div className="bg-blue-200 p-2">Tâche privée, pas de client</div>
          );
        },
        accessorKey: "customer",
      },
      {
        header: "Statut",
        cell: (row) => {
          const value: string = row.getValue() as string;
          return TaskStatusCell(value as TaskStatus);
        },
        accessorKey: "status",
        enableSorting: false,
      },
      {
        header: " ",
        cell: (getContext) => {
          return DropdownActionItem({
            pathToEditRow: undefined,
            mutationToEditRow: EDIT_TASK,
            mutationToRemoveRow: REMOVE_TASK,

            // si le customer n' existe pas c' est une tâche privée donc on peut l' effacer
            showDeleteRow: !getContext.row.original.customer.id,
            showEditRow: true,
            showViewRow: true,
            item: getOneTask(getContext.row.original.id, getAllNotPendingTasks),
            setModal: setModalRow,
            setModalEdit: setModalEditRow,
            setItem: setModalItemRow,
          });
        },
        accessorKey: "",
      },
    ],
    [getAllNotPendingTasks],
  );

  return (
    <div className="w-full h-full m-auto">
      <Table
        data={data as ItemNotPendingTask[]}
        columns={cols}
        showFooter
        showNavigation
        showGlobalFilter
        showButtonAddItem
        showButtonActionItem={false}
        showSelectDateFilter
        labelButtonActionItem=""
        labelButtonAddItem="Ajouter une tâche privée"
        pathButtonAddItem="/liste-des-taches-en-cours/creation-tache-privee"
        modalFilter={modalFilter}
        setModalFilter={setModalFilter}
        showFilterButton
      />
      <ModalTaskRow open={modalRow} setOpen={setModalRow} item={modalItemRow} />
      <UpdateTaskBySecretaryModal
        showModal={modalEditRow}
        setShowModal={setModalEditRow}
        data={
          modalItemRow as FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks
        }
      />
      <ModalFilterSecretaryTableTasks
        open={modalFilter}
        setOpen={setModalFilter}
        datas={data as ItemNotPendingTask[]}
      />{" "}
    </div>
  );
}
