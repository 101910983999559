import { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DocumentNode } from "graphql";
import ReactPaginate from "react-paginate";
import DateCell from "../../../components/table/cell-component/DateCell";
import StatusCell from "../../../components/table/cell-component/StatusCell";
import UserCell from "../../../components/table/cell-component/UserCell";
import DropdownActionItem from "../../../components/table/button/DropdownActionItem";
import {
  EDIT_QUOTE_AGREEMENT,
  REMOVE_QUOTE_AGREEMENT,
} from "../../../API/modules/quote-agreement/resolvers/mutations";
import ModalQuoteAgreementRow from "../modale/ModalQuoteAgreementRow";
import { useUserContext } from "../../../context/userContext";
import ModalFilterTableQuoteAgreement from "../modale/ModalFilterTableQuoteAgreement";
import CurrentAmountComponent from "../component/CurrentAmountComponent";
import { UserRole } from "../../../API/models/types";
import {
  ItemQuoteAgreement,
  QuoteAgreementData,
  UserInformation,
} from "../../models/models";
import TableNotPaginateData from "../../../components/table/TableNotPaginateData";

interface Props {
  getOneQuoteAgreements: (
    value: string,
    value1: QuoteAgreementData[],
  ) => QuoteAgreementData;
  getAllQuoteAgreements: QuoteAgreementData[];
  refetchQueries: DocumentNode;
  data: ItemQuoteAgreement[];
}

export default function TableQuoteAgreement(props: Props) {
  // modal view row table
  const { getOneQuoteAgreements, getAllQuoteAgreements, refetchQueries, data } =
    props;
  const [modalRow, setModalRow] = useState<boolean>(false);
  const [modalItemRow, setModalItemRow] = useState<QuoteAgreementData>();
  // Modal détail de la tâche : "edit"
  const [, setModalEditRow] = useState<boolean>(false);
  // contexte user
  const { user } = useUserContext();

  const cols = useMemo<ColumnDef<ItemQuoteAgreement>[]>(
    () => [
      {
        header: "Contrat",
        cell: (row) => row.renderValue(),
        accessorKey: "contract",
        filter: "fuzzy",
      },
      {
        header: "Heures Prev",
        cell: (row) => row.renderValue(),
        accessorKey: "hourlyAmount",
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        header: "Heures Restantes",
        /* eslint-disable react/no-unstable-nested-components */
        cell: (row) => {
          return <CurrentAmountComponent value={row.renderValue() as string} />;
        },
        accessorKey: "currentAmount",
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        header: "Client",
        filter: "fuzzy",
        cell: (row) => {
          const value: UserInformation = row.getValue() as UserInformation;
          if (user.role !== UserRole.CUSTOMER) {
            return UserCell(value, false);
          }
          return null;
        },
        accessorKey: "client",
        enableGlobalFilter: false,
      },
      {
        header: "userNameClient",
        cell: (row) => row.renderValue(),
        accessorKey: "userNameClient",
        filter: "fuzzy",
      },
      {
        header: "Secrétaire",

        cell: (row) => {
          const value: UserInformation = row.getValue() as UserInformation;
          if (user.role !== UserRole.SECRETARY) {
            return UserCell(value, false);
          }
          return null;
        },
        accessorKey: "secretary",
        enableGlobalFilter: false,
      },
      {
        header: "userNameSecretary",
        cell: (row) => row.renderValue(),
        accessorKey: "userNameSecretary",
        filter: "fuzzy",
      },
      {
        header: "Date",
        cell: (row) => {
          const value: Date = row.getValue() as Date;
          return DateCell(
            value.toLocaleDateString("fr-fr", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
            false,
          );
        },
        accessorKey: "date",
        sortType: "datetime",
      },
      {
        header: "Statut",
        cell: (row) => {
          const value: string = row.getValue() as string;
          return StatusCell(value);
        },
        accessorKey: "status",
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        header: " ",
        enableSorting: false,
        cell: (getContext) => {
          return DropdownActionItem({
            pathToEditRow: "/contrat-devis/edition",
            mutationToEditRow: EDIT_QUOTE_AGREEMENT,
            mutationToRemoveRow: REMOVE_QUOTE_AGREEMENT,
            refetchQueries,
            showDeleteRow: false,
            showEditRow:
              user.role === UserRole.ADMIN ||
              user.role === UserRole.SUPER_ADMIN,
            showViewRow: true,
            item:
              getAllQuoteAgreements.length > 0 &&
              getOneQuoteAgreements(
                getContext.row.original.id,
                getAllQuoteAgreements,
              ),
            setModal: setModalRow,
            setModalEdit: setModalEditRow,
            setItem: setModalItemRow,
          });
        },
        accessorKey: "",
      },
    ],
    [getAllQuoteAgreements, getOneQuoteAgreements, refetchQueries, user.role],
  );

  // méthode pour cacher des colonnes en fonction du role

  const hiddenColumnsQuoteAgreementByRole = (
    role: UserRole,
  ): VisibilityState => {
    if (role === UserRole.CUSTOMER) {
      return { client: false, userNameClient: false, userNameSecretary: false };
    }
    if (role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN) {
      return { userNameSecretary: false, userNameClient: false };
    }
    if (role === UserRole.SECRETARY) {
      return {
        secretary: false,
        userNameSecretary: false,
        userNameClient: false,
      };
    }
    return {};
  };
  // ouverture fermeture fenetre modale pour filtrer la donnée
  const [modalFilter, setModalFilter] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<ItemQuoteAgreement[]>([]);
  const updateFilteredData = useCallback(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    updateFilteredData();
  }, [updateFilteredData]);
  // nombre de ligne par page de tableau
  const ROWBYPAGE: number = 6;

  // calcul nombre de page
  const nbPageFloat: number = filteredData && filteredData.length / ROWBYPAGE;

  // nombre de page arrondi à l' entier supérieur
  // const nbPage = Number(nbPageFloat.toFixed(0));
  const nbPage = Math.ceil(nbPageFloat);
  const myFilters: string | null = localStorage.getItem(
    "myQuoteAgreementsListPage",
  );
  const [page, setPage] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (myFilters && myFilters !== null) {
      setPage(myFilters && JSON.parse(myFilters) && JSON.parse(myFilters).page);
    } else {
      setPage(1);
    }
  }, [myFilters]);
  const handlePageChange = (event: any) => {
    setPage(event.selected + 1);
    localStorage.setItem(
      "myQuoteAgreementsListPage",
      JSON.stringify({ page: event.selected + 1 }),
    );
  };
  return (
    <div className="w-full">
      <TableNotPaginateData
        data={filteredData as ItemQuoteAgreement[]}
        columns={cols}
        showFooter
        showNavigation
        showGlobalFilter
        showButtonAddItem={
          user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN
        }
        showButtonActionItem={false}
        labelButtonAddItem="Ajouter un contrat"
        pathButtonAddItem="/contrat-devis/creation"
        hiddenColumns={hiddenColumnsQuoteAgreementByRole(user.role as UserRole)}
        modalFilter={modalFilter}
        setModalFilter={setModalFilter}
        showFilterButton
        rowByPage={ROWBYPAGE}
        page={page && page}
      />
      <ModalQuoteAgreementRow
        open={modalRow}
        setOpen={setModalRow}
        item={modalItemRow as QuoteAgreementData}
      />
      <ModalFilterTableQuoteAgreement
        open={modalFilter}
        setOpen={setModalFilter}
        datas={data as ItemQuoteAgreement[]}
        setDatas={setFilteredData}
      />
      <div className="flex flex-row justify-left text-center">
        <div className="basis-4/5 text-left">
          <span className="items-center gap-1 flex flex-row text-xs p-4">
            <p className="text-gray-400 text-xs">Page</p>
            <strong className="flex color:#40788B">{page && page}</strong>
            <p className="text-gray-400 text-xs">sur</p>
            {nbPage === 0 ? <strong>1</strong> : <strong> {nbPage}</strong>}
          </span>
        </div>
        <div className="basis-1/5">
          {nbPage !== 0 ? (
            <ReactPaginate
              onPageChange={handlePageChange}
              pageRangeDisplayed={ROWBYPAGE}
              pageCount={nbPage}
              forcePage={page ? page - 1 : 0}
              marginPagesDisplayed={2}
              nextLabel=">"
              containerClassName="pagination"
              previousLinkClassName="pagination__link"
              nextLinkClassName="pagination__link"
              disabledClassName="pagination__link--disabled"
              activeClassName="pagination__link--active"
              previousLabel="<"
              onPageActive={() => page}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
