import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import ActiveToggle from "../../../components/shared/ActiveToggle";
import ProfileButton from "../../../components/table/cell-component/ProfileButton";
import SelectAdmin from "../../../components/table/cell-component/SelectAdmin";
import UserCell from "../../../components/table/cell-component/UserCell";
import { UserRole } from "../../../API/models/types";
import { ItemSecretariesList, UserInformation } from "../../models/models";
import TableNotPaginateData from "../../../components/table/TableNotPaginateData";

interface Props {
  currentUserRole: UserRole;
  data: ItemSecretariesList[];
  rowByPage: number;
  page: number;
}
export default function TableSecretariesList(props: Props) {
  const { currentUserRole, data, rowByPage, page } = props;

  // header table
  const cols = useMemo<ColumnDef<ItemSecretariesList>[]>(
    () =>
      [
        {
          header: "Secrétaires",
          cell: (row: any) => {
            const value: UserInformation = row.getValue() as UserInformation;
            return UserCell(value, false);
          },
          accessorKey: "secretary",
          enableGlobalFilter: false,
        },

        {
          header: "userNameSecretary",
          cell: (row: any) => row.renderValue(),
          accessorKey: "userNameSecretary",
          filter: "fuzzy",
        },

        {
          header: "Téléphone",
          cell: (row: any) => {
            return row.renderValue();
          },
          accessorKey: "phone_number",
          enableGlobalFilter: false,
        },
        {
          header: "Zone géographique",
          cell: (row: any) => {
            return row.getValue();
          },
          accessorKey: "geographicalArea",
          enableGlobalFilter: false,
        },
        currentUserRole !== UserRole.CUSTOMER &&
        currentUserRole !== UserRole.ADMIN
          ? {
              header: "Affectation Admin",
              enableSorting: false,
              cell: (getContext: any) => {
                return SelectAdmin({
                  adminId: getContext.row.original.adminId as string,
                  userId: getContext.row.original.id as string,
                  value: getContext.row.original.isActive as boolean,
                });
              },
              accessorKey: "adminId",
            }
          : (null as never),

        currentUserRole !== UserRole.CUSTOMER &&
        currentUserRole !== UserRole.ADMIN
          ? {
              header: "Desactiver / Activer",
              enableSorting: false,
              cell: (getContext: any) => {
                return ActiveToggle({
                  value: getContext.row.original.isActive as boolean,
                  userId: getContext.row.original.id,
                  adminId: getContext.row.original.adminId as string,
                });
              },
              accessorKey: "isActive",
            }
          : (null as never),
        {
          header: " ",
          enableSorting: false,
          cell: (getContext: any) => {
            const { id } = getContext.row.original;
            const path: string = `/profil/${id}`;
            return ProfileButton(path as string);
          },
          accessorKey: "profilePath",
        },
      ].filter((col) => col !== null),
    [currentUserRole],
  );
  return (
    <div>
      {" "}
      <TableNotPaginateData
        data={data}
        columns={cols}
        showFooter
        showNavigation
        showGlobalFilter
        showButtonAddItem={false}
        showButtonActionItem={false}
        labelButtonAddItem=""
        pathButtonAddItem=""
        showFilterButton={false}
        hiddenColumns={{ userNameSecretary: false }}
        rowByPage={rowByPage}
        page={page}
      />
    </div>
  );
}
