import { DocumentNode, gql } from "@apollo/client";

export const DOWNLOAD_CSV_FRAMEWORK_AGREEMENTS_LIST: DocumentNode = gql`
  query DownloadCsvFrameworkAgreementsTasksList(
    $role: UserRole!
    $userId: String!
  ) {
    downloadCsvFrameworkAgreementsList(role: $role, userId: $userId) {
      csvData
    }
  }
`;
export const GET_ALL_FRAMEWORK_AGREEMENTS: DocumentNode = gql`
  query GetAllFrameworkAgreements {
    getAllFrameworkAgreements {
      id
      createdAt
      updatedAt
      label
      reference
      url
      description
      isActive
      user {
        id
        createdAt
        updatedAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
    }
  }
`;
// export const GET_FRAMEWORK_AGREEMENTS_FILTERED: DocumentNode = gql`
//   query filteredFrameworkAgreement(
//     $userId: String
//     $isActive: Boolean
//     $contractName: String
//   ) {
//     filteredFrameworkAgreement(
//       userId: $userId
//       contractName: $contractName
//       isActive: $isActive
//     ) {
//       id
//       createdAt
//       updatedAt
//       label
//       reference
//       url
//       description
//       isActive
//       user {
//         id
//         createdAt
//         updatedAt
//         admin {
//           id
//         }
//         first_name
//         last_name
//         avatar_url
//         phone_number
//         email
//         geographical_area
//         siret
//         business_name
//         experience
//         criticity_request
//         commentary
//         isActive
//         isVerified
//         role
//         taskTypes {
//           id
//           label
//           categoryTaskType {
//             id
//             label
//           }
//         }
//       }
//     }
//   }
// `;

export const GET_ALL_FRAMEWORK_AGREEMENTS_FOR_USER: DocumentNode = gql`
  query getAllFrameworkAgreementsForUser($userId: String!) {
    getAllFrameworkAgreementsForUser(userId: $userId) {
      id
      createdAt
      updatedAt
      label
      reference
      url
      description
      isActive
      user {
        id
        createdAt
        updatedAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
    }
  }
`;
export const GET_ALL_FRAMEWORK_AGREEMENTS_FOR_ADMIN: DocumentNode = gql`
  query GetAllFrameworkAgreementsForAdmin($adminId: String!) {
    getAllFrameworkAgreementsForAdmin(adminId: $adminId) {
      id
      createdAt
      updatedAt
      label
      reference
      url
      description
      isActive
      user {
        id
        createdAt
        admin {
          id
        }
        updatedAt
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
    }
  }
`;
export const GET_ONE_FRAMEWORK_AGREEMENTS: DocumentNode = gql`
  query GetOneFrameworkAgreement($id: String!) {
    getOneFrameworkAgreement(id: $id) {
      id
      createdAt
      updatedAt
      label
      reference
      url
      description
      isActive
      user {
        id
        createdAt
        updatedAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
    }
  }
`;
export const GET_FRAMEWORK_AGREEMENTS_PAGINATION: DocumentNode = gql`
  query frameworkAgreementPagination(
    $skip: Int!
    $take: Int!
    $sortBy: FrameworkAgreementPaginationSortBy
  ) {
    frameworkAgreementPagination(skip: $skip, take: $take, sortBy: $sortBy) {
      nodes {
        id
        createdAt
        updatedAt
        label
        reference
        url
        description
        isActive
        user {
          id
          createdAt
          updatedAt
          admin {
            id
          }
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
      }
    }
  }
`;
