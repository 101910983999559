import { Label, Modal, Select, ToggleSwitch } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { Task } from "../hook/useTasks";
import { UserRole } from "../../../API/models/types";
import { useUserContext } from "../../../context/userContext";

interface ModalFilterCalendarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  datas: Task[];
  setDatas: (data: Task[]) => void;
}

export interface InitialCalendarFilter {
  secretary?: string;
  customer?: string;
  showPrivateTasks: boolean;
}

export default function ModalFilterCalendar(props: ModalFilterCalendarProps) {
  // ouverture fermeture modale + data à filtrer
  const { open, setOpen, datas, setDatas } = props;

  // contexte user
  const { user } = useUserContext();

  // toggle d'affichage du select clients // secrétaires
  const [toggleSecretariesOrCustomers, setToggleSecretariesOrCustomers] =
    useState<boolean>(user.role === UserRole.SECRETARY);

  const [filteredData, setFilteredData] = useState<Task[]>([]);

  // Initialize filteredData without private tasks when component mounts
  useEffect(() => {
    const nonPrivateTasks = datas.filter((task) => !task.isPrivate);
    setFilteredData(nonPrivateTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // enregistrement localStorage des options du filtre pour un utilisateur
  // LocalStorage GET
  const myCalendarFilters: string | null =
    localStorage.getItem("myCalendarFilters");

  const [initialFilter, setInitialFilter] = useState<InitialCalendarFilter>(
    myCalendarFilters
      ? (JSON.parse(myCalendarFilters) as InitialCalendarFilter)
      : {
          secretary: "Toutes les secrétaires",
          customer: "Tous les clients",
          showPrivateTasks: false, // par défaut, on affiche pas les tâches privées
        },
  );

  // state formulaire filtrage
  const [secretary, setSecretary] = useState<string>(
    initialFilter.secretary ? initialFilter.secretary : "",
  );
  const [customer, setCustomer] = useState<string>(
    initialFilter.customer ? initialFilter.customer : "",
  );

  // récupération de l'état du filtre des tâches privées
  // Pour l'instant on réinitialise ce filtre à chaque remontage du composant à true
  // On devrait ajouter ce filtre dans la requête pour que les données ne soit pas initialisées
  // A chaque appel avec les tâches privées
  const [showPrivateTasks, setShowPrivateTasks] = useState<boolean>(
    initialFilter.showPrivateTasks ? initialFilter.showPrivateTasks : false,
  );

  // fonction initialiser le filtre
  const handleClick = () => {
    switch (user.role) {
      case UserRole.SECRETARY:
        setCustomer("Tous les clients");
        setToggleSecretariesOrCustomers(true); // Pour filtrer par clients
        setShowPrivateTasks(false); // Pour les secrétaires, on affiche pas les tâches privées par défaut
        break;
      case UserRole.CUSTOMER:
        setSecretary("Toutes les secrétaires");
        setToggleSecretariesOrCustomers(false); // Pour filtrer par secrétaires
        setShowPrivateTasks(false); // Pour les clients, on n'affiche pas les tâches privées par défaut
        break;
      default:
        setSecretary("Toutes les secrétaires");
        setCustomer("Tous les clients");
        setToggleSecretariesOrCustomers(false);
        setShowPrivateTasks(false); // Pour tous les autres utilisateurs, on n'affiche pas les tâches privées par défaut
    }
  };

  // application du filtre sur la donnée
  const handleSubmit = useCallback(() => {
    let filterDatas: Task[] = [...datas];

    // filtre client et secretaire
    if (secretary !== "" && secretary !== "Toutes les secrétaires") {
      filterDatas = filterDatas.filter(
        (element) => element.secretary?.id === secretary,
      );
    }

    if (customer !== "" && customer !== "Tous les clients") {
      filterDatas = filterDatas.filter(
        (element) => element.customer?.id === customer,
      );
    }

    // filtre des tâches privées
    if (!showPrivateTasks) {
      filterDatas = filterDatas.filter((element) => !element.isPrivate);
    }

    localStorage.setItem(
      "myCalendarFilters",
      JSON.stringify({
        secretary,
        customer,
        showPrivateTasks,
      }),
    );
    setInitialFilter({
      secretary,
      customer,
      showPrivateTasks,
    });
    if (filterDatas.length !== filteredData.length) {
      setFilteredData(filterDatas);
    }
    setDatas(filterDatas);
  }, [customer, datas, filteredData, secretary, showPrivateTasks, setDatas]);

  // Reset secretaries or customers filter when toggle is pressed
  useEffect(() => {
    if (toggleSecretariesOrCustomers) {
      setSecretary("Toutes les secrétaires");
    } else {
      setCustomer("Tous les clients");
    }
  }, [toggleSecretariesOrCustomers]);

  // ** MANAGE SELECT OPTIONS DISTINCTS **
  const optionsCustomerSelect = () => {
    // fonction ppur avoir la liste des clients sélectionnables
    const customers = datas.map((element) => {
      return element.customer?.id;
    });
    // fonction pour avoir un tableau d'id avec des clients uniques
    let uniqueCustomers = customers.filter(
      (element, index) => customers.indexOf(element) === index,
    );
    // construction du tableau pour les champs de séléction des clients
    const customersOptions: Task[] = [];
    datas.map((element: Task) => {
      if (
        element.customer?.id &&
        uniqueCustomers.includes(element.customer.id) &&
        uniqueCustomers.length > 0
      ) {
        const index: number = uniqueCustomers.indexOf(element.customer?.id);
        if (uniqueCustomers.length > 1) {
          uniqueCustomers.splice(index, 1);
        } else {
          uniqueCustomers = [];
        }
        customersOptions.push(element);
      }
      return element;
    });
    // retour options
    return (
      customersOptions.length > 0 &&
      customersOptions.map((element: Task) => {
        return (
          <option
            key={element.id}
            value={element.customer ? element.customer.id : ""}
          >
            {element.customer &&
              `${element.customer.last_name.toUpperCase()} ${
                element.customer.first_name
              }`}
          </option>
        );
      })
    );
  };

  const optionsSecretarySelect = () => {
    // fonction ppur avoir la liste des secrétaires sélectionnables
    const secretaries = datas.map((element) => {
      return element.secretary?.id;
    });
    // fonction pour avoir un tableau avec des donnees uniques
    let uniqueSecretaries = secretaries.filter(
      (element, index) => secretaries.indexOf(element) === index,
    );

    // construction du tableau pour les champs de sélection des secrétaires
    const secretariesOptions: Task[] = [];
    datas.map((element: Task) => {
      if (
        uniqueSecretaries.includes(element.secretary?.id) &&
        uniqueSecretaries.length > 0
      ) {
        const index: number = uniqueSecretaries.indexOf(element.secretary?.id);
        if (uniqueSecretaries.length > 1) {
          uniqueSecretaries.splice(index, 1);
        } else {
          uniqueSecretaries = [];
        }
        secretariesOptions.push(element);
      }
      return element;
    });
    // retour options
    return (
      secretariesOptions.length > 0 &&
      secretariesOptions.map((element: Task) => {
        return (
          <option
            key={element.id}
            value={element.secretary ? element.secretary.id : ""}
          >
            {element.secretary &&
              `${element.secretary.last_name.toUpperCase()} ${
                element.secretary.first_name
              }`}
          </option>
        );
      })
    );
  };

  return (
    <Modal size="sm" show={open} popup onClose={() => setOpen(false)}>
      <div className="w-full border-b-2 border-gray-200 mb-5">
        <Modal.Header>
          <p className="font-semibold">Filtres</p>
        </Modal.Header>
      </div>
      <Modal.Body>
        <div className="flex justify-left">
          <button
            type="button"
            className="button-add bg-add-blue-dark mr-2 text-sm"
            onClick={() => {
              handleSubmit();
              setOpen(!open);
            }}
          >
            Appliquer
          </button>

          <button
            type="button"
            className="px-2 py-1 button-add bg-add-blue-dark text-sm"
            onClick={handleClick}
          >
            Vider
          </button>
        </div>
        <div className="flex flex-col gap-4 my-6 w-fit" id="toggle">
          {user.role !== UserRole.CUSTOMER &&
          user.role !== UserRole.SECRETARY ? (
            <ToggleSwitch
              checked={toggleSecretariesOrCustomers}
              label={`Filtrer par ${
                toggleSecretariesOrCustomers ? "clients" : "secrétaires"
              }`}
              onChange={() =>
                setToggleSecretariesOrCustomers(!toggleSecretariesOrCustomers)
              }
            />
          ) : null}
        </div>
        {toggleSecretariesOrCustomers ? (
          <div className="mb-3">
            <div className="mb-2 block">
              <Label
                htmlFor="customerSelectCalendarFilter"
                value="Sélectionnez un client"
              />
            </div>
            <Select
              value={customer}
              onChange={(e: any) => {
                setCustomer(e.target.value);
              }}
              id="customerSelectCalendarFilter"
              disabled={!toggleSecretariesOrCustomers}
            >
              <option value="">Tous les clients</option>
              {optionsCustomerSelect()}
            </Select>
          </div>
        ) : (
          <div className="mb-3">
            <div className="mb-2 block">
              <Label
                htmlFor="secretarySelectCalendarFilter"
                value="Selectionnez une secrétaire"
              />
            </div>
            <Select
              value={secretary}
              disabled={toggleSecretariesOrCustomers}
              id="secretarySelectCalendarFilter"
              onChange={(e: any) => {
                setSecretary(e.target.value);
              }}
            >
              <option value="">Toutes les secrétaires</option>
              {optionsSecretarySelect()}
            </Select>
          </div>
        )}
        {user.role !== UserRole.CUSTOMER ? (
          <div className="mb-3">
            <div className="mb-2 block">
              <ToggleSwitch
                checked={showPrivateTasks}
                label="Afficher les tâches privées"
                onChange={() => setShowPrivateTasks(!showPrivateTasks)}
              />
            </div>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}
