import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { ApolloError } from "@apollo/client";
import {
  FindAgendaTasksForAdmin_findAgendaTasksForAdmin,
  FindAgendaTasksForCustomers_findAgendaTasksForCustomers,
  FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin,
  FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries,
  FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks,
  FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries,
  FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks,
  FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins,
  FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks,
  FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers,
  FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks,
  FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin,
  FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks,
  FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
  GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
  Recurrence,
  getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary,
} from "../../API/models/types";
import {
  FIND_AGENDA_TASKS_FOR_ADMIN,
  FIND_AGENDA_TASKS_FOR_CUSTOMERS,
  FIND_AGENDA_TASKS_FOR_SUPER_ADMIN,
  FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
  FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
  FIND_ALL_PAGINATE_TASKS_FOR_ADMINS,
  FIND_ALL_PAGINATE_TASKS_FOR_CUSTOMERS,
  FIND_ALL_PAGINATE_TASKS_FOR_SUPER_ADMIN,
  FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
} from "../../API/modules/task/resolvers/queries";

import { FilterTask, Option, OptionsSelect } from "../models/models";
import { GET_QUOTE_AGREEMENTS_BETWEEN_CUTOMER_AND_SECRETARY } from "../../API/modules/quote-agreement/resolvers/queries";
import { GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES } from "../../API/modules/category-task-type/resolvers/queries";

// liste tâches pour role super-admin
export function FindAgendaTasksForSuperAdmin(firstDayOfMonth?: Date) {
  const { data, loading, error } = useQuery(FIND_AGENDA_TASKS_FOR_SUPER_ADMIN, {
    variables: {
      startDate: firstDayOfMonth,
    },
    fetchPolicy: "cache-and-network",
  });
  const tasks = data
    ? ([
        ...data.findAgendaTasksForSuperAdmin,
      ] as FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin[])
    : ([] as FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin[]);
  return { tasks, loading, error };
}
// liste tâches paginées pour role super-admin
export function FindAllPaginateTasksForSuperAdmin(paginationArgs: FilterTask): {
  tasks: FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(
    FIND_ALL_PAGINATE_TASKS_FOR_SUPER_ADMIN,
    {
      variables: {
        paginationArgs,
      },
      fetchPolicy: "cache-and-network",
    },
  );

  const tasks = data
    ? (data.findAllPaginateTasksForSuperAdmin as FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin)
    : ({
        tasks: [],
        total: 0,
        __typename: "OutputPaginateTasks",
      } as FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin);

  return { tasks, loading, error };
}
// liste tâches paginées pas en attente pour role secretaire
export function FindAllPaginateNotPendingTasksForSecretaries(
  paginationArgs: FilterTask,
  userId: string,
): {
  tasks: FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(
    FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        paginationArgs,
        userId,
      },
    },
  );

  const tasks = data
    ? (data.findAllPaginateNotPendingTasksForSecretaries as FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries)
    : ({
        tasks: [],
        total: 0,
        hasPendingTask: false,
        __typename: "OutputPaginateTasks",
      } as FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries);

  return { tasks, loading, error };
}
// liste tâches paginées  en attente pour role secretaire
export function FindAllPaginatePendingTasksForSecretaries(
  paginationArgs: FilterTask,
  userId: string,
): {
  tasks: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(
    FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        paginationArgs,
        userId,
      },
    },
  );

  const tasks = data
    ? (data.findAllPaginatePendingTasksForSecretaries as FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries)
    : ({
        tasks:
          [] as FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks[],
        total: 0,
        __typename: "OutputPaginateTasks",
      } as FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries);

  return { tasks, loading, error };
}
// liste tâches paginées pour role customer
export function FindAllPaginateTasksForCustomers(
  paginationArgs: FilterTask,
  userId: string,
): {
  tasks: FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(
    FIND_ALL_PAGINATE_TASKS_FOR_CUSTOMERS,
    {
      variables: {
        paginationArgs,
        userId,
      },
      fetchPolicy: "cache-and-network",
    },
  );

  const tasks = data
    ? (data.findAllPaginateTasksForCustomers as FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers)
    : ({
        tasks:
          [] as FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks[],
        total: 0,
        __typename: "OutputPaginateTasks",
      } as FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers);

  return { tasks, loading, error };
}
// liste tâches paginées pour role admin
export function FindAllPaginateTasksForAdmins(
  paginationArgs: FilterTask,
  adminId: string,
): {
  tasks: FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(
    FIND_ALL_PAGINATE_TASKS_FOR_ADMINS,
    {
      variables: {
        paginationArgs,
        adminId,
      },
      skip: !adminId,
      fetchPolicy: "cache-and-network",
    },
  );

  const tasks = data
    ? (data.findAllPaginateTasksForAdmins as FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins)
    : ({
        tasks:
          [] as FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks[],
        total: 0,
        hasPendingTask: false,
        __typename: "OutputPaginateTasks",
      } as FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins);

  return { tasks, loading, error };
}

// liste tâches pour role admin
export function FindAgendaTasksForAdmin(
  adminId: string,
  firstDayOfMonth?: Date,
) {
  const { data, loading, error } = useQuery(FIND_AGENDA_TASKS_FOR_ADMIN, {
    variables: { adminId, startDate: firstDayOfMonth },
    skip: !adminId,
    fetchPolicy: "cache-and-network",
  });
  const tasks = data
    ? ([
        ...data.findAgendaTasksForAdmin,
      ] as FindAgendaTasksForAdmin_findAgendaTasksForAdmin[])
    : ([] as FindAgendaTasksForAdmin_findAgendaTasksForAdmin[]);
  return { tasks, loading, error };
}
// liste tâches pour role client
export function FindAgendaTasksForCustomers(
  userId: string,
  firstDayOfMonth?: Date,
) {
  const { data, loading, error } = useQuery(FIND_AGENDA_TASKS_FOR_CUSTOMERS, {
    variables: { customerId: userId, startDate: firstDayOfMonth },
    skip: !userId,
    fetchPolicy: "cache-and-network",
  });
  const tasks = data
    ? ([
        ...data.findAgendaTasksForCustomers,
      ] as FindAgendaTasksForCustomers_findAgendaTasksForCustomers[])
    : ([] as FindAgendaTasksForCustomers_findAgendaTasksForCustomers[]);

  return { tasks, loading, error };
}
// liste tâches pour secretaire
export function FindNotPendingAgendaTasksForSecretaries(
  userId: string,
  firstDayOfMonth?: Date,
) {
  const { data, loading, error } = useQuery(
    FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
    {
      variables: { input: { userId, startDate: firstDayOfMonth } },
      skip: !userId,
      fetchPolicy: "cache-and-network",
    },
  );

  const tasks = data
    ? ([
        ...data.findNotPendingAgendaTasksForSecretaries,
      ] as FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries[])
    : ([] as FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries[]);

  return { tasks, loading, error };
}

type TaskArray =
  | FindAllPaginateTasksForSuperAdmin_findAllPaginateTasksForSuperAdmin_tasks
  | FindAllPaginateNotPendingTasksForSecretaries_findAllPaginateNotPendingTasksForSecretaries_tasks
  | FindAllPaginateTasksForCustomers_findAllPaginateTasksForCustomers_tasks
  | FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks
  | FindAllPaginateTasksForAdmins_findAllPaginateTasksForAdmins_tasks
  | FindAgendaTasksForSuperAdmin_findAgendaTasksForSuperAdmin
  | FindAgendaTasksForCustomers_findAgendaTasksForCustomers
  | FindAgendaTasksForAdmin_findAgendaTasksForAdmin
  | FindNotPendingAgendaTasksForSecretaries_findNotPendingAgendaTasksForSecretaries;
// methode retourne une tâche en filtrant avec l'id
export const getOneTask = (id: string, array: TaskArray[]) => {
  return (
    array.length > 0 &&
    array.filter((element: TaskArray) => element.id === id)[0]
  );
};

// all categories task type
export function GetAllCategoriesTaskTypeForTask() {
  const { data: dataFromQuery } = useQuery(
    GET_ALL_CATEGORY_TASKS_TYPE_WITH_TASK_TYPES,
  );
  const getAllCategoryTaskTypeWithTaskTypes = dataFromQuery
    ? ([
        ...dataFromQuery.getAllCategoryTaskTypeWithTaskTypes,
      ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[])
    : ([] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[]);
  return getAllCategoryTaskTypeWithTaskTypes;
}
// cntrat devis actif entre client et secrétaire
export function GetOneActiveQuoteAgreementBetweenCustomerAndSecretary(
  customerId: string,
  secretaryId: string,
) {
  const { data } = useQuery(
    GET_QUOTE_AGREEMENTS_BETWEEN_CUTOMER_AND_SECRETARY,
    {
      variables: { customerId, secretaryId },
    },
  );

  const getOneActiveQuoteAgreementBetweenCustomerAndSecretary = data
    ? ([
        ...data.getOneActiveQuoteAgreementBetweenCustomerAndSecretary,
      ] as getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary[])
    : ([] as getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary[]);
  return getOneActiveQuoteAgreementBetweenCustomerAndSecretary;
}

// TaskType Select options
export function getOptions(
  getAllCategory: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[],
) {
  const optionsToDisplay: OptionsSelect[] = [];
  const categoryArray = [
    ...getAllCategory,
  ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes[];
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  categoryArray &&
    categoryArray.map(
      (
        element: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes,
      ) => {
        const subOptionsArray: Option[] = [];
        const tasktypes = [
          ...element.taskTypes,
        ] as GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes[];
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        tasktypes &&
          tasktypes.map(
            (
              tasktype: GetAllCategoryTaskTypeWithTaskTypes_getAllCategoryTaskTypeWithTaskTypes_taskTypes,
            ) => {
              subOptionsArray.push({
                value: tasktype.id,
                label: tasktype.label,
              });
              return tasktype;
            },
          );
        optionsToDisplay.push({
          label: element.label,
          options: subOptionsArray,
        });
        return element;
      },
    );
  return optionsToDisplay;
}
// fonction qui calcule la différence entre les heures du contrats devis et les heures planifiées par une tâche,renvoie true si c' est negatif, false si c'est positif ou égal à zéro
export const differenceBetweenCurrentAmountAndPlannedDuration = (
  currentAmount: number,
  plannedDuration: number,
) => {
  if (Math.sign(currentAmount - plannedDuration) === -1) {
    return true;
  }
  return false;
};

// méthode qui calcule la somme des durées prévues pour une tâche et ses récurrences
export const sumOfOneTaskAndHisRecurrencesPlannedDuration = (
  plannedDuration: number,
  recurrence: Recurrence,
  endDateRecurrence: Date,
) => {
  const startDate = new Date();
  let nbOfRecurrence: number = 1;
  const WNbJours = endDateRecurrence.getTime() - startDate.getTime();
  if (recurrence === Recurrence.ALL_THE_MONTH) {
    nbOfRecurrence = Math.ceil(WNbJours / (1000 * 60 * 60 * 24 * 30));
  }
  if (recurrence === Recurrence.ALL_THE_WEEK) {
    nbOfRecurrence = Math.ceil(WNbJours / (1000 * 60 * 60 * 24 * 7));
  }
  if (recurrence === Recurrence.ALL_THE_DAY) {
    nbOfRecurrence = Math.ceil(WNbJours / (1000 * 60 * 60 * 24));
  }
  return Math.abs(Math.ceil(plannedDuration * nbOfRecurrence));
};
