import { useMutation } from "@apollo/client";
import { Modal, Label, TextInput } from "flowbite-react";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { SendPasswordResetRequestVariables } from "../API/models/types";
import { SEND_PASSWORD_RESET_REQUEST } from "../API/modules/user/resolvers/mutation";

interface ResetPasswordModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

type FormData = {
  email: string;
};

function ResetPasswordModal({ isOpen, setIsOpen }: ResetPasswordModalProps) {
  const { register, handleSubmit } = useForm<FormData>();

  // SEND PASSWORD RESET PASSWORD
  const [sendPasswordResetRequest, { error, data }] = useMutation(
    SEND_PASSWORD_RESET_REQUEST,
  );

  const onSubmit = async ({ email }: SendPasswordResetRequestVariables) => {
    try {
      await sendPasswordResetRequest({
        variables: { email },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err); // Handle the error
    }
  };

  return (
    <Modal size="md" onClose={() => setIsOpen(false)} show={isOpen} popup>
      <Modal.Header />
      <Modal.Body>
        <form
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <h3 className="text-xl font-medium">Changer son mot de passe</h3>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="email" value="Saisissez votre email" />
            </div>
            <TextInput
              id="email"
              placeholder="name@example.com"
              type="email"
              required
              {...register("email")}
            />
          </div>
          <div className="w-full">
            <button
              type="submit"
              className="button-add bg-add-blue-dark p-2 uppercase font-semibold"
            >
              Envoyer
            </button>
          </div>
          {error && (
            <p className="p-4 text-sm text-red-800 rounded-lg bg-red-50">
              Error: {error.message}
            </p>
          )}
          {data?.sendPasswordResetRequest?.resetPasswordToken && (
            <p className="p-4 text-sm text-green-800 rounded-lg bg-green-50">
              Vous venez de recevoir un email pour réinitialiser votre mot de
              passe.
            </p>
          )}
          {data?.sendPasswordResetRequest?.errors && (
            <p className="p-4 text-sm text-red-800 rounded-lg bg-red-50">
              {data.sendPasswordResetRequest.errors.join(", ")}
            </p>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ResetPasswordModal;
