/* eslint-disable import/prefer-default-export */
import dayjs from "dayjs";
import locale from "dayjs/locale/fr";

export function getMonth(month = dayjs().month(), year = dayjs().year()) {
  dayjs().locale({ ...locale });
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();

  let currentMonthCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      // eslint-disable-next-line no-plusplus
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
}
