import { useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { GET_ONE_USER_BY_ID } from "../../API/modules/user/resolvers/queries";
import { UserContext } from "../../context/userContext";
import { GetUserByID_getUserByID } from "../../API/models/types";
import SpinnerComponent from "../../components/shared/SpinnerComponent";
import EditMyAccountForm from "./EditMyAccountForm";
import SomethingHappened from "../SomethingHappened";

export default function EditMyAccount() {
  const { id } = useParams<string>();
  const { user } = useContext(UserContext);
  const { data, error, loading } = useQuery(GET_ONE_USER_BY_ID, {
    variables: {
      id,
    },
  });

  return !error ? (
    <div>
      {!loading ? (
        <div>
          {user !== null &&
          user &&
          user.role !== null &&
          data !== null &&
          data ? (
            <EditMyAccountForm
              data={data && (data.getUserByID as GetUserByID_getUserByID)}
              role={user.role}
            />
          ) : null}
        </div>
      ) : (
        <SpinnerComponent />
      )}
    </div>
  ) : (
    <SomethingHappened />
  );
}
