import { useMutation } from "@apollo/client";
import { ColumnDef } from "@tanstack/react-table";
import { useState, useMemo, useContext, useCallback } from "react";
import Table from "../../../../components/table/Table";
import {
  FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks,
  UpdateTaskInput,
} from "../../../../API/models/types";
import {
  EDIT_TASK,
  REMOVE_TASK,
  UPDATE_TASKS,
} from "../../../../API/modules/task/resolvers/mutations";
import {
  FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
  FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
  FIND_ALL_PAGINATE_TASKS_FOR_SUPER_ADMIN,
  FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
} from "../../../../API/modules/task/resolvers/queries";
import ConfirmTaskToggle from "../../../../components/shared/ConfirmTaskToggle";
import DropdownActionItem from "../../../../components/table/button/DropdownActionItem";
import DateCell from "../../../../components/table/cell-component/DateCell";
import UserCell from "../../../../components/table/cell-component/UserCell";
import { useUserContext } from "../../../../context/userContext";
import {
  ItemPendingTask,
  UserInformation,
  ItemNotPendingTask,
  FilterTask,
} from "../../../models/models";
import { getOneTask } from "../../../utils/QueriesTasks";
import ModalFilterSecretaryTableTasks from "../../modale/ModalFilterSecretaryTableTasks";
import ModalTaskRow from "../../modale/ModalTaskRow";
import UpdateTaskBySecretaryModal from "../../modale/UpdateTaskBySecretaryModal";
import FilterTasksContext, {
  FilterTasksContextType,
} from "../../../../context/FilterContext";

interface TablePendingTasksProps {
  getAllPendingTasks: FindAllPaginatePendingTasksForSecretaries_findAllPaginatePendingTasksForSecretaries_tasks[];
  data: ItemPendingTask[];
}
export default function TablePendingTasks(props: TablePendingTasksProps) {
  // contexte user
  const { user } = useUserContext();
  // contexte filtre
  const { filters } = useContext<FilterTasksContextType>(FilterTasksContext);

  const { data, getAllPendingTasks } = props;

  // Gestion du toggle pour passer plusieurs tâches en statut "A réaliser"
  const [taskToggles, setTaskToggles] = useState<string[]>([]);
  const setTaskToggle = useCallback((value: string, isActive: boolean) => {
    setTaskToggles((prevToggles) => {
      const updatedToggles = [...prevToggles];
      if (isActive) {
        updatedToggles.push(value);
      } else {
        const index = updatedToggles.indexOf(value);
        if (index > -1) {
          updatedToggles.splice(index, 1);
        }
      }
      return updatedToggles;
    });
  }, []);

  // modal view row table
  const [modalRow, setModalRow] = useState<boolean>(false);
  const [modalItemRow, setModalItemRow] = useState<any>();

  // Modal détail de la tâche : "edit"
  const [modalEditRow, setModalEditRow] = useState<boolean>(false);

  const cols = useMemo<ColumnDef<ItemPendingTask>[]>(
    () => [
      {
        header: "Tâches",
        cell: (row) => row.renderValue(),
        accessorKey: "label",
      },
      {
        header: "Date et heure",
        cell: (row) => {
          const value: string = row.getValue() as string;
          const isIso = true;
          return DateCell(value, isIso);
        },
        accessorKey: "startDate",
      },
      {
        header: "Durée prév.",
        cell: (row) => row.renderValue(),
        accessorKey: "plannedDuration",
        enableSorting: false,
      },
      {
        header: "Catégorie",
        cell: (row) => row.renderValue(),
        accessorKey: "category",
      },
      {
        header: "Client",
        cell: (row) => {
          const value: UserInformation = row.getValue() as UserInformation;
          return UserCell(value, false);
        },
        accessorKey: "customer",
      },
      {
        header: "Confirmer la tâche",
        enableSorting: false,
        cell: (getContext: any) => {
          return ConfirmTaskToggle({
            taskId: getContext.row.original.id,
            setTaskToggle,
          });
        },
        accessorKey: "isConfirmed",
      },
      {
        header: " ",
        cell: (getContext) => {
          return DropdownActionItem({
            pathToEditRow: "",
            mutationToEditRow: EDIT_TASK,
            mutationToRemoveRow: REMOVE_TASK,
            refetchQueries: FIND_ALL_PAGINATE_TASKS_FOR_SUPER_ADMIN,
            showDeleteRow: false,
            showEditRow: true,
            showViewRow: true,
            item: getOneTask(getContext.row.original.id, getAllPendingTasks),
            setModal: setModalRow,
            setModalEdit: setModalEditRow,
            setItem: setModalItemRow,
          });
        },
        accessorKey: "",
      },
    ],
    [getAllPendingTasks, setTaskToggle],
  );

  // ouverture fermeture fenetre modale pour filtrer la donnée
  const [modalFilter, setModalFilter] = useState(false);

  // Gestion de l'update des tasks au click sur "enregistrer"
  const [updateTasks] = useMutation<UpdateTaskInput>(UPDATE_TASKS);
  const handleClickOnSave = async () => {
    if (taskToggles.length > 0) {
      try {
        await updateTasks({
          variables: { updateTasksInput: { taskIds: taskToggles } },
          refetchQueries: [
            {
              query: FIND_ALL_PAGINATE_NOT_PENDING_TASKS_FOR_SECRETARIES,
              variables: {
                paginationArgs: filters as FilterTask,
                userId: user.id,
              },
            },
            {
              query: FIND_ALL_PAGINATE_PENDING_TASKS_FOR_SECRETARIES,
              variables: {
                paginationArgs: filters as FilterTask,
                userId: user.id,
              },
            },
            {
              query: FIND_NOT_PENDING_AGENDA_TASKS_FOR_SECRETARIES,
              variables: {
                input: {
                  userId: user.id,
                  startDate: new Date(),
                },
              },
            },
          ],
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setTaskToggles([]);
      }
    }
  };

  return (
    <div className="w-full h-full m-auto">
      <Table
        data={data as ItemPendingTask[]}
        columns={cols}
        showFooter
        showNavigation
        showGlobalFilter
        showButtonAddItem={false}
        showButtonActionItem
        showSelectDateFilter
        labelButtonActionItem="Enregistrer"
        labelButtonAddItem=""
        pathButtonAddItem=""
        handleActionButtonActionItem={handleClickOnSave}
        modalFilter={modalFilter}
        setModalFilter={setModalFilter}
        showFilterButton
      />
      <UpdateTaskBySecretaryModal
        showModal={modalEditRow}
        setShowModal={setModalEditRow}
        data={modalItemRow}
      />
      <ModalTaskRow open={modalRow} setOpen={setModalRow} item={modalItemRow} />
      <ModalFilterSecretaryTableTasks
        open={modalFilter}
        setOpen={setModalFilter}
        datas={data as ItemNotPendingTask[]}
      />
    </div>
  );
}
