import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

interface Props {
  setDateAndHourPicker: (value: Date) => void;
  /* eslint-disable react/require-default-props */
  dateDefaultValue?: Date | null;
  // eslint-disable-next-line react/no-unused-prop-types
  showAllDate?: boolean;
}
export default function DateAndHourPicker(props: Props) {
  dayjs.extend(utc);

  const { setDateAndHourPicker, dateDefaultValue, showAllDate = false } = props;
  const [dateSelected, setDateSelected] = useState<string>("");
  const [hourSelected, setHourSelected] = useState<string>("");
  const handleChangeDate = (e: any) => {
    setDateSelected(e.target.value);
    if (e.target.value !== "" && hourSelected !== "") {
      setDateAndHourPicker(
        dayjs.utc(new Date(`${e.target.value}T${hourSelected}:00`)).toDate(),
      );
    }
  };
  const handleChangeHour = (e: any) => {
    setHourSelected(e.target.value);
    if (dateSelected !== "" && e.target.value !== "") {
      setDateAndHourPicker(
        dayjs.utc(new Date(`${dateSelected}T${e.target.value}:00`)).toDate(),
      );
    }
  };
  // If update case, se default values
  useEffect(() => {
    if (dateDefaultValue) {
      // date to display
      const date = dayjs.utc(dateDefaultValue).local();
      const formattedDate = date.format("YYYY-MM-DD");
      setDateSelected(formattedDate);
      // time to display
      const formattedTime = date.format("HH:mm");
      setHourSelected(formattedTime);
    }
  }, [dateDefaultValue]);

  // Limiter la date sélectionnable à 2 ans dans le futur
  const maxYear = new Date().getFullYear() + 2;
  const maxDate = new Date(maxYear, 11, 31).toISOString().substring(0, 10);

  return (
    <div className="flex">
      <div className="mr-2">
        {showAllDate ? (
          <input
            className="flex-shrink-0 z-10 datepicker-closed inline-flex items-center py-2.5 px-4 text-sm font-medium bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
            type="date"
            value={dateSelected}
            onChange={handleChangeDate}
            max={maxDate}
          />
        ) : (
          <input
            className="flex-shrink-0 z-10 datepicker-closed inline-flex items-center py-2.5 px-4 text-sm font-medium bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
            type="date"
            value={dateSelected}
            onChange={handleChangeDate}
            min={new Date().toISOString().substring(0, 10)}
            max={maxDate}
          />
        )}
      </div>
      <div className="">
        <input
          className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium bg-gray-100 border border-gray-300 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 rounded-lg"
          type="time"
          value={hourSelected}
          onChange={handleChangeHour}
        />
      </div>
    </div>
  );
}
