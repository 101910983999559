import { DocumentNode, gql } from "@apollo/client";

export const DOWNLOAD_CSV_QUOTE_AGREEMENTS_LIST: DocumentNode = gql`
  query DownloadCsvQuoteAgreementsTasksList(
    $role: UserRole!
    $userId: String!
  ) {
    downloadCsvQuoteAgreementsList(role: $role, userId: $userId) {
      csvData
    }
  }
`;
export const GET_ALL_QUOTE_AGREEMENTS: DocumentNode = gql`
  query GetAllQuoteAgreements {
    getAllQuoteAgreements {
      id
      createdAt
      updatedAt
      label
      hourlyAmount
      currentAmount
      reference
      description
      isActive
      url
      user {
        id
        createdAt
        first_name
        admin {
          id
        }
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
      frameworkAgreement {
        id
        createdAt
        label
        reference
        url
        description
        isActive
        user {
          id
          admin {
            id
          }
          createdAt
          updatedAt
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
      }
    }
  }
`;
export const GET_QUOTE_AGREEMENTS_BETWEEN_CUTOMER_AND_SECRETARY: DocumentNode = gql`
  query getOneActiveQuoteAgreementBetweenCustomerAndSecretary(
    $customerId: String!
    $secretaryId: String!
  ) {
    getOneActiveQuoteAgreementBetweenCustomerAndSecretary(
      secretaryId: $secretaryId
      customerId: $customerId
    ) {
      id
      createdAt
      updatedAt
      label
      hourlyAmount
      currentAmount
      reference
      description
      isActive
      url
      user {
        id
        createdAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
      frameworkAgreement {
        id
        createdAt
        label
        reference
        url
        description
        isActive
        user {
          id
          createdAt
          updatedAt
          admin {
            id
          }
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
      }
    }
  }
`;
export const GET_ALL_QUOTE_AGREEMENTS_FOR_CUSTOMER: DocumentNode = gql`
  query getAllQuoteAgreementsForCustomer($customerId: String!) {
    getAllQuoteAgreementsForCustomer(customerId: $customerId) {
      id
      createdAt
      updatedAt
      label
      hourlyAmount
      currentAmount
      reference
      description
      isActive
      url
      user {
        id
        createdAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
      frameworkAgreement {
        id
        createdAt
        label
        reference
        url
        description
        isActive
        user {
          id
          createdAt
          updatedAt
          admin {
            id
          }
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
      }
    }
  }
`;
export const GET_ALL_QUOTE_AGREEMENTS_FOR_ADMIN: DocumentNode = gql`
  query GetAllQuoteAgreementsForAdmin($adminId: String!) {
    getAllQuoteAgreementsForAdmin(adminId: $adminId) {
      id
      createdAt
      updatedAt
      label
      hourlyAmount
      currentAmount
      reference
      description
      isActive
      url
      user {
        id
        createdAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
      frameworkAgreement {
        id
        createdAt
        label
        reference
        url
        description
        isActive
        user {
          id
          createdAt
          updatedAt
          admin {
            id
          }
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
      }
    }
  }
`;
export const GET_ALL_QUOTE_AGREEMENTS_FOR_SECRETARY: DocumentNode = gql`
  query GetAllQuoteAgreementsForSecretary($secretaryId: String!) {
    getAllQuoteAgreementsForSecretary(secretaryId: $secretaryId) {
      id
      createdAt
      updatedAt
      label
      hourlyAmount
      currentAmount
      reference
      description
      isActive
      url
      user {
        id
        createdAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
      frameworkAgreement {
        id
        createdAt
        label
        reference
        url
        description
        isActive
        user {
          id
          createdAt
          updatedAt
          admin {
            id
          }
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
      }
    }
  }
`;
export const GET_ONE_QUOTE_AGREEMENTS: DocumentNode = gql`
  query GetOneQuoteAgreement($id: String!) {
    getOneQuoteAgreement(id: $id) {
      id
      createdAt
      updatedAt
      label
      hourlyAmount
      currentAmount
      reference
      description
      isActive
      url
      user {
        id
        createdAt
        updatedAt
        admin {
          id
        }
        first_name
        last_name
        avatar_url
        phone_number
        email
        geographical_area
        siret
        business_name
        experience
        criticity_request
        commentary
        isActive
        isVerified
        role
        taskTypes {
          id
          label
          categoryTaskType {
            id
            label
          }
        }
      }
      frameworkAgreement {
        id
        createdAt
        updatedAt
        label
        reference
        url
        description
        isActive
        user {
          id
          createdAt
          updatedAt
          admin {
            id
          }
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
      }
    }
  }
`;
