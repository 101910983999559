/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import { Label, TextInput } from "flowbite-react";
import nosengagements from "../assets/illustrations/nosengagements.png";
import { RESET_PASSWORD } from "../API/modules/user/resolvers/mutation";
import { ResetPasswordVariables } from "../API/models/types";

type ResetPasswordParams = {
  token: string;
} & Record<string, string | undefined>;

export interface ResetPasswordFormData {
  newPassword: string;
  newPasswordRepeated: string;
  resetPasswordToken: string;
}

type UseFormReturnWithErrors<T extends ResetPasswordFormData> =
  UseFormReturn<T> & {
    errors: Record<keyof T, any>;
  };

function ResetPassword() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  // Je récupère le resetPasswordToken dans l'url (le lien qui était dans l'email utilisateur)
  const { resetPasswordToken } = useParams<ResetPasswordParams>();

  // Je gère les inputs de mon form
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordFormData>({
    mode: "onChange",
  }) as UseFormReturnWithErrors<ResetPasswordFormData>;

  // Mutation
  const [resetPassword, { error }] = useMutation(RESET_PASSWORD);
  const onSubmit: SubmitHandler<ResetPasswordFormData> = async (
    data: ResetPasswordVariables,
  ) => {
    try {
      const response = await resetPassword({
        variables: {
          resetPasswordToken,
          newPassword: data.newPassword,
        },
      });
      if (response) {
        setSuccessMessage("Votre mot de passe a été changé avec succès.");
        setTimeout(() => {
          setSuccessMessage(null);
          navigate("/login");
        }, 3000);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Erreur lors du changement de mot de passe :", err);
    }
  };

  return (
    <main className="min-w-screen min-h-screen flex items-center justify-center px-5 py-5">
      <div className="rounded-3xl shadow-xl lg:w-1/2 overflow-hidden h-[600px]">
        <div className="md:flex w-full h-full">
          <img
            alt="Atelier du dirigeant app illustration"
            className="lg:w-1/3 w-full lg:h-auto h-64 object-contain object-left bg-add-green-light hidden lg:block"
            src={nosengagements}
          />
          <form
            className="w-full py-14 lg:py-20 px-14"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="mb-6 text-2xl md:text-3xl lg:text-4xl">
              Changement de mot de passe
            </h1>
            <div className="mb-6 flex flex-col gap-y-3">
              <Label htmlFor="newPassword" value="Nouveau mot de passe" />
              <TextInput
                id="newPassword"
                placeholder="••••••••"
                type="password"
                {...register("newPassword", {
                  required: true,
                  pattern:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                })}
              />
              {errors.newPassword && errors.newPassword.type === "required" && (
                <span
                  className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  Ce champ est requis
                </span>
              )}
              {errors.newPassword && errors.newPassword.type === "pattern" && (
                <span
                  className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  Le mot de passe doit contenir au moins 8 caractères, dont une
                  majuscule, une minuscule et un chiffre.
                </span>
              )}
            </div>
            <div className="mb-6 flex flex-col gap-y-3">
              <Label
                htmlFor="newPasswordRepeated"
                value="Répétez le nouveau mot de passe"
              />
              <TextInput
                id="newPasswordRepeated"
                placeholder="••••••••"
                type="password"
                {...register("newPasswordRepeated", {
                  required: true,
                  validate: (value) => value === watch("newPassword"),
                })}
              />
              {errors.newPasswordRepeated &&
                errors.newPasswordRepeated.type === "required" && (
                  <span
                    className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    Ce champ est requis
                  </span>
                )}
              {errors.newPasswordRepeated && (
                <span
                  className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  Les mots de passe ne correspondent pas
                </span>
              )}
              {error?.message && (
                <span
                  className="p-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  {error.message.includes("15mn")
                    ? error.message
                    : "Une erreur est survenue. Veuillez réessayer plus tard."}
                </span>
              )}
              {successMessage && (
                <span
                  className="p-4 text-sm text-green-800 rounded-lg bg-green-50"
                  role="alert"
                >
                  {successMessage}
                </span>
              )}
            </div>
            <input
              type="submit"
              className="w-full bg-add-blue-dark p-2 uppercase font-semibold button-add cursor-pointer"
            />
          </form>
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
