import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import locale from "dayjs/locale/fr";
import isoWeek from "dayjs/plugin/isoWeek";

export interface HourMatrix {
  day: dayjs.Dayjs;
  hours: dayjs.Dayjs[];
}

export type DaysMatrix = HourMatrix[];

// weakIndex représente le numéro de la semaine calendaire
// Initialisé à la semaine actuelle dans le context calendarContext
export function getWeek(
  weekIndex: number,
  yearIndex = dayjs().year(),
): DaysMatrix {
  dayjs().locale({ ...locale });
  dayjs.extend(utc);
  dayjs.extend(isoWeek);

  // Obtenir l'année en cours
  const currentIsoWeekYear = yearIndex;

  // Utilisation du format ISO pour définir le début de la semaine sur un lundi
  const firstWeekStartOfYear = dayjs()
    .year(currentIsoWeekYear)
    .startOf("year")
    .startOf("isoWeek");

  // Calculer le début de la semaine souhaitée en fonction de weekIndex
  const weekStart = firstWeekStartOfYear.add((weekIndex - 1) * 7, "day");

  // Créer un tableau contenant les informations sur les 7 jours de la semaine souhaitée
  const daysMatrix = new Array(7).fill(null).map((_, dayIndex) => {
    // Obtenir le jour courant en ajoutant l'index (0 à 6) au début de la semaine
    const currentDay = weekStart.clone().add(dayIndex, "day");

    // Créer un tableau contenant les heures de travail (de 8h00 à 18h00) pour le jour courant
    const hoursMatrix = new Array(14).fill(null).map((hour, hourIndex) => {
      // Obtenir l'heure en ajoutant hourIndex (0 à 10) à 8h00 du jour courant
      return currentDay.hour(7 + hourIndex);
    });

    // Retourner un objet contenant le jour courant et le tableau des heures de travail
    return {
      day: currentDay,
      hours: hoursMatrix,
    };
  });

  return daysMatrix;
}
