import { FormEvent } from "react";
import { CustomForm } from "../../../pages/models/models";

/* eslint-disable prefer-template */
interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onSubmit"> {
  value: string;
  setValue: (value: string) => void;
  onSubmit: (event: FormEvent<CustomForm>) => void;
}

function SearchBar({ onSubmit, value, setValue }: Props) {
  // const [inputValue, setInputValue] = useState(value);
  return (
    <form onSubmit={onSubmit}>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>

        <input
          type="text"
          id="search"
          className="text-xs block w-full p-3 pl-10  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="..."
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />

        <button
          type="submit"
          className="button-add bg-add-blue-dark text-xs absolute w-auto right-1 bottom-1.5 font-medium rounded-lg x-4 py-2"
        >
          Recherche
        </button>
      </div>
    </form>
  );
}
export default SearchBar;
