import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// eslint-disable-next-line import/no-cycle
import useLocalStorageService from "../hooks/useLocalStorage";
import { UserRole } from "../API/models/types";

export interface IContextUser {
  token: string | null;
  email: string | null;
  isActive: boolean;
  role: UserRole | null;
  id: string | null;
  avatarUrl: string | null;
}

export const emptyUser: IContextUser = {
  token: null,
  email: null,
  isActive: false,
  role: null,
  id: null,
  avatarUrl: null,
};

export type UserContextType = {
  user: IContextUser;
  setUser: (newValue: IContextUser) => void;
};

const initialContext: UserContextType = {
  user: emptyUser,
  setUser: (newValue: IContextUser) => {
    initialContext.user = newValue;
  },
};

export const UserContext = createContext<UserContextType>(initialContext);

// Le composant ContextProvider permet de nous brancher sur le Contexte UserContext
// i.e : le contexte UserContext sera accessible dans les composants enfant de ContextProvider
export function ContextProvider({ children }: { children: React.ReactNode }) {
  const { getUser } = useLocalStorageService();
  const userFromLocalStorage = getUser();
  const [user, setUserState] = useState<IContextUser>(userFromLocalStorage);

  const setUser = useCallback((newValue: IContextUser) => {
    setUserState(newValue);
  }, []);

  // When Wrap the Context value inside of useMemo,
  // all values will be memoized, and useMemo will
  // only recompute the memoized value when one of the dependencies has changed.
  const contextValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  // Effect that retrieves the user from the local storage on mount
  useEffect(() => {
    if (userFromLocalStorage) {
      setUser(userFromLocalStorage);
    }
  }, [setUser, userFromLocalStorage]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

// on prépare un hook custom qui nous permet de récupérer le contexte UserContext
export const useUserContext = () => useContext<UserContextType>(UserContext);
