import { ColorClasses } from "../../components/shared/ColorPicker";

// state
// eslint-disable-next-line import/prefer-default-export
export const colorsClasses: ColorClasses = {
  teal: "#14B8A6",
  blue: "#2563eb",
  indigo: "#6366F1",
  gray: "#6B7280",
  green: "#10B981",
  red: "#EF4444",
  purple: "#8B5CF6",
  fuchsia: "#D946EF",
};
