import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { GetCustomerDashbouardData_getCustomerDashboardData_taskData } from "../../../../API/models/types";

interface TasksTypeOverviewChartProps {
  taskData: GetCustomerDashbouardData_getCustomerDashboardData_taskData[];
  colors: string[];
}

function TasksTypeOverviewChart({
  taskData,
  colors,
}: TasksTypeOverviewChartProps) {
  const [series, setSeries] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    if (taskData) {
      const taskNames: string[] = [];
      const taskCounts: number[] = [];
      taskData.forEach(
        (task: GetCustomerDashbouardData_getCustomerDashboardData_taskData) => {
          taskNames.push(task.label);
          taskCounts.push(task.percentage);
        },
      );

      setLabels(taskNames);
      setSeries(taskCounts);
    }
  }, [taskData]);

  const options: ApexCharts.ApexOptions = {
    labels,
    colors,
    chart: {
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      colors: ["#fff"],
    },
    plotOptions: {
      pie: {
        donut: {
          size: "5%",
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9,
        },
      },
    },
    tooltip: {
      shared: true,
      followCursor: false,
      fillSeriesColor: false,
      inverseOrder: true,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
      x: {
        show: true,
        formatter(_, { seriesIndex, w }) {
          const label = w.config.labels[seriesIndex];
          return label;
        },
      },
      y: {
        formatter(value) {
          return `${value}%`;
        },
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return series.length > 0 ? (
    <Chart height={305} options={options} series={series} type="donut" />
  ) : null;
}

export default TasksTypeOverviewChart;
