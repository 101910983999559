import { DocumentNode, gql } from "@apollo/client";

export const EDIT_FRAMEWORK_AGREEMENT: DocumentNode = gql`
  mutation UpdateFrameworkAgreement($input: FrameworkAgreementUpdateInput!) {
    updateFrameworkAgreement(input: $input) {
      frameworkAgreement {
        id
        createdAt
        updatedAt
        label
        reference
        url
        description
        isActive
        user {
          id
          createdAt
          updatedAt
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_FRAMEWORK_AGREEMENT: DocumentNode = gql`
  mutation RemoveFrameworkAgreement($id: String!) {
    removeFrameworkAgreement(id: $id)
  }
`;

export const CREATED_FRAMEWORK_AGREEMENT: DocumentNode = gql`
  mutation CreateFrameworkAgreement($input: FrameworkAgreementCreateInput!) {
    createFrameworkAgreement(input: $input) {
      frameworkAgreement {
        id
        createdAt
        updatedAt
        label
        reference
        url
        description
        isActive
        user {
          id
          createdAt
          updatedAt
          first_name
          last_name
          avatar_url
          phone_number
          email
          geographical_area
          siret
          business_name
          experience
          criticity_request
          commentary
          isActive
          isVerified
          role
          taskTypes {
            id
            label
            categoryTaskType {
              id
              label
            }
          }
        }
      }
    }
  }
`;
