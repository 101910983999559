import { useEffect } from "react";
import { getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary } from "../../../API/models/types";
import { GetOneActiveQuoteAgreementBetweenCustomerAndSecretary } from "../../utils/QueriesTasks";

interface Props {
  customerId: string;
  secretaryId: string;

  setQuoteAgreement: (
    value: getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary | null,
  ) => void;
}
export default function InputReferenceContract(props: Props) {
  const { customerId, secretaryId, setQuoteAgreement } = props;

  const quoteAgreement = GetOneActiveQuoteAgreementBetweenCustomerAndSecretary(
    customerId,
    secretaryId,
  );

  useEffect(() => {
    if (quoteAgreement && quoteAgreement.length === 1) {
      setQuoteAgreement(
        quoteAgreement[0] as getOneActiveQuoteAgreementBetweenCustomerAndSecretary_getOneActiveQuoteAgreementBetweenCustomerAndSecretary,
      );
    } else {
      setQuoteAgreement(null);
    }
  }, [quoteAgreement, setQuoteAgreement, customerId, secretaryId]);

  return (
    <input
      type="text"
      id="contract"
      readOnly
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      disabled
      value={
        quoteAgreement.length > 0 ? (quoteAgreement[0].label as string) : ""
      }
    />
  );
}
